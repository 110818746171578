<template>
    <vx-card v-if="!this.authenticatedUser.privacy_policy" class="mb-base">
        <div class="h5 text-danger mb-1 mt-2">Please complete your registration</div>
        <form @submit.prevent="updateUser" id="privacy_policy_form">
          <vs-checkbox v-model="accountForm.privacy_policy" class="mt-4">I agree to <a @click.stop target="_blank" href="https://ergmonkey.com/terms">T.O.S.</a> and <a @click.stop target="_blank" href="https://ergmonkey.com/privacy">Privacy Policy</a></vs-checkbox>
          <span class="text-danger text-sm" v-if="formControlHasError(accountForm, 'privacy_policy')">{{ formControlGetError(accountForm, 'privacy_policy') }}</span>

          <vs-checkbox v-model="accountForm.receive_marketing_updates" class="mt-4">I agree to receive marketing updates</vs-checkbox>
          <span class="text-danger text-sm" v-if="formControlHasError(accountForm, 'receive_marketing_updates')">{{ formControlGetError(accountForm, 'receive_marketing_updates') }}</span>

          <!-- Save & Reset Button -->
          <div class="flex-wrap items-center justify-end mt-2">
            <vs-button class="ml-auto mt-2" button="submit">Save</vs-button>
          </div>
        </form>
    </vx-card>
</template>

<script>
    import {mapGetters} from "vuex";
    import moment from "moment";
    import Form from 'vform';
    import FormErrors from '@/components/mixins/FormErrors'

    export default {
        name: 'privacy-policy-widget',

        components: {},
        data: () =>({
            accountForm: new Form({
                privacy_policy: '',
                receive_marketing_updates: '',
              }),
        }),
        mixins: [FormErrors],
        computed: mapGetters({
          authenticatedUser: 'auth/getAuthenticatedUser',
        }),
        methods: {
          async updateUser() {
            this.formResetErrors(this.accountForm);

            this.accountForm.post('/users/me/update-privacy-policy')
              .then(({data}) => {
                this.$vs.notify({
                  text: data.message,
                  color:'success',
                  position:'top-right',
                  time:5000,
                });

                this.$store.dispatch('auth/fetchUser');
              })
              .catch(error => {
                this.$vs.notify({
                  text: error.response.data.message,
                  color:'danger',
                  position:'top-right',
                  time:5000,
                });
              });
          }
        },
        created() {
        },
        mounted() {
          this.accountForm.privacy_policy = this.authenticatedUser.privacy_policy;
          this.accountForm.receive_marketing_updates = this.authenticatedUser.receive_marketing_updates;
        }
    }
</script>

<style lang="scss">
  #privacy_policy_form {
    .vs-checkbox--input {
      width: 5%;
    }
  }
</style>
