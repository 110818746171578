<template>
  <vx-card title="Summary List">
      <ag-summary-list :items="items" />
  </vx-card>
</template>


<script>
    import AgSummaryList from "./AgSummaryList";
    export default {
        name: 'summary-list',

        components: {AgSummaryList},
        props: {
            items: {
                type: [Array, Object],
                default: () => []
            }
        },
    }
</script>
