<template>
  <vx-card>
    <template slot="title">
      <router-link :to="{ name: 'public-workout-plans.show', params: { id: item.id }}"><h4 class="">{{ item.name }}</h4></router-link>
      <h6 class="" style="color: rgb(184, 194, 204);">{{ item.description | truncate(25) }}</h6>
    </template>
    <template slot="actions">
      <template v-if="item.user_id == this.authenticatedUser.id">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <small class="flex cursor-pointer">
            <feather-icon icon="MoreVerticalIcon" svgClasses="w-6 h-6 text-grey"></feather-icon>
          </small>
          <vs-dropdown-menu class="w-32">
            <vs-dropdown-item :to="{ name: 'workout-plans.edit', params: { id: item.id }}">Edit</vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template v-else>

      </template>
    </template>
    <router-link :to="{ name: 'public-workout-plans.show', params: { id: item.id }}"><img :src="item.image != null ? item.image_url_256 : 'https://api.ergmonkey.com/img/placeholders/workout_plans_image-256.jpg'" class="responsive rounded-lg"></router-link>
    <div class="flex mt-6 flex-wrap" v-if="false">
      <span class="flex mr-6">
        <feather-icon class="cursor-pointer" icon="UsersIcon" svgClasses="text-success stroke-current h-6 w-6" />
        <span class="ml-2">111</span>
      </span>
    </div>
  </vx-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'single-workout-plan-card',
  props: {
    item: {
      type: [Object],
      default: () => {}
    },
  },
  computed: mapGetters({
    authenticatedUser: 'auth/getAuthenticatedUser',
  }),
  methods: {}
}
</script>

