<!-- =========================================================================================
    File Name: ForgotPassword.vue
    Description: FOrgot Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="h-screen flex w-full bg-img">
        <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="vx-row">
                        <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                            <img src="@/assets/images/pages/forgot-password.png" alt="login" class="mx-auto">
                        </div>
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
                          <form @submit.prevent="onResetPasswordFormSubmit">
                            <div class="p-8">
                                <div class="vx-card__title mb-4">
                                    <h4 class="mb-4">Reset Password</h4>
                                    <p>Enter data to reset your password.</p>
                                </div>

                                <vs-input type="email" label="Email" v-model="resetPasswordForm.email" class="w-full" />
                                <span class="text-danger text-sm" v-if="formControlHasError(resetPasswordForm, 'email')">{{ formControlGetError(resetPasswordForm, 'email') }}</span>

                                <vs-input
                                  type="password"
                                  name="password"
                                  label="Password"
                                  v-model="resetPasswordForm.password"
                                  class="w-full mt-2" />
                                <span class="text-danger text-sm" v-if="formControlHasError(resetPasswordForm, 'password')">{{ formControlGetError(resetPasswordForm, 'password') }}</span>

                                <vs-input
                                  type="password"
                                  name="password_confirmation"
                                  label="Password Confirmation"
                                  v-model="resetPasswordForm.password_confirmation"
                                  class="w-full mt-2" />
                                <span class="text-danger text-sm" v-if="formControlHasError(resetPasswordForm, 'password_confirmation')">{{ formControlGetError(resetPasswordForm, 'password_confirmation') }}</span>

                                <div class="mt-8">
                                  <vs-button type="border" :to="{ name: 'web.auth.login' }" class="px-4 w-full md:w-auto">Back To Login</vs-button>
                                  <vs-button button="submit" class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0" :pressed.sync="resetPasswordForm.busy">Submit</vs-button>
                                </div>

                            </div>
                          </form>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>

      <vs-popup class="holamundo"  title="Reset Password" :active.sync="resetPasswordSuccessModalShow">
        <p>{{ resetPasswordSuccessModalText }}</p>
      </vs-popup>
    </div>
</template>

<script>
  import Form from 'vform'
  import FormErrors from '@/components/mixins/FormErrors'

  export default {
    // Set meta tags
    head: {
      title: {
        inner: 'Reset Password'
      }
    },

    mixins: [
      FormErrors
    ],

    data: () => ({
      resetPasswordForm: new Form({
        token: '',
        email: '',
        password: '',
        password_confirmation: ''
      }),

      resetPasswordSuccessModalText: '',
      resetPasswordSuccessModalShow: false
    }),

    beforeMount: async function () {
      await this.$store.dispatch('auth/logout');
    },

    methods: {
      async onResetPasswordFormSubmit() {
        this.formResetErrors(this.resetPasswordForm);

        this.resetPasswordForm.token = this.$route.params.token || '';

        this.resetPasswordForm.post('/auth/reset-password')
          .then(({data}) => {
            this.resetPasswordSuccessModalText = data.message;
            this.resetPasswordSuccessModalShow = true;
            this.resetPasswordForm.reset();
          })
          .catch(error => {
            if (error.response && error.response.status && error.response.status !== 422 && error.response.data && error.response.data.message) {
              this.$vs.notify({
                text: error.response.data.message,
                color:'danger',
                position:'top-right',
                time:5000,
              });
            }

            this.$scrollTo('#form-reset-password');
          });
      }
    }
  }
</script>
