<template>
  <v-select :multiple="multiple" :filterable="false" :options="newOptions" @search="onSearch" @input="onChange">
    <template slot="no-options">
      type to search
    </template>
  </v-select>
</template>

<script>
  import {mapGetters} from 'vuex'
  import $ from 'jquery'
  import vSelect from 'vue-select'
  import axios from "axios";

  export default {
    name: 'VueSelectAjax',

    components: {
      vSelect,
    },

    data() {
      return {
        newOptions: [],
      }
    },

    model: {
      event: 'change',
      prop: 'value'
    },

    props: {
      allowClear: {
        type: Boolean,
        default: true
      },

      httpType: {
        type: String,
        default: 'GET'
      },

      itemID: {
        type: String,
        default: ''
      },

      itemText: {
        type: String,
        default: ''
      },

      itemImage: {
        type: String,
        default: ''
      },

      multiple: {
        type: Boolean,
        default: false
      },

      options: {
        type: Array,
        default: () => []
      },

      placeholder: {
        type: String,
        default: ''
      },

      url: {
        type: String,
        default: ''
      },

      value: null,
    },

    computed: {
      ...mapGetters({
        accessToken: 'auth/getAccessToken',
      }),
    },

    watch: {},


    methods: {
      onSearch(search, loading) {
        let self = this;
        loading(true);
        $.ajax({
          cache: true,
          data: $.param({
            client_id: process.env.VUE_APP_API_CLIENT_ID || '',
            client_secret: process.env.VUE_APP_API_CLIENT_SECRET || '',
            page: 1,
            per_page: 10,
            term: search,
            'filters[term]': search,
          }),
          dataType: 'json',
          delay: 250,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Access-Control-Allow-Origin, Origin, X-Requested-With, Content-Type, Accept, Authorization, Access-Control-Allow-Headers',
            'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + self.accessToken
          },
          type: self.httpType,
          url: (process.env.VUE_APP_API_URL || '') + self.url
        }).then(function (data) {
          let parsed = [];
          parsed = data.data.map(function (item) {
            return {
              id: item[self.itemID],
              label: (self.itemImage ? '<img src="' + item[self.itemImage] + '" height="20" width="20" class="margin-right-5"/>' : '') + item[self.itemText]
            };
          });
          loading(false);
          self.newOptions = parsed;
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color: 'danger',
            position: 'top-right',
            time: 5000,
          });
        });
      },
      onChange(val) {
        this.$emit('change', val.id);
      },
      initSelect(options) {
      },

      getPreselectedOptions() {
        let self = this, select = $(this.$el);

        if ((self.multiple && this.value.length > 0) || this.value.toString().length > 0) {
          $.ajax({
            cache: true,
            data: $.param({
              client_id: process.env.VUE_APP_API_CLIENT_ID || '',
              client_secret: process.env.VUE_APP_API_CLIENT_SECRET || '',
              page: 1,
              per_page: 10,
              term: '',
              'filters[term]': '',
              'filters[ids]': this.value
            }),
            dataType: 'json',
            delay: 250,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/x-www-form-urlencoded',
              'Access-Control-Allow-Credentials': 'true',
              'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Access-Control-Allow-Origin, Origin, X-Requested-With, Content-Type, Accept, Authorization, Access-Control-Allow-Headers',
              'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
              'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer ' + self.accessToken
            },
            type: self.httpType,
            url: (process.env.VUE_APP_API_URL || '') + self.url
          }).then(function (data) {
            if (data.data && data.data.length > 0) {
              let option = '';
              for (let i = 0; i < data.data.length; i++) {
                option = {
                  label: self.itemImage ? '<img src="' + data.data[i][self.itemImage] + '" height="20" width="20" class="margin-right-5"/>' : ''+ data.data[i][self.itemText],
                  id: data.data[i][self.itemID],
                  };

                self.newOptions.push(option);
              }
            }
          });
        }
      }
    },

    mounted: async function () {
      let self = this;
      // await this.initSelect(this.options);

      await this.getPreselectedOptions();
    },

    beforeDestroy() {
      //$(this.$el).off().select2('destroy');
    }
  };
</script>
