<template>
  <vx-card title="History Comparison">
    <template v-if="false">
      <upgrade-message-only title="To see History Comparison upgrade to the Standard Plan"></upgrade-message-only>
    </template>
    <template v-else>
      <template v-if="items.length > 0">
          <public-history-comparison-table :items="items"/>
      </template>
      <template v-else>
        There are no workouts
      </template>
    </template>
  </vx-card>
</template>
<script>
  import axios from 'axios'
  import moment from "moment";
  import {mapGetters} from "vuex";
  import UpgradeMessageOnly from "../global/UpgradeMessageOnly";
  import PublicHistoryComparisonTable from "./PublicHistoryComparisonTable";
  //import { each} from 'lodash';

  export default {
    name: 'public-history-comparasion',

    components: {PublicHistoryComparisonTable, UpgradeMessageOnly},
    props: {
      result: {
        type: [Array, Object],
        default: () => []
      }
    },
    data() {
      return {
        loaded: false,
        items: [],
        title: '',
        allowedPlans: [2,3,4,5,6,7,8,9,10,11],
      }
    },
    methods: {
      getHistoryResults() {
        axios.get('public-results/' + this.result.id + '/simillar?per_page=5&order_by=date&order_direction=desc').then((data) => {
          this.loaded = true;
          this.items = data.data.results.data;

          var i = 0;
          for (i; i < this.items.length; i++) {
            this.items[i].difference = 0;
            if (this.items[i + 1] != undefined && this.items[i + 1].power != 0) {
              this.items[i].difference = (this.items[i].power * 100 / this.items[i + 1].power) - 100;

            }
          }
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      }
    },
    created() {
        this.getHistoryResults();
    }
  };
</script>
