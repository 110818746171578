export default [
  //Auth routes
  ...middleware('auth', [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./../layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {path: '/dashboard', name: 'account.home.index', component: require('@/views/Dashboard').default, meta: {label: 'Dashboard'}},

        {path: '/calendar', name: 'workouts.index', component: require('@/views/pages/calendar/Index').default, meta: {label: 'Calendar'}},

        //settings
        {path: '/settings', name: 'account.settings', component: require('@/views/pages/settings/UserSettings').default,
          meta: {
            pageTitle: 'Settings',
          }
        },
        {path: '/me/account', name: 'account.account.edit', component: require('@/views/pages/settings/UserSettings').default, meta: {pageTitle: 'Settings'}},
        {path: '/me/integrations', name: 'account.integrations.edit', component: require('@/views/pages/settings/UserSettings').default, meta: {}},
        {path: '/me/integrations/logbook/connect', name: 'account.integrations.connect_logbook', component: require('@/views/pages/settings/ConnectLogbook').default, meta: {}},
        {path: '/me/integrations/rp3/connect', name: 'account.integrations.connect_rp3', component: require('@/views/pages/settings/ConnectRp3').default, meta: {}},
        {path: '/me/notification-settings', name: 'account.notifications.edit', component: require('@/views/pages/settings/UserSettings').default, meta: {}},
        {path: '/me/billing', name: 'account.billing.edit', component: require('@/views/pages/settings/UserSettings').default, meta: {}},
        {path: '/me/preferences', name: 'account.preferences.edit', component: require('@/views/pages/settings/UserSettings').default, meta: {}},

        {path: '/results/:id', name: 'results.show', component: require('@/views/pages/results/Show').default, meta: {pageTitle: ' '}},
        {path: '/planned-workouts/:id', name: 'planned-workouts.show', component: require('@/views/pages/results/PlannedWorkoutShow').default, meta: {pageTitle: ' '}},

        //Groups
        {path: '/groups/my', name: 'groups.index', component: require('@/views/pages/groups/Index').default, meta: {pageTitle: 'My Groups'}},
        {path: '/groups/create', name: 'groups.create', component: require('@/views/pages/groups/Create').default, meta: {pageTitle: 'New Group'}},
        {path: '/group/:id/edit', name: 'groups.edit', component: require('@/views/pages/groups/Edit').default, meta: {pageTitle: 'Edit Group'}},

        //reports
        {path: '/reports/personal-bests', name: 'reports.personal_best', component: require('@/views/pages/reports/PersonalBest').default, meta: {pageTitle: 'Personal Bests'}},
        {path: '/reports/trend', name: 'reports.trend', component: require('@/views/pages/reports/Trend').default, meta: {pageTitle: 'Trend Report'}},
        {path: '/reports/lockdown-meters', name: 'reports.lockdown_meters', component: require('@/views/pages/reports/LockdownMeters').default, meta: {pageTitle: 'Lockdown Meters'}},
        {path: '/reports/heart-rate/:id?', name: 'reports.heart_rate', component: require('@/views/pages/reports/HeartRate').default, meta: {pageTitle: 'Heart Rate Report'}},
        {path: '/reports/stats', name: 'reports.stats', component: require('@/views/pages/reports/Stats').default, meta: {pageTitle: 'Stats Report'}},
        {path: '/reports/progress/:id?', name: 'reports.progress', component: require('@/views/pages/reports/Progress').default, meta: {pageTitle: 'Progress Report'}},
        {path: '/reports/performance/:id?', name: 'reports.performance', component: require('@/views/pages/reports/Performance').default, meta: {pageTitle: 'Performance Report'}},
        {path: '/reports/comparison/:id?', name: 'reports.comparison', component: require('@/views/pages/reports/Comparison').default, meta: {pageTitle: 'Comparison Report'}},
        {path: '/reports/benchmark/:id?', name: 'reports.benchmark', component: require('@/views/pages/reports/Benchmark').default, meta: {pageTitle: 'Power Report'}},

        {path: '/workout-plans', name: 'workout-plans.index', component: require('@/views/pages/workout-plans/Index').default, meta: {pageTitle: 'Workout Plans'}},
        {path: '/workout-plans/my', name: 'workout-plans.my', component: require('@/views/pages/workout-plans/My').default, meta: {pageTitle: 'My Workout Plans'}},
        {path: '/workout-plans/create', name: 'workout-plans.create', component: require('@/views/pages/workout-plans/Create').default, meta: {pageTitle: 'New Workout Plan'}},
        {path: '/workout-plans/:id/edit', name: 'workout-plans.edit', component: require('@/views/pages/workout-plans/EditWorkoutPlan').default, meta: {pageTitle: 'Edit Workout Plan'}},
        {path: '/workout-plans/:id/edit/workouts', name: 'workout-plans.edit-workouts', component: require('@/views/pages/workout-plans/EditWorkoutPlan').default, meta: {pageTitle: 'Edit Workout Plan'}},

        {path: '/me/2020/stats', name: 'me.yearly_stats', component: require('@/views/pages/reports/MyYearlyStats').default, meta: {pageTitle: ' '}},
        {path: '/me/2021/stats', name: 'me.yearly_2021_stats', component: require('@/views/pages/reports/MyYearly2021Stats').default, meta: {pageTitle: ' '}},
        {path: '/me/2022/stats', name: 'me.yearly_2022_stats', component: require('@/views/pages/reports/MyYearly2022Stats').default, meta: {pageTitle: ' '}},
        {path: '/me/2023/stats', name: 'me.yearly_2023_stats', component: require('@/views/pages/reports/MyYearly2023Stats').default, meta: {pageTitle: ' '}},
      ]
    }
    //
    //
    //     {path: '/group-users/:id/edit', name: 'group-user.edit', component: require('@/views/pages/groups/GroupUserEdit').default, meta: {layout: 'LayoutAuth', label: 'Edit Group User'}},
    //
  ]),

  // Auth admin routes
  ...middleware('authAdmin', [
    {
      path: '',
      component: () => import('@/layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {path: '/admin/badges', name: 'admin.badges', component: require('@/views/pages/badges/Index').default, meta: {pageTitle: 'Badges'}},
        {path: '/admin/badges/create', name: 'admin.badges_create', component: require('@/views/pages/badges/Create').default, meta: {pageTitle: 'New Badge'}},
        {path: 'admin/badges/:id/edit', name: 'admin.badges_edit', component: require('@/views/pages/badges/Edit').default, meta: {pageTitle: 'Edit Badge'}},
      ],
    }
    ]),

  // Public routes
  ...middleware('public', [
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {path: '/logout', name: 'web.auth.logout', component: require('@/views/pages/auth/Logout').default},
        {path: '/error-404', name: 'page-error-404', component: () => import('@/views/pages/Error404.vue')
        },
      ],
    },

    {
      path: '',
      component: () => import('./../layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {path: '/share/lockdown-meters/:id', name: 'public-lockdown-meters.show', component: require('@/views/pages/reports/PublicLockdownMeters').default, meta: {pageTitle: 'Lockdown Meters'}},
        {path: '/groups/group/:id', name: 'public-groups.show', component: require('@/views/pages/groups/Show').default, meta: {pageTitle: ' '}},
        {path: '/share/:id', name: 'public-results.show', component: require('@/views/pages/results/Public').default, meta: {pageTitle: ' '}},
        {path: '/year/2020/:id', name: 'yearly-stats.show', component: require('@/views/pages/reports/YearlyStats').default, meta: {pageTitle: ' '}},
        {path: '/year/2021/:id', name: 'yearly-2021-stats.show', component: require('@/views/pages/reports/Yearly2021Stats').default, meta: {pageTitle: ' '}},
        {path: '/year/2022/:id', name: 'yearly-2022-stats.show', component: require('@/views/pages/reports/Yearly2022Stats').default, meta: {pageTitle: ' '}},
        {path: '/year/2023/:id', name: 'yearly-2023-stats.show', component: require('@/views/pages/reports/Yearly2023Stats').default, meta: {pageTitle: ' '}},
        {path: '/workout-plans/plan/:id', name: 'public-workout-plans.show', component: require('@/views/pages/workout-plans/Show').default, meta: {pageTitle: ' '}},
      ],
    },

    //
    // ,
    //
    // {path: '/groups/public', name: 'public-groups.index', component: require('@/views/pages/groups/PublicIndex').default, meta: {layout: 'LayoutAuth', label: ''}},


    // Guest routes
    ...middleware('guest', [
      {
        path: '',
        component: () => import('@/layouts/full-page/FullPage.vue'),
        children: [
          // =============================================================================
          // PAGES
          // =============================================================================
          {path: '/', name: 'web.home.index', component: require('@/views/pages/auth/Login').default},
          {path: '/login', name: 'web.auth.login', component: require('@/views/pages/auth/Login').default},
          {path: '/password/forgot', name: 'web.auth.forgot_password', component: require('@/views/pages/auth/ForgotPassword').default},
          {path: '/register', name: 'web.auth.register', component: require('@/views/pages/auth/Register').default},
          {path: '/password/reset/:token', name: 'web.auth.reset_password', component: require('@/views/pages/auth/ResetPassword').default},
          {path: '/activate-account/:encrypted_id/:encrypted_email', name: 'web.auth.activate_account', component: require('@/views/pages/auth/ActivateAccount').default},
        ]
      },

    ]),


  ]),

  {path: '*', redirect: '/error-404'}
]

/**
 * Group routes by middleware.
 *
 * @param  {String|Function} middleware
 * @param  {Array} routes
 *
 * @return {Array}
 */
function middleware(middleware, routes) {
  routes.forEach(route =>
    (route.middleware || (route.middleware = [])).unshift(middleware)
  );

  return routes;
}
