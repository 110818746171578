<template>
  <vx-card>
    <ag-group-users-list :items="items" />
  </vx-card>
</template>

<script>
  import axios from 'axios'
  import AgGroupUsersList from "../../../components/groups/AgGroupUsersList";

  export default {
    components: {AgGroupUsersList},
    props: {
      items: {
        type: [Array, Object],
        default: () => []
      }
    },
    data: () => {
      return {
      }
    },
    methods: {
    },
    created() {
    }
  }
</script>
