<template>
  <div style="line-height: 23px; !important; margin-top: 12px;">
    <strong>&nbsp;</strong><br/>
    <strong title="Average ETS">{{ avgEtsScore | formatNumber }}</strong> (avg)
  </div>
</template>

<script>
export default {
  name: 'CellRendererFooterEtsScore',
  computed: {
    avgEtsScore() {
      let filteredWorkouts = _.filter(this.$parent.$parent.items, ({ets_score}) => ets_score > 0);
      let average = _.meanBy(filteredWorkouts, 'ets_score');
      return average;
    },
  }
}
</script>
