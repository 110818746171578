<template>
  <div v-if="loaded">
    <portal to="title-bar-actions">
      <vs-button radius icon="icon-share-2" icon-pack="feather" class="mr-2" @click="shareModal = true" title="Share"/>
      <vs-button v-if="group.logged_in_user_group != undefined && group.logged_in_user_group.status == 1  && group.logged_in_user_group.role == 1"  radius icon="icon-edit" color="success" icon-pack="feather" class="mr-2" title="Edit" :to="{ name: 'groups.edit', params: { id: group.id }}"/>
      <template v-if="group.logged_in_user_group != undefined && group.logged_in_user_group.status == 1">
        <vs-button icon="icon-log-out" icon-pack="feather" color="warning" @click.prevent="groupLeaveDialogShow" title="Leave Group">Leave Group</vs-button>
      </template>
      <template v-else>
        <vs-button icon="icon-log-in" icon-pack="feather" @click.prevent="joinGroup" title="Join Group">Join Group</vs-button>
      </template>
    </portal>
    <vx-card class="mb-base">
      <div class="vx-row mt-6">
        <div class="vx-col md:w-2/5 w-full flex items-center justify-center">
          <div class="product-img-container w-3/5 mx-auto mb-10 md:mb-0">
            <img :src="group.image != null ? group.image_url_256 : 'https://api.ergmonkey.com/img/placeholders/groups_image-256.jpg'" :alt="group.name" class="responsive">
          </div>
        </div>

        <!-- Item Content -->
        <div class="vx-col md:w-3/5 w-full">

          <h3>
            {{ group.name }}
            <template v-if="!(group.logged_in_user_group != undefined && group.logged_in_user_group.status == 1)">
              <vs-button class="ml-2" @click.prevent="joinGroup" title="Join">Join Group</vs-button>
            </template>
          </h3>

          <vs-divider />

          <p>{{ group.description }}</p>

        </div>

      </div>
    </vx-card>

    <vs-row class="mb-base">
      <group-summary-widget :group="group" />
    </vs-row>
    <vs-row>
      <vx-card ref="filterCard" title="Filters" class="user-list-filters mb-8" collapse-action refresh-content-action
               @refresh="resetFilter">
        <form @submit.prevent="filterFormSubmit">
          <div class="vx-row">
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
              <label class="text-sm opacity-75">Range</label>
              <v-select :options="range_options" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="filterForm.range"
                        class="md:mb-0"/>
            </div>
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full" v-if="filterForm.range.value == 'custom'">
              <label class="text-sm opacity-75">Start Date</label>
              <div class="vs-con-input">
                <datetime v-model="filterForm.date_from"
                          :class="{'is-invalid': formControlHasError(filterForm, 'date_from')}"
                          input-class="vs-inputx vs-input--input normal" zone="local" value-zone="local"
                          class="theme-green w-full" title="Hint: Year and Month are also clickable"></datetime>
              </div>
            </div>
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full" v-if="filterForm.range.value == 'custom'">
              <label class="text-sm opacity-75">End Date</label>
              <datetime v-model="filterForm.date_to" :class="{'is-invalid': formControlHasError(filterForm, 'date_to')}"
                        input-class="vs-inputx vs-input--input normal" zone="local" value-zone="local"
                        class="theme-green w-full" title="Hint: Year and Month are also clickable"></datetime>
            </div>
          </div>
        </form>
      </vx-card>
    </vs-row>

    <vs-row>
      <vx-card>
        <vs-tabs>
          <vs-tab label="No of Workouts">
            <div class="con-tab-ejemplo">
              <leaderboard-list :items="leaderboardResults" sort-by="number_of_workouts" sort-direction="desc"></leaderboard-list>
            </div>
          </vs-tab>
          <vs-tab label="Total Distance">
            <div class="con-tab-ejemplo">
              <leaderboard-list :items="leaderboardResults" sort-by="total_distance" sort-direction="desc"></leaderboard-list>
            </div>
          </vs-tab>
          <vs-tab label="Total Time">
            <div class="con-tab-ejemplo">
              <leaderboard-list :items="leaderboardResults" sort-by="total_time" sort-direction="desc"></leaderboard-list>
            </div>
          </vs-tab>
          <vs-tab label="Number of Strokes">
            <div class="con-tab-ejemplo">
              <leaderboard-list :items="leaderboardResults" sort-by="number_of_strokes" sort-direction="desc"></leaderboard-list>
            </div>
          </vs-tab>
          <vs-tab label="Workouts">
            <div class="con-tab-ejemplo">
              <group-workouts-list :items="results"></group-workouts-list>
            </div>
          </vs-tab>
        </vs-tabs>
      </vx-card>
    </vs-row>

    <vs-popup title="Share Group" :active.sync="shareModal">
      <vs-row class="mb-2">
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="facebook" class="vs-component vs-button vs-button-null vs-button-filled btn-facebook w-full mr-2">
              <feather-icon icon="FacebookIcon" svgClasses="h-4 w-4" /> Facebook
            </network>
          </vs-col>
        </social-sharing>
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="twitter" class="vs-component vs-button vs-button-null vs-button-filled btn-twitter w-full ml-2">
              <feather-icon icon="TwitterIcon" svgClasses="h-4 w-4" /> Twitter
            </network>
          </vs-col>
        </social-sharing>
      </vs-row>
      <vs-row class="mb-2">
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="linkedin" class="vs-component vs-button vs-button-null vs-button-filled btn-linkedin w-full mr-2">
              <feather-icon icon="LinkedinIcon" svgClasses="h-4 w-4" /> LinkedIn
            </network>
          </vs-col>
        </social-sharing>
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="reddit" class="vs-component vs-button vs-button-null vs-button-filled btn-reddit w-full ml-2">
              <feather-icon icon="RedditIcon" svgClasses="h-4 w-4" /> Reddit
            </network>
          </vs-col>
        </social-sharing>
      </vs-row>
      <vs-row class="mb-2">
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="email" class="vs-component vs-button vs-button-warning vs-button-filled w-full mr-2">
              <feather-icon icon="MailIcon" svgClasses="h-4 w-4" /> Email
            </network>
          </vs-col>
        </social-sharing>
        <vs-col vs-w="6">
          <vs-button color="primary" v-clipboard:copy="shareUrl" v-clipboard:success="onCopySuccess" class="w-full ml-2">
            <feather-icon icon="CopyIcon" svgClasses="h-4 w-4" /> Copy
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>

  </div>
</template>

<script>
  import axios from 'axios'
  import {mapGetters} from 'vuex'
  import FormErrors from '@/components/mixins/FormErrors'
  import Numbers from '@/components/mixins/Numbers'
  import Form from "vform";
  import moment from "moment";
  import GroupWorkoutsList from "../../../components/groups/GroupWorkoutsList";
  import LeaderboardList from "../../../components/groups/LeaderboardList";
  import $ from 'jquery'
  import GroupSummaryWidget from "../../../components/groups/GroupSummaryWidget";
  import vSelect from 'vue-select'

  export default {
    components: {
      GroupSummaryWidget,
      vSelect,
      LeaderboardList,
      GroupWorkoutsList
      },
    // Set meta tags
    head: {
      title: function () {
        return {
          inner: this.title
        }
      },
    },

    mixins: [FormErrors, Numbers],

    data: () => ({
      group: null,
      title: '',
      loaded: false,
      filterForm: new Form({
        range: { label: 'This Week', value: 'this_week' },
        date_from: '',
        date_to: '',

      }),
      range_options: [
        {
          label: 'Today',
          value: 'today'
        },
        {
          label: 'This Week',
          value: 'this_week'
        },
        {
          label: 'This Month',
          value: 'this_month'
        },
        {
          label: 'This Year',
          value: 'this_year'
        },
        {
          label: 'All Time',
          value: 'all_time'
        },
        {
          label: 'Custom',
          value: 'custom'
        }],
      results: [],
      resultsLoaded: false,
      leaderboardResults: [],
      leaderboardResultsLoaded: false,
      shareModal: false,
      shareDesription: '',
      shareQuote: '',
      shareHashtags: '',
      shareTwitterUser: 'ergmonkey_'
    }),
    computed: {
      shareUrl: function () {
        return 'https://stats.ergmonkey.com/groups/group/' + this.$route.params.id;
      },
      shareTitle: function () {
        return this.item != null ? this.item.name : '';
      },
      ...mapGetters({
        authenticatedUser: 'auth/getAuthenticatedUser',
      }),
    },
    watch: {
      filterForm: {
        handler(val) {
          if(val.range != 'custom')
          {
            val.date_from = '';
            val.date_to = '';
          }

          if(val.range != 'custom' || (val.range == 'custom' && val.date_from != '' && val.date_to != ''))
          {
            this.filterFormSubmit();
          }
        },
        deep: true
      },
    },
    methods: {
      getGroup(groupId) {
        this.loaded = false;

        axios.get('groups/' + groupId + '?include=logged_in_user_group,statistics').then((data) => {
          this.group = data.data.group;
          this.title = this.group.name;
          this.page_title = this.group.name;
          this.$emit('changeRouteTitle', this.page_title);
          this.loaded = true;

          this.filterFormSubmit();
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },

      resetFilter() {
        this.filterForm = new Form({
          range: { label: 'This Week', value: 'this_week' },
          date_from: '',
          date_to: '',
        });
        this.resultsLoaded = false;
      },

      async filterFormSubmit() {
        this.resultsLoaded = false;

        let params = _.clone(this.filterForm);

        params.range = this.filterForm.range.value != 'this_week' ? this.filterForm.range.value : '';
        params.date_from = this.filterForm.date_from == '' ? '' :  moment.utc(this.filterForm.date_from).format('YYYY-MM-DD');
        params.date_to = this.filterForm.date_to == '' ? '' : moment.utc(this.filterForm.date_to).format('YYYY-MM-DD');

        axios.get('groups/' + this.$route.params.id + '/results?per_page=10000&include=public_user&' + $.param({filters: params})).then((data) => {
          this.results = data.data.results.data;
          this.resultsLoaded = true;
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });

        axios.get('groups/' + this.$route.params.id + '/leaderboard?per_page=10000&include=public_user&' + $.param({filters: params})).then((data) => {
          this.leaderboardResults = data.data.results.data;
          this.leaderboardResultsLoaded = true;
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },

      joinGroup() {
        axios.post('groups/'+ this.$route.params.id +'/join')
          .then(({data}) => {
            this.$vs.notify({
              text: data.message,
              color:'success',
              position:'top-right',
              time:5000,
            });

            this.$router.go();
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },
      onCopySuccess() {
        this.shareModal = false;
        this.$vs.notify({
          text: "Copied!",
          color:'success',
          position:'top-right',
          time:5000,
        });
      },

      groupLeaveDialogShow() {
        this.$vs.dialog({
          type: 'confirm',
          color: 'warning',
          title: `Please Confirm`,
          text: 'Are you sure you want to leave this group?',
          acceptText: 'Yes, leave it',
          accept: this.leaveGroup
        })
      },

      leaveGroup() {
        axios.post('groups/'+ this.group.id +'/leave')
          .then(({data}) => {
            this.$vs.notify({
              text: data.message,
              color:'success',
              position:'top-right',
              time:5000,
            });

            this.$router.push({ name: 'groups.index' });
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },

    },
    created() {
      this.getGroup(this.$route.params.id);
    },
  }
</script>
