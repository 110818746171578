<template>
  <vx-card>
    <form @submit.prevent="groupFormSubmit">
      <!-- Img Row -->
      <vue-image-input :placeholder="'groups_image-128.jpg'" :folder="'groups_image'"
                       v-model="groupForm.image"></vue-image-input>

      <!-- Info -->
      <vs-input
        label="Name"
        name="name"
        v-model="groupForm.name"
        class="w-full mt-2"/>
      <span class="text-danger text-sm" v-if="formControlHasError(groupForm, 'name')">{{ formControlGetError(groupForm, 'name') }}</span>

      <vs-textarea
        label="Description"
        name="description"
        v-model="groupForm.description"
        class="w-full mt-2" height="200px"/>
      <span class="text-danger text-sm" v-if="formControlHasError(groupForm, 'description')">{{ formControlGetError(groupForm, 'description') }}</span>

      <vs-checkbox v-model="groupForm.public" class="mt-4">Public</vs-checkbox>
      <span class="text-danger text-sm" v-if="formControlHasError(groupForm, 'public')">{{ formControlGetError(groupForm, 'public') }}</span>

      <!-- Save & Reset Button -->
      <div class="flex-wrap items-center justify-end mt-2">
        <vs-button class="ml-auto mt-2" button="submit">Save</vs-button>
        <vs-button type="border" class="ml-4 mb-2" color="warning" @click.prevent="$router.go(-1)">Cancel</vs-button>
      </div>
    </form>
  </vx-card>
</template>

<script>
  import axios from 'axios'
  import {mapGetters} from 'vuex'
  import Form from 'vform'
  import FormErrors from '@/components/mixins/FormErrors'
  import Numbers from '@/components/mixins/Numbers'
  import moment from 'moment'

  export default {

    // Set meta tags
    head: {
      title: {
        inner: 'New Group'
      }
    },

    mixins: [FormErrors, Numbers],

    data: () => ({
      groupForm: new Form({
        name: '',
        description: '',
        image: '',
        public: ''
      }),
    }),

    methods: {
      groupFormSubmit() {
        this.formResetErrors(this.groupForm);

        this.groupForm.post('/groups/create')
          .then(({data}) => {
            let group = data.group;

            this.$vs.notify(
              {
                text: data.message,
                color:'success',
                position:'top-right',
                time:5000,
              }
            );

            this.$router.push({ name: 'public-groups.show', params: { id: group.id }});
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },

    }
  }
</script>
