import axios from 'axios'

// Default state
const getDefaultState = () => {
    return {
        integrationsLoaded: false,
        integrations: [],
        integrationsConstants: {
            1: {name: 'Logbook', logo: 'concept_2_logo.png'},
            2: {name: 'RP3', logo: 'cRP3-Dynamic_logo.svg'}
        },
        logbookIntegration: null,
        rp3Integration: null
    }
};

// State
export const state = getDefaultState();

// Getters
export const getters = {
    getIntegrationsLoaded: state => state.integrationsLoaded,
    getIntegrations: state => state.integrations,
    getIntegrationsConstants: state => state.integrationsConstants,
    getLogbookIntegration: state => state.logbookIntegration,
    getRp3Integration: state => state.rp3Integration
};

// Mutations
export const mutations = {
    SET_INTEGRATIONS_LOADED(state, {loaded}) {
        state.integrationsLoaded = loaded;
    },
    SET_INTEGRATIONS(state, {integrations}) {
        state.integrations = integrations;
    },
    SET_LOGBOOK_INTEGRATION(state, {integration}) {
        state.logbookIntegration = integration;
    },
    SET_RP3_INTEGRATION(state, {integration}) {
      state.rp3Integration = integration;
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState());
    }
};

// Actions
export const actions = {
    setIntegrationsLoaded({commit}, payload) {
        commit('SET_INTEGRATIONS_LOADED', payload);
    },

    async fetchIntegrations({commit}) {
        commit('SET_INTEGRATIONS_LOADED', {loaded: false});
        commit('SET_LOGBOOK_INTEGRATION', {integration: null});
        commit('SET_RP3_INTEGRATION', {integration: null});

        return new Promise((resolve, reject) => {
            axios.get('users/me/integrations')
                .then((response) => {
                    commit('SET_INTEGRATIONS', {integrations: response.data.data});
                    commit('SET_INTEGRATIONS_LOADED', {loaded: true});

                    for(var i=0; i < state.integrations.length; i++){
                        if( state.integrations[i].source == 1){
                            commit('SET_LOGBOOK_INTEGRATION', {integration: state.integrations[i]});
                        }
                        if( state.integrations[i].source == 2){
                          commit('SET_RP3_INTEGRATION', {integration: state.integrations[i]});
                        }
                    }

                    resolve(response)
                }, response => {
                    reject(response)
                })
                .catch(error => {
                    commit('SET_INTEGRATIONS', {integrations: []});
                    reject(error)
                });
        });
    },
};
