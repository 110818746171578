<template>
    <vx-card class="mb-base" title="Split">
        <highcharts :options="chartOptions"></highcharts>
    </vx-card>
</template>
<script>
    import Highcharts from 'highcharts'
    import $ from 'jquery'
    import moment from 'moment'
    import {formatDate, formatTime, formatTimeAsPace} from "../../shared/utils";

    export default {
        name: 'split-line-chart',

        components: {
        },
        props: {
            items: {
                type: [Array, Object],
                default: () => []
            }
        },
        data() {
            return {
                loaded: false,
                chartOptions: {
                    chart: {
                        type: 'spline',
                        zoomType: 'xy',
                        backgroundColor: 'rgba(0,0,0,0)',
                        events: {
                            load: function() {
                                /* On mobile, remove labels */
                                if ($(window).width() < 900) {
                                    this.yAxis[0].update({
                                        title: false,
                                    });
                                    this.tooltip.update({
                                        style: {
                                            width: Math.min(300, 2*$(window).width()/3)
                                        }
                                    });
                                }
                            }
                        }
                    },
                    title: false,
                    xAxis: {
                        categories: [],
                        crosshair: true
                    },
                    yAxis: [{
                        reversed: true,
                        title: {
                            text: 'Pace'
                        },
                        labels: {
                            formatter: function() {
                                return formatTime(this.value);
                            }
                        },
                        plotLines: [],
                    }],
                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 500
                            },
                            chartOptions: {
                                legend: {
                                    floating: false,
                                    layout: 'horizontal',
                                    align: 'center',
                                    verticalAlign: 'bottom',
                                    x: 0,
                                    y: 0
                                }
                            }
                        }]
                    },
                    tooltip: {
                        shared: true,
                        formatter: function() {
                            let s = '<b>' + this.x + '</b>';
                            $.each(this.points, function(i, point) {
                                let value = point.y;
                                let name = this.series.name;
                                if(name == 'Average Pace')
                                {
                                    value = formatTimeAsPace(value);
                                }
                                s += '<br/>' + '<span style="color:' + point.color + '">\u25CF</span> ' + this.series.name + ': ' + value;
                            });
                            return s;
                        },
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    },
                    series: [],
                    credits: {
                        enabled: false
                    },
                }
            }
        },

        methods: {
        },
        mounted() {
            this.loaded = true;

            var i = 0;
            let averagePace = [];
            let categories = [];
            let paceSum = 0;
            for(i; i < this.items.length; i++){
                averagePace.push(parseFloat(this.items[i].average_pace));
                paceSum += parseFloat(this.items[i].average_pace);
                if(this.items[i].week != undefined)
                {
                    categories.push(this.items[i].year + '/Week ' + this.items[i].week);
                }
                else if(this.items[i].month != undefined)
                {
                    categories.push(this.items[i].year + '/' + moment.months(this.items[i].month - 1));
                } else if(this.items[i].date != undefined)
                {
                    categories.push(formatDate(this.items[i].date));
                } else if(this.items[i].day_of_week != undefined)
                {
                    categories.push(moment.weekdays(this.items[i].day_of_week));
                } else {
                    categories.push(this.items[i].year);
                }
            }

            this.chartOptions.xAxis.categories = categories;

            this.chartOptions.series.push({
                name: "Average Pace",
                data: averagePace
            });

            this.chartOptions.yAxis[0].plotLines.push({
                 value: paceSum / this.items.length,
                 color: 'green',
                 dashStyle: 'shortdash',
                 width: 2,
                 label: {
                     text: 'Average pace'
                 }
             });
        }
    };
</script>
