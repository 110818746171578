<template>
  <div>
    <template v-if="!this.authenticatedUserHasPermissionToSee(this.allowedPlans)">
      <upgrade-message title="To see your Personal Best Report upgrade to the Standard Plan."></upgrade-message>
    </template>
    <template v-else>
      <vx-card ref="filterCard" title="Filters" class="user-list-filters mb-8" collapse-action refresh-content-action
               @refresh="resetFilter">
        <form @submit.prevent="filterFormSubmit">
          <div class="vx-row">
            <div class="vx-col w-full">
              <label class="text-sm opacity-75">Tags</label>
              <v-select multiple :closeOnSelect="false" v-model="filterForm.tags" :options="this.authenticatedUser.user_tags" placeholder="All">
                <div slot="no-options">No matching tags.</div>
              </v-select>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full" v-if="this.getAuthenticatedUserResultTypes().length > 1">
              <label class="text-sm opacity-75">Machine Type</label>
              <v-select :options="this.getAuthenticatedUserResultTypes()" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="filterForm.type" placeholder="All"
                        class="md:mb-0"/>
            </div>
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
              <label class="text-sm opacity-75">Start Date</label>
              <div class="vs-con-input">
                <datetime v-model="filterForm.date_from"
                          :class="{'is-invalid': formControlHasError(filterForm, 'date_from')}"
                          input-class="vs-inputx vs-input--input normal" zone="local" value-zone="local"
                          class="theme-green w-full" title="Hint: Year and Month are also clickable"></datetime>
              </div>
            </div>
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
              <label class="text-sm opacity-75">End Date</label>
              <datetime v-model="filterForm.date_to" :class="{'is-invalid': formControlHasError(filterForm, 'date_to')}"
                        input-class="vs-inputx vs-input--input normal" zone="local" value-zone="local"
                        class="theme-green w-full" title="Hint: Year and Month are also clickable"></datetime>
            </div>
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
              <label class="text-sm opacity-75">Workout Type</label>
              <v-select :options="workout_types" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="filterForm.workout_type" placeholder="All"/>
            </div>
          </div>
        </form>
      </vx-card>

      <ag-session-list-without-comments v-if="loaded" :items="results"></ag-session-list-without-comments>
    </template>
  </div>
</template>

<script>
  import axios from 'axios'
  import Form from 'vform'
  import FormErrors from '@/components/mixins/FormErrors'
  import moment from 'moment'
  import {mapGetters} from "vuex";
  import UpgradeMessage from "../../../components/global/UpgradeMessage";
  import vSelect from 'vue-select'
  import $ from 'jquery'
  import AgSessionListWithoutComments from "../../../components/reports/AgSessionListWithoutComments";
  import {clone} from 'lodash';
  import {WORKOUT_TYPES_PREDEFINED} from "../../../shared/constants";

  export default {
    head: {
      title: {
        inner: 'Personal Bests'
      }
    },

    components: {
      AgSessionListWithoutComments,
      vSelect,
      UpgradeMessage
    },
    computed: mapGetters({
      authenticatedUser: 'auth/getAuthenticatedUser',
      authenticatedUserHasPermissionToSee: 'auth/getAuthenticatedUserHasPermissionToSee',
      getAuthenticatedUserResultTypes: 'auth/getAuthenticatedUserResultTypes',
    }),
    mixins: [FormErrors],
    data: () => ({
      filterForm: new Form({
        type_object: {
          label: 'All',
          value: ''
        },
        type: '',
        date_from: moment().subtract(1, 'year').format('YYYY-MM-DD'),
        date_to: moment().format('YYYY-MM-DD'),
        workout_type: '',
        tags: ''
      }),
      loaded: false,
      results: null,
      allowedPlans: ['', 1, 2, 3, 4, 5,6,7,8,9,10,11],
      workout_types: WORKOUT_TYPES_PREDEFINED
    }),
    watch: {
      filterForm: {
        handler(val) {
          if (this.authenticatedUserHasPermissionToSee(this.allowedPlans)) {
            this.filterFormSubmit();
          }
        },
        deep: true
      },
    },
    methods: {
      async filterFormSubmit() {
        if (this.loaded == true && !this.authenticatedUserHasPermissionToSee(this.allowedPlans)) {
          return true;
        }
        this.loaded = false;

        let params = _.clone(this.filterForm);

        if(this.getAuthenticatedUserResultTypes().length == 1)
        {
          this.filterForm.type = this.getAuthenticatedUserResultTypes()[0];
        }

        params.date_from = moment(this.filterForm.date_from).format('YYYY-MM-DD');
        params.date_to = moment(this.filterForm.date_to).format('YYYY-MM-DD');
        params.type = this.filterForm.type != '' ? this.filterForm.type.value : '';
        params.workout_type = this.filterForm.workout_type != '' ? this.filterForm.workout_type.value : '';

        axios.get('users/me/results-personal-best?per_page=10000&order_by=date&order_direction=desc&' + $.param({filters: params})).then((data) => {
          this.results = data.data.results.data;
          this.loaded = true;
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },
      resetFilter() {
        this.filterForm = new Form({
          type: '',
          date_from: moment().subtract(1, 'year').format('YYYY-MM-DD'),
          date_to: moment().format('YYYY-MM-DD'),
          workout_type: '',
          tags: ''
        });
        this.$refs.filterCard.removeRefreshAnimation();
      }
    },
    created() {
    }
  }
</script>
