<template>
  <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen" :value="active_tab">

    <!-- GENERAL -->
    <vs-tab icon-pack="feather" icon="icon-info" :label="!isSmallerScreen ? 'General' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <group-settings-general />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-users" :label="!isSmallerScreen ? 'Users' : ''">
      <div class="tab-info md:ml-4 md:mt-0 mt-4 ml-0">
        <group-settings-users v-if="group != null" :items="group.group_users" />
      </div>
    </vs-tab>
  </vs-tabs>
</template>

<script>
import GroupSettingsGeneral from "./GroupSettingsGeneral";
import GroupSettingsUsers from "./GroupSettingsUsers";
import axios from "axios";

export default {
  components: {
    GroupSettingsUsers,
    GroupSettingsGeneral,
  },
  data () {
    return {
      active_tab: 0,
      group: null,
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },

  methods: {
    getGroup(groupId) {
      this.loaded = false;

      axios.get('groups/' + groupId + '?include=logged_in_user_group,group_users.public_user').then((data) => {
        this.group = data.data.group;
      }).catch(error => {
        this.$vs.notify({
          text: error.response.data.message,
          color:'danger',
          position:'top-right',
          time:5000,
        });
      });
    },
  },

  created () {
    this.getGroup(this.$route.params.id);
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
