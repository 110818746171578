<template>
    <vx-card class="mb-base" title="Heart Rate Distribution">
      <template v-if="!this.authenticatedUserHasPermissionToSee(this.allowedPlans)">
        <upgrade-message title="To see Heart Rate Distribution upgrade to Standard Plan"
                         border-variant="none"></upgrade-message>
      </template>
      <template v-else>
        <template v-if="result != null && result != ''">
          <highcharts :options="chartOptions"></highcharts>
        </template>
        <template v-else>
            There is no heart data
        </template>
      </template>
    </vx-card>
</template>
<script>
  import Highcharts from 'highcharts'
  import {mapGetters} from "vuex";
  import UpgradeMessage from "../../components/global/UpgradeMessage";
  import {timeInHours} from "../../shared/utils";
  import $ from 'jquery';

  export default {
    name: 'hr-report-summary-pie-chart',

    components: {
      UpgradeMessage
    },
    props: {
      result: {
        type: [Array, Object],
        default: () => []
      }
    },
    computed: {
      maxHR() {
        if(this.authenticatedUser.max_heart_rate)
        {
          return this.authenticatedUser.max_heart_rate;
        }
        return 220;
      },
      hrZonePercentages() {
        if(this.authenticatedUser.heart_rate_zones)
        {
          return this.authenticatedUser.heart_rate_zones;
        }

        return this.defaultHrZones;
      },
      ...mapGetters({
        authenticatedUserHasPermissionToSee: 'auth/getAuthenticatedUserHasPermissionToSee',
        authenticatedUser: 'auth/getAuthenticatedUser',
      })
    },
    data() {
      return {
        loaded: false,
        items: [],
        allowedPlans: ['', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,11],
        chartOptions: {
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            backgroundColor: 'rgba(0,0,0,0)',
          },
          legend: {
            align: 'left',
            layout: $(window).width() < 900 ? 'horizontal' : 'vertical',
            verticalAlign: $(window).width() < 900 ? 'bottom' : 'top',
            x: 0,
            y: 0,
            labelFormatter: function () {
              return this.tooltip_info;
            }
          },
          title: false,
          tooltip: {
            formatter: function () {
              let s = '<b>' + this.point.name + '</b><br>';
              s += this.point.percentage.toFixed(1) + '%';
              if(this.point.y > 10)
              {
                s += ' (' + timeInHours(this.point.y) +')'
              } else {
                s += ' (< 1 sec)'
              }
              return s;
            },
            useHTML: true
          },
          accessibility: {
            point: {
              valueSuffix: '%'
            }
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
              }
            }
          },
          series: [],
          credits: {
            enabled: false
          },
        },
        defaultHrZones: {
          zone_1: '50',
          zone_2: '60',
          zone_3: '70',
          zone_4: '80',
          zone_5: '90',
        },
      }
    },

    methods: {
    },
    mounted() {
      console.log(this.result);
      if (this.authenticatedUserHasPermissionToSee(this.allowedPlans)) {
        if (this.result != null && this.result != "") {
          this.chartOptions.series.push({
            name: 'Zones',
            colorByPoint: true,
            showInLegend: true,
            data: [{
              name: 'Not In Zones',
              y: this.result.not_in_zones,
              tooltip_info: '(<'+ parseInt(this.maxHR/100*this.hrZonePercentages.zone_1)+'bpm)',
              color: '#c3c6c7'
            }, {
                name: 'Zone 1',
                y: this.result.zone_1,
                tooltip_info: '('+ parseInt(this.maxHR/100*this.hrZonePercentages.zone_1) +'-'+ parseInt(this.maxHR/100*this.hrZonePercentages.zone_2) + 'bpm)',
                selected: true,
                color: '#6d7278'
              }, {
                name: 'Zone 2',
                y: this.result.zone_2,
                tooltip_info: '('+ parseInt(this.maxHR/100*this.hrZonePercentages.zone_2) +'-'+ parseInt(this.maxHR/100*this.hrZonePercentages.zone_3) + 'bpm)',
                color: '#0b81b3'
              }, {
                name: 'Zone 3',
                y: this.result.zone_3,
                tooltip_info: '('+ parseInt(this.maxHR/100*this.hrZonePercentages.zone_3) +'-'+ parseInt(this.maxHR/100*this.hrZonePercentages.zone_4) + 'bpm)',
                color: "#3e9449"
              }, {
                name: 'Zone 4',
                y: this.result.zone_4,
                tooltip_info: '('+ parseInt(this.maxHR/100*this.hrZonePercentages.zone_4) +'-'+ parseInt(this.maxHR/100*this.hrZonePercentages.zone_5) + 'bpm)',
                color: '#e96126'
              }, {
                name: 'Zone 5',
                y: this.result.zone_5,
                tooltip_info: '(>='+ parseInt(this.maxHR/100*this.hrZonePercentages.zone_5) + 'bpm)',
                color: '#d72433'
              }]
          });
        }
      }
    }
  };
</script>
