<template>
  <vx-card v-if="loaded">
    <portal to="title-bar-actions">
      <vs-button radius icon="icon-trash-2" color="danger" icon-pack="feather" title="Delete" @click.prevent="groupDeleteDialogShow()"/>
    </portal>
    <form @submit.prevent="groupFormSubmit">
      <!-- Img Row -->
      <vue-image-input :placeholder="'groups_image-128.jpg'" :folder="'groups_image'"
                       v-model="groupForm.image"></vue-image-input>

      <!-- Info -->
      <vs-input
        label="Name"
        name="name"
        v-model="groupForm.name"
        class="w-full mt-2"/>
      <span class="text-danger text-sm" v-if="formControlHasError(groupForm, 'name')">{{ formControlGetError(groupForm, 'name') }}</span>

      <vs-textarea
        label="Description"
        name="description"
        v-model="groupForm.description"
        class="w-full mt-2" height="200px"/>
      <span class="text-danger text-sm" v-if="formControlHasError(groupForm, 'description')">{{ formControlGetError(groupForm, 'description') }}</span>

      <vs-checkbox v-model="groupForm.public" class="mt-4">Public</vs-checkbox>
      <span class="text-danger text-sm" v-if="formControlHasError(groupForm, 'public')">{{ formControlGetError(groupForm, 'public') }}</span>

      <!-- Save & Reset Button -->
      <div class="flex-wrap items-center justify-end mt-2">
        <vs-button class="ml-auto mt-2" button="submit">Save</vs-button>
        <vs-button type="border" class="ml-4 mb-2" color="warning" @click.prevent="$router.go(-1)">Cancel</vs-button>
      </div>
    </form>
  </vx-card>
</template>

<script>
  import axios from 'axios'
  import {mapGetters} from 'vuex'
  import Form from 'vform'
  import FormErrors from '@/components/mixins/FormErrors'
  import Numbers from '@/components/mixins/Numbers'
  import moment from 'moment'
  import {formatDateTime} from "../../../shared/utils";

  export default {
    components: {

    },
    // Set meta tags
    head: {
      title: {
        inner: 'Edit Group'
      }
    },

    mixins: [FormErrors, Numbers],

    data: () => ({
      group: null,
      loaded: false,
      groupForm: new Form({
        name: '',
        description: '',
        image: '',
        public: ''
      }),
      groupUsers: []
    }),

    methods: {
      groupFormSubmit() {
        this.formResetErrors(this.groupForm);

        this.groupForm.post('/groups/' + this.group.id + '/update')
          .then(({data}) => {
            this.$vs.notify(
              {
                text: data.message,
                color:'success',
                position:'top-right',
                time:5000,
              }
            );

            this.$router.push({name: 'public-groups.show', params: {id: this.group.id}});
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },

      getGroup(groupId) {
        this.loaded = false;

        axios.get('groups/' + groupId + '?include=logged_in_user_group,group_users.public_user').then((data) => {
          this.group = data.data.group;

          if (this.group.logged_in_user_group != undefined && this.group.logged_in_user_group.status == 1 && this.group.logged_in_user_group.role == 1) {
            this.groupForm.name = this.group.name;
            this.groupForm.image = this.group.image;
            this.groupForm.description = this.group.description;
            this.groupForm.public = this.group.public;
            this.loaded = true;
          } else {
            this.$vs.notify({
              text: 'You do not have permission to edit this group.',
              color:'danger',
              position:'top-right',
              time:5000,
            });

            this.$router.go('-1');
          }
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },

      groupDeleteDialogShow() {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: `Please Confirm`,
          text: 'Are you sure you want to delete this group?',
          acceptText: 'Yes, delete it',
          accept: this.deleteGroup
        })
      },

      deleteGroup() {
        axios.post('groups/'+ this.group.id +'/destroy')
          .then(({data}) => {
            this.$vs.notify(
              {
                text: data.message,
                color:'success',
                position:'top-right',
                time:5000,
              }
            );

            this.$router.push({ name: 'groups.index' });
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },

    },
    created() {
      this.getGroup(this.$route.params.id);
    },
  }
</script>
