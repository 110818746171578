<template>
  <vx-card class="mb-base" title="Average Heart Rate">
      <highcharts :options="chartOptions"></highcharts>
  </vx-card>
</template>
<script>
    import Highcharts from 'highcharts'
    import $ from 'jquery'
    import moment from 'moment'
    import { formatTime, formatTimeAsPace } from '@/shared/utils'

    export default {
        name: 'average-heart-rate-line-chart',

        components: {
        },
        props: {
            items: {
                type: [Array, Object],
                default: () => []
            }
        },
        data() {
            return {
                loaded: false,
                chartOptions: {
                    chart: {
                        zoomType: 'xy',
                        type: 'spline',
                        backgroundColor: 'rgba(0,0,0,0)',
                        events: {
                            load: function() {
                                /* On mobile, remove labels */
                                if ($(window).width() < 900) {
                                    this.yAxis[0].update({
                                        title: false,
                                    });
                                    this.tooltip.update({
                                        style: {
                                            width: Math.min(300, 2*$(window).width()/3)
                                        }
                                    });
                                }
                            }
                        }
                    },
                    title: false,
                    xAxis: {
                        startOnTick: true,
                        endOnTick: true,
                        showLastLabel: true,
                        categories: [],
                        labels: {
                            formatter() {
                                return Highcharts.dateFormat('%e/%b/%y', this.value)
                            }
                        },
                        // type: 'datetime',
                        // dateTimeLabelFormats: { // don't display the dummy year
                        //     month: '%e. %b',
                        //     year: '%b'
                        // },
                    },
                    yAxis: [{ // Primary yAxis
                        labels: {
                            // style: {
                            //   color: Highcharts.getOptions().colors[2]
                            // },
                            formatter: function() {
                                return this.value;
                            }
                        },
                        title: {
                            text: 'Heart Rate',
                            // style: {
                            //   color: Highcharts.getOptions().colors[2]
                            // }
                        },
                        allowDecimals: false,
                    }],
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        itemWidth: 200,
                        verticalAlign: 'bottom',
                        backgroundColor:
                            Highcharts.defaultOptions.legend.backgroundColor || // theme
                            'rgba(255,255,255,0.25)'
                    },
                    series: [],
                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 500
                            },
                            chartOptions: {
                                legend: {
                                    floating: false,
                                    layout: 'horizontal',
                                    align: 'center',
                                    verticalAlign: 'bottom',
                                    x: 0,
                                    y: 0
                                }
                            }
                        }]
                    },
                    tooltip: {
                        shared: true,
                        formatter: function() {
                            let s = '<b>Average Heart Rate:</b>';
                            s += '<br/>' + Highcharts.dateFormat('%e/%b/%y', this.x) + ' : ' + this.y + ' bpm';
                            return s;
                        },
                        useHTML: true
                    },
                    credits: {
                        enabled: false
                    },
                }
            }
        },

        methods: {
        },
        mounted() {
            this.loaded = true;

            let averageHeartRate = [];
          let categories = [];

            var i = 0;
            for(i; i < this.items.length; i++){
                let y = moment(this.items[i].date, 'YYYY-MM-DD HH:mm:ss').format('YYYY');
                let m = moment(this.items[i].date, 'YYYY-MM-DD HH:mm:ss').format('M');
                let d = moment(this.items[i].date, 'YYYY-MM-DD HH:mm:ss').format('D');
                let itemDate = Date.UTC(y,m-1,d);

              // averageHeartRate.push({x: itemDate, y: this.items[i].average_heart_rate > 0 ? this.items[i].average_heart_rate : null});
              categories.push(itemDate);
               averageHeartRate.push(this.items[i].average_heart_rate > 0 ? this.items[i].average_heart_rate : null);
            }

          this.chartOptions.xAxis.categories = categories;

          this.chartOptions.series.push({
            name: "Average Heart Rate",
            data: averageHeartRate,
            tooltip: {
              valueSuffix: ' bpm'
            },
            marker: {
              enabled: true
            },
            yAxis: 0,
            color: Highcharts.getOptions().colors[2],
            connectNulls: true
          });
        }
    };
</script>
