/**
 * Imports
 */

import Vue from 'vue'
import Router from 'vue-router'
import {sync} from 'vuex-router-sync'
import VueScrollTo from 'vue-scrollto'
import store from '@/store'
import routes from './routes'

/**
 * Use
 */

Vue.use(Router);

/**
 * Load middlewares
 */

const routeMiddleware = resolveMiddleware(
    require.context('./middlewares', false, /.*\.js$/)
);

/**
 * Create a Router
 */

const router = new Router({
  mode: 'history',
  scrollBehavior: scrollBehaviorInit,
  base: process.env.BASE_URL || '',
  routes: routes.map(beforeEnter)
});

/**
 * Register after hook
 */

router.afterEach((to, from) => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = "none";
  }

  // Collapse dropdowns
  if (!to || !from || to.path !== from.path) {
    router.app.$emit('bv::dropdown::shown', false);
  }
});

/**
 * Sync
 */

sync(store, router);

export default router;

/**
 * Scroll behavior.
 *
 * @param  {Route} to
 * @param  {Route} from
 * @param  {Object|undefined} savedPosition
 *
 * @return {Object}
 */
function scrollBehaviorInit(to, from, savedPosition) {
  if (to.hash && to.hash.length > 0) {
    VueScrollTo.scrollTo(to.hash);
    return {selector: to.hash}
  }

  if (savedPosition) {
    return savedPosition;
  }

  const [component] = router.getMatchedComponents({...to}).slice(-1);

  if (component && component.scrollToTop === false) {
    return {};
  }

  return {x: 0, y: 0};
}

/**
 * Resolve middleware.
 *
 * @param  {Object} requireContext
 * @return {Object}
 */
function resolveMiddleware(requireContext) {
  return requireContext.keys()
      .map(file =>
          [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
      )
      .reduce((guards, [name, guard]) => (
          {...guards, [name]: guard.default}
      ), {});
}

/**
 * Add beforeEnter guard to the route.
 *
 * @param {Object} route
 *
 * @return {Object}
 */
function beforeEnter(route) {
  if (route.children) {
    route.children.forEach(beforeEnter);
  }

  if (!route.middleware) {
    return route;
  }

  route.beforeEnter = async (...args) => {
    await store.dispatch('auth/loadFromStorage');

    if (!store.getters['auth/getIsUserAuthenticated'] && store.getters['auth/getAccessToken']) {
      try {
        await store.dispatch('auth/fetchUser');
      } catch (e) {
        await store.dispatch('auth/logout');
      }
    }

    if (!Array.isArray(route.middleware)) {
      route.middleware = [route.middleware];
    }

    route.middleware.forEach(middleware => {
      if (typeof middleware === 'function') {
        middleware(...args);
      } else if (routeMiddleware[middleware]) {
        routeMiddleware[middleware](...args);
      } else {
        throw Error(`Undefined middleware [${middleware}]`);
      }
    });
  };

  return route;
}

