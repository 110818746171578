<template>
  <div>
    <template v-if="!this.authenticatedUserHasPermissionToSee(this.allowedPlans)">
      <upgrade-message title="To see your Performance Report upgrade to the Standard Plan."></upgrade-message>
    </template>
    <template v-else>
      <vx-card ref="filterCard" title="Filters" class="user-list-filters mb-8" collapse-action refresh-content-action
               @refresh="resetFilter">
        <form @submit.prevent="filterFormSubmit">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full">
              <label class="text-sm opacity-75">Name</label>
              <vue-select-ajax v-model="filterForm.similar_id" :itemID="'id'" :itemText="'name'"
                               :multiple="false" :url="'/users/me/results-search?fields=id|name'" >
              </vue-select-ajax>
            </div>

            <div class="vx-col md:w-1/2 sm:w-1/2 w-full">
              <label class="text-sm opacity-75">Tags</label>
              <v-select multiple :closeOnSelect="false" v-model="filterForm.tags" :options="this.authenticatedUser.user_tags" placeholder="All">
                <div slot="no-options">No matching tags.</div>
              </v-select>
            </div>
          </div>

          <template v-if="mostCommonWorkouts">
            <vs-divider></vs-divider>
            <span>Suggestions: </span>
            <template v-for="(workout, index) in mostCommonWorkouts">
              <a @click="updateFilter(workout)" class="cursor-pointer">{{ workout.name }}</a><template v-if="index < mostCommonWorkouts.length-1"> , </template>
            </template>
          </template>
        </form>
      </vx-card>

      <template v-if="(filterForm.similar_id == null || filterForm.similar_id == '') && !loaded">
        <vs-alert active="true">
          Please select a workout
        </vs-alert>
      </template>
      <template v-if="loaded">
        <div class="vx-col w-full mb-base">
          <result-summary-widget title="Personal Best" v-if="pb.id != ''" :result="pb" />
        </div>

        <div class="vx-col w-full mb-base">
          <ag-session-list-with-improvements v-if="loaded" :items="results"></ag-session-list-with-improvements>
        </div>

        <split-trend-chart v-if="results.length" :items="results"></split-trend-chart>
      </template>
    </template>
  </div>
</template>

<script>
  import axios from 'axios'
  import Form from 'vform'
  import FormErrors from '@/components/mixins/FormErrors'
  import moment from 'moment'
  import {mapGetters} from "vuex";
  import UpgradeMessage from "../../../components/global/UpgradeMessage";
  import vSelect from 'vue-select'
  import $ from 'jquery'
  import {clone} from 'lodash';
  import AgSessionListWithImprovements from "../../../components/reports/AgSessionListWithImprovements";
  import SplitTrendChart from "../../../components/reports/SplitTrendChart";
  import ResultSummaryWidget from "../../../components/result/ResultSummaryWidget";

  export default {
    head: {
      title: {
        inner: 'Progress Report'
      }
    },

    components: {
      ResultSummaryWidget,
      SplitTrendChart,
      AgSessionListWithImprovements,
      vSelect,
      UpgradeMessage
    },
    computed: {
      ...mapGetters({
        authenticatedUser: 'auth/getAuthenticatedUser',
        authenticatedUserHasPermissionToSee: 'auth/getAuthenticatedUserHasPermissionToSee',
      }),
      pb() {
        let pb = {id: '', pace: 10000};
        _.each(this.results, item => {
          if (item.pace < pb.pace) {
            pb = item;
          }
        });
        return pb;
      }
    },
    mixins: [FormErrors],
    data: () => ({
      filterForm: new Form({
        similar_id: '',
        tags: ''
      }),
      loaded: false,
      results: null,
      allowedPlans: [2, 3, 4, 5,6,7,8,9,10,11],
      mostCommonWorkouts: null,
    }),
    watch: {
      filterForm: {
        handler(val) {
          if (this.authenticatedUserHasPermissionToSee(this.allowedPlans) && this.filterForm.similar_id != '' && this.filterForm.similar_id != null) {
            this.filterFormSubmit();
          }
        },
        deep: true
      },
    },
    methods: {
      async filterFormSubmit() {
        if (this.loaded == true && !this.authenticatedUserHasPermissionToSee(this.allowedPlans)) {
          return true;
        }
        this.loaded = false;

        let params = _.clone(this.filterForm);

        axios.get('users/me/results?per_page=10000' + $.param({filters: params})).then((data) => {
          var i = 0;
          var temp = data.data.results.data;
          for (i; i < temp.length; i++) {
            temp[i].difference = 0;
            if (temp[i + 1] != undefined && temp[i + 1].power != 0) {
              temp[i].difference = (temp[i].power * 100 / temp[i + 1].power) - 100;

            }
          }
          this.results = temp;
          this.loaded = true;
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },
      resetFilter() {
        this.filterForm = new Form({
          similar_id: '',
          tags: ''
        });
        this.$refs.filterCard.removeRefreshAnimation();
      },
      getSuggestions() {
        axios.get('/users/me/grouped-results?limit=10&fields=id|name').then((data) => {
          this.mostCommonWorkouts = data.data.data;
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },
      updateFilter(workout) {
        this.filterForm = new Form({
          similar_id: workout.id,
          tags: ''
        });
      }
    },
    created() {
      this.getSuggestions();
    }
  }
</script>
