<template>
  <div class="flex items-center">
    <b>Total: {{ params.value  }}</b>
  </div>
</template>

<script>
export default {
  name: 'CellRendererTotalNumberOfWorkouts',
  computed: {
  }
}
</script>
