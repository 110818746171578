<template>
    <div class="flex items-center mt-3">
      <feather-icon icon="EditIcon" v-if="false" svgClasses="h-6 w-6 mr-4 hover:text-primary cursor-pointer" @click="$router.push({name: 'results.show', params: { id: params.data.id }})" />
      <feather-icon icon="TrashIcon" svgClasses="h-6 w-6 hover:text-danger cursor-pointer" @click="groupUserDeleteDialogShow" />
    </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'CellRendererGroupUserActions',
  data: () => {
    return {
      item: null
    }
  },
  created() {
    this.item = this.params.data;
  },
  methods: {
    groupUserDeleteDialogShow() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Please Confirm`,
        text: 'Are you sure you want to remove this user from group?',
        acceptText: 'Yes, remove him/her',
        accept: this.deleteGroupUser
      })
    },

    deleteGroupUser() {
      axios.post('group-user/'+ this.item.id +'/destroy')
        .then(({data}) => {
          this.$vs.notify(
            {
              text: data.message,
              color:'success',
              position:'top-right',
              time:5000,
            }
          );

          window.location.reload();
        })
        .catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
    },
  }
}
</script>
