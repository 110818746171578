/**
 * Imports
 */

import Vue from 'vue'
import Vuex from 'vuex'
import '@/plugins/vue2-storage'
import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

/**
 * Use
 */

Vue.use(Vuex);

/**
 * Load store modules dynamically
 */

const requireContext = require.context('./modules', false, /.*\.js$/);
const modules = requireContext.keys()
  .map(file => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }

    return {...modules, [name]: module}
  }, {});

/**
 * Export store with modules
 */

export default new Vuex.Store({
  modules,
  getters,
  mutations,
  state,
  actions,
  strict: process.env.NODE_ENV !== 'production'
});
