<template>
  <vx-card>
    <form @submit.prevent="badgeFormSubmit">
      <!-- Img Row -->
      <vue-image-input :placeholder="'badges_image-128.jpg'" :folder="'badges_image'"
                       v-model="badgeForm.image"></vue-image-input>

      <!-- Info -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <label class="text-sm opacity-75">Group</label>
          <v-select :options="groups" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="badgeForm.group" placeholder="Select Group"
                    class="md:mb-0"/>
        </div>
      </div>

      <vs-input
        label="Name"
        name="name"
        v-model="badgeForm.name"
        class="w-full mt-2"/>
      <span class="text-danger text-sm" v-if="formControlHasError(badgeForm, 'name')">{{ formControlGetError(badgeForm, 'name') }}</span>

      <vs-textarea
        label="Description"
        name="description"
        v-model="badgeForm.description"
        class="w-full mt-2" height="200px"/>
      <span class="text-danger text-sm" v-if="formControlHasError(badgeForm, 'description')">{{ formControlGetError(badgeForm, 'description') }}</span>

      <div class="vx-row">
        <div class="vx-col w-full">
          <label class="text-sm opacity-75">Type</label>
          <v-select :options="types" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="badgeForm.type_object" placeholder="Select Type"
                    class="md:mb-0"/>
        </div>
      </div>

      <vs-input
        label="Value"
        name="value"
        v-model="badgeForm.value"
        class="w-full mt-2"/>
      <span class="text-danger text-sm" v-if="formControlHasError(badgeForm, 'value')">{{ formControlGetError(badgeForm, 'value') }}</span>

      <vs-checkbox v-model="badgeForm.public" class="mt-4">Public</vs-checkbox>
      <span class="text-danger text-sm" v-if="formControlHasError(badgeForm, 'public')">{{ formControlGetError(badgeForm, 'public') }}</span>

      <!-- Save & Reset Button -->
      <div class="flex-wrap items-center justify-end mt-2">
        <vs-button class="ml-auto mt-2" button="submit">Save</vs-button>
        <vs-button type="border" class="ml-4 mb-2" color="warning" @click.prevent="$router.go(-1)">Cancel</vs-button>
      </div>
    </form>
  </vx-card>
</template>

<script>
  import axios from 'axios'
  import {mapGetters} from 'vuex'
  import Form from 'vform'
  import FormErrors from '@/components/mixins/FormErrors'
  import Numbers from '@/components/mixins/Numbers'
  import moment from 'moment'
  import {BADGE_GROUPS, BADGE_VALUE_TYPES} from "@/shared/constants";
  import vSelect from 'vue-select'

  export default {

    // Set meta tags
    head: {
      title: {
        inner: 'New Badge'
      }
    },

    mixins: [FormErrors, Numbers],
    components: {
      vSelect
    },

    data: () => ({
      badgeForm: new Form({
        group: '',
        group_id: '',
        name: '',
        description: '',
        image: '',
        type: '',
        type_object: '',
        value: '',
        public: ''
      }),
      groups: BADGE_GROUPS,
      types: BADGE_VALUE_TYPES,
    }),

    methods: {
      badgeFormSubmit() {
        this.formResetErrors(this.badgeForm);

        this.badgeForm.group_id = this.badgeForm.group != '' ? this.badgeForm.group.value : '';
        this.badgeForm.type = this.badgeForm.type_object != '' ? this.badgeForm.type_object.value : '';

        this.badgeForm.post('admin/badges/create')
          .then(({data}) => {
            let badge = data.badge;

            this.$vs.notify(
              {
                text: data.message,
                color:'success',
                position:'top-right',
                time:5000,
              }
            );

            this.$router.push({ name: 'admin.badges'});
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },

    }
  }
</script>
