<template>
  <div class="flex items-center">
    {{ params.value | timeAsPace }}
  </div>
</template>

<script>
export default {
  name: 'CellRendererPace',
  computed: {
  }
}
</script>
