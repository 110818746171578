<template>
  <div class="flex items-center">
    <router-link :to="{ name: 'results.show', params: { id: params.data.id }}" @click.stop.prevent class="text-inherit hover:text-primary" :title="params.value">{{ params.value }}</router-link>
  </div>
</template>

<script>
export default {
  name: 'CellRendererWorkoutLink',
  computed: {
  }
}
</script>
