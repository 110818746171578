<template>
    <vx-card v-if="show" class="mb-base">
      <template v-if="this.authenticatedUser.subscription_status == 1">
        <div class="h5 text-warning mb-1 mt-2">Your subscription is in pending status!</div>
        <div class="text-muted text-uppercase font-weight-bold font-xs">To review click <router-link :to="{ name: 'account.billing.edit'}">here</router-link></div>
      </template>
      <template v-else-if="this.authenticatedUser.subscription_status == 3">
        <div class="h5 text-warning mb-1 mt-2">Your subscription has been canceled!</div>
        <template v-if="subscriptionExpired">
          <div class="text-muted text-uppercase font-weight-bold font-xs">Your subscription ended on {{ this.authenticatedUser.next_bill_date | formatDate }}. You can only view data from the last 365 days. To update click <router-link :to="{ name: 'account.billing.edit'}">here</router-link></div>
        </template>
        <template v-else>
          <div class="text-muted text-uppercase font-weight-bold font-xs">It will remain active until {{ this.authenticatedUser.next_bill_date | formatDate }}. To update click <router-link :to="{ name: 'account.billing.edit'}">here</router-link></div>
        </template>
      </template>
      <template v-else>
        <template v-if="isTrial">
          <div class="h5 text-primary mb-1 mt-2">14 Day Free Trial of Premium.</div>
          <div class="text-muted text-uppercase font-weight-bold font-xs">Your 14 day free trial is active. You can use all the premium features during your trial. After the trial is complete you'll automatically be downgraded to a free account. Upgrade any time to keep use Premium or Performance features. To update click <router-link :to="{ name: 'account.billing.edit'}">here</router-link></div>
        </template>
        <template v-else>
          <div class="h5 text-primary mb-1 mt-2">You are using a free plan!</div>
          <template v-if="subscriptionExpired">
            <div class="text-muted text-uppercase font-weight-bold font-xs">You can only view data from the last 365 days. To update click <router-link :to="{ name: 'account.billing.edit'}">here</router-link></div>
          </template>
          <template v-else>
            <div class="text-muted text-uppercase font-weight-bold font-xs">To see more features click <router-link :to="{ name: 'account.billing.edit'}">here</router-link></div>
          </template>
        </template>
      </template>
    </vx-card>
</template>

<script>
    import {mapGetters} from "vuex";
    import moment from "moment";

    export default {
        name: 'upgrade-widget',

        components: {},
        data: () => {
            return {
              allowedPlans: [2,3,4,5,6,7,8,9,10,11],
              show: false,
              isTrial: false,
            }
        },
        computed: mapGetters({
          authenticatedUser: 'auth/getAuthenticatedUser',
          subscriptionExpired : 'auth/getAuthenticatedUserHasSubscriptionExpired'
        }),
        methods: {
        },
        created() {
          if(this.allowedPlans.indexOf(this.authenticatedUser.subscription_plan_id) < 0 || this.authenticatedUser.subscription_status != 2)
          {
            this.show = true;
            this.isTrial = moment().subtract(14, 'day').isBefore(this.authenticatedUser.email_verified_at);
          }
        }
    }
</script>
