export default {
    methods: {
        parseToInt(value) {
            value = "" + $.trim(value) + "";
            value = value.replace(/(\.\d*[1-9])0+/, '$1').replace(/\.0*/, '');
            value = value.replace(/[^0-9-]/, "");
            value = value === "" ? 0 : value;
            return parseInt(value, 10);
        },

        parseToFloat(value) {
            value = "" + $.trim(value) + "";
            value = value.replace(/[^0-9-.]/, "");
            value = value === "" ? 0 : value;
            return parseFloat(value);
        }
    }
}
