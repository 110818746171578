<template>
  <div>
    <portal to="title-bar-actions">
      <vs-button radius icon="icon-plus" icon-pack="feather" title="New Group" :to="{ name: 'groups.create' }"/>
    </portal>
    <vs-row v-if="loaded">
      <template v-if="items.length">
          <template v-for="item in items">
            <div class="vx-col w-full lg:w-1/6 sm:w-1/2 mb-base mr-base">
              <single-group-card :item="item"></single-group-card>
            </div>
          </template>
      </template>
      <template v-else>
        <vx-card>
          There are no groups
        </vx-card>
      </template>
    </vs-row>
    <div v-else>
      <loader></loader>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import SingleGroupCard from "../../../components/groups/SingleGroupCard";

  export default {
    components: {SingleGroupCard},
    head: {
      title: {
        inner: 'My Groups'
      }
    },
    data: () => {
      return {
        loaded: false,
        items: [],
      }
    },
    methods: {
      getGroups() {
        this.loaded = false;

        axios.get('users/me/groups?per_page=1000&order_by=name&order_direction=asc&include=logged_in_user_group').then((data) => {
          this.items = data.data.groups.data;
          this.loaded = true;
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },
    },
    created() {
      this.getGroups();
    }
  }

</script>
