<template>
  <vx-card>
    <div class="vs-row" style="display: flex">
      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
        <vs-icon icon-pack="material-icons" icon="people" class="inline-flex rounded-full mb-4" size="large"/>
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ group.statistics.number_of_users | formatNumber }}</h2>
          <span>Users</span>
        </div>
      </div>

      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
        <vs-icon icon-pack="material-icons" icon="format_list_numbered" class="inline-flex rounded-full mb-4" size="large"/>
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ group.statistics.number_of_workouts | formatNumber }}</h2>
          <span>Workouts</span>
        </div>
      </div>

      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
        <vs-icon icon-pack="material-icons" icon="settings_ethernet" class="inline-flex rounded-full mb-4" size="large"/>
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ group.statistics.total_distance | formatNumber }}m</h2>
          <span>Total Distance</span>
        </div>
      </div>

      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
        <vs-icon icon-pack="material-icons" icon="access_time" class="inline-flex rounded-full mb-4" size="large"/>
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ group.statistics.total_time | timeInHours }}</h2>
          <span>Total Time</span>
        </div>
      </div>

      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
        <vs-icon icon-pack="material-icons" icon="speed" class="inline-flex rounded-full mb-4" size="large"/>
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ group.statistics.average_pace | timeAsPace }}</h2>
          <span>Ave. Pace</span>
        </div>
      </div>

      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
        <vs-icon icon-pack="material-icons" icon="format_list_numbered_rtl" class="inline-flex rounded-full mb-4" size="large"/>
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ group.statistics.number_of_strokes | formatNumber }}</h2>
          <span>Strokes</span>
        </div>
      </div>

    </div>
  </vx-card>
</template>

<script>
    export default {
        name: 'group-summary-widget',

      props: {
        group: {
          type: [Array, Object],
          default: () => []
        },
      },
    }
</script>
