<template>
  <div>
    <template v-if="items.length">
      <ag-grid-vue class="ag-theme-material w-100 ag-grid-table"
                   :columnDefs="columnDef"
                   :defaultColDef="defaultColDef"
                   :rowData="items"
                   @first-data-rendered="onFirstDataRendered">
      </ag-grid-vue>
    </template>
    <template v-else>
      There are no workouts
    </template>
  </div>
</template>

<script>
  import { AgGridVue } from 'ag-grid-vue'
  import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

  import CellRendererDistance from './../global/cell-renderer/CellRendererDistance.vue'
  import CellRendererTime from './../global/cell-renderer/CellRendererTime.vue'
  import CellRendererPace from './../global/cell-renderer/CellRendererPace.vue'
  import CellRendererType from './../global/cell-renderer/CellRendererType.vue'
  import CellRendererWorkoutType from './../global/cell-renderer/CellRendererWorkoutType.vue'
  import CellRendererGroupUserImage from './cell-renderer/CellRendererGroupUserImage.vue'
  import CellRendererGroupUserName from './cell-renderer/CellRendererGroupUserName.vue'

  export default {
    name: 'ag-group-workouts-list',

    components: {
      AgGridVue,
      CellRendererGroupUserImage,
      CellRendererGroupUserName,
      CellRendererDistance,
      CellRendererTime,
      CellRendererPace,
      CellRendererWorkoutType,
      CellRendererType
    },
    props: {
      items: {
        type: [Array, Object],
        default: () => []
      },
      sortBy: {
        type: String,
        default: 'number_of_workouts'
      },
      sortDirection: {
        type: String,
        default: 'asc'
      }
    },
    data: () => {
      return {
        defaultColDef: { resizable: true },
        gridColumnApi: null,
      }
    },
    computed: {
      columnDef() {
        if(this.$store.state.windowWidth < 992) {
          return [
            {field: 'name', headerName: 'Name', sortable: true, cellRendererFramework: 'CellRendererGroupUserName'},
          ]
        }
        return [
          {field: 'image', headerName: '', cellRendererFramework: 'CellRendererGroupUserImage'},
          {field: 'name', headerName: 'Name', cellRendererFramework: 'CellRendererGroupUserName', suppressSizeToFit: true},
          {field: 'date', headerName: 'Date'},
          {field: 'name', headerName: 'Workout Name'},
          {field: 'distance', headerName: 'Distance', cellRendererFramework: 'CellRendererDistance'},
          {field: 'time', headerName: 'Time', cellRendererFramework: 'CellRendererTime'},
          {field: 'pace', headerName: 'Pace', cellRendererFramework: 'CellRendererPace'},
          {field: 'type', headerName: 'Type', cellRendererFramework: 'CellRendererType'},
          {field: 'workout_type', headerName: 'Workout Type', cellRendererFramework: 'CellRendererWorkoutType'},
        ];
      }

    },
    methods: {
      onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
      }
    },
    created() {
    }
  }
</script>
