<template>
  <div class="flex flex-wrap items-center mb-base image-holder">
    <vs-avatar :src="url" size="70px" class="mr-4 mb-4" @click="selectImage"/>
    <div>
      <vs-button class="mr-4 sm:mb-0 mb-2" @click="selectImage">Upload photo</vs-button>
      <vs-button v-show="value.length > 0" type="border" color="danger" @click.prevent="clearImage">Remove</vs-button>
      <p class="text-sm mt-2">Allowed JPG, GIF or PNG. Max size of 800kB</p>
    </div>
    <input ref="fileInput" type="file" :id="fileInputId" @change="onFileChange">
  </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'VueImageInput',

        model: {
            event: 'change',
            prop: 'value'
        },

        props: {
            placeholder: {type: String, default: ''},
            folder: {type: String, default: ''},
            value: null
        },

        data: () => ({
            fileInputId: ''
        }),

        computed: {
            url: function () {
                return (this.value.length > 0) ? (process.env.VUE_APP_API_URL + '/uploads/' + this.folder + '/' + this.value) : (process.env.VUE_APP_API_URL + '/img/placeholders/' + this.placeholder)
            }
        },

        mounted() {
            this.setFileInputId();
        },

        methods: {
            setFileInputId() {
                let len = 32, rand = "", possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz", i;
                for (i = 0; i < len; i = i + 1) {
                    rand += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                this.fileInputId = rand;
            },

            async selectImage() {
                document.getElementById(this.fileInputId).click()
            },

            async clearImage() {
                this.$emit('change', '');
            },

            async onFileChange(e) {
                let files = (e.target && e.target.files) ? e.target.files : ((e.dataTransfer && e.dataTransfer.files) ? e.dataTransfer.files : []);

                if (files.length < 1) {
                    return
                }

                if (files[0].size <= 0) {
                    this.$vs.notify({
                      text: 'Selected file is empty or invalid.',
                      color:'danger',
                      position:'top-right',
                      time:5000,
                    });

                    return
                }

                if (files[0].type.substr(0, 6) !== 'image/') {
                    this.$vs.notify({
                      text: 'Selected file is not an image.',
                      color:'danger',
                      position:'top-right',
                      time:5000,
                    });

                    return
                }

                const formData = new FormData();
                formData.append('source', files[0]);

                axios.post('/file/upload?allowed_types=image&folder=' + this.folder, formData, {headers: {'content-type': 'multipart/form-data'}})
                    .then(({data}) => {
                        this.$emit('change', data.filename);
                    })
                    .catch(error => {
                        this.$vs.notify({
                          text: error.response.data.message,
                          color:'danger',
                          position:'top-right',
                          time:5000,
                        });
                    });
            }
        }
    };
</script>
