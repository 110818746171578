<template>
  <span>
    {{ params.value | formatGroupUserStatus }}
  </span>
</template>

<script>
export default {
  name: 'CellRendererGroupUserStatus',
  computed: {
  }
}
</script>
