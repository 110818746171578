<template>
    <div>
        -
    </div>
</template>

<script>
    export default {
        beforeMount: async function () {
            await this.$store.dispatch('auth/logout');

            this.$store.dispatch('auth/activateAccount', {encrypted_id: this.$route.params.encrypted_id || '', encrypted_email: this.$route.params.encrypted_email || ''})
                .then(({data}) => {
                    this.$store.dispatch('auth/login', {
                        accessToken: data.token.access_token,
                        refreshToken: data.token.refresh_token,
                        expiresIn: parseInt(data.token.expires_in),
                        user: data.user
                    });

                    this.$router.push({name: 'account.home.index'});
                })
                .catch(error => {
                    this.$vs.notify({
                      text: error.response.data.message,
                      color:'danger',
                      position:'top-right',
                      time:5000,
                    });

                    this.$router.push({name: 'web.auth.login'});
                });
        }
    }
</script>
