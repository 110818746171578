export const SECONDS_IN_HOUR = 3600;
export const DATE_FORMAT_API = 'YYYY-MM-DD';
export const DATE_FORMAT_LONG = 'ddd DD/MMM/YYYY';
export const DATE_FORMAT_SHORT = 'DD/MMM/YYYY';
export const DATE_TIME_PICKER_DATE_FORMAT = 'D d/M/yy';
export const DAY_FORMAT_SHORT = 'ddd';
export const DAY_MONTH_FORMAT = 'DD MMM';
export const HOURS_FORMAT = 'HH';
export const MINUTES_FORMAT = 'mm';
export const MINUTES_SECONDS_FORMAT = 'mm:ss';
export const MONTH_YEAR_FORMAT = 'MMMM-YYYY';
export const TIME_FORMAT_LONG = 'HH:mm:ss';
export const TIME_FORMAT_SHORT = 'HH:mm';
export const TIME_FORMAT_IN_MINUTES_LONG = 'mm:ss.S';
export const TIME_FORMAT_FULL = 'HH:mm:ss.S';
export const YEAR_FORMAT = 'YYYY';
export const YEAR_MONTH_FORMAT = 'YYYY-MM';

export const DAY_MONTH_DATE = 'ddd MMM DD';

export const MACHINE_TYPES = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Rower',
    value: '1'
  }, {
    label: 'SkiErg',
    value: '2'
  }, {
    label: 'Bike',
    value: '3'
  }, {
    label: 'Dynamic',
    value: '4',
  }, {
    label: 'Slides',
    value: '5',
  }, {
    label: 'Paddle',
    value: '6',
  }, {
    label: 'Water',
    value: '7',
  }, {
    label: 'Snow',
    value: '8',
  }, {
    label: 'Rollerski',
    value: '9',
  }, {
    label: 'MultiErg',
    value: '10',
  }];

export const WORKOUT_TYPES = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Unknown',
    value: '1'
  }, {
    label: 'Just Row',
    value: '2'
  }, {
    label: 'Fixed Distance Splits',
    value: '3'
  }, {
    label: 'Fixed Time Splits',
    value: '4',
  }, {
    label: 'Fixed Calorie',
    value: '5',
  }, {
    label: 'Fixed Time Interval',
    value: '6',
  }, {
    label: 'Fixed Distance Interval',
    value: '7',
  }, {
    label: 'Fixed Calorie Interval',
    value: '8',
  }, {
    label: 'Fixed Variable Interval',
    value: '9',
  }, {
    label: 'Fixed Variable Interval / Undefined Rest',
    value: '8',
  }];

export const WORKOUT_TYPES_PREDEFINED = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Fixed Distance Splits',
    value: '3'
  }, {
    label: 'Fixed Time Splits',
    value: '4',
  }, {
    label: 'Fixed Calorie',
    value: '5',
  }, {
    label: 'Fixed Time Interval',
    value: '6',
  }, {
    label: 'Fixed Distance Interval',
    value: '7',
  }, {
    label: 'Fixed Calorie Interval',
    value: '8',
  }, {
    label: 'Fixed Variable Interval',
    value: '9',
  }, {
    label: 'Fixed Variable Interval / Undefined Rest',
    value: '8',
  }];

export const TEST_NAMES = ['100m', '01:00', '250m', '02:00', '500m', '04:00', '1,000m', '2,000m', '5,000m', '6,000m', '10,000m', '30:00', '01:00:00', '21,097m', '42,195m'];
export const TEST_NAMES_TYPE = ['', 'row', 'ski', 'bike', 'dynamic', 'slides', 'paddle', 'water', 'snow', 'rollerski', 'multiErg'];

export const BADGE_GROUPS = [
  {
    label: 'Top',
    value: '913153e2-d5bc-11eb-b8bc-0242ac130003'
  }];

export const BADGE_VALUE_TYPES = [
  {
    label: 'Total Distance',
    value: '1'
  }, {
    label: 'Total Time',
    value: '2'
  }, {
    label: 'Single Workout Distance',
    value: '3'
  }, {
    label: 'Single Workout Time',
    value: '4'
  }, {
    label: 'Single Workout Split (avg)',
    value: '5'
  }, {
    label: 'Total Number Of Sessions',
    value: '6'
  }];

export const SPORTS = [
  {
    label: 'Row',
    value: '1'
  }, {
    label: 'Bike',
    value: '2'
  }, {
    label: 'Ski',
    value: '3'
  }, {
    label: 'Gym',
    value: '4'
  }, {
    label: 'Run',
    value: '5'
  }, {
    label: 'Swim',
    value: '6'
  }, {
    label: 'Other',
    value: '255'
  }];
