<template>
    <ag-group-leaderboard-list :items="items" :sort-by="sortBy" :sort-direction="sortDirection"/>
</template>

<script>
    import AgGroupLeaderboardList from "./AgGroupLeaderboardList";
    export default {
        name: 'leaderboard-list',

        components: {AgGroupLeaderboardList},
        props: {
            items: {
                type: [Array, Object],
                default: () => []
            },
          sortBy: {
              type: String,
              default: 'number_of_workouts'
            },
          sortDirection: {
              type: String,
              default: 'asc'
            }
        },
        data: () => {
            return {
            }
        },
      computed: {
      },
      methods: {
      },
      created() {
      }
    }
</script>
