<template>
  <div>
    <portal to="title-bar-actions">
      <vs-button v-if="false" radius icon="icon-plus" icon-pack="feather" title="New Workout Plan" :to="{ name: 'workout-plans.create' }"/>
    </portal>
    <vs-row v-if="loaded">
      <template v-if="items.length">
        <template v-for="item in items">
          <div class="vx-col w-full lg:w-1/6 sm:w-1/2 mb-base mr-base">
            <workout-plan-grid-view :item="item">
              <template slot="action-buttons">
                <div class="flex flex-wrap">

                  <!-- PRIMARY BUTTON: ADD TO WISH LIST -->
                  <div v-if="false"
                    class="item-view-primary-action-btn p-3 flex flex-grow items-center justify-center cursor-pointer">
                    <feather-icon icon="HeartIcon" :svgClasses="[{'text-danger fill-current' : 0}, 'h-4 w-4']" />

                    <span class="text-sm font-semibold ml-2">WISHLIST</span>
                  </div>

                  <!-- SECONDARY BUTTON: ADD-TO/VIEW-IN CART -->
                  <div
                    class="item-view-secondary-action-btn bg-primary p-3 flex flex-grow items-center justify-center text-white cursor-pointer"
                    @click="openCheckout(item.paddle_id, item.id)">
                    <feather-icon icon="ShoppingBagIcon" svgClasses="h-4 w-4" />

                    <span class="text-sm font-semibold ml-2" v-if="0">VIEW IN CART</span>
                    <span class="text-sm font-semibold ml-2" v-else>ADD TO CART</span>
                  </div>
                </div>
              </template>
            </workout-plan-grid-view>
          </div>
        </template>
      </template>
      <template v-else>
        <vx-card>
          There are no workout plans
        </vx-card>
      </template>
    </vs-row>
    <div v-else>
      <loader></loader>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Form from 'vform'
  import FormErrors from '@/components/mixins/FormErrors'
  import $ from 'jquery'
  import WorkoutPlanGridView from "../../../components/workout-plans/WorkoutPlanGridView";
  import {mapGetters} from "vuex";

  export default {
    components: {WorkoutPlanGridView},
    mixins: [FormErrors],
    data: () => ({
      filterForm: new Form({
        search_name: '',
      }),
      loaded: false,
      items: [],
    }),
    watch: {
      filterForm: {
        handler(val) {
            this.filterFormSubmit();
        },
        deep: true
      },
    },
    computed: mapGetters({
      authenticatedUser: 'auth/getAuthenticatedUser',
    }),
    methods: {
      async filterFormSubmit() {
        this.loaded = false;
        let params = this.filterForm;

        axios.get('workout-plans?per_page=1000&order_by=name&order_direction=asc'+ $.param({filters: params})).then((data) => {
          this.items = data.data.workout_plans.data;
          this.loaded = true;
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },
      resetFilter() {
        this.filterForm = new Form({
          search_name: '',
        });
        this.loaded = false;
      },
      openCheckout(productId, workoutPlanId) {
        var self = this;
        Paddle.Checkout.open({
          product: productId,
          email: this.authenticatedUser.email,
          passthrough: '{"user_id": "'+ this.authenticatedUser.id +'", "workout_plan_id": "'+ workoutPlanId +'"}',
          successCallback: function (data) {
            self.$vs.notify({
              text: "Thank you!",
              color:'success',
              position:'top-right',
              time:5000,
            });
            self.$parent.key ++;
          },
          closeCallback: function (data) {
            //debugger;
            // console.log(data);
            // alert('Failed to checkout');
          }
        });
      }
    },
    created() {
      this.filterForm = new Form({
        search_name: '',
      });
    },
    mounted() {
      Paddle.Setup({vendor: 105993});
      this.$store.dispatch('auth/fetchUser');
    },
  }

</script>
