<template>
  <div class="vx-row">
    <portal to="title-bar-actions v-if=false">
      <vs-button radius icon="icon-share-2" icon-pack="feather" @click="shareModal = true"/>
    </portal>
    <div class="dashboard-analytics vx-col w-full mb-base" v-if="loaded">
      <vx-card slot="no-body" class="text-center bg-primary-gradient greet-user">
        <img src="@/assets/images/elements/decore-left.png" class="decore-left" alt="Decore Left" width="200" >
        <img src="@/assets/images/elements/decore-right.png" class="decore-right" alt="Decore Right" width="175">
        <feather-icon icon="AwardIcon" class="p-6 mb-8 bg-primary inline-flex rounded-full text-white shadow" svgClasses="h-8 w-8"></feather-icon>
        <template v-if="this.authenticatedUser != null && this.authenticatedUser.id == this.result.id">
          <h1 class="mb-6 text-white">Your 2022 in Stats</h1>
          <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white">Here are your achievements from 2022.</p>
          <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white">Share your year with friends - <strong>{{ shareUrl }}</strong></p>
        </template>
        <template v-else-if="this.authenticatedUser != null">
          <h1 class="mb-6 text-white">{{ this.title }}</h1>
          <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white">Here are their achievements from the year just gone..</p>
          <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white">See your stats <router-link class="text-white font-bold" :to="{ name: 'yearly-stats.show', params: { id: this.authenticatedUser.id }}">here</router-link></p>
        </template>
        <template v-else>
          <h1 class="mb-6 text-white">{{ this.title }}</h1>
          <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white">Here are their achievements from the year just gone..</p>
          <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white">See your stats for 2022 by joining ErgMonkey - <router-link class="text-white font-bold" :to="{ name: 'web.auth.register'}">Join today</router-link></p>
        </template>

      </vx-card>
    </div>
    <div class="vx-col w-full mb-base">
      <public-summary-2022-widget/>
    </div>
    <div class="vx-col w-full mb-base">
      <public-workouts-2022-heatmap />
    </div>
    <div class="vx-col w-full mb-base">
      <public-cumulative-2022-distance-trend />
    </div>
    <div class="dashboard-analytics vx-col w-full mb-base" v-if="loaded">
      <vx-card slot="no-body" class="text-center bg-primary-gradient greet-user">
        <h1 class="text-white">You've made an impact on all your best scores this year!</h1>
      </vx-card>
    </div>
    <div class="vx-col w-full mb-base">
      <public-pbs-2022-summary/>
    </div>
    <div class="vx-col w-full mb-base">
      <public-pbs-2022-heatmap />
    </div>
    <div class="dashboard-analytics vx-col w-full mb-base" v-if="loaded">
      <vx-card slot="no-body" class="text-center bg-primary-gradient greet-user">
        <h1 class="text-white">You've had a lot of fun on the machine and these are favourites workouts, other stats.</h1>
      </vx-card>
    </div>
    <div class="vx-col w-full mb-base">
      <public-most-frequent-2022-workouts/>
    </div>
    <div class="vx-col w-full mb-base">
      <public-summary-per-week-day-2022-chart/>
    </div>
    <div class="vx-col w-full mb-base">
      <public-summary-per-hour-2022-chart/>
    </div>
    <div class="dashboard-analytics vx-col w-full mb-base" v-if="loaded">
      <vx-card slot="no-body" class="text-center bg-primary-gradient greet-user">
        <h1 class="text-white">Here is a breakdown of what you did on each machine.</h1>
      </vx-card>
    </div>
    <div class="vx-col w-full mb-base">
      <public-summary-widget-2022-per-type/>
    </div>
    <template v-if="this.authenticatedUser == null">
      <vx-card slot="no-body" class="text-center bg-primary-gradient greet-user">
        <h1 class="text-white">See your stats for 2022 by joining ErgMonkey - <router-link class="text-white font-bold" :to="{ name: 'web.auth.register'}">Join today</router-link></h1>
      </vx-card>
    </template>

    <vs-popup title="Share Workout" :active.sync="shareModal">
      <vs-row class="mb-2">
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="facebook" class="vs-component vs-button vs-button-null vs-button-filled btn-facebook w-full mr-2">
              <feather-icon icon="FacebookIcon" svgClasses="h-4 w-4" /> Facebook
            </network>
          </vs-col>
        </social-sharing>
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="twitter" class="vs-component vs-button vs-button-null vs-button-filled btn-twitter w-full ml-2">
              <feather-icon icon="TwitterIcon" svgClasses="h-4 w-4" /> Twitter
            </network>
          </vs-col>
        </social-sharing>
      </vs-row>
      <vs-row class="mb-2">
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="linkedin" class="vs-component vs-button vs-button-null vs-button-filled btn-linkedin w-full mr-2">
              <feather-icon icon="LinkedinIcon" svgClasses="h-4 w-4" /> LinkedIn
            </network>
          </vs-col>
        </social-sharing>
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="reddit" class="vs-component vs-button vs-button-null vs-button-filled btn-reddit w-full ml-2">
              <feather-icon icon="RedditIcon" svgClasses="h-4 w-4" /> Reddit
            </network>
          </vs-col>
        </social-sharing>
      </vs-row>
      <vs-row class="mb-2">
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="email" class="vs-component vs-button vs-button-warning vs-button-filled w-full mr-2">
              <feather-icon icon="MailIcon" svgClasses="h-4 w-4" /> Email
            </network>
          </vs-col>
        </social-sharing>
        <vs-col vs-w="6">
          <vs-button color="primary" v-clipboard:copy="shareUrl" v-clipboard:success="onCopySuccess" class="w-full ml-2">
            <feather-icon icon="CopyIcon" svgClasses="h-4 w-4" /> Copy
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
  import axios from 'axios'
  import {each} from 'lodash';
  import {formatDateTime} from "../../../shared/utils";
  import moment from "moment";
  import PublicSummary2022Widget from "../../../components/reports/PublicSummary2022Widget";
  import {mapGetters} from "vuex";
  import PublicWorkouts2022Heatmap from "@/components/reports/PublicWorkouts2022Heatmap";
  import PublicCumulative2022DistanceTrend from "@/components/dashboard/PublicCumulative2022DistanceTrend";
  import PublicPbs2022Summary from "@/components/reports/PublicPbs2022SummaryWidget";
  import PublicPbs2022Heatmap from "@/components/reports/PublicPbs2022Heatmap";
  import PublicMostFrequent2022Workouts from "@/components/reports/PublicMostFrequent2022Workouts";
  import PublicSummaryPerWeekDay2022Chart from "@/components/reports/PublicSummaryPerWeekDay2022Chart";
  import PublicSummaryPerHour2022Chart from "@/components/reports/PublicSummaryPerHour2022Chart";
  import PublicSummaryWidget2022PerType from "@/components/reports/PublicSummaryWidget2022PerType";

  export default {
    head: {
      title: function () {
        return {
          inner: this.title
        }
      },
    },

    components: {
      PublicSummaryWidget2022PerType,
      PublicSummaryPerHour2022Chart,
      PublicSummaryPerWeekDay2022Chart,
      PublicMostFrequent2022Workouts,
      PublicPbs2022Heatmap,
      PublicPbs2022Summary,
      PublicCumulative2022DistanceTrend,
      PublicWorkouts2022Heatmap,
      PublicSummary2022Widget
    },
    computed: {
      shareUrl: function () {
        return 'https://stats.ergmonkey.com/year/2022/' + this.$route.params.id;
      },
      shareTitle: function () {
        return this.page_title;
      },
      ...mapGetters({
        authenticatedUser: 'auth/getAuthenticatedUser',
      })
    },
    data: () => {
      return {
        loaded: false,
        result: null,
        title: '',
        page_title: '',
        shareModal: false,
        shareDesription: '',
        shareQuote: '',
        shareHashtags: '',
        shareTwitterUser: 'ergmonkey_'
      }
    },
    methods: {
      getUser(userId) {
        this.loaded = false;

        axios.get('users/find/' + userId).then((data) => {
          this.result = data.data.user;
          this.title = this.result.first_name + ' ' + this.result.last_name + ' 2022 in Stats';
          this.loaded = true;
        }).catch(error => {
          this.$store.dispatch('alerts/addRegularAlert', {
            contentHTML: error.response.data.message,
            dismissible: true,
            duration: 5000,
            type: 'danger'
          });
        });
      },

      onCopySuccess() {
        this.shareModal = false;
        this.$vs.notify({
          text: "Copied!",
          color:'success',
          position:'top-right',
          time:5000,
        });
      },
    },
    created() {
      this.getUser(this.$route.params.id);
    },
    beforeRouteUpdate(to, from, next) {
      this.getUser(to.params.id);
      next();
    }
  }
</script>

<style lang="scss">
  /*! rtl:begin:ignore */
  .dashboard-analytics {
    .greet-user{
      position: relative;

      .decore-left{
        position: absolute;
        left:0;
        top: 0;
      }
      .decore-right{
        position: absolute;
        right:0;
        top: 0;
      }
    }

    @media(max-width: 576px) {
      .decore-left, .decore-right{
        width: 140px;
      }
    }
  }
  /*! rtl:end:ignore */
</style>
