import { render, staticRenderFns } from "./WorkoutsInspirationContainer.vue?vue&type=template&id=967eb58c&"
import script from "./WorkoutsInspirationContainer.vue?vue&type=script&lang=js&"
export * from "./WorkoutsInspirationContainer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports