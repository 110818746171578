<template>
    <div v-if="loaded">
        <template v-if="items.length">
            <template v-for="item in items">
              Sport: <span class="font-semibold">{{ item.sport | formatSportName }}</span><br/>
              Name: <span class="font-semibold">{{ item.name }}</span><br/>
              <template v-if="item.duration">
                Duration: <span class="font-semibold">{{ item.duration | formatWorkoutInspirationDuration }}</span><br/>
              </template>
              <template v-if="item.source">
                Source: <span class="font-semibold">{{ item.source }}</span><br/><br/>
              </template>
              Description: <span class="font-semibold">{{ item.description }}</span><br/>
            </template>
        </template>
        <template v-else>
            There are no workouts
        </template>
    </div>
    <div v-else>
        <loader></loader>
    </div>
</template>


<script>
    import axios from 'axios'

    export default {
        name: 'workouts-inspiration',

        components: {},
        data: () => {
            return {
                loaded: false,
                items: [],
                fields: [
                    {key: 'name', label: 'Name', sortable: true},
                ],
            }
        },
        methods: {
            getResults() {
                this.loaded = false;

                axios.get('/workouts?order_by=rand&order_direction=asc').then((data) => {
                    this.items = data.data.workouts.data;
                    this.loaded = true;
                }).catch(error => {
                  this.$vs.notify({
                    text: error.response.data.message,
                    color:'danger',
                    position:'top-right',
                    time:5000,
                  });
                });
            }
        },
        created() {
            this.getResults();
        }
    }
</script>
