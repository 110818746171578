<template>
  <vx-card>
    <template slot="title">
      <router-link :to="{ name: 'public-groups.show', params: { id: item.id }}"><h4 class="">{{ item.name }}</h4></router-link>
      <h6 class="" style="color: rgb(184, 194, 204);">{{ item.description | truncate(25) }}</h6>
    </template>
    <template slot="actions">
      <template v-if="item.logged_in_user_group != undefined && item.logged_in_user_group.status == 1  && item.logged_in_user_group.role == 1">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <small class="flex cursor-pointer">
            <feather-icon icon="MoreVerticalIcon" svgClasses="w-6 h-6 text-grey"></feather-icon>
          </small>
          <vs-dropdown-menu class="w-32">
            <vs-dropdown-item :to="{ name: 'groups.edit', params: { id: item.id }}">Edit</vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template v-else>
        &nbsp;
      </template>
    </template>
    <router-link :to="{ name: 'public-groups.show', params: { id: item.id }}"><img :src="item.image != null ? item.image_url_256 : 'https://api.ergmonkey.com/img/placeholders/groups_image-256.jpg'" class="responsive rounded-lg"></router-link>
    <div class="flex mt-6 flex-wrap" v-if="false">
      <span class="flex mr-6">
        <feather-icon class="cursor-pointer" icon="UsersIcon" svgClasses="text-success stroke-current h-6 w-6" />
        <span class="ml-2">111</span>
      </span>
    </div>
  </vx-card>
</template>

<script>
  export default {
    name: 'single-group-card',
    props: {
      item: {
        type: [Object],
        default: () => {}
      },
    },
    computed: {},
    methods: {}
  }
</script>

