<template>
  <vx-card ref="workoutInspirationCard" title="Workout Inspiration" refresh-content-action @refresh="refreshWorkoutInspiration">
    <workouts-inspiration :key="inspiration_key"></workouts-inspiration>
  </vx-card>
</template>


<script>
    import WorkoutsInspiration from "./WorkoutsInspiration";

    export default {
        name: 'workouts-inspiration-container',

        components: {WorkoutsInspiration},
        data: () => {
            return {
              inspiration_key: 0,
            }
        },
        methods: {
          refreshWorkoutInspiration () {
            this.inspiration_key++;
            this.$refs.workoutInspirationCard.removeRefreshAnimation();
          }
        },
        created() {
        }
    }
</script>
