<template>
  <div class="flex items-center">
    <template v-if="params.value == 0">
      -
    </template>
    <template v-else-if="params.value > 0">
      <span class="text-success">+ {{ params.value | formatPercentage }}%</span>
    </template>
    <template v-else>
      <span class="text-danger">{{ params.value | formatPercentage }}%</span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CellRendererDifference',
  computed: {
  }
}
</script>
