<template>
  <div v-if="loaded">
    <div class="vx-col w-full mb-base">
      <result-summary-widget :result="result"/>
    </div>
    <vs-row>
      <vs-col v-for="interval in intervalDataForChart">
        <vx-card class="mb-base" :title="interval.title">
          <split-chart :interval=interval :source="result.source"></split-chart>
        </vx-card>
      </vs-col>
    </vs-row>
    <div class="vx-row flex-col-reverse md:flex-col-reverse sm:flex-row lg:flex-row mb-base">
      <div class="vx-col w-full md:w-full sm:w-full lg:w-1/2 xl:w-1/2 flex flex-col justify-between">
        <interval-table-card :result="result"></interval-table-card>
      </div>
      <div class="vx-col w-full md:w-full sm:w-full lg:w-1/2 xl:w-1/2 flex flex-col lg:mb-0 md:mb-base sm:mb-0 mb-base">
        <public-history-comparasion :result="result"></public-history-comparasion>
      </div>
    </div>
    <div class="vx-row flex-col-reverse md:flex-col-reverse sm:flex-row lg:flex-row mb-base">
      <div class="vx-col w-full md:w-full sm:w-full lg:w-1/2 xl:w-1/2 flex flex-col justify-between">
        <vx-card title="Workout Details">
          Name: <span class="font-bold">{{ result.name }}</span><br/>
          Date: <span class="font-bold">{{ result.date | formatDateTime }}</span><br/>
          Workout: <span class="font-bold">{{ result.type | formatTypeOfSession }}</span><br/>
          Workout Type: <span class="font-bold">{{ result.workout_type | formatWorkoutType }}</span><br/>
          Weight Class: <span class="font-bold">{{ result.weight_class | formatWeight }}</span><br/>
          Verified: <span class="font-bold">{{ result.verified ? 'Yes' : 'No' }}</span><br/>
          Entered: <span class="font-bold">{{ result.source_app }}</span><br/>
          Comment: <span class="font-bold">{{ result.comments }}</span><br/>
        </vx-card>
      </div>
      <div class="vx-col w-full md:w-full sm:w-full lg:w-1/2 xl:w-1/2 flex flex-col lg:mb-0 md:mb-base sm:mb-0 mb-base">
        <public-single-workout-hr-pie-chart :result="result"></public-single-workout-hr-pie-chart>
      </div>
    </div>
    <vs-row class="mb-base" v-if="hasPermissionToSeeFeature([3,5])">
      <public-ergo-score-trend-chart :result="result"></public-ergo-score-trend-chart>
    </vs-row>
    <vs-row class="mb-base" v-if="hasPermissionToSeeFeature([3,5])">
      <public-pace-trend-chart :result="result"></public-pace-trend-chart>
    </vs-row>
    <vs-row class="mb-base" v-if="hasPermissionToSeeFeature([3,5])">
      <public-rate-and-pace-trend-chart :result="result"></public-rate-and-pace-trend-chart>
    </vs-row>
    <vs-row class="mb-base" v-if="hasPermissionToSeeFeature([3,5])">
      <public-pb-trend-chart :result="result"></public-pb-trend-chart>
    </vs-row>
  </div>
</template>

<script>
  import axios from 'axios'
  import {each} from 'lodash';
  import SplitChart from "../../../components/result/SplitChart";
  import IntervalTable from "../../../components/result/IntervalTable";
  import {formatDateTime} from "../../../shared/utils";
  import moment from "moment";
  import PublicHistoryComparasion from "../../../components/result/PublicHistoryComparasion";
  import ResultSummaryWidget from "../../../components/result/ResultSummaryWidget";
  import IntervalTableCard from "../../../components/result/IntervalTableCard";
  import SingleWorkoutHrPieChart from "../../../components/result/SingleWorkoutHrPieChart";
  import PublicErgoScoreTrendChart from "../../../components/result/PublicErgoScoreTrendChart";
  import PublicPaceTrendChart from "../../../components/result/PublicPaceTrendChart";
  import PublicRateAndPaceTrendChart from "../../../components/result/PublicRateAndPaceTrendChart";
  import PublicPbTrendChart from "../../../components/result/PublicPbTrendChart";
  import PublicSingleWorkoutHrPieChart from "../../../components/result/PublicSingleWorkoutHrPieChart";

  export default {
    head: {
      title: function () {
        return {
          inner: this.title
        }
      },
    },

    components: {
      PublicSingleWorkoutHrPieChart,
      PublicPbTrendChart,
      PublicRateAndPaceTrendChart,
      PublicPaceTrendChart,
      PublicErgoScoreTrendChart,
      SingleWorkoutHrPieChart,
      IntervalTableCard,
      ResultSummaryWidget,
      PublicHistoryComparasion,
      IntervalTable,
      SplitChart,
    },
    computed: {
      intervalDataForChart: function () {
        let items = [];
        let title = '';
        let row_number = 1;
        let previous_timestamp = 0;
        var i = 0;

        if (this.result.details != undefined && this.result.details.workout.intervals != undefined) {
          items = this.result.details.workout.intervals;
          title = 'Interval';

          each(items, item => {
            item.stroke_data = [];
            item.title = title + ' ' + row_number;
            if(item.machine != undefined) {
              item.title = item.title + ' - ' + item.machine;
            }
            item.row_number = row_number++;

            if (this.strokeData != undefined) {
              for (i; i < this.strokeData.length; i++) {
                if (this.strokeData[i].t >= previous_timestamp) {
                  if ((item.type == 'distance' && (this.strokeData[i].d / 10 <= item.distance)) || (item.type == 'time' && (this.strokeData[i].t / 10 <= item.time)) || (item.type == 'stroke' && (this.strokeData[i].t / 10 <= item.time))) {
                    item.stroke_data.push(this.strokeData[i]);
                  }
                  previous_timestamp = this.strokeData[i].t;
                } else {
                  previous_timestamp = 0;
                  break;
                }

              }
            }
          });
        }
        if (this.result.details != undefined && this.result.details.workout.splits != undefined && this.result.details.workout.splits.length > 0) {
          let item = {};
          item.title = 'Workout Graph';
          item.stroke_data = this.strokeData;
          items.push(item);
        }

        return items;
      },
    },
    data: () => {
      return {
        loaded: false,
        result: null,
        title: '',
        page_title: ''
      }
    },
    methods: {
      getResult(resultId) {
        this.loaded = false;

        axios.get('public-results/' + resultId + '?include=details,public_user').then((data) => {
          this.result = data.data.result;
          this.title = this.result.public_user.first_name + ' ' + this.result.public_user.last_name + ' - ' + this.result.name;
          if (this.result.details != undefined) {
            this.strokeData = this.result.details.stroke_data.data;
          }
          this.loaded = true;
          this.page_title = this.result.public_user.first_name + ' ' + this.result.public_user.last_name + ' - ' + this.result.name + " (" + formatDateTime(this.result.date) + ")";
          this.$emit('changeRouteTitle', this.page_title);
        }).catch(error => {
          this.$store.dispatch('alerts/addRegularAlert', {
            contentHTML: error.response.data.message,
            dismissible: true,
            duration: 5000,
            type: 'danger'
          });
        });
      },

      hasPermissionToSeeFeature(allowedPlans) {
        if (this.result.public_user !== null && this.result.public_user.next_bill_date != '' && moment().subtract(1, 'day').isAfter(this.result.public_user.next_bill_date)) {
          return false;
        }
        if (allowedPlans.indexOf(this.result.public_user.subscription_plan_id) < 0 && moment().subtract(14, 'day').isAfter(this.result.public_user.created_at)) {
          return false;
        }

        return true;
      }
    },
    created() {
      this.getResult(this.$route.params.id);
    },
    beforeRouteUpdate(to, from, next) {
      this.getResult(to.params.id);
      next();
    }
  }
</script>
