<template>
  <vx-card v-if="loaded" title="PB Calendar">
    <template slot="actions" v-if="false">
      <vs-dropdown vs-trigger-click class="cursor-pointer">
        <small class="flex cursor-pointer">
          <span>{{ selectedItem != undefined ? selectedItem.year : '' }}</span>
          <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" class="ml-1" />
        </small>
        <vs-dropdown-menu class="w-32">
          <template v-if="loaded" v-for="item in itemsPerYear">
            <template v-if="item != undefined">
              <vs-dropdown-item @click="selectedItem = item">{{ item.year }}</vs-dropdown-item>
            </template>
          </template>
        </vs-dropdown-menu>
      </vs-dropdown>
    </template>
    <template v-if="selectedItem != undefined">
      <h5 v-if="false">{{ selectedItem.year }}</h5>
      <ergmonkey-calendar-heatmap :values="selectedItem.values" :end-date="selectedItem.end" tooltip-unit="m" :range-color="colors" :locale="locale" no-data-text="No data for this day." :max="max"/>
    </template>
  </vx-card>
</template>
<script>
  import $ from 'jquery'
  import {formatDate, formatDateTime, formatTime, formatTimeAsPace, timeInHours} from "../../shared/utils";
  import moment from "moment";
  import axios from "axios";
  import ErgmonkeyCalendarHeatmap from "../heatmap/ErgmonkeyCalendarHeatmap";

  export default {
    name: 'public-pbs-2023-heatmap',

    components: {
      ErgmonkeyCalendarHeatmap
    },
    props: {
      // summaryResults: {
      //   type: [Array, Object],
      //   default: () => []
      // }
    },
    data: () => {
      return {
        items: [],
        itemsPerYear: [],
        colors:  ['#ebedf0', '#28C76F'],
        locale: { more: 'PB', less: 'No PB'},
        max: 100,
        loaded: false,
        filterText: '2023',
        selectedItem: {}
      }
    },

    methods: {
      getResults() {
        axios.get('users/'+this.$route.params.id + '/public-results-personal-best?filters[date_from]=2023-01-01&filters[date_to]=2023-12-31&per_page=1000').then((data) => {
          this.items = data.data.results.data;

          if(this.items.length > 0)
          {
            var i = 0;
            for (i; i < this.items.length; i++) {
              let y = moment(this.items[i].date, 'YYYY-MM-DD HH:mm:ss').format('YYYY');

              if(this.itemsPerYear[y] == undefined)
              {
                this.itemsPerYear[y] = {
                  year: y,
                  end: y+'-12-31',
                  values: []
                };
              }

              this.itemsPerYear[y].values.push({date: moment(this.items[i].date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'), count: this.items[i].distance, name: this.items[i].name });
            }
          }

          this.itemsPerYear = _.reverse(this.itemsPerYear);
          this.selectedItem = this.itemsPerYear[0];

          this.loaded = true;
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },
    },
    created() {
      this.getResults();
    },
  };
</script>
