import _ from 'lodash'

export default {
    methods: {
        formControlHasError(form, name) {
            return form.errors.has(name);
        },

        formControlGetError(form, name) {
            return form.errors.get(name);
        },

        formResetErrors(form) {
            form.errors.clear();
        },

        formMultiSelectItemHasError(form, name) {
            return form.errors.has(name) || Object.keys(form.errors.all()).join('|').indexOf(name + '.') >= 0;
        },

        formMultiSelectItemGetError(form, name) {
            let arrayErrorNames = Object.keys(form.errors.all()).filter(function (item) {
                return item.indexOf(name + '.') >= 0;
            });

            return form.errors.only(arrayErrorNames).reduce(function (result, item) {
                return result ? (result + '<br/>' + item) : (result + item);
            }, '');
        },

        formModalHasError(form, names) {
            return !names.every(function (name) {
                return !form.errors.has(name)
            });
        },

        convertErrorsToArray(errors) {
            if (!errors) {
                return {};
            }

            let converted = {};

            _.forEach(errors, function (value, key) {
                let tmp = {};
                _.set(tmp, key, value);
                Object.assign(converted, tmp);
            });

            return converted;
        }
    }
}
