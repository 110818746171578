<template>
  <div>
    <template v-if="!this.authenticatedUserHasPermissionToSee(this.allowedPlans)">
      <upgrade-message title="To see your Comparison Report upgrade to the Standard Plan."></upgrade-message>
    </template>
    <template v-else>
      <vx-card ref="filterCard" title="Filters" class="user-list-filters mb-8" collapse-action refresh-content-action
               @refresh="resetFilter">
        <form @submit.prevent="filterFormSubmit">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full">
              <label class="text-sm opacity-75">Name</label>
              <vue-select-ajax v-model="filterForm.similar_id" :itemID="'id'" :itemText="'name'"
                               :multiple="false" :url="'/users/me/results-search?fields=id|name'" >
              </vue-select-ajax>
            </div>

            <div class="vx-col md:w-1/2 sm:w-1/2 w-full">
              <label class="text-sm opacity-75">Tags</label>
              <v-select multiple :closeOnSelect="false" v-model="filterForm.tags" :options="this.authenticatedUser.user_tags" placeholder="All">
                <div slot="no-options">No matching tags.</div>
              </v-select>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
              <label class="text-sm opacity-75">Start Date</label>
              <div class="vs-con-input">
                <datetime v-model="filterForm.date_from"
                          :class="{'is-invalid': formControlHasError(filterForm, 'date_from')}"
                          input-class="vs-inputx vs-input--input normal" zone="local" value-zone="local"
                          class="theme-green w-full" title="Hint: Year and Month are also clickable"></datetime>
              </div>
            </div>
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
              <label class="text-sm opacity-75">End Date</label>
              <datetime v-model="filterForm.date_to" :class="{'is-invalid': formControlHasError(filterForm, 'date_to')}"
                        input-class="vs-inputx vs-input--input normal" zone="local" value-zone="local"
                        class="theme-green w-full" title="Hint: Year and Month are also clickable"></datetime>
            </div>
          </div>

          <template v-if="mostCommonWorkouts">
            <vs-divider></vs-divider>
            <span>Suggestions: </span>
            <template v-for="(workout, index) in mostCommonWorkouts">
              <a @click="updateFilter(workout)" class="cursor-pointer">{{ workout.name }}</a><template v-if="index < mostCommonWorkouts.length-1"> , </template>
            </template>
          </template>
        </form>
      </vx-card>

      <template v-if="(filterForm.similar_id == null || filterForm.similar_id == '') && !loaded">
        <vs-alert active="true">
          Please select a workout
        </vs-alert>
      </template>
      <template v-if="loaded">
        <div class="vx-col w-full mb-base">
          <selectable-ag-session-list-with-improvements :items="results"></selectable-ag-session-list-with-improvements>
        </div>

        <synchronized-compare-chart :items="selected"></synchronized-compare-chart>
      </template>
    </template>
  </div>
</template>

<script>
  import axios from 'axios'
  import Form from 'vform'
  import FormErrors from '@/components/mixins/FormErrors'
  import moment from 'moment'
  import {mapGetters} from "vuex";
  import UpgradeMessage from "../../../components/global/UpgradeMessage";
  import vSelect from 'vue-select'
  import $ from 'jquery'
  import {clone} from 'lodash';
  import SelectableAgSessionListWithImprovements from "../../../components/reports/SelectableAgSessionListWithImprovements";
  import SynchronizedCompareChart from "../../../components/reports/SynchronizedCompareChart";

  export default {
    head: {
      title: {
        inner: 'Progress Report'
      }
    },

    components: {
      SynchronizedCompareChart,
      SelectableAgSessionListWithImprovements,
      vSelect,
      UpgradeMessage
    },
    computed: {
      ...mapGetters({
        authenticatedUser: 'auth/getAuthenticatedUser',
        authenticatedUserHasPermissionToSee: 'auth/getAuthenticatedUserHasPermissionToSee',
      }),
      resultsForGraphs() {
        return _.cloneDeep(this.results);
      }
    },
    mixins: [FormErrors],
    data: () => ({
      filterForm: new Form({
        similar_id: '',
        date_from: moment().subtract(1, 'year').format('YYYY-MM-DD'),
        date_to: moment().format('YYYY-MM-DD'),
        tags: ''
      }),
      loaded: false,
      results: null,
      allowedPlans: [2, 3, 4, 5,6,7,8,9,10,11],
      allowedPlans: [2, 3, 4, 5,6,7,8,9,10,11],
      selected: [],
      mostCommonWorkouts: null,
    }),
    watch: {
      filterForm: {
        handler(val) {
          if (this.authenticatedUserHasPermissionToSee(this.allowedPlans) && this.filterForm.similar_id != '' && this.filterForm.similar_id != null) {
            this.filterFormSubmit();
          }
        },
        deep: true
      },
    },
    methods: {
      async filterFormSubmit() {
        if (this.loaded == true && !this.authenticatedUserHasPermissionToSee(this.allowedPlans)) {
          return true;
        }
        this.loaded = false;

        let params = _.clone(this.filterForm);

        if(this.filterForm.date_from != '')
        {
          params.date_from = moment(this.filterForm.date_from).format('YYYY-MM-DD');
        }
        if(this.filterForm.date_to != '')
        {
          params.date_to = moment(this.filterForm.date_to).format('YYYY-MM-DD');
        }

        axios.get('users/me/results?per_page=10000&include=details&' + $.param({filters: params})).then((data) => {
          var i = 0;
          var temp = data.data.results.data;
          for (i; i < temp.length; i++) {
            temp[i].difference = 0;
            if (temp[i + 1] != undefined && temp[i + 1].power != 0) {
              temp[i].difference = (temp[i].power * 100 / temp[i + 1].power) - 100;

            }
          }
          this.results = temp;
          this.selected = this.results;
          this.loaded = true;
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },
      resetFilter() {
        this.filterForm = new Form({
          similar_id: '',
          date_from: moment().subtract(1, 'year').format('YYYY-MM-DD'),
          date_to: moment().format('YYYY-MM-DD'),
          tags: ''
        });
        this.$refs.filterCard.removeRefreshAnimation();
      },
      getSuggestions() {
        axios.get('/users/me/grouped-results?limit=10&fields=id|name').then((data) => {
          this.mostCommonWorkouts = data.data.data;
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },
      updateFilter(workout) {
        this.filterForm = new Form({
          similar_id: workout.id,
          date_from: '',
          date_to: moment().format('YYYY-MM-DD'),
          tags: ''
        });
      }
    },
    created() {
      if (this.$route.params.id != undefined) {
        this.filterForm = new Form({
          similar_id: this.$route.params.id,
          date_from: '',
          date_to: moment().format('YYYY-MM-DD'),
          tags: ''
        });
      }
      this.getSuggestions();
    }
  }
</script>
