import store from '@/store'

export default (to, from, next) => {
    if (store.getters['auth/getIsUserAuthenticated']) {
        if (store.getters['auth/getIsUserAuthenticatedAndActive']) {
          if (store.getters['auth/getAuthenticatedUserHasSubscriptionExpired'] && to.name != 'account.billing.edit') {
            next({name: 'account.billing.edit'});
          }
          if(to.path == '/')
          {
            next({name: 'account.home.index'});
          }
          next();
        } else {
            next({name: 'web.errors.inactive'});
        }
    } else {
        next({name: 'web.auth.login'});
    }
}
