<template>
  <vx-card v-if="loaded">
    <form @submit.prevent="preferenceSettingsFormSubmit">
      <div class="mb-base">
        <h6 class="mb-4">Preferences</h6>

        <div class="flex items-center mb-4" v-for="preferenceSetting in preferenceSettings">
          <vs-switch v-model="preferenceSetting.value" />
          <span class="ml-4">{{ preferenceSetting.preference.display_name }}</span>
        </div>
      </div>

      <div class="flex-wrap items-center justify-end mt-base">
        <vs-button class="ml-auto mt-2" button="submit">Save</vs-button>
      </div>
    </form>
  </vx-card>
</template>

<script>
  import axios from "axios";
  import {mapGetters} from "vuex";
  import Highcharts from 'highcharts'
  import highContrastDark from "highcharts/themes/high-contrast-dark";
  import avocado from "highcharts/themes/avocado";

  export default {
    // Set meta tags
    head: {
      title: {
        inner: 'Preferences'
      }
    },
    components: {},
    data: () => ({
      key: 1,
      loaded: false,
      preferenceSettings: null,
      busy: false,
    }),
    methods: {
      fetchPreferenceSettings() {
        this.loaded = false;

        axios.get('users/me/preference-settings?include=preference').then((data) => {
          this.preferenceSettings = data.data.data;
          this.loaded = true;
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },

      preparePreferenceSettings() {
        return this.preferenceSettings;
      },

      preferenceSettingsFormSubmit() {
        let that = this;
        axios.post('users/me/preference-settings/update', {
          preference_settings: this.preparePreferenceSettings()
        }).then(({data}) => {
          let theme = 'light';

          var defaultOptions = Highcharts.getOptions();
          for (var prop in defaultOptions) {
            if (typeof defaultOptions[prop] !== 'function') delete defaultOptions[prop];
          }
          // Fall back to the defaults that we captured initially, this resets the theme
          Highcharts.setOptions(that.$parent.$parent.$parent.$parent.highchartDefaults);

          _.each(this.preparePreferenceSettings(), preference => {
            if (preference.preference_id == 2 && preference.value) {
              theme = 'dark';
              highContrastDark(Highcharts);
            }
          });
          this.$store.dispatch('updateTheme', theme);

          this.$vs.notify(
            {
              text: data.message,
              color:'success',
              position:'top-right',
              time:5000,
            }
          );
          //this.$router.replace({name: 'account.preferences.edit'});
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      }
    },
    created() {
      this.fetchPreferenceSettings();
    },
  }
</script>
