<template>
  <vx-card title="Pace Trend">
      <highcharts :options="chartOptions" style="height: 250px;"></highcharts>
  </vx-card>
</template>
<script>
  import axios from 'axios'
  import Highcharts from 'highcharts'
  import $ from 'jquery'
  import moment from 'moment'
  import {formatTime, formatTimeAsPace} from '@/shared/utils'
  import {formatDate} from "../../shared/utils";


  export default {
    name: 'public-pace-trend-chart',

    components: {},
    props: {
      result: {
        type: [Array, Object],
        default: () => []
      }
    },
    data() {
      return {
        loaded: false,
        items: [],
        allowedPlans: [3, 5,8,9,10,11],
        chartOptions: {
          chart: {
            type: 'scatter',
            zoomType: 'xy',
            backgroundColor: 'rgba(0,0,0,0)',
            events: {
              load: function() {
                /* On mobile, remove labels */
                if ($(window).width() < 900) {
                  this.yAxis[0].update({
                    title: false,
                  });
                  this.tooltip.update({
                    style: {
                      width: Math.min(300, 2*$(window).width()/3)
                    }
                  });
                }
              }
            }
          },
          title: false,
          xAxis: {
            showLastLabel: true,
            tickInterval: 1,
            labels: {
              formatter() {
                return moment().month(this.value).format('MMM');
              }
            },
          },
          yAxis: [{
            reversed: true,
            title: {
              text: 'Pace'
            },
            labels: {
              formatter: function() {
                return formatTime(this.value);
              }
            },
            plotLines: [],
          }],
          tooltip: {
            shared: true,
            formatter: function () {
              let s = '<b>' + this.series.name + '</b><br>';
              s += '<br/>' + Highcharts.dateFormat('%e-%b-%y', this.point.date);
              s += '<br/> Stroke rate : ' + this.point.stroke_rate + ' spm';
              s += '<br/> Pace : ' + formatTimeAsPace(this.point.y);
              return s;
            },
            useHTML: true
          },
          plotOptions: {
            scatter: {
              marker: {
                radius: 5,
                states: {
                  hover: {
                    enabled: true,
                    lineColor: 'rgb(100,100,100)'
                  }
                }
              },
              states: {
                hover: {
                  marker: {
                    enabled: false
                  }
                }
              },
            }
          },
          series: [],
          credits: {
            enabled: false
          },
        }
      }
    },

    methods: {
      getHistoryResultsForTrendChart() {
        axios.get('public-results/' + this.result.id + '/simillar?per_page=1000&order_by=date&order_direction=desc').then((data) => {
          this.loaded = true;
          this.items = data.data.results.data;

          let years = [];
          var i = 0;
          let averagePace = [];
          let paceSum = 0;
          let categories = [];
          for (i; i < this.items.length; i++) {
            averagePace.push(parseFloat(this.items[i].pace));
            paceSum += parseFloat(this.items[i].pace);
            categories.push(formatDate(this.items[i].date));

            let y = moment(this.items[i].date, 'YYYY-MM-DD HH:mm:ss').format('YYYY');
            let m = moment(this.items[i].date, 'YYYY-MM-DD HH:mm:ss').format('M');
            let d = moment(this.items[i].date, 'YYYY-MM-DD HH:mm:ss').format('D');
            let itemDate = Date.UTC(y, m - 1, d);

            if(years[y] == undefined)
            {
              years[y] = [];
            }
            years[y].push({x: parseInt(m-1), y: parseFloat(this.items[i].pace), name: this.items[i].name, date: itemDate, stroke_rate: this.items[i].stroke_rate});
          }
          for (var k in years){
            this.chartOptions.series.push({
              name: k,
              data: years[k],
            });
          }

          this.chartOptions.yAxis[0].plotLines.push({
            value: paceSum / this.items.length,
            color: 'green',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: 'Average pace'
            }
          });
        }).catch(error => {
          this.$store.dispatch('alerts/addRegularAlert', {
            contentHTML: error.response.data.message,
            dismissible: true,
            duration: 5000,
            type: 'danger'
          });
        });
      }
    },
    mounted() {
      this.getHistoryResultsForTrendChart();
    }
  };
</script>
