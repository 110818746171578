<template>
  <div>
    <template v-if="false && !this.authenticatedUserHasPermissionToSee(this.allowedPlans)">
      <upgrade-message title="To see your Heart Rate Distribution upgrade to the Standard Plan."></upgrade-message>
    </template>
    <template v-else>
      <heart-rate-distribution-stacked-bar-chart v-if="loaded" :items="results"></heart-rate-distribution-stacked-bar-chart>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import Form from 'vform'
import FormErrors from '@/components/mixins/FormErrors'
import moment from 'moment'
import {mapGetters} from "vuex";
import $ from 'jquery'
import {clone} from 'lodash';
import HeartRateDistributionStackedBarChart from "../reports/HeartRateDistributionStackedBarChart";
import UpgradeMessage from "../global/UpgradeMessage";

export default {
  name: 'hr-distribution-stacked-bar-chart-with-ets',

  components: {
    UpgradeMessage,
    HeartRateDistributionStackedBarChart,
  },
  computed: mapGetters({
    authenticatedUser: 'auth/getAuthenticatedUser',
    authenticatedUserHasPermissionToSee: 'auth/getAuthenticatedUserHasPermissionToSee',
  }),
  mixins: [FormErrors],
  data: () => ({
    filterForm: new Form({
      similar_id: '',
      type: '',
      date_from: moment().subtract(6, 'month').format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD'),
      workout_type: '',
      tags: ''
    }),
    loaded: false,
    results: null,
    summaryResults: null,
    allowedPlans: ['', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,11],
  }),
  methods: {
    async filterFormSubmit() {
      if (this.loaded == true && !this.authenticatedUserHasPermissionToSee(this.allowedPlans)) {
        return true;
      }
      this.loaded = false;
      this.hrResultsLoaded = false;

      let params = _.clone(this.filterForm);

      if(this.filterForm.date_from != '')
      {
        params.date_from = moment(this.filterForm.date_from).format('YYYY-MM-DD');
      }
      if(this.filterForm.date_to != '')
      {
        params.date_to = moment(this.filterForm.date_to).format('YYYY-MM-DD');
      }
      params.type = this.filterForm.type != '' ? this.filterForm.type.value : '';
      params.workout_type = this.filterForm.workout_type != '' ? this.filterForm.workout_type.value : '';

      axios.get('users/me/results?per_page=10000&order_by=date&order_direction=asc&' + $.param({filters: params})).then((data) => {
        this.results = data.data.results.data;
        this.loaded = true;
      }).catch(error => {
        this.$vs.notify({
          text: error.response.data.message,
          color:'danger',
          position:'top-right',
          time:5000,
        });
      });
    },
  },
  created() {
      this.filterFormSubmit();
  }
}
</script>
