<template>
  <div style="line-height: 23px; !important; margin-top: 12px;">
    <strong title="Total Time">&nbsp;</strong><br/>
    <strong title="Average Rate">{{ avgRate }}</strong> (avg)
  </div>
</template>

<script>
export default {
  name: 'CellRendererFooterRate',
  computed: {
    avgRate() {
      let sum = 0;
      let count = 0;
      _.each(this.$parent.$parent.items, item => {
        if (item.stroke_rate != "") {
          sum += item.stroke_rate;
          count++;
        }
      });
      if (count == 0) {
        return 0;
      }
      return Math.round(sum / count);
    },
  }
}
</script>
