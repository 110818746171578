<template>
  <div style="line-height: 23px; !important; margin-top: 12px;">
    <strong>&nbsp;</strong><br/>
    <strong>
      <template v-if="avgDiff == 0">
        -
      </template>
      <template v-else-if="avgDiff > 0">
        <span class="text-success">+ {{ avgDiff | formatPercentage }}%</span>
      </template>
      <template v-else>
        <span class="text-danger">{{ avgDiff | formatPercentage }}%</span>
      </template>
    </strong> (avg)
  </div>
</template>

<script>
export default {
  name: 'CellRendererFooterDifference',
  computed: {
    avgDiff() {
      let sum = 0;
      let count = 0;
      _.each(this.$parent.$parent.items, item => {
        if (item.difference != "") {
          sum += item.difference;
          count++;
        }
      });
      return sum / count;
    },
  }
}
</script>
