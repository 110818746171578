<template>
  <div>
    <div class="container-fluid">
      <vx-card ref="filterCard" title="Filters" class="mb-8" collapse-action :collapsed="true">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full">
              <vs-checkbox v-model="include_planned_workouts">Show planned workouts</vs-checkbox>
            </div>
          </div>
      </vx-card>
      <vx-card>
        <FullCalendar :defaultView="defaultView"
                      :plugins="calendarPlugins"
                      :header="{
                          left: this.left,
                          center: 'title',
                          right: this.right
                        }"
                      :events="events"
                      :config="config"
                      :views="views"
                      :default-date="defaultDate"
                      @eventClick="eventSelected"
                      @datesRender="handleMonthChange"
                      @dateClick="dateClick"
                      :first-day="1"
        />
      </vx-card>

      <vs-popup title="New Workout" :active.sync="newWorkoutOptions.visibility">
        <form @submit.prevent="newWorkoutFormSubmit">
          <vs-row>
            <vs-input
              label="Name"
              name="name"
              v-model="newWorkoutForm.name"
              class="w-full mt-2"/>
            <span class="text-danger text-sm" v-if="formControlHasError(newWorkoutForm, 'name')">{{ formControlGetError(newWorkoutForm, 'name') }}</span>
          </vs-row>

          <vs-row>
            <div class="vx-col w-full">
              <label class="text-sm opacity-75">Sport</label>
              <v-select :options="sports" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="newWorkoutForm.sportChooser" placeholder="Select sport"/>
              <span class="text-danger text-sm" v-if="formControlHasError(newWorkoutForm, 'sport')">{{ formControlGetError(newWorkoutForm, 'sport') }}</span>
            </div>
          </vs-row>

          <vs-row>
            <vs-textarea
              label="Description"
              name="description"
              v-model="newWorkoutForm.description"
              class="w-full mt-2" height="200px"/>
            <span class="text-danger text-sm" v-if="formControlHasError(newWorkoutForm, 'description')">{{ formControlGetError(newWorkoutForm, 'description') }}</span>
          </vs-row>

          <div class="flex-wrap items-center justify-end mt-2">
            <vs-button class="ml-auto mt-2" button="submit" @click.prevent="newWorkoutFormSubmit()">Save</vs-button>
            <vs-button type="border" class="ml-4 mb-2" color="warning" @click.prevent="newWorkoutOptions.visibility = false">Cancel</vs-button>
          </div>
        </form>
      </vs-popup>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import timeGridPlugin from '@fullcalendar/timegrid'
  import listPlugin from '@fullcalendar/list'
  import interactionPlugin from '@fullcalendar/interaction'
  import moment from 'moment'
  import Form from "vform";
  import FormErrors from '@/components/mixins/FormErrors'
  import $ from 'jquery'
  import vSelect from "vue-select"
  import {SPORTS} from "../../../shared/constants";
  import {mapGetters} from "vuex";

  export default {
    head: {
      title: {
        inner: 'Calendar'
      }
    },

    components: {
      FullCalendar, // make the <FullCalendar> tag available
      vSelect
    },
    mixins: [FormErrors],
    data() {
      let left = "prev,next today";
      //let right = "dayGridMonth,timeGridWeek,timeGridDay,listWeek";
      let right = "dayGridMonth,customListView";
      let defaultView = "dayGridMonth";
      let defaultDate = moment().format('YYYY-MM-DD');
      if (this.windowWidth < 765) {
        left = 'prev,next';
        right = '';
        defaultView = "listWeek";
      }

      return {
        calendarPlugins: [ // plugins must be defined in the JS
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
          interactionPlugin // needed for dateClick
        ],
        config: {},
        calendarWeekends: true,
        events: [],
        loaded: false,
        defaultView: defaultView,
        defaultDate: defaultDate,
        start: moment().subtract(3, 'month').format('YYYY-MM-DD'),
        end: moment().add(3, 'month').format('YYYY-MM-DD'),
        right: right,
        left: left,
        views: {
          customListView: {
            type: 'listWeek',
            duration: {month: 1},
            buttonText: 'list'
          }
        },
        include_planned_workouts: 1,
        allowedPlans: [3, 5, 8, 9, 10, 11],
        allowedPlansForPlanning: [10, 11],
        newWorkoutForm: new Form({
          name: '',
          sportChooser: '',
          sport: '',
          description: '',
        }),
        newWorkoutOptions: {
          busy: false,
          visibility: false
        },
        sports: SPORTS,
      }
    },
    computed: mapGetters({
      authenticatedUser: 'auth/getAuthenticatedUser',
    }),
    methods: {
      getEvents() {
        this.loaded = false;

        let eventColors = ['#9381FF', '#20a8d8', '#FFD8BE', '#4dbd74', '#f86c6b', '#ffc107', '#63c2de', '#767522', '#0A210F', '#60435F', '#F6511D', '#808F85', '#6B0F1A'];

        axios.get('users/me/results?per_page=1000&order_by=date&order_direction=desc&filters[date_from]='+this.start+'&filters[date_to]='+this.end+'&filters[include_planned_workouts]='+this.include_planned_workouts).then((data) => {
        // axios.get('users/me/results?per_page=1000&order_by=date&order_direction=desc').then((data) => {
          this.events = data.data.results.data.map(item => {
            return {
              // item,
              id: item.id,
              title: item.name,
              start: item.date,
              end: moment(item.date, 'YYYY-MM-DD HH:mm:ss').add(parseInt(item.time), 'second').format('YYYY-MM-DD HH:mm:ss'),
              textColor: 'white',
              color: eventColors[item.type],
              allDay: item.source == 3 ? 1: 0,
              extendedProps: {
                id: item.id,
                source: item.source
              }
            };
          });
          this.loaded = true;
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },
      eventSelected(info) {
        if(info.event.extendedProps.source == 3)
        {
          this.$router.push('/planned-workouts/' + info.event.id);
        } else {
          this.$router.push('/results/' + info.event.id);
        }
      },
      handleMonthChange: function(arg) {
          this.start = moment(arg.view.currentStart, "YYYY/MM/DD").subtract(3, 'month').format('YYYY-MM-DD');
          this.end = moment(arg.view.currentEnd, "YYYY/MM/DD").add(3, 'month').format('YYYY-MM-DD');

          this.getEvents();
      },
      dateClick: function (info) {
        if(this.allowedPlansForPlanning.indexOf(this.authenticatedUser.subscription_plan_id) < 0 || this.authenticatedUser.subscription_status != 2) {
          this.$vs.dialog({
            color:'warning',
            title: `Upgrade`,
            text: 'To create planned workouts upgrade to the Plus Plan.',
            accept:this.goToBilling,
            acceptText: 'Upgrade'
          })
        } else {
          this.newWorkoutDialogShow(info.dateStr);
        }
      },
      newWorkoutDialogShow(date) {
        this.newWorkoutForm = new Form({
          name: '',
          sportChooser: '',
          sport: '',
          description: '',
          date: date,
        });

        this.newWorkoutOptions.busy = false;
        this.newWorkoutOptions.visibility = true;
      },
      goToBilling() {
        this.$router.push({ name: 'account.billing.edit'});
      },
      newWorkoutFormSubmit() {
        this.formResetErrors(this.newWorkoutForm);

        this.newWorkoutForm.sport = this.newWorkoutForm.sportChooser != '' ? this.newWorkoutForm.sportChooser.value : '';

        this.newWorkoutForm.post('/results/create')
          .then(({data}) => {
            this.$vs.notify(
              {
                text: data.message,
                color:'success',
                position:'top-right',
                time:5000,
              }
            );

            this.getEvents();
            this.newWorkoutOptions.visibility = false;
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },
    },
    watch: {
      include_planned_workouts: {
        handler(val) {
          this.getEvents()
        },
        deep: true
      }
    },
    created() {
      if (this.$route.query.goToDate != '' && this.$route.query.goToDate != undefined) {
        this.defaultDate = this.$route.query.goToDate;
      }
      // this.getEvents();
    }
  }

</script>
