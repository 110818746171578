<template>
  <div class="flex items-center">
    {{ params.value | formatDate }}
  </div>
</template>

<script>
export default {
  name: 'CellRendererDate',
  computed: {
  }
}
</script>
