<template>
  <vx-card>
    <form @submit.prevent="hrFormSubmit">
      <div class="vx-row">
        <div class="vx-col vs-lg=2 mb-2">
          <vs-input
            label="Max Heart Rate"
            name="max_heart_rate"
            v-model="hrForm.max_heart_rate"
            description-text="Default: 220 - your age"
            :placeholder="recommended_max_heart_rate"
            class="w-full"/>
          <span class="text-danger text-sm" v-if="formControlHasError(hrForm, 'max_heart_rate')">{{ formControlGetError(hrForm, 'max_heart_rate') }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col vs-lg=2 mb-2">
          <vs-input
            label="Zone 1 (%)"
            name="zone_1"
            v-model="hrForm.zone_1"
            description-text="Default: 50% of Max Heart Rate"
            :placeholder="defaultHrZones.zone_1"
            class="w-full"/>
          <span class="text-danger text-sm" v-if="formControlHasError(hrForm, 'zone_1')">{{ formControlGetError(hrForm, 'zone_1') }}</span>
        </div>

        <div class="vx-col vs-lg=2 mb-2">
          <vs-input
            label="Zone 2 (%)"
            name="zone_2"
            v-model="hrForm.zone_2"
            description-text="Default: 60% of Max Heart Rate"
            :placeholder="defaultHrZones.zone_2"
            class="w-full"/>
          <span class="text-danger text-sm" v-if="formControlHasError(hrForm, 'zone_2')">{{ formControlGetError(hrForm, 'zone_2') }}</span>
        </div>

        <div class="vx-col vs-lg=2 mb-2">
          <vs-input
            label="Zone 3 (%)"
            name="zone_3"
            v-model="hrForm.zone_3"
            description-text="Default: 70% of Max Heart Rate"
            :placeholder="defaultHrZones.zone_3"
            class="w-full"/>
          <span class="text-danger text-sm" v-if="formControlHasError(hrForm, 'zone_3')">{{ formControlGetError(hrForm, 'zone_3') }}</span>
        </div>

        <div class="vx-col vs-lg=2 mb-2">
          <vs-input
            label="Zone 4 (%)"
            name="zone_4"
            v-model="hrForm.zone_4"
            description-text="Default: 80% of Max Heart Rate"
            :placeholder="defaultHrZones.zone_4"
            class="w-full"/>
          <span class="text-danger text-sm" v-if="formControlHasError(hrForm, 'zone_4')">{{ formControlGetError(hrForm, 'zone_4') }}</span>
        </div>

        <div class="vx-col vs-lg=2 mb-2">
          <vs-input
            label="Zone 5 (%)"
            name="zone_5"
            v-model="hrForm.zone_5"
            description-text="Default: 90% of Max Heart Rate"
            :placeholder="defaultHrZones.zone_5"
            class="w-full"/>
          <span class="text-danger text-sm" v-if="formControlHasError(hrForm, 'zone_5')">{{ formControlGetError(hrForm, 'zone_5') }}</span>
        </div>
      </div>

      <vs-checkbox v-model="hrForm.process_previous_workouts" class="mb-2">Apply current HR settings to previous workouts</vs-checkbox>
      <span class="text-danger text-sm" v-if="formControlHasError(hrForm, 'process_previous_workouts')">{{ formControlGetError(hrForm, 'process_previous_workouts') }}</span>

      <!-- Save & Reset Button -->
      <div class="flex-wrap items-center justify-end">
        <vs-button class="ml-auto mt-2" button="submit">Save</vs-button>
        <vs-button class="ml-4 mt-2" type="border" color="warning" @click="resetHrDialogShow()">Reset to Default</vs-button>
      </div>
    </form>
  </vx-card>
</template>

<script>
  import axios from 'axios'
  import {mapGetters} from 'vuex'
  import Form from 'vform'
  import FormErrors from '@/components/mixins/FormErrors'
  import Numbers from '@/components/mixins/Numbers'
  import moment from 'moment'

  export default {
    components: {

    },
    // Set meta tags
    head: {
      title: {
        inner: 'Account Settings'
      }
    },

    mixins: [FormErrors, Numbers],

    computed: {
      ...mapGetters({
        authenticatedUser: 'auth/getAuthenticatedUser',
      }),
      recommended_max_heart_rate() {
        let maxHr = 220;

        if(this.authenticatedUser.date_of_birth != undefined)
        {
          maxHr = maxHr - moment().diff(this.authenticatedUser.date_of_birth, 'years',false);
        }

        return maxHr.toString();
      }
    },

    data: () => ({
      hrForm: new Form({
        max_heart_rate: '',
        zone_1: '',
        zone_2: '',
        zone_3: '',
        zone_4: '',
        zone_5: '',
        process_previous_workouts: 0,
      }),
      defaultHrZones: {
        zone_1: '50',
        zone_2: '60',
        zone_3: '70',
        zone_4: '80',
        zone_5: '90',
      },
      resetHrOptions: {
        busy: false,
        visibility: false
      },
    }),

    mounted() {
      this.hrForm.max_heart_rate = this.authenticatedUser.max_heart_rate;
      this.hrForm.zone_1 = this.authenticatedUser.heart_rate_zones.zone_1 != undefined ? this.authenticatedUser.heart_rate_zones.zone_1 : '';
      this.hrForm.zone_2 = this.authenticatedUser.heart_rate_zones.zone_2 != undefined ? this.authenticatedUser.heart_rate_zones.zone_2 : '';
      this.hrForm.zone_3 = this.authenticatedUser.heart_rate_zones.zone_3 != undefined ? this.authenticatedUser.heart_rate_zones.zone_3 : '';
      this.hrForm.zone_4 = this.authenticatedUser.heart_rate_zones.zone_4 != undefined ? this.authenticatedUser.heart_rate_zones.zone_4 : '';
      this.hrForm.zone_5 = this.authenticatedUser.heart_rate_zones.zone_5 != undefined ? this.authenticatedUser.heart_rate_zones.zone_5 : '';
    },

    methods: {
      hrFormSubmit() {
        this.formResetErrors(this.hrForm);

        this.hrForm.post('/users/me/update-hr-info')
          .then(({data}) => {
            this.$vs.notify(
              {
                text: data.message,
                color:'success',
                position:'top-right',
                time:5000,
              }
            );

            this.$store.dispatch('auth/fetchUser');
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },

      resetHrToDefault() {
        this.hrForm = new Form({
          max_heart_rate: '',
          zone_1: '',
          zone_2: '',
          zone_3: '',
          zone_4: '',
          zone_5: '',
        });
        this.hrFormSubmit();

        this.resetHrOptions.visibility = false;
      },

      resetHrDialogShow(id) {
        this.resetHrOptions.busy = false;
        this.resetHrOptions.visibility = true;

        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: `Please Confirm`,
          text: 'Are you sure you want to reset HR settings to default?',
          acceptText: 'Yes, reset it',
          accept: this.resetHrToDefault
        })
      },

      resetHrDialogClose() {
        this.resetHrOptions.busy = false;
      },

    }
  }
</script>
