import { SECONDS_IN_HOUR, TIME_FORMAT_LONG, MINUTES_SECONDS_FORMAT, TIME_FORMAT_IN_MINUTES_LONG } from '@/shared/constants.js';
import moment from 'moment'

export function random (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * Randomize array element order in-place.
 * Using Durstenfeld shuffle algorithm.
 */
export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1))
    let temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

export function formatTime(time) {
  const timeFormat = time >= SECONDS_IN_HOUR ? TIME_FORMAT_LONG : MINUTES_SECONDS_FORMAT;
  return time
      ? moment()
          .startOf('day')
          .add(time, 's')
          .format(timeFormat)
      : '';
}

export function timeInHours(value) {
  let hours =  parseInt(Math.floor(value / 3600));
  let minutes = parseInt(Math.floor((value - (hours * 3600)) / 60));
  let seconds= parseInt((value - ((hours * 3600) + (minutes * 60))) % 60);

  let dHours = (hours > 9 ? hours : '0' + hours);
  let dMins = (minutes > 9 ? minutes : '0' + minutes);
  let dSecs = (seconds > 9 ? seconds : '0' + seconds);

  return dHours + ":" + dMins + ":" + dSecs;
}

export function formatTimeAsPace(time) {
  const timeFormat = TIME_FORMAT_IN_MINUTES_LONG;
  return time
    ? moment()
      .startOf('day')
      .add(time, 's')
      .format(timeFormat)
    : '';
}

export function formatDate(value, format = 'DD/MMM/YYYY') {
  if (value) {
    try {
      return moment(String(value)).format(format);
    } catch (e) {
      return '';
    }
  } else {
    return '';
  }
}

export function formatDateTime(value, format = 'MM/DD/YYYY HH:mm') {
  if (value) {
    try {
      return moment(String(value)).format(format);
    } catch (e) {
      return '';
    }
  } else {
    return '';
  }
}

export function calculatePaceFromTime(time, distance) {
  let pace = '';

  if(distance > 0)
  {
    pace = ((500 * time) / distance).toFixed(2);
  }

  return pace;
}

export function paceToWatts(pace) {
  let watts = '';

  if(pace > 0)
  {
    watts = (28/Math.pow(pace/500, 3)/10).toFixed(0);
  }

  return watts;
}

export function formatSummaryResposePeriod(data) {
  if(data.week != undefined)
  {
    return data.year + '/Week ' + data.week;
  }
  else if(data.month != undefined)
  {
    return data.year + '/' + moment.months(data.month - 1);
  } else if(data.date != undefined)
  {
    return formatDate(data.date);
  } else if(data.day_of_week != undefined)
  {
    return moment.weekdays(data.day_of_week);
  } else {
    return data.year;
  }
}
