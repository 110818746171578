<template>
  <vx-card v-if="integrationsLoaded">
    <div class="mb-8 flex flex-wrap justify-between items-center">
      <img
        src="@/assets/images/brand/concept_2_logo.png"
        alt="Concept 2"
        class="concept2-integrations-logo mr-4"/>
      <template v-if="logbookIntegration != null">
          <div class="mb-2">
            <p>Account is connected with Logbook.</p>
            <p class="font-medium mb-4">{{ logbookIntegration.name }}</p>
            <p>Last synced at: <span class="text-primary">{{ logbookIntegration.results_synced_at  == '' ? 'Syncing' : logbookIntegration.results_synced_at + ' UTC' }}</span></p>
          </div>
          <vs-button type="border" class="ml-auto mb-2" color="success" @click="resyncLogbook">Re-Sync workouts</vs-button>
          <vs-button type="border" class="ml-auto mb-2" color="danger" @click.prevent="integrationDeleteDialogShow(logbookIntegration.id)">Disconnect</vs-button>
      </template>
      <template v-else>
        <vs-button class="ml-auto" @click="connectLogbook">Connect</vs-button>
      </template>
    </div>

    <vs-divider class="m-1" />

    <div class="mt-6 mb-6 flex flex-wrap justify-between items-center">
      <img
        src="@/assets/images/brand/RP3-Dynamic_logo.svg"
        alt="RP3 Dynamic"
        class="rp3-integrations-logo mr-4"/>
      <template v-if="rp3Integration != null">
        <div class="mb-2">
          <p>Account is connected with RP3.</p>
          <p class="font-medium mb-4">{{ rp3Integration.name }}</p>
          <p>Last synced at: <span class="text-primary">{{ rp3Integration.results_synced_at  == '' ? 'Syncing' : rp3Integration.results_synced_at + ' UTC' }}</span></p>
        </div>
        <vs-button type="border" class="ml-auto mb-2" color="success" @click="resyncRp3">Re-Sync workouts</vs-button>
        <vs-button type="border" class="ml-auto mb-2" color="danger" @click.prevent="integrationRp3DeleteDialogShow(rp3Integration.id)">Disconnect</vs-button>
      </template>
      <template v-else>
        <vs-button class="ml-auto" @click="connectRp3">Connect</vs-button>
      </template>
    </div>
  </vx-card>
</template>

<script>
  import axios from 'axios'
  import {mapGetters} from 'vuex'
  import Numbers from '@/components/mixins/Numbers'

  export default {
    // Set meta tags
    head: {
      title: {
        inner: 'Integrations'
      }
    },

    mixins: [Numbers],

    components: {},

    computed: mapGetters({
      integrations: 'integrations/getIntegrations',
      integrationsLoaded: 'integrations/getIntegrationsLoaded',
      integrationsConstants: 'integrations/getIntegrationsConstants',
      logbookIntegration: 'integrations/getLogbookIntegration',
      rp3Integration: 'integrations/getRp3Integration',
    }),

    data: () => ({
      componentKey: 0
    }),

    beforeMount: async function () {
      await this.$store.dispatch('integrations/setIntegrationsLoaded', {loaded: false});
    },

    mounted: async function () {
      await Promise.all([
        this.fetchIntegrations(),
      ]);

    },

    methods: {
      fetchIntegrations() {
        this.$store.dispatch('integrations/fetchIntegrations')
          .catch(error => {
            this.$vs.notify({
                text: error.response.data.message,
                color:'danger',
                position:'top-right',
                time:5000,
            });
          });
      },

      integrationDeleteDialogShow(id) {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: `Please Confirm`,
          text: 'Are you sure you want to disconnect this integration?',
          acceptText: 'Yes, disconnect',
          accept: this.disconnectLogbook
        })
      },

      integrationRp3DeleteDialogShow(id) {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: `Please Confirm`,
          text: 'Are you sure you want to disconnect this integration?',
          acceptText: 'Yes, disconnect',
          accept: this.disconnectRp3
        })
      },

      connectLogbook() {
        axios.get('/connect/logbook/generate-authorization-url')
          .then(({data}) => {
            window.location.replace(data.url);
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },

      connectRp3() {
        axios.get('/connect/rp3/generate-authorization-url')
          .then(({data}) => {
            window.location.replace(data.url);
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },

      disconnectLogbook() {
        axios.post('/disconnect/logbook')
          .then(({data}) => {
            this.$vs.notify({
              text: data.message,
              color:'success',
              position:'top-right',
              time:5000,
            });

            this.fetchIntegrations();
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },

      disconnectRp3() {
        axios.post('/disconnect/rp3')
          .then(({data}) => {
            this.$vs.notify({
              text: data.message,
              color:'success',
              position:'top-right',
              time:5000,
            });

            this.fetchIntegrations();
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },

      resyncLogbook() {
        axios.post('/resync/logbook')
          .then(({data}) => {
            this.$vs.notify(
              {
                text: data.message,
                color:'success',
                position:'top-right',
                time:5000,
              }
            );

            this.fetchIntegrations();
          })
          .catch(error => {
            this.$vs.notify({
                text: error.response.data.message,
                color:'danger',
                position:'top-right',
                time:5000,
              });
          });
      },

      resyncRp3() {
        axios.post('/resync/rp3')
          .then(({data}) => {
            this.$vs.notify(
              {
                text: data.message,
                color:'success',
                position:'top-right',
                time:5000,
              }
            );

            this.fetchIntegrations();
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      }
    }
  }
</script>
