<template>
  <vx-card class="mb-base" :title="title">
    <highcharts :options="chartOptions"></highcharts>
  </vx-card>
</template>
<script>
  import Highcharts from 'highcharts'
  import $ from 'jquery'
  import moment from 'moment'
  import {formatDate, timeInHours} from "../../shared/utils";
  import axios from "axios";

  export default {
    name: 'public-summary-per-week-day-2023-chart',

    components: {
    },
    props: {
      title: {
        type: String,
        default: 'Favourite Day of the Week'
      }
    },
    data() {
      return {
        loaded: false,
        chartOptions: {
          chart: {
            type: 'column',
            zoomType: 'xy',
            backgroundColor: 'rgba(0,0,0,0)',
            events: {
              load: function() {
                /* On mobile, remove labels */
                if ($(window).width() < 900) {
                  this.yAxis[0].update({
                    title: false,
                  });
                  this.yAxis[1].update({
                    title: false,
                  });
                  this.tooltip.update({
                    style: {
                      width: Math.min(300, 2*$(window).width()/3)
                    }
                  });
                }
              }
            }
          },
          title: false,
          xAxis: {
            categories: [],
            crosshair: true
          },
          yAxis: [{
            min: 0,
            title: {
              text: 'Distance'
            }
          },
            { // Secondary yAxis
              opposite: true,
              maxPadding: 0,
              title: {
                text: 'Time',
                style: {
                  color: Highcharts.getOptions().colors[0]
                }
              },
              labels: {
                style: {
                  color: Highcharts.getOptions().colors[0]
                },
                formatter: function() {
                  return timeInHours(this.value);
                }
              },
            },
            { // Secondary yAxis
              opposite: true,
              maxPadding: 0,
              title: {
                text: 'Number of Workouts',
                // style: {
                //   color: Highcharts.getOptions().colors[1]
                // }
              },
              // labels: {
              //   style: {
              //     color: Highcharts.getOptions().colors[1]
              //   },
              // },
            }],
          responsive: {
            rules: [{
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  floating: false,
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                  x: 0,
                  y: 0
                }
              }
            }]
          },
          tooltip: {
            shared: true,
            formatter: function() {
              let s = '<b>' + this.x + '</b>';
              $.each(this.points, function(i, point) {
                let value = point.y;
                let name = this.series.name;
                if(name == 'Time')
                {
                  value = timeInHours(value);
                }
                s += '<br/>' + '<span style="color:' + point.color + '">\u25CF</span> ' + this.series.name + ': ' + value + this.series.tooltipOptions.valueSuffix;
              });
              return s;
            },
            useHTML: true
          },
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0
            }
          },
          series: [],
          credits: {
            enabled: false
          },
        }
      }
    },

    methods: {
      getResults() {
        this.loaded = false;

        let params = {
          date_from: '2023-01-01',
          date_to: '2023-12-31',
          group_by: 'day_of_week',
        };

        axios.get('reports/public-summary?user_id='+this.$route.params.id + '&group_by=day_of_week&' + $.param({filters: params})).then((data) => {
          this.items = data.data.data;
          this.loaded = true;

          this.populateChart();
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },
      populateChart() {
        var i = 0;
        let categories = [];
        let distances = [];
        let times = [];
        let workouts = [];
        for(i; i < this.items.length; i++){
          distances.push(parseInt(this.items[i].total_distance));
          times.push(parseFloat(this.items[i].total_time));
          workouts.push(parseInt(this.items[i].total_number_of_workouts));
          if(this.items[i].day_of_week != undefined)
          {
            categories.push(this.$options.filters.formatDayOfWeek(this.items[i].day_of_week));
          }
        }

        this.chartOptions.xAxis.categories = categories;

        this.chartOptions.series.push({
          name: "Distance",
          data: distances,
          tooltip: {
            valueSuffix: ' m'
          },
        });
        this.chartOptions.series.push({
          yAxis: 1,
          name: "Time",
          data: times,
          tooltip: {
            valueSuffix: ''
          },
        });
        this.chartOptions.series.push({
          yAxis: 2,
          name: "Workouts",
          data: workouts,
          tooltip: {
            valueSuffix: ''
          },
        });
      }
    },
    mounted() {
      this.getResults();
    }
  };
</script>
