<template xmlns="http://www.w3.org/1999/html">
  <div v-if="loaded">
    <portal to="title-bar-actions">
      <v-select multiple taggable push-tags :closeOnSelect="false" v-model="result.tags" :options="result.user_tags" class="mr-2" style="min-width: 160px" placeholder="Tags">
        <div slot="no-options">No matching tags. Please create your own.</div>
      </v-select>
      <vs-button v-if="false" icon="icon-share-2" icon-pack="feather" @click="shareModal = true" title="Share Workout">Share</vs-button>
      <vs-button icon="icon-edit" icon-pack="feather" @click.prevent="editWorkoutDialogShow()" color="success" class="ml-2" title="Edit Workout"/>
      <vs-button icon="icon-trash" icon-pack="feather" @click.prevent="resultDeleteDialogShow()" color="danger" class="ml-2" title="Delete Workout"/>
    </portal>
    <div class="vx-row flex-col-reverse md:flex-col-reverse sm:flex-row lg:flex-row mb-base">
      <div class="vx-col w-full md:w-full sm:w-full lg:w-1/2 xl:w-1/2 flex flex-col justify-between">
        <vx-card title="Workout Details">
          Sport: <span class="font-semibold">{{ result.sport | formatSportName }}</span><br/>
          Description: <span class="font-semibold">{{ result.description }}</span><br/>
        </vx-card>
      </div>
      <div class="vx-col w-full md:w-full sm:w-full lg:w-1/2 xl:w-1/2 flex flex-col lg:mb-0 md:mb-base sm:mb-0 mb-base">
        <vx-card title="Comment">
          <form @submit.prevent="updateComment">
            <vs-textarea
              label="Comment"
              name="comment"
              v-model="result.comments"
              class="w-full mt-2" height="200px"/>
            <div class="flex-wrap items-center justify-end mt-2">
              <vs-button class="ml-auto mt-2" button="submit" @click.prevent="updateComment()">Save</vs-button>
              <vs-button type="border" class="ml-4 mb-2" color="warning" @click.prevent="result.comments = originalComment">Cancel</vs-button>
            </div>
          </form>
        </vx-card>
      </div>
    </div>

    <vs-popup title="Share Workout" :active.sync="shareModal">
      <vs-row class="mb-2">
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="facebook" class="vs-component vs-button vs-button-null vs-button-filled btn-facebook w-full mr-2">
              <feather-icon icon="FacebookIcon" svgClasses="h-4 w-4" /> Facebook
            </network>
          </vs-col>
        </social-sharing>
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="twitter" class="vs-component vs-button vs-button-null vs-button-filled btn-twitter w-full ml-2">
              <feather-icon icon="TwitterIcon" svgClasses="h-4 w-4" /> Twitter
            </network>
          </vs-col>
        </social-sharing>
      </vs-row>
      <vs-row class="mb-2">
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="linkedin" class="vs-component vs-button vs-button-null vs-button-filled btn-linkedin w-full mr-2">
              <feather-icon icon="LinkedinIcon" svgClasses="h-4 w-4" /> LinkedIn
            </network>
          </vs-col>
        </social-sharing>
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="reddit" class="vs-component vs-button vs-button-null vs-button-filled btn-reddit w-full ml-2">
              <feather-icon icon="RedditIcon" svgClasses="h-4 w-4" /> Reddit
            </network>
          </vs-col>
        </social-sharing>
      </vs-row>
      <vs-row class="mb-2">
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="email" class="vs-component vs-button vs-button-warning vs-button-filled w-full mr-2">
              <feather-icon icon="MailIcon" svgClasses="h-4 w-4" /> Email
            </network>
          </vs-col>
        </social-sharing>
        <vs-col vs-w="6">
          <vs-button color="primary" v-clipboard:copy="shareUrl" v-clipboard:success="onCopySuccess" class="w-full ml-2">
            <feather-icon icon="CopyIcon" svgClasses="h-4 w-4" /> Copy
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>

    <vs-popup title="Edit Workout" :active.sync="editWorkoutOptions.visibility">
      <form @submit.prevent="editWorkoutFormSubmit">
        <vs-row>
          <vs-input
            label="Name"
            name="name"
            v-model="editWorkoutForm.name"
            class="w-full mt-2"/>
          <span class="text-danger text-sm" v-if="formControlHasError(editWorkoutForm, 'name')">{{ formControlGetError(editWorkoutForm, 'name') }}</span>
        </vs-row>

        <vs-row>
          <div class="vx-col w-full">
            <label class="text-sm opacity-75">Sport</label>
            <v-select :options="sports" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="editWorkoutForm.sportChooser" :value="editWorkoutForm.sport" placeholder="Select sport"/>
            <span class="text-danger text-sm" v-if="formControlHasError(editWorkoutForm, 'sport')">{{ formControlGetError(editWorkoutForm, 'sport') }}</span>
          </div>
        </vs-row>

        <vs-row>
          <vs-textarea
            label="Description"
            name="description"
            v-model="editWorkoutForm.description"
            class="w-full mt-2" height="200px"/>
          <span class="text-danger text-sm" v-if="formControlHasError(editWorkoutForm, 'description')">{{ formControlGetError(editWorkoutForm, 'description') }}</span>
        </vs-row>

        <div class="flex-wrap items-center justify-end mt-2">
          <vs-button class="ml-auto mt-2" button="submit" @click.prevent="editWorkoutFormSubmit()">Save</vs-button>
          <vs-button type="border" class="ml-4 mb-2" color="warning" @click.prevent="editWorkoutOptions.visibility = false">Cancel</vs-button>
        </div>
      </form>
    </vs-popup>

  </div>
</template>

<script>
  import axios from 'axios'
  import {each} from 'lodash';
  import {formatDate, formatDateTime} from "../../../shared/utils";
  import vSelect from 'vue-select'
  import Form from "vform";
  import FormErrors from '@/components/mixins/FormErrors'
  import {SPORTS} from "../../../shared/constants";

  export default {
    components: {
      vSelect
    },
    computed: {
      shareUrl: function () {
        return 'https://stats.ergmonkey.com/share/' + this.$route.params.id;
      },
      shareTitle: function () {
        return this.result != null ? this.result.name : '';
      }
    },
    data: () => {
      return {
        loaded: false,
        result: null,
        tags: null,
        title: '',
        page_title: '',
        shareModal: false,
        shareDesription: '',
        shareQuote: '',
        shareHashtags: '',
        shareTwitterUser: 'ergmonkey_',
        originalComment: '',
        editWorkoutForm: new Form({
          id: '',
          name: '',
          sportChooser: '',
          sport: '',
          description: '',
        }),
        editWorkoutOptions: {
          busy: false,
          visibility: false
        },
        sports: SPORTS,
      }
    },
    mixins: [FormErrors],
    watch: {
      result: {
        handler: function(val, oldVal) {
          if(this.loaded && oldVal != null) {
            if(val.tags != this.tags)
            {
              axios.post('results/' + this.$route.params.id +'/update', {
                'tags': val.tags
              }).then(({data}) => {
                this.$vs.notify({
                  text: data.message,
                  color:'success',
                  position:'top-right',
                  time:3000,
                });

                this.tags = val.tags;

                this.$store.dispatch('auth/fetchUser');
              }).catch(error => {
                this.$vs.notify({
                  text: error.response.data.message,
                  color:'danger',
                  position:'top-right',
                  time:5000,
                });
              });
            }
          }
        },
        deep: true
      },
    },
    methods: {
      getResult(resultId) {
        this.loaded = false;

        axios.get('users/me/results/' + resultId + '?include=details,user_tags,badges').then((data) => {
          this.result = data.data.result;
          this.tags = data.data.result.tags;
          this.title = this.result.name;
          this.loaded = true;
          this.page_title = this.result.name + " (" + formatDate(this.result.date) + ")";
          this.originalComment = this.result.comment;
          this.$emit('changeRouteTitle', this.page_title);
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },
      onCopySuccess() {
        this.shareModal = false;
        this.$vs.notify({
          text: "Copied!",
          color:'success',
          position:'top-right',
          time:5000,
        });
      },

      resultDeleteDialogShow() {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: `Please Confirm`,
          text: 'Are you sure you want to delete this workout?',
          acceptText: 'Yes, delete it',
          accept: this.deleteResult
        })
      },

      deleteResult() {
        axios.post('/results/'+this.$route.params.id+'/destroy')
          .then(({data}) => {
            this.$vs.notify({
              text: data.message,
              color:'success',
              position:'top-right',
              time:5000,
            });

            this.$router.push({name: 'account.home.index'});
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },
      updateComment() {
        let that = this;
        axios.post('results/' + this.$route.params.id +'/update', {
          'comments': this.result.comments
        }).then(({data}) => {
          that.originalComment = this.result.comments;
          this.$vs.notify({
            text: data.message,
            color:'success',
            position:'top-right',
            time:3000,
          });
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },
      editWorkoutDialogShow(workout) {
        this.editWorkoutForm = new Form({
          id: this.result.id,
          name: this.result.name,
          sportChooser: this.sports.find(
            sport => sport.value == this.result.sport
          ),
          description: this.result.description,
        });

        this.editWorkoutOptions.busy = false;
        this.editWorkoutOptions.visibility = true;
      },
      editWorkoutFormSubmit() {
        this.formResetErrors(this.editWorkoutForm);

        this.editWorkoutForm.sport = this.editWorkoutForm.sportChooser != '' ? this.editWorkoutForm.sportChooser.value : '';

        this.editWorkoutOptions.visibility = false;
        this.editWorkoutForm.post('results/' + this.$route.params.id +'/update')
          .then(({data}) => {

            this.$vs.notify(
              {
                text: data.message,
                color:'success',
                position:'top-right',
                time:5000,
              }
            );


            this.getResult(this.$route.params.id);
          })
          .catch(error => {
            this.editWorkoutOptions.visibility = true;
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },
    },
    created() {
      this.getResult(this.$route.params.id);
    },
    beforeRouteUpdate(to, from, next) {
      this.getResult(to.params.id);
      next();
    },
  }
</script>
