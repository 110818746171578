import axios from 'axios'
import store from '@/store'

// Request interceptor
axios.interceptors.request.use(request => {
  if (request.url.substring(0, 4) !== 'http') {
    request.baseURL = process.env.VUE_APP_API_URL || "";
  }

  request.headers.crossDomain = true;

  request.headers.common['Accept'] = 'application/json';
  request.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
  request.headers.common['Access-Control-Allow-Credentials'] = 'true';
  request.headers.common['Access-Control-Allow-Headers'] = 'Access-Control-Allow-Headers, Access-Control-Allow-Origin, Origin, X-Requested-With, Content-Type, Accept, Authorization, Access-Control-Allow-Headers';
  request.headers.common['Access-Control-Allow-Methods'] = 'GET,HEAD,OPTIONS,POST,PUT';
  request.headers.common['Access-Control-Allow-Origin'] = '*';

  // request.headers.common['Accept'] = 'application/json';
  // request.headers.common['Access-Control-Allow-Credentials'] = 'true';
  // request.headers.common['Access-Control-Allow-Headers'] = '*';
  // request.headers.common['Access-Control-Allow-Methods'] = '*';
  // request.headers.common['Access-Control-Allow-Origin'] = '*';
  //
  // if (request.method.toLowerCase() !== 'get') {
  //     request.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
  //     request.headers.common['Access-Control-Max-Age'] = '600';
  // } else {
  //     request.headers.common['Content-Type'] = 'application/json';
  // }


  const accessToken = store.getters['auth/getAccessToken'];
  //const refreshToken = store.getters['auth/getRefreshToken'];
  const accountId = store.getters['auth/getAccountId'];

  if (accessToken) {
    request.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }

  let clientId = process.env.VUE_APP_API_CLIENT_ID || "", clientSecret = process.env.VUE_APP_API_CLIENT_SECRET || "";

  request.url = request.url + (request.url.split('?')[1] ? '&' : '?') + 'client_id=' + clientId + '&client_secret=' + clientSecret;

  return request;
});

// Response interceptor
axios.interceptors.response.use(function (response) {
  return response;
}, error => {
  let response = error.response || {status: 0};

  if (response.status >= 500) {
    this.$vs.notify({
      text: 'The server encountered an unexpected condition which prevented it from fulfilling the request.',
      color:'danger',
      position:'top-right',
      time:5000,
    });
  }

  return Promise.reject(error);
});
