<template>
  <div class="flex items-center">
    {{ params.value | formatDate }}
    <template v-if="params.data.id == $parent.$parent.pb.id">
      <span style="color: Gold">
        <feather-icon icon="AwardIcon" svgClasses="h-4 w-4" title="Personal Best"></feather-icon>
      </span>
  </template>
  </div>
</template>

<script>
import Form from "vform";
import moment from "moment";

export default {
  name: 'CellRendererDateWithPB',
  computed: {
  },
}
</script>
