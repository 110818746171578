<template>
  <vx-card title="Ergo Score Trend">
      <highcharts :options="chartOptions" style="height: 250px;"></highcharts>
  </vx-card>
</template>
<script>
    import axios from 'axios'
    import Highcharts from 'highcharts'
    import $ from 'jquery'
    import moment from 'moment'
    import { formatTimeAsPace } from '@/shared/utils'
    import {mapGetters} from "vuex";

    export default {
        name: 'public-ergo-score-trend-chart',

        components: {
        },
        props: {
            result: {
                type: [Array, Object],
                default: () => []
            }
        },
        data() {
            return {
                loaded: false,
                items: [],
                allowedPlans: [3,5,8,9,10,11],
                chartOptions: {
                    chart: {
                        zoomType: 'xy',
                        type: 'scatter',
                        backgroundColor: 'rgba(0,0,0,0)',
                        events: {
                            load: function() {
                                /* On mobile, remove labels */
                                if ($(window).width() < 900) {
                                    this.yAxis[0].update({
                                        title: false,
                                    });
                                    this.tooltip.update({
                                        style: {
                                            width: Math.min(300, 2*$(window).width()/3)
                                        }
                                    });
                                }
                            }
                        }
                    },
                    title: false,
                    xAxis: {
                        startOnTick: true,
                        endOnTick: true,
                        showLastLabel: true,
                        labels: {
                            formatter() {
                                return Highcharts.dateFormat('%e-%b-%y', this.value)
                            }
                        },
                        type: 'datetime',
                        dateTimeLabelFormats: { // don't display the dummy year
                            month: '%e. %b',
                            year: '%b'
                        },
                    },
                    yAxis: [{ // Primary yAxis
                        labels: {
                            style: {
                                color: Highcharts.getOptions().colors[1]
                            }
                        },
                        title: {
                            text: 'Stroke Rate',
                            style: {
                                color: Highcharts.getOptions().colors[1]
                            }
                        },
                        allowDecimals: false,

                    }],
                    series: [],
                    tooltip: {
                        shared: true,
                        formatter: function() {
                            let s = '<b>'+this.series.name+'</b><br>';
                            s += '<br/>' + Highcharts.dateFormat('%e-%b-%y', this.point.x);
                            s += '<br/> Stroke rate : ' + this.point.y + ' spm';
                            s += '<br/> Pace : ' + formatTimeAsPace(this.point.pace);
                            return s;
                        },
                        useHTML: true
                    },
                    plotOptions: {
                        scatter: {
                            marker: {
                                radius: 5,
                                states: {
                                    hover: {
                                        enabled: true,
                                        lineColor: 'rgb(100,100,100)'
                                    }
                                }
                            },
                            states: {
                                hover: {
                                    marker: {
                                        enabled: false
                                    }
                                }
                            },
                        }
                    },
                    credits: {
                        enabled: false
                    }
                }
            }
        },

        methods: {
            getHistoryResultsForTrendChart() {
                axios.get('public-results/'+this.result.id+'/simillar?per_page=1000&order_by=date&order_direction=desc').then((data) => {
                    this.loaded = true;
                    this.items =  data.data.results.data;

                    let rate16 = [];
                    let rate23 = [];
                    let rate29 = [];

                    var i = 0;
                    for(i; i < this.items.length; i++){

                        let y = moment(this.items[i].date, 'YYYY-MM-DD HH:mm:ss').format('YYYY');
                        let m = moment(this.items[i].date, 'YYYY-MM-DD HH:mm:ss').format('M');
                        let d = moment(this.items[i].date, 'YYYY-MM-DD HH:mm:ss').format('D');
                        let itemDate = Date.UTC(y,m-1,d);

                        if(this.items[i].stroke_rate >= 16 && this.items[i].stroke_rate <= 22)
                        {
                            rate16.push({x: itemDate, y: this.items[i].stroke_rate, name: this.items[i].name, pace: this.items[i].pace});
                        }
                        if(this.items[i].stroke_rate >= 23 && this.items[i].stroke_rate <= 28)
                        {
                            rate23.push({x: itemDate, y: this.items[i].stroke_rate, name: this.items[i].name, pace: this.items[i].pace});
                        }
                        if(this.items[i].stroke_rate >= 29)
                        {
                            rate29.push({x: itemDate, y: this.items[i].stroke_rate, name: this.items[i].name, pace: this.items[i].pace});
                        }
                    }

                    this.chartOptions.series.push({
                        name: "Rate 16 - 22",
                        data: rate16,
                        tooltip: {
                            valueSuffix: ' spm'
                        },
                    });
                    this.chartOptions.series.push({
                        name: "Rate 23 - 28",
                        data: rate23,
                        tooltip: {
                            valueSuffix: ' spm'
                        },
                    });
                    this.chartOptions.series.push({
                        name: "Rate 29+",
                        data: rate29,
                        tooltip: {
                            valueSuffix: ' spm'
                        },
                    });
                }).catch(error => {
                    this.$store.dispatch('alerts/addRegularAlert', {
                        contentHTML: error.response.data.message,
                        dismissible: true,
                        duration: 5000,
                        type: 'danger'
                    });
                });
            }
        },
        mounted() {
            this.getHistoryResultsForTrendChart();
        }
    };
</script>
