<template>
  <vx-card>
    <form @submit.prevent="accountFormSubmit">
      <!-- Img Row -->
      <vue-image-input :placeholder="'users_image-128.jpg'" :folder="'users_image'"
                       v-model="accountForm.image"></vue-image-input>

      <!-- Info -->
      <vs-input
        label="First Name"
        name="first_name"
        v-model="accountForm.first_name"
        class="w-full mt-2"/>
      <span class="text-danger text-sm" v-if="formControlHasError(accountForm, 'first_name')">{{ formControlGetError(accountForm, 'first_name') }}</span>

      <vs-input
        label="Last Name"
        name="last_name"
        v-model="accountForm.last_name"
        class="w-full mt-2"/>
      <span class="text-danger text-sm" v-if="formControlHasError(accountForm, 'last_name')">{{ formControlGetError(accountForm, 'last_name') }}</span>

      <div class="vs-component vs-con-input-label vs-input w-full mt-2 vs-input-primary">
        <label class="vs-input--label">Date Of Birth</label>
        <div class="vs-con-input">
          <datetime v-model="accountForm.date_of_birth"
                    :class="{'is-invalid': formControlHasError(accountForm, 'date_of_birth')}"
                    input-class="vs-inputx vs-input--input normal" zone="utc" value-zone="utc"
                    :max-datetime="maxDatetime" class="theme-green w-full" title="Hint: Year and Month are also clickable">
          </datetime>
        </div>
      </div>

      <vs-input
        name="email"
        type="email"
        label="Email"
        v-model="accountForm.email"
        class="w-full mt-2"/>
      <span class="text-danger text-sm" v-if="formControlHasError(accountForm, 'email')">{{ formControlGetError(accountForm, 'email') }}</span>

      <vs-input
        type="password"
        name="password"
        label="Password"
        v-model="accountForm.password"
        description-text="Leave this field empty to keep your current password."
        class="w-full mt-2"/>
      <span class="text-danger text-sm" v-if="formControlHasError(accountForm, 'password')">{{ formControlGetError(accountForm, 'password') }}</span>

      <vs-input
        type="password"
        name="password_confirmation"
        label="Password Confirmation"
        v-model="accountForm.password_confirmation"
        description-text="Leave this field empty to keep your current password."
        class="w-full mt-2"/>
      <span class="text-danger text-sm" v-if="formControlHasError(accountForm, 'password_confirmation')">{{ formControlGetError(accountForm, 'password_confirmation') }}</span>

      <vs-checkbox v-model="accountForm.receive_marketing_updates" class="mt-4">Receive marketing updates</vs-checkbox>
      <span class="text-danger text-sm" v-if="formControlHasError(accountForm, 'receive_marketing_updates')">{{ formControlGetError(accountForm, 'receive_marketing_updates') }}</span>

      <!-- Save & Reset Button -->
      <div class="flex-wrap items-center justify-end mt-2">
        <vs-button class="ml-auto mt-2" button="submit">Save</vs-button>
        <vs-button type="border" class="ml-4 mb-2" color="danger" @click.prevent="accountDeleteDialogShow()">Delete</vs-button>
      </div>
    </form>
  </vx-card>
</template>

<script>
  import axios from 'axios'
  import {mapGetters} from 'vuex'
  import Form from 'vform'
  import FormErrors from '@/components/mixins/FormErrors'
  import Numbers from '@/components/mixins/Numbers'
  import moment from 'moment'

  export default {
    components: {},
    // Set meta tags
    head: {
      title: {
        inner: 'Account Settings'
      }
    },

    mixins: [FormErrors, Numbers],

    computed: {
      ...mapGetters({
        authenticatedUser: 'auth/getAuthenticatedUser',
      })
    },

    data: () => ({
      accountForm: new Form({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        image: '',
        receive_marketing_updates: ''
      }),
      maxDatetime: moment.utc().subtract(5, 'years').format(),
    }),

    mounted() {
      this.accountForm.first_name = this.authenticatedUser.first_name;
      this.accountForm.last_name = this.authenticatedUser.last_name;
      this.accountForm.email = this.authenticatedUser.email;
      this.accountForm.image = this.authenticatedUser.image;
      //this.accountForm.date_of_birth = DateTime.fromSQL(this.authenticatedUser.date_of_birth).toLocaleString(DateTime.DATE_SHORT);
      this.accountForm.date_of_birth = moment.utc(this.authenticatedUser.date_of_birth).format();
      this.accountForm.receive_marketing_updates = this.authenticatedUser.receive_marketing_updates;
    },

    methods: {
      accountFormSubmit() {
        this.formResetErrors(this.accountForm);

        this.accountForm.post('/users/me/update')
          .then(({data}) => {
            this.$vs.notify({
              text: data.message,
              color:'success',
              position:'top-right',
              time:5000,
            });

            this.$store.dispatch('auth/fetchUser');
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },

      accountDeleteDialogShow(id) {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: `Please Confirm`,
          text: 'Are you sure you want to delete your account?',
          acceptText: 'Yes, delete it',
          accept: this.deleteAccount
        })
      },

      deleteAccount() {
        axios.post('/users/me/destroy')
          .then(({data}) => {
            this.$vs.notify({
              text: data.message,
              color:'success',
              position:'top-right',
              time:5000,
            });

            this.$router.push({name: 'web.auth.logout'});
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      }
    }
  }
</script>
