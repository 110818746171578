if (process.env.NODE_ENV === 'production') {
    const APP_ID = process.env.VUE_APP_INTERCOM_APP_ID;
    window.intercomSettings = {
        app_id: APP_ID,
    };
    (function() {
        const w = window;
        const ic = w.Intercom;
        if (typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
        } else {
            const d = document;
            const i = function() {
                i.c(arguments);
            };
            i.q = [];
            i.c = function(args) {
                i.q.push(args);
            };
            w.Intercom = i;
            const l = function() {
                const s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = false;
                s.defer = true;
                s.src = 'https://widget.intercom.io/widget/' + APP_ID;
                var x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === 'complete') {
                l();
            } else if (w.attachEvent) {
                w.attachEvent('onload', l);
            } else {
                w.addEventListener('load', l, false);
            }
        }
    })();
}
