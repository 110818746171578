<template>
    <vs-alert active="true" color="success">
      Connecting...
    </vs-alert>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'ConnectRp3',

        beforeCreate() {
            axios.post('/connect/rp3', {
                'code': this.$route.query.code
            }).then((data) => {
              this.$vs.notify({
                text: data.data.message,
                color:'success',
                position:'top-right',
                time:5000,
              });

                this.$router.push({ name: "account.integrations.edit"})
            }).catch(error => {
                this.$vs.notify({
                  text: error.response.data.message,
                  color:'danger',
                  position:'top-right',
                  time:5000,
                });
            });
        },

    };
</script>
