<template>
  <div class="flex items-center">
    {{ params.value | formatNumber }}m
  </div>
</template>

<script>
export default {
  name: 'CellRendererDistance',
  computed: {
  }
}
</script>
