<template>

</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    computed: {
      ...mapGetters({
        authenticatedUser: 'auth/getAuthenticatedUser',
      })
    },
    created() {
      this.$router.replace({name: 'yearly-2021-stats.show', params: { id: this.authenticatedUser.id }})
    },
  }
</script>
