<template>
    <vx-card v-if="loaded" title="Latest Workouts" >
      <template slot="actions" v-if="items.length">
          <a class="cursor-pointer ml-auto text-primary" @click="resyncLogbook">Re-Sync workouts</a>
      </template>
      <template >
        <template v-if="items.length">
          <ag-session-list :items="items" :default-columns="defaultColumns"></ag-session-list>
        </template>
        <template v-else>
            There are no workouts
        </template>
      </template>
    </vx-card>
</template>


<script>
    import axios from 'axios'
    import AgSessionList from "../reports/AgSessionList";

    export default {
        name: 'my-latest-workouts',

        components: {AgSessionList},
        data: () => {
            return {
                loaded: false,
                items: [],
                defaultColumns: ['date','name','distance','time','pace','stroke_rate','power', 'ets_score', 'actions']
            }
        },
        methods: {
            getResults() {
                this.loaded = false;

                axios.get('users/me/results?per_page=10&order_by=date&order_direction=desc').then((data) => {
                    this.items = data.data.results.data;
                    this.loaded = true;
                }).catch(error => {
                  this.$vs.notify({
                    text: error.response.data.message,
                    color:'danger',
                    position:'top-right',
                    time:5000,
                  });
                });
            },
            resyncLogbook() {
              axios.post('/resync/logbook')
                .then(({data}) => {
                  this.$vs.notify(
                    {
                      text: data.message,
                      color:'success',
                      position:'top-right',
                      time:5000,
                    }
                  );
                })
                .catch(error => {
                  this.$vs.notify({
                    text: error.response.data.message,
                    color:'danger',
                    position:'top-right',
                    time:5000,
                  });
                });
            }
        },
        created() {
            this.getResults();
        }
    }
</script>
