<template>
  <vx-card class="mb-base" title="Training Load">
    <highcharts :options="chartOptions"></highcharts>
  </vx-card>
</template>
<script>
  import Highcharts from 'highcharts'
  import $ from 'jquery'
  import moment from 'moment'
  import {formatTime, formatTimeAsPace} from '@/shared/utils'
  import {formatDate, formatDateTime} from "../../shared/utils";
  import {mapGetters} from "vuex";

  export default {
    name: 'heart-rate-distribution-stacked-bar-chart',

    components: {},
    props: {
      items: {
        type: [Array, Object],
        default: () => []
      }
    },
    computed: {
      maxHR() {
        if(this.authenticatedUser.max_heart_rate)
        {
          return this.authenticatedUser.max_heart_rate;
        }
        return 220;
      },
      hrZonePercentages() {
        if(this.authenticatedUser.heart_rate_zones)
        {
          return this.authenticatedUser.heart_rate_zones;
        }

        return this.defaultHrZones;
      },
      ...mapGetters({
        authenticatedUserHasPermissionToSee: 'auth/getAuthenticatedUserHasPermissionToSee',
        authenticatedUser: 'auth/getAuthenticatedUser',
      })
    },
    data() {
      return {
        loaded: false,
        chartOptions: {
          chart: {
            zoomType: 'xy',
            type: 'column',
            backgroundColor: 'rgba(0,0,0,0)',
            events: {
              load: function () {
                /* On mobile, remove labels */
                if ($(window).width() < 900) {
                  this.yAxis[0].update({
                    title: false,
                  });
                  this.tooltip.update({
                    style: {
                      width: Math.min(300, 2 * $(window).width() / 3)
                    }
                  });
                }

                $('.highcharts-scrollbar').hide();
              },
            }
          },
          title: false,
          xAxis: {
            startOnTick: true,
            // endOnTick: true,
            showLastLabel: true,
            categories: [],
            events: {
              afterSetExtremes: function() {
                this.displayBtn ? $('.highcharts-scrollbar').show() : $('.highcharts-scrollbar').hide()
              }
            }
          },
          yAxis: [{ // Primary yAxis
            labels: {
              // style: {
              //   color: Highcharts.getOptions().colors[2]
              // },
              formatter: function () {
                return this.value;
              }
            },
            title: {
              text: '% in Heart Rate Zone',
              // style: {
              //   color: Highcharts.getOptions().colors[2]
              // }
            },
            allowDecimals: false,
          }, { // Secondary yAxis
            opposite: true,
            maxPadding: 0,
            title: {
              text: 'Training Load',
            },
          }],
          plotOptions: {
            column: {
              stacking: 'percent'
            },
            series: {
              pointPadding: 0.1,
              groupPadding: 0,
              borderWidth: 0,
              shadow: false
            }
          },
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            labelFormatter: function () {
              return this.name + ' '+this.options.tooltip_info;
            },
            backgroundColor:
              Highcharts.defaultOptions.legend.backgroundColor || // theme
              'rgba(255,255,255,0.25)'
          },
          scrollbar: {
            enabled: true
          },
          series: [],
          responsive: {
            rules: [{
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  floating: false,
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                  x: 0,
                  y: 0
                }
              }
            }]
          },
          tooltip: {
            shared: true,
            formatter: function() {
              let notInZones = this.points.slice(-1);
              let s = '<b>' + this.x + '</b>';
              if(notInZones != undefined && notInZones[0].point != undefined && notInZones[0].point.name != undefined)
              {
                s += '<br/><b>'+ notInZones[0].point.name +'</b>';
                s += '<br/>Pace: <b>'+ formatTimeAsPace(notInZones[0].point.pace) +'</b><br/><br/>';
              }

              $.each(this.points, function(i, point) {
                if(this.point.percentage)
                {
                  s += '<br/>' + '<span style="color:' + point.color + '">\u25CF</span> ' + this.series.name + ': ';

                  s += this.point.percentage.toFixed(1) + '%';
                  if(this.point.y > 10)
                  {
                    s += ' (' + formatTime(this.point.y) +')'
                  } else {
                    s += ' (< 1 sec)'
                  }
                } else {
                  if(this.point.series.name == 'Training Load') {
                    s += '<br/>' + '<span style="color:' + point.color + '">\u25CF</span> ' + this.series.name + ': ';
                    s += this.point.y;
                  }
                }
              });
              return s;
            },
          },
          credits: {
            enabled: false
          },
        },
        defaultHrZones: {
          zone_1: '50',
          zone_2: '60',
          zone_3: '70',
          zone_4: '80',
          zone_5: '90',
        },
      }
    },

    methods: {},
    mounted() {
      this.loaded = true;

      let notInZones = [];
      let zone1 = [];
      let zone2 = [];
      let zone3 = [];
      let zone4 = [];
      let zone5 = [];
      let categories = [];
      let ets = [];

      var i = 0;
      for (i; i < this.items.length; i++) {
        if(this.items[i].heart_rate.average != undefined && (this.items[i].time_spent_in_heart_rate_zones.not_in_zones > 0 ||  this.items[i].time_spent_in_heart_rate_zones.zone_1 > 0 || this.items[i].time_spent_in_heart_rate_zones.zone_2 > 0 || this.items[i].time_spent_in_heart_rate_zones.zone_3 > 0 || this.items[i].time_spent_in_heart_rate_zones.zone_4 > 0 || this.items[i].time_spent_in_heart_rate_zones.zone_5 > 0))
        {
          let itemDate = formatDate(this.items[i].date);

          categories.push(itemDate);
          notInZones.push({y: parseInt(this.items[i].time_spent_in_heart_rate_zones.not_in_zones), name: this.items[i].name, pace: this.items[i].pace});
          zone1.push(parseInt(this.items[i].time_spent_in_heart_rate_zones.zone_1));
          zone2.push(parseInt(this.items[i].time_spent_in_heart_rate_zones.zone_2));
          zone3.push(parseInt(this.items[i].time_spent_in_heart_rate_zones.zone_3));
          zone4.push(parseInt(this.items[i].time_spent_in_heart_rate_zones.zone_4));
          zone5.push(parseInt(this.items[i].time_spent_in_heart_rate_zones.zone_5));

          ets.push(this.items[i].ets_score);
        }
      }

      this.chartOptions.xAxis.categories = categories;

      this.chartOptions.series.push({
        name: "Zone 5",
        data: zone5,
        color: '#d72433',
        tooltip_info: '(>='+ parseInt(this.maxHR/100*this.hrZonePercentages.zone_5) + 'bpm)',
      });

      this.chartOptions.series.push({
        name: "Zone 4",
        data: zone4,
        color: '#e96126',
        tooltip_info: '('+ parseInt(this.maxHR/100*this.hrZonePercentages.zone_4) +'-'+ parseInt(this.maxHR/100*this.hrZonePercentages.zone_5) + 'bpm)',
      });

      this.chartOptions.series.push({
        name: "Zone 3",
        data: zone3,
        color: "#3e9449",
        tooltip_info: '('+ parseInt(this.maxHR/100*this.hrZonePercentages.zone_3) +'-'+ parseInt(this.maxHR/100*this.hrZonePercentages.zone_4) + 'bpm)',
      });

      this.chartOptions.series.push({
        name: "Zone 2",
        data: zone2,
        color: '#0b81b3',
        tooltip_info: '('+ parseInt(this.maxHR/100*this.hrZonePercentages.zone_2) +'-'+ parseInt(this.maxHR/100*this.hrZonePercentages.zone_3) + 'bpm)',
      });

      this.chartOptions.series.push({
        name: "Zone 1",
        data: zone1,
        color: '#6d7278',
        tooltip_info: '('+ parseInt(this.maxHR/100*this.hrZonePercentages.zone_1) +'-'+ parseInt(this.maxHR/100*this.hrZonePercentages.zone_2) + 'bpm)',
      });

      this.chartOptions.series.push({
        name: "Not In Zones",
        data: notInZones,
        color: '#c3c6c7',
        tooltip_info: '(<'+ parseInt(this.maxHR/100*this.hrZonePercentages.zone_1)+'bpm)',
      });

      this.chartOptions.series.push({
        name: "Training Load",
        data: ets,
        yAxis: 1,
        type: 'spline',
        tooltip_info: '',
        connectNulls: true
      });
    }
  };
</script>
