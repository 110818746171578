<template>
  <div id="div-with-loading" class="vs-con-loading__container" style="min-height: 60px"></div>
</template>

<script>
  import {colors} from "../../../themeConfig";

    export default {
        name: 'loader',

      mounted() {
        this.$vs.loading({
          container: '#div-with-loading',
          scale: 0.6
        })
      }
    }
</script>

