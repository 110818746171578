<template>
  <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen" :value="active_tab">

    <!-- GENERAL -->
    <vs-tab icon-pack="feather" icon="icon-edit" :label="!isSmallerScreen ? 'General' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <edit-workout-plan></edit-workout-plan>
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-grid" :label="!isSmallerScreen ? 'Workouts' : ''">
      <div class="tab-info md:ml-4 md:mt-0 mt-4 ml-0">
        <create-workouts></create-workouts>
      </div>
    </vs-tab>
  </vs-tabs>
</template>

<script>

import CreateWorkouts from "./CreateWorkouts";
import EditWorkoutPlan from "./Edit";
export default {
  components: {
    EditWorkoutPlan,
    CreateWorkouts
  },
  data () {
    return {
      active_tab: 0,
      key: 1,
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },

  created () {
    if(this.$route.name == 'workout-plans.edit-workouts')
    {
      this.active_tab = 2;
    }
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
