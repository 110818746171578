export const DEFAULT_RANGE_COLOR = ['#ebedf0', '#cececf', '#73b3f3', '#3886e1', '#aad76a', '#43ba64', '#f3e07a', '#ffa46c', '#fb5d5d', '#bd0201','#800000']

export const DEFAULT_LOCALE = {
  months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  on: 'on',
  less: 'Less',
  more: 'More'
}

export const DEFAULT_TOOLTIP_UNIT = 'contributions'

export const DAYS_IN_ONE_YEAR = 365

export const DAYS_IN_WEEK = 7

export const SQUARE_SIZE = 10
