<template>
  <div class="flex items-center">
    {{ params.data.public_user.first_name + ' ' + params.data.public_user.last_name }}
  </div>
</template>

<script>
export default {
  name: 'CellRendererGroupUserName',
  computed: {
  }
}
</script>
