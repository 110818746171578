<template>
    <div class="flex items-center mt-3">
      <feather-icon icon="ArrowRightCircleIcon" svgClasses="h-6 w-6 mr-4 hover:text-primary cursor-pointer" @click="$router.push({name: 'results.show', params: { id: params.data.id }})" />
      <feather-icon icon="LayersIcon" svgClasses="h-6 w-6 hover:text-primary cursor-pointer" @click="$router.push({name: 'reports.comparison', params: { id: params.data.id }})" />
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  methods: {
  }
}
</script>
