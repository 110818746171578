<template>
    <ag-grid-vue class="ag-theme-material w-100 ag-grid-table"
                 :columnDefs="columnDef"
                 :defaultColDef="defaultColDef"
                 :rowData="items"
                 @first-data-rendered="onFirstDataRendered">
    </ag-grid-vue>
</template>
<script>
    import { each} from 'lodash';
    import { AgGridVue } from 'ag-grid-vue'
    import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

    import CellRendererTime from '../global/cell-renderer/CellRendererTime.vue'
    import CellRendererPace from '../global/cell-renderer/CellRendererPace.vue'
    import CellRendererDistance from '../global/cell-renderer/CellRendererDistance.vue'
    import CellRendererDate from "../global/cell-renderer/CellRendererDate";
    import CellRendererWorkoutActions from '../global/cell-renderer/CellRendererWorkoutActions.vue'
    import CellRendererDifference from "../global/cell-renderer/CellRendererDifference";

    import {calculatePaceFromTime, paceToWatts} from "../../shared/utils"

    export default {
        name: 'public-history-comparison-table',

        components: {
          AgGridVue,
          CellRendererTime,
          CellRendererPace,
          CellRendererDistance,
          CellRendererDate,
          CellRendererWorkoutActions,
          CellRendererDifference
        },
        props: {
          items: {
            type: [Array, Object],
            default: () => []
          },
        },
        data() {
            return {
                // items: [],
                title: '',
                defaultColDef: { resizable: true },
                columnDef: [
                  {field: 'date', headerName: 'Date', sortable: true, cellRendererFramework: 'CellRendererDate', minWidth: 100},
                  {field: 'time', headerName: 'Time', sortable: true, cellRendererFramework: 'CellRendererTime', minWidth: 80},
                  {field: 'distance', headerName: 'Distance', sortable: true, cellRendererFramework: 'CellRendererDistance', minWidth: 100},
                  {field: 'pace', headerName: 'Pace', sortable: true, cellRendererFramework: 'CellRendererPace', minWidth: 80},
                  {field: 'power', headerName: 'Watts', sortable: true, minWidth: 60},
                  {field: 'stroke_rate', headerName: 'S/m', sortable: true, minWidth: 60},
                  {field: 'difference', headerName: 'Diff.', sortable: true, cellRendererFramework: 'CellRendererDifference', minWidth: 70},
                  {field: 'actions', sortable: false, cellRendererFramework: 'CellRendererWorkoutActions', minWidth: 90},
                ],
            }
        },
        computed: {
            avgWatts() {
                let average = _.meanBy(this.items, 'watts');
                return average;
            },

        },
        created() {
        },
        methods: {
          onFirstDataRendered(params) {
            params.api.sizeColumnsToFit();
          }
        },
    };
</script>
