<template>
  <vx-card :title="title">
    <template slot="actions">
      <vs-dropdown vs-trigger-click class="cursor-pointer">
        <small class="flex cursor-pointer">
          <span>{{ filterText }}</span>
          <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" class="ml-1" />
        </small>
        <vs-dropdown-menu class="w-32">
          <vs-dropdown-item @click="updateGraph('week')">Last 7 days</vs-dropdown-item>
          <vs-dropdown-item @click="updateGraph('month')">Last Month</vs-dropdown-item>
          <vs-dropdown-item @click="updateGraph('year')">Last Year</vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
    </template>
    <summary-chart-widget v-if="loaded" :items="results" :title="title" :key="key"></summary-chart-widget>
  </vx-card>
</template>


<script>
  import axios from 'axios'
  import moment from "moment";
  import SummaryChartWidget from "./SummaryChartWidget";
  import $ from 'jquery'

  export default {
    name: 'this-week-vs-previous',

    components: {SummaryChartWidget},
    data: () => {
      return {
        loaded: false,
        results: null,
        title: 'This Week VS Previous',
        key: 1,
        selected: 'week',
        filterText: 'Last 7 days'
      }
    },
    methods: {
      async filterFormSubmit(params) {
        axios.get('reports/summary?' + $.param({filters: params}) + '&group_by=' + params.group_by).then((data) => {
          this.key++;
          this.results = data.data.data;
          this.loaded = true;
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },

      updateGraph(val) {
        let params = {};
        if (val == 'month') {
          params.date_from = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
          params.date_to = moment().endOf('month').format('YYYY-MM-DD');
          params.group_by = 'month';
          this.title = 'This Month VS Previous';
          this.filterText= 'Last Month';
        } else if (val == 'year') {
          params.date_from = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
          params.date_to = moment().endOf('year').format('YYYY-MM-DD');
          params.group_by = 'year';
          this.title = 'This Year VS Previous';
          this.filterText= 'Last Year';
        } else {
          params.date_from = moment().subtract(7, 'day').startOf('week').format('YYYY-MM-DD');
          params.date_to = moment().endOf('week').format('YYYY-MM-DD');
          params.group_by = 'week';
          this.title = 'This Week VS Previous';
          this.filterText= 'Last 7 days';
        }

        this.filterFormSubmit(params);
      },
    },
    created() {
      let params = {};
      params.date_from = moment().subtract(7, 'day').startOf('week').format('YYYY-MM-DD');
      params.date_to = moment().endOf('week').format('YYYY-MM-DD');
      params.group_by = 'week';
      this.filterFormSubmit(params);
    }
  }
</script>
