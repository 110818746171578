<template>
  <highcharts :options="chartOptions"></highcharts>
</template>
<script>
    import Highcharts from 'highcharts'
    import $ from 'jquery'
    import moment from 'moment'
    import {formatDate, formatTime} from "../../shared/utils";

    export default {
        name: 'summary-chart-widget',

        components: {
        },
        props: {
            items: {
                type: [Array, Object],
                default: () => []
            },
            title: {
              type: String,
              default: 'Summary'
            }
        },
        data() {
            return {
                loaded: false,
                chartOptions: {
                    chart: {
                        type: 'column',
                        zoomType: 'xy',
                        backgroundColor: 'rgba(0,0,0,0)',
                        events: {
                            load: function() {
                                /* On mobile, remove labels */
                                if ($(window).width() < 900) {
                                    this.yAxis[0].update({
                                        title: false,
                                    });
                                    this.yAxis[1].update({
                                        title: false,
                                    });
                                    this.tooltip.update({
                                        style: {
                                            width: Math.min(300, 2*$(window).width()/3)
                                        }
                                    });
                                }
                            }
                        }
                    },
                    title: false,
                    xAxis: {
                        categories: [],
                        crosshair: true
                    },
                    yAxis: [{
                        min: 0,
                        title: {
                            text: 'Distance'
                            }
                        },
                        { // Secondary yAxis
                            opposite: true,
                            maxPadding: 0,
                            title: {
                            text: 'Time',
                                style: {
                                color: Highcharts.getOptions().colors[0]
                            }
                        },
                        labels: {
                            style: {
                                color: Highcharts.getOptions().colors[0]
                            },
                            formatter: function() {
                                return formatTime(this.value);
                            }
                        },
                    }],
                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 500
                            },
                            chartOptions: {
                                legend: {
                                    floating: false,
                                    layout: 'horizontal',
                                    align: 'center',
                                    verticalAlign: 'bottom',
                                    x: 0,
                                    y: 0
                                }
                            }
                        }]
                    },
                    tooltip: {
                        shared: true,
                        formatter: function() {
                            let s = '<b>' + this.x + '</b>';
                            $.each(this.points, function(i, point) {
                                let value = point.y;
                                let name = this.series.name;
                                if(name == 'Time')
                                {
                                    value = formatTime(value);
                                }
                                s += '<br/>' + '<span style="color:' + point.color + '">\u25CF</span> ' + this.series.name + ': ' + value + this.series.tooltipOptions.valueSuffix;
                            });
                            return s;
                        },
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    },
                    series: [],
                    credits: {
                        enabled: false
                    }
                }
            }
        },

        methods: {
        },
        mounted() {
            this.loaded = true;

            var i = 0;
            let categories = [];
            let distances = [];
            let times = [];
            for(i; i < this.items.length; i++){
                distances.push(parseInt(this.items[i].total_distance));
                times.push(parseFloat(this.items[i].total_time));
                if(this.items[i].week != undefined)
                {
                    categories.push(this.items[i].year + '/Week ' + this.items[i].week);
                }
                else if(this.items[i].month != undefined)
                {
                    categories.push(this.items[i].year + '/' + moment.months(this.items[i].month - 1));
                } else if(this.items[i].date != undefined)
                {
                    categories.push(formatDate(this.items[i].date));
                } else if(this.items[i].day_of_week != undefined)
                {
                    categories.push(moment.weekdays(this.items[i].day_of_week));
                } else {
                    categories.push(this.items[i].year);
                }
            }

            this.chartOptions.xAxis.categories = categories;

            this.chartOptions.series.push({
                name: "Distance",
                data: distances,
                tooltip: {
                    valueSuffix: ' m'
                },
            });
            this.chartOptions.series.push({
                yAxis: 1,
                name: "Time",
                data: times,
                tooltip: {
                    valueSuffix: ''
                },
            });

        }
    };
</script>
