<template>
  <div style="line-height: 23px; !important; margin-top: 12px;">
    <strong title="Total Time">{{ totalTime | timeInHours }}</strong> (&Sigma;)<br/>
    <strong title="Average Time">{{ avgTime | timeInHours }}</strong> (avg)
  </div>
</template>

<script>
export default {
  name: 'CellRendererFooterTotalTime',
  computed: {
    totalTime() {
      return _(this.$parent.$parent.items)
        .groupBy('name')
        .map((objs, key) => {
          return {
            'name': key,
            'time': _.sumBy(objs, 'time')
          }
        })
        .value()[0].time;
    },
    avgTime() {
      let average = _.meanBy(this.$parent.$parent.items, 'time');
      return average;
    },
  }
}
</script>
