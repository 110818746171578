<template>
  <div>
    <vx-card v-if="authenticatedUser == null" card-background="success" content-color="#fff" class="text-center mb-base">
      Use Logbook? See your lockdown meters today and other great reports and insights from ErgMonkey.com. <router-link :to="{ name: 'web.auth.register' }" class="font-bold" style="color: white !important;">Join ErgMonkey Today</router-link>
    </vx-card>
    <vs-row>
      <vs-col>
        <vx-card class="mb-base">
            Lockdown started: <span class="font-bold">{{ result.public_user.lockdown_started | formatDate}}</span>
        </vx-card>
      </vs-col>
    </vs-row>
    <vs-row>
      <div class="vx-col w-full mb-base">
        <lockdown-summary-widgets :summary="summary"></lockdown-summary-widgets>
      </div>
      <div class="vx-col w-full mb-base">
        <lockdown-progress-graph-distance v-if="summaryResults!= null"
                                          :summaryResults="summaryResults" :minDate="result.public_user.lockdown_started"></lockdown-progress-graph-distance>
      </div>
    </vs-row>
  </div>
</template>

<script>
  import axios from 'axios'
  import moment from 'moment'
  import {mapGetters} from "vuex";
  import LockdownSummaryWidgets from "../../../components/reports/LockdownSummaryWidgets";
  import LockdownProgressGraphDistance from "../../../components/reports/LockdownProgressGraphDistance";
  import {formatDateTime} from "../../../shared/utils";

  export default {
    name: 'stats-report',
    head: {
      title: function () {
        return {
          inner: this.title
        }
      },
      meta: [
        { name: 'og:image', content: 'https://stats.ergmonkey.com/img/brand/lockdown-meters-sharing.png', id: 'og:image'},
      ]
    },
    components: {
      LockdownProgressGraphDistance,
      LockdownSummaryWidgets,
    },
    computed: {
      ...mapGetters({
        authenticatedUser: 'auth/getAuthenticatedUser',
      }),
    },
    data: () => ({
      result: null,
      loaded: false,
      summaryResults: null,
      summary: null,
      title: 'Lockdown Meters',
      page_title: ''
    }),
    methods: {
      async getLockdownMetersResult(id) {
        this.loaded = false;

        axios.get('public-lockdown-meters-results/' + id + '?include=results,public_user,summary').then((data) => {
          this.result = data.data.result;
          this.title = 'Lockdown Meters - ' +  this.result.public_user.first_name;

          if (this.result.results != undefined) {
            this.summaryResults = this.result.results;
          }

          if (this.result.summary != undefined) {
            this.summary = this.result.summary;
          }

          this.loaded = true;
          this.page_title = 'Lockdown Meters - ' +  this.result.public_user.first_name + ' ' + this.result.public_user.last_name;
          this.$emit('changeRouteTitle', this.page_title);
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },
    },
    created() {
      this.getLockdownMetersResult(this.$route.params.id);
    },
    beforeRouteUpdate(to, from, next) {
      this.getLockdownMetersResult(to.params.id);
      next();
    }
  }
</script>
