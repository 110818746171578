<template>
    <vx-card v-if="integrationsLoaded && integrations.length == 0" class="mb-base">
        <div class="h5 text-danger mb-1 mt-2">Get Started - Connect your account to Logbook to see your workouts, stats and more in ErgMonkey.</div>
        <div class="text-muted text-uppercase font-weight-bold font-xs">To connect click <router-link :to="{ name: 'account.integrations.edit'}">here</router-link></div>
    </vx-card>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: 'integrations-widget',

    components: {},

    computed: mapGetters({
      integrations: 'integrations/getIntegrations',
      integrationsLoaded: 'integrations/getIntegrationsLoaded'
    }),

    data: () => ({
      integrationActionsOptions: {
        id: '',
        busy: false,
        visibility: false
      },
      componentKey: 0
    }),

    beforeMount: async function () {
      await this.$store.dispatch('integrations/setIntegrationsLoaded', {loaded: false});
    },

    mounted: async function () {
      await Promise.all([
        this.fetchIntegrations(),
      ]);

    },

    methods: {
      fetchIntegrations() {
        this.$store.dispatch('integrations/fetchIntegrations')
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      }
    }
  }
</script>
