<template>
  <div>
    <vs-row>
      <vx-card class="mb-base">
          <form @submit.prevent="filterFormSubmit">
            <div class="vs-component vs-con-input-label vs-input w-full mt-2 vs-input-primary">
              <label class="vs-input--label">Lockdown Started</label>
              <div class="vs-con-input">
                <datetime v-model="filterForm.date_from"
                          :class="{'is-invalid': formControlHasError(filterForm, 'date_from')}"
                          input-class="vs-inputx vs-input--input normal" zone="utc" value-zone="utc"
                          :max-datetime="maxDatetime" class="theme-green w-full" title="Hint: Year and Month are also clickable"></datetime>
                <span>
                  <div class="con-text-validation span-text-validation vs-input--text-validation-span">
                    <span class="span-text-validation">Let us know when lockdown started</span>
                  </div>
                </span>
              </div>
            </div>
          </form>
      </vx-card>
      <template v-if="filterForm.date_from && loaded">
        <portal to="title-bar-actions">
          <vs-button radius icon="icon-share-2" icon-pack="feather" @click="shareModal = true"/>
        </portal>

        <div class="vx-col w-full mb-base">
          <lockdown-summary-widgets :filters="filterForm"></lockdown-summary-widgets>
        </div>
        <div class="vx-col w-full mb-base">
          <lockdown-progress-graph-distance v-if="summaryResults!= null"
                                            :summaryResults="summaryResults" :minDate="authenticatedUser.lockdown_started"></lockdown-progress-graph-distance>
        </div>
      </template>
      <div class="vx-col w-full mb-base">
<!--            <summary-chart v-if="false" :items="summaryResults"></summary-chart>-->
        <vx-card card-background="success" content-color="#fff" class="text-center">
          Inspire your friends to stay fit and healthy. <vs-button type="flat" text-color="#fff" @click="shareModal = true"><feather-icon icon="Share2Icon" svgClasses="h-4 w-4" /> Share</vs-button>
        </vx-card>
      </div>
    </vs-row>

    <vs-popup title="Share Lockdown Meteres" :active.sync="shareModal">
      <vs-row class="mb-2">
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="facebook" class="vs-component vs-button vs-button-null vs-button-filled btn-facebook w-full mr-2">
              <feather-icon icon="FacebookIcon" svgClasses="h-4 w-4" /> Facebook
            </network>
          </vs-col>
        </social-sharing>
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="twitter" class="vs-component vs-button vs-button-null vs-button-filled btn-twitter w-full ml-2">
              <feather-icon icon="TwitterIcon" svgClasses="h-4 w-4" /> Twitter
            </network>
          </vs-col>
        </social-sharing>
      </vs-row>
      <vs-row class="mb-2">
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="linkedin" class="vs-component vs-button vs-button-null vs-button-filled btn-linkedin w-full mr-2">
              <feather-icon icon="LinkedinIcon" svgClasses="h-4 w-4" /> LinkedIn
            </network>
          </vs-col>
        </social-sharing>
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="reddit" class="vs-component vs-button vs-button-null vs-button-filled btn-reddit w-full ml-2">
              <feather-icon icon="RedditIcon" svgClasses="h-4 w-4" /> Reddit
            </network>
          </vs-col>
        </social-sharing>
      </vs-row>
      <vs-row class="mb-2">
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="email" class="vs-component vs-button vs-button-warning vs-button-filled w-full mr-2">
              <feather-icon icon="MailIcon" svgClasses="h-4 w-4" /> Email
            </network>
          </vs-col>
        </social-sharing>
        <vs-col vs-w="6">
          <vs-button color="primary" v-clipboard:copy="shareUrl" v-clipboard:success="onCopySuccess" class="w-full ml-2">
            <feather-icon icon="CopyIcon" svgClasses="h-4 w-4" /> Copy
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>

  </div>
</template>

<script>
  import axios from 'axios'
  import $ from 'jquery'
  import Form from 'vform'
  import FormErrors from '@/components/mixins/FormErrors'
  import moment from 'moment'
  // import SummaryChart from "../../../components/reports/SummaryChart";
  // import SplitLineChart from "../../../components/reports/SplitLineChart";
  import {mapGetters} from "vuex";
  import LockdownSummaryWidgets from "../../../components/reports/LockdownSummaryWidgets";
  import LockdownProgressGraphDistance from "../../../components/reports/LockdownProgressGraphDistance";

  export default {
    name: 'lockdown-meters-report',
    head: {
      title: {
        inner: 'Lockdown Meters Report'
      }
    },
    components: {
      LockdownProgressGraphDistance,
      LockdownSummaryWidgets,
      // SplitLineChart, SummaryChart
    },
    computed: {
      shareUrl: function () {
        return 'https://stats.ergmonkey.com/share/lockdown-meters/' + this.authenticatedUser.id;
      },
      shareTitle: function () {
        return 'My Lockdown meters';
      },
      ...mapGetters({
        authenticatedUser: 'auth/getAuthenticatedUser',
      }),
    },
    mixins: [FormErrors],
    data: () => ({
      filterForm: new Form({
        date_from: '',
        group_by: 'date'
      }),
      maxDatetime: moment.utc().format(),
      loaded: false,
      summaryResults: null,
      shareModal: false,
      shareDesription: '',
      shareQuote: '',
      shareHashtags: '',
      shareTwitterUser: 'ergmonkey_'
    }),
    watch: {
      filterForm: {
        handler(val) {
          this.filterFormSubmit();

          this.updateLockdownStarted();
        },
        deep: true
      }
    },
    methods: {
      async filterFormSubmit() {
        this.loaded = false;

        if (this.filterForm.date_from != '') {
          let params = new Form({
            date_from: moment.utc(this.filterForm.date_from).format('YYYY-MM-DD'),
            group_by: 'date'
          });

          axios.get('reports/summary?' + $.param({filters: params}) + '&group_by=' + this.filterForm.group_by).then((data) => {
            this.summaryResults = data.data.data;
            this.loaded = true;
          }).catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
        }
      },

      async updateLockdownStarted() {
        if (this.filterForm.date_from != '' && moment.utc(this.filterForm.date_from).format('YYYY-MM-DD') != this.authenticatedUser.lockdown_started) {
          let lockdownStarted = moment.utc(this.filterForm.date_from).format('YYYY-MM-DD');

          axios.post('/users/me/update-lockdown-started', {
            'lockdown_started': lockdownStarted
          }).then(({data}) => {
            this.$vs.notify({
              text: data.message,
              color:'success',
              position:'top-right',
              time:5000,
            });
          })
            .catch(error => {
              this.$vs.notify({
                text: error.response.data.message,
                color:'danger',
                position:'top-right',
                time:5000,
              });
            });
        }
      },

      onCopySuccess() {
        this.shareModal = false;
        this.$vs.notify({
          text: "Copied!",
          color:'success',
          position:'top-right',
          time:5000,
        });
      },
    },
    mounted() {
      if (this.authenticatedUser.lockdown_started != '') {
        this.filterForm.date_from = moment.utc(this.authenticatedUser.lockdown_started).format();
      }
    },
    created() {
      this.filterFormSubmit();
    }
  }
</script>
