<template>
  <div>
    <highcharts :options="chartOptions" style="height: 250px;"></highcharts>
    <highcharts v-if="source == 2" :options="chartOptions2" style="height: 250px;"></highcharts>
  </div>
</template>
<script>
    import {each} from 'lodash';
    import { formatTime, formatTimeAsPace } from '@/shared/utils'
    import Highcharts from 'highcharts'
    import $ from 'jquery'

    export default {
        name: 'split-chart',

        components: {
        },
        props: {
            interval: {
                type: [Array, Object],
                default: () => []
            },
            source: {
              type: Number,
              default: 1
            }
        },
        data() {
            let time = [];
            let pace = [];
            let strokeRate = [];
            let heartRate = [];

            //
          let power = [];
          let strokeLength = [];
          let driveTime = [];
          let recoverTime = [];
          let peakForcePos = [];

            each(this.interval.stroke_data, split => {
                time.push(split.t / 10);
                pace.push(split.p != 0 ? split.p / 10 : null);
                strokeRate.push(split.spm != 0 ? split.spm : null);
                heartRate.push(split.hr != 0 ? split.hr : null);

                //
              power.push(split.power != 0 ? split.power : null);
              strokeLength.push(split.stroke_length != 0 ? split.stroke_length : null);
              driveTime.push(split.drive_time != 0 ? split.drive_time : null);
              recoverTime.push(split.recover_time != 0 ? split.recover_time : null);
              peakForcePos.push(split.rel_peak_force_pos != 0 ? parseInt(split.rel_peak_force_pos * 100) : null);
                //
            });

            return {
                chartOptions: {
                    chart: {
                        zoomType: 'xy',
                        backgroundColor: 'rgba(0,0,0,0)',
                        events: {
                            load: function() {
                                /* On mobile, remove labels */
                                if ($(window).width() < 900) {
                                    this.yAxis[0].update({
                                        title: false,
                                    });
                                    this.yAxis[1].update({
                                        title: false,
                                    });
                                    this.yAxis[2].update({
                                        title: false,
                                    });
                                    this.tooltip.update({
                                        style: {
                                            width: Math.min(300, 2*$(window).width()/3)
                                        }
                                    });
                                }
                            }
                        }
                    },
                    title: false,
                    xAxis: [{
                        categories: time,
                        crosshair: true,
                        labels: {
                            formatter: function() {
                                return formatTime(this.value);
                            }
                        },
                    }],
                    yAxis: [{ // Primary yAxis
                        labels: {
                            style: {
                                color: Highcharts.getOptions().colors[1]
                            }
                        },
                        title: {
                            text: 'Stroke Rate',
                            style: {
                                color: Highcharts.getOptions().colors[1]
                            }
                        },
                        opposite: true,
                        allowDecimals: false,

                    }, { // Secondary yAxis
                        reversed: true,
                        maxPadding: 0,
                        title: {
                            text: 'Pace',
                            style: {
                                color: Highcharts.getOptions().colors[0]
                            }
                        },
                        labels: {
                            style: {
                                color: Highcharts.getOptions().colors[0]
                            },
                            formatter: function() {
                                return formatTime(this.value);
                            }
                        },
                    }, { // Tertiary yAxis
                        title: {
                            text: 'Heart Rate',
                            style: {
                                color: Highcharts.getOptions().colors[2]
                            }
                        },
                        labels: {
                            style: {
                                color: Highcharts.getOptions().colors[2]
                            }
                        },
                        opposite: true,
                        allowDecimals: false,
                    }],
                    series: [{
                        name: 'Pace',
                        type: 'spline',
                        yAxis: 1,
                        data: pace,
                        marker: {
                            enabled: false
                        },
                        tooltip: {
                            formatter: function () {
                                return formatTime(this.y);
                            }
                        }
                    }, {
                        name: 'Stroke Rate',
                        type: 'spline',
                        data: strokeRate,
                        marker: {
                            enabled: false
                        }
                    }, {
                        name: 'Heart Rate',
                        type: 'spline',
                        data: heartRate,
                        yAxis: 2,
                        tooltip: {
                            valueSuffix: ' bpm'
                        },
                        marker: {
                            enabled: false
                        }
                    }],
                    credits: {
                        enabled: false
                    },
                    tooltip: {
                        shared: true,
                        formatter: function() {
                            let s = '<b>' + formatTime(this.x) + '</b>';
                            $.each(this.points, function(i, point) {
                                let value = point.y;
                                let name = this.series.name;
                                if(name == 'Pace')
                                {
                                    value = formatTimeAsPace(value);
                                }

                                s += '<br/>' + '<span style="color:' + point.color + '">\u25CF</span> ' + this.series.name + ': ' + value;
                            });
                            return s;
                        },
                        useHTML: true
                    }
                },
              chartOptions2: {
                chart: {
                  zoomType: 'xy',
                  backgroundColor: 'rgba(0,0,0,0)',
                  events: {
                    load: function() {
                      /* On mobile, remove labels */
                      if ($(window).width() < 900) {
                        this.yAxis[0].update({
                          title: false,
                        });
                        this.yAxis[1].update({
                          title: false,
                        });
                        this.yAxis[2].update({
                          title: false,
                        });
                        this.tooltip.update({
                          style: {
                            width: Math.min(300, 2*$(window).width()/3)
                          }
                        });
                      }
                    }
                  }
                },
                title: false,
                xAxis: [{
                  categories: time,
                  crosshair: true,
                  labels: {
                    formatter: function() {
                      return formatTime(this.value);
                    }
                  },
                }],
                yAxis: [{ // Primary yAxis
                  labels: {
                    style: {
                      color: Highcharts.getOptions().colors[3]
                    }
                  },
                  title: {
                    text: 'Stroke Length',
                    style: {
                      color: Highcharts.getOptions().colors[3]
                    }
                  },
                  opposite: true,
                  allowDecimals: false,

                }, { // Secondary yAxis
                  maxPadding: 0,
                  title: {
                    text: 'Power',
                    style: {
                      color: Highcharts.getOptions().colors[4]
                    }
                  },
                  labels: {
                    style: {
                      color: Highcharts.getOptions().colors[4]
                    },
                    // formatter: function() {
                    //   return formatTime(this.value);
                    // }
                  },
                }, { // Tertiary yAxis
                  title: {
                    text: 'Rel. Peak Force Position',
                    style: {
                      color: Highcharts.getOptions().colors[5]
                    }
                  },
                  labels: {
                    style: {
                      color: Highcharts.getOptions().colors[5]
                    }
                  },
                  opposite: true,
                  allowDecimals: false,
                },{ // Tertiary yAxis
                  title: {
                    text: 'Time',
                    style: {
                      color: Highcharts.getOptions().colors[6]
                    }
                  },
                  labels: {
                    style: {
                      color: Highcharts.getOptions().colors[6]
                    }
                  },
                  opposite: true,
                  allowDecimals: false,
                  visible: false
                }],
                series: [{
                  name: 'Power',
                  type: 'spline',
                  yAxis: 1,
                  data: power,
                  marker: {
                    enabled: false
                  },
                  tooltip: {
                    valueSuffix: ' W'
                  },
                  color: Highcharts.getOptions().colors[4],
                  zIndex: 4,
                }, {
                  name: 'Stroke Length',
                  type: 'spline',
                  data: strokeLength,
                  marker: {
                    enabled: false
                  },
                  tooltip: {
                    valueSuffix: ' cm'
                  },
                  color: Highcharts.getOptions().colors[3],
                  zIndex: 3,
                }, {
                  name: 'Rel. Peak Force Position',
                  type: 'spline',
                  data: peakForcePos,
                  yAxis: 2,
                  tooltip: {
                    valueSuffix: ' %'
                  },
                  marker: {
                    enabled: false
                  },
                  color: Highcharts.getOptions().colors[5],
                  zIndex: 2,
                },{
                  name: 'Recover Time',
                  type: 'column',
                  stacking: 'normal',
                  data: recoverTime,
                  yAxis: 3,
                  tooltip: {
                    valueSuffix: ' sec'
                  },
                  marker: {
                    enabled: false
                  },
                  color: Highcharts.getOptions().colors[6],
                  zIndex: 1,
                  // opacity: 0.6,
                },{
                  name: 'Drive Time',
                  type: 'column',
                  stacking: 'normal',
                  data: driveTime,
                  yAxis: 3,
                  tooltip: {
                    valueSuffix: ' sec'
                  },
                  marker: {
                    enabled: false
                  },
                  color: Highcharts.getOptions().colors[7],
                  zIndex: 0,
                  // opacity: 0.6,
                }],
                credits: {
                  enabled: false
                },
                tooltip: {
                  shared: true,
                  formatter: function() {
                    let s = '<b>' + formatTime(this.x) + '</b>';
                    $.each(this.points, function(i, point) {
                      let value = point.y;
                      let name = this.series.name;
                      if(name == 'Pace')
                      {
                        value = formatTimeAsPace(value);
                      }

                      s += '<br/>' + '<span style="color:' + point.color + '">\u25CF</span> ' + this.series.name + ': ' + value + this.series.tooltipOptions.valueSuffix;
                    });
                    return s;
                  },
                  useHTML: true
                }
              },
            }
        },
    };
</script>
