<template>
  <vx-card>
    <!-- SLOT = ACTIONS -->
    <template slot="actions" v-if="false">
      <change-time-duration-dropdown />
    </template>

    <div class="vs-row" v-if="loaded" style="display: flex">
      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
        <vs-icon icon-pack="material-icons" icon="format_list_numbered" class="inline-flex rounded-full mb-4" size="large"/>
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ summaryData.total_number_of_workouts }}</h2>
          <span>Workouts</span>
        </div>
      </div>

      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
        <vs-icon icon-pack="material-icons" icon="settings_ethernet" class="inline-flex rounded-full mb-4" size="large"/>
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ summaryData.total_distance | formatNumber }}m</h2>
          <span>Distance</span>
        </div>
      </div>

      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
        <vs-icon icon-pack="material-icons" icon="access_time" class="inline-flex rounded-full mb-4" size="large"/>
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ summaryData.total_time | timeInHours }}</h2>
          <span>Time</span>
        </div>
      </div>

      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
        <vs-icon icon-pack="material-icons" icon="speed" class="inline-flex rounded-full mb-4" size="large"/>
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ summaryData.average_pace | timeAsPace }}</h2>
          <span>Ave. Pace</span>
        </div>
      </div>

      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
        <vs-icon icon-pack="material-icons" icon="rowing" class="inline-flex rounded-full mb-4" size="large"/>
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ summaryData.average_stroke_rate | formatNumber }}</h2>
          <span>Ave. Rate</span>
        </div>
      </div>

      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
        <vs-icon icon-pack="material-icons" icon="format_list_numbered_rtl" class="inline-flex rounded-full mb-4" size="large"/>
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ summaryData.total_number_of_strokes | formatNumber }}</h2>
          <span>Total Number of Stokes</span>
        </div>
      </div>

    </div>
    <loader v-else/>
  </vx-card>
</template>

<script>
    import axios from 'axios'
    import $ from 'jquery'

    export default {
        name: 'lockdown-summary-widgets',

        components: {},
        props: {
          filters: {
            type: [Array, Object],
            default: () => []
          },
          summary: {
            type: [Array, Object],
            default: () => []
          },
        },
        data: () => {
            return {
                loaded: false,
                summaryData: '',
            }
        },
        methods: {
            getResults() {
                this.loaded = false;

                if(this.summary.total_number_of_workouts != undefined) {
                  this.summaryData = this.summary;
                  this.loaded = true;
                } else {
                  axios.get('reports/summary?' + $.param({filters: this.filters})).then((data) => {
                    this.summaryData = data.data.data;
                    this.loaded = true;
                  }).catch(error => {
                    this.$vs.notify({
                      text: error.response.data.message,
                      color:'danger',
                      position:'top-right',
                      time:5000,
                    });
                  });
                }
            }
        },
        created() {
            this.getResults();
        }
    }
</script>
