<template>
  <div>
    <template v-if="!this.authenticatedUserHasPermissionToSee(this.allowedPlans)">
      <upgrade-message title="To see your Power Report upgrade to the Standard Plan."></upgrade-message>
    </template>
    <template v-else>
      <vx-card ref="filterCard" title="Filters" class="user-list-filters mb-8" collapse-action refresh-content-action
               @refresh="resetFilter">
        <form @submit.prevent="filterFormSubmit">
          <div class="vx-row" v-if="this.getAuthenticatedUserResultTypes().length > 1">
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
              <label class="text-sm opacity-75">Machine Type</label>
              <v-select :options="this.getAuthenticatedUserResultTypes()" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="filterForm.type" placeholder="All"
                        class="md:mb-0"/>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
              <label class="text-sm opacity-75">Start Date 1</label>
              <div class="vs-con-input">
                <datetime v-model="filterForm.date_from1"
                          :class="{'is-invalid': formControlHasError(filterForm, 'date_from1')}"
                          input-class="vs-inputx vs-input--input normal" zone="local" value-zone="local"
                          class="theme-green w-full" title="Hint: Year and Month are also clickable"></datetime>
              </div>
            </div>
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
              <label class="text-sm opacity-75">End Date 1</label>
              <datetime v-model="filterForm.date_to1" :class="{'is-invalid': formControlHasError(filterForm, 'date_to1')}"
                        input-class="vs-inputx vs-input--input normal" zone="local" value-zone="local"
                        class="theme-green w-full" title="Hint: Year and Month are also clickable"></datetime>
            </div>
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
              <label class="text-sm opacity-75">Start Date 2</label>
              <div class="vs-con-input">
                <datetime v-model="filterForm.date_from2"
                          :class="{'is-invalid': formControlHasError(filterForm, 'date_from2')}"
                          input-class="vs-inputx vs-input--input normal" zone="local" value-zone="local"
                          class="theme-green w-full" title="Hint: Year and Month are also clickable"></datetime>
              </div>
            </div>
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
              <label class="text-sm opacity-75">End Date 2</label>
              <datetime v-model="filterForm.date_to2" :class="{'is-invalid': formControlHasError(filterForm, 'date_to2')}"
                        input-class="vs-inputx vs-input--input normal" zone="local" value-zone="local"
                        class="theme-green w-full" title="Hint: Year and Month are also clickable"></datetime>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full">
              <label class="text-sm opacity-75">Test</label>
              <ul class="demo-alignment mt-0">
                <li class="mt-0 mb-2" v-for="test in test_names">
                  <vs-checkbox v-model="filterForm.test_names" :vs-value="test">{{ test }}</vs-checkbox>
                </li>
              </ul>
            </div>
          </div>

        </form>
      </vx-card>

      <template v-if="filterForm.type == ''">
        <vs-alert active="true">
          Please select a type
        </vs-alert>
      </template>
      <template v-else>
        <vx-card class="mb-base" title="Watts" v-if="allLoaded">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
              <benchmark-chart-by-distance :items="mixedResults"></benchmark-chart-by-distance>
            </div>
            <div class="vx-col md:w-1/2 w-full">
              <benchmark-chart-by-time :items="mixedResults" />
            </div>
          </div>
        </vx-card>
        <benchmark-table v-if="allLoaded" :items="mixedResults" />
      </template>
    </template>
  </div>
</template>

<script>
  import axios from 'axios'
  import Form from 'vform'
  import FormErrors from '@/components/mixins/FormErrors'
  import moment from 'moment'
  import {mapGetters} from "vuex";
  import UpgradeMessage from "../../../components/global/UpgradeMessage";
  import $ from 'jquery'
  import {clone} from 'lodash';
  import {TEST_NAMES, TEST_NAMES_TYPE} from "../../../shared/constants";
  import vSelect from 'vue-select'
  import BenchmarkTable from "@/components/reports/BenchmarkTable";
  import BenchmarkChartByDistance from "@/components/reports/BenchmarkChartByDistance";
  import BenchmarkChartByTime from "@/components/reports/BenchmarkChartByTime";

  export default {
    head: {
      title: {
        inner: 'Power Report'
      }
    },

    components: {
      BenchmarkChartByTime,
      BenchmarkChartByDistance,
      BenchmarkTable,
      UpgradeMessage,
      vSelect
    },
    computed: {
      ...mapGetters({
        authenticatedUser: 'auth/getAuthenticatedUser',
        authenticatedUserHasPermissionToSee: 'auth/getAuthenticatedUserHasPermissionToSee',
        getAuthenticatedUserResultTypes: 'auth/getAuthenticatedUserResultTypes',
      }),
      mixedResults() {
        let type = this.filterForm.type != '' ? this.filterForm.type.value : '';
        if(type != '' && (this.resultsPB != null || this.resultsLine1 != null || this.resultsLine2 != null))
        {
          let typeName = TEST_NAMES_TYPE[type];
          let mixedResults = [];
          _.each(this.filterForm.test_names, item => {
            let row = {
              'name' : item,
              'line_1': _.find(this.resultsLine1, { name: item + ' ' + typeName }),
              'line_2': _.find(this.resultsLine2, { name: item + ' ' + typeName }),
              'line_pb': _.find(this.resultsPB, { name: item + ' ' + typeName}),
            }
            mixedResults.push(row);
          });

          return mixedResults;
        }
        return null;
      },
      allLoaded() {
        return this.loaded && this.line1Loaded && this.line2Loaded;
      }
    },
    mixins: [FormErrors],
    data: () => ({
      filterForm: new Form({
        type: '',
        date_from1: moment().subtract(1, 'year').format('YYYY-MM-DD'),
        date_to1: moment().format('YYYY-MM-DD'),
        date_from2: '',
        date_to2: '',
        test_names: TEST_NAMES,
      }),
      loaded: false,
      line1Loaded: false,
      line2Loaded: false,
      resultsPB: null,
      resultsLine1: null,
      resultsLine2: null,
      allowedPlans: [3, 5,8,9,10,11],
      selected: [],
      test_names: TEST_NAMES,
      filterText: 'View By Distance',
      chartType: 'distance'
    }),
    watch: {
      filterForm: {
        handler(val) {
          if (this.authenticatedUserHasPermissionToSee(this.allowedPlans)) {
            this.filterFormSubmit();
          }
        },
        deep: true
      },
    },
    methods: {
      async filterFormSubmit() {
        if (this.loaded == true && !this.authenticatedUserHasPermissionToSee(this.allowedPlans)) {
          return true;
        }

        if(this.getAuthenticatedUserResultTypes().length == 1)
        {
          this.filterForm.type = this.getAuthenticatedUserResultTypes()[0];
        }

        if(this.filterForm.type == '')
        {
          return true;
        }

        this.loaded = false;

        let params = _.clone(this.filterForm);
        params.type = this.filterForm.type != '' ? this.filterForm.type.value : '';

        if(params.type == '')
        {
          return true;
        }

        //PB
        params.date_from = null;
        params.date_to = null;
        axios.get('users/me/benchmark?per_page=100&' + $.param({filters: params})).then((data) => {
          this.resultsPB = data.data.results.data;
          this.loaded = true;
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
        //END PB

        //Line 1
        if(this.filterForm.date_from1 != '' && this.filterForm.date_to1 != '')
        {
          this.line1Loaded = false;
          params.date_from = moment(this.filterForm.date_from1).format('YYYY-MM-DD');
          params.date_to = moment(this.filterForm.date_to1).format('YYYY-MM-DD');

          axios.get('users/me/benchmark?per_page=100&' + $.param({filters: params})).then((data) => {
            this.resultsLine1 = data.data.results.data;
            this.line1Loaded = true;
          }).catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
        } else {
          this.line1Loaded = true;
        }
        //END Line 1

        //Line 2
        if(this.filterForm.date_from2 != '' && this.filterForm.date_to2 != '')
        {
          this.line2Loaded = false;
          params.date_from = moment(this.filterForm.date_from2).format('YYYY-MM-DD');
          params.date_to = moment(this.filterForm.date_to2).format('YYYY-MM-DD');

          axios.get('users/me/benchmark?per_page=100&' + $.param({filters: params})).then((data) => {
            this.resultsLine2 = data.data.results.data;
            this.line2Loaded = true;
          }).catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
        } else {
          this.line2Loaded = true;
        }
        //END Line 1
      },
      resetFilter() {
        this.filterForm = new Form({
          type: '',
          date_from1: moment().subtract(1, 'year').format('YYYY-MM-DD'),
          date_to1: moment().format('YYYY-MM-DD'),
          test_names: TEST_NAMES,
        });
        this.$refs.filterCard.removeRefreshAnimation();
      },
      updateFilter(workout) {
        this.filterForm = new Form({
          type: '',
          date_from1: '',
          date_to1: moment().format('YYYY-MM-DD'),
          test_names: TEST_NAMES,
        });
      },

      updateGraph(val) {
        console.log(this.chartType);
        this.chartType = val;
        this.filterText = 'View By Distance';
        if(val == 'time')
        {
          this.filterText = 'View By Time';
        }

      }
    },
    created() {
      this.$mixpanel.track("REPORT", {"title": "Benchmark"});
      if (this.$route.params.id != undefined) {
        this.filterForm = new Form({
          type: '',
          date_from1: '',
          date_to1: moment().format('YYYY-MM-DD'),
          test_names: TEST_NAMES,
        });
      }
    }
  }
</script>

<style lang="scss">
  .vs-checkbox--input {
    width: 100%;
  }
</style>
