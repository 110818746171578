<template>
  <div id="item-detail-page" v-if="loaded">
    <portal to="title-bar-actions">
      <vs-button radius icon="icon-share-2" icon-pack="feather" class="mr-2" @click="shareModal = true" title="Share"/>
      <vs-button v-if="workoutPlan.user_id != undefined && this.authenticatedUser != undefined && workoutPlan.user_id == this.authenticatedUser.id"  radius icon="icon-edit" color="success" icon-pack="feather" class="mr-2" title="Edit" :to="{ name: 'workout-plans.edit', params: { id: workoutPlan.id }}"/>
    </portal>
    <vx-card class="mb-base">
      <div class="vx-row mt-6">
        <div class="vx-col md:w-2/5 w-full flex items-center justify-center">
          <div class="product-img-container w-3/5 mx-auto mb-10 md:mb-0">
            <img :src="workoutPlan.image != null ? workoutPlan.image_url_256 : 'https://api.ergmonkey.com/img/placeholders/workout_plans_image-256.jpg'" :alt="workoutPlan.name" class="responsive">
          </div>
        </div>

        <!-- Item Content -->
        <div class="vx-col md:w-3/5 w-full">

          <h3>
            {{ workoutPlan.name }}
          </h3>

          <p class="my-2">
            <span class="mr-2">by</span>
            <span>{{ workoutPlan.public_user.first_name + ' ' +  workoutPlan.public_user.last_name}}</span>
          </p>
          <p class="flex items-center flex-wrap">
            <span class="text-2xl leading-none font-medium text-primary mr-4 mt-2">${{ workoutPlan.price }}</span>
          </p>

          <vs-divider />

          <p>{{ workoutPlan.description }}</p>

          <vs-divider />

          <!-- Add to cart -->
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="flex flex-wrap items-start mb-4">

                <!-- Add To Cart Button -->
                <vs-button
                  class="mr-4 mb-4"
                  icon-pack="feather"
                  icon="icon-shopping-cart"
                  v-if="!false"
                  @click="toggleItemInCart(item_data)">
                  ADD TO CART
                </vs-button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </vx-card>

    <!-- Product Feature/Meta Row -->
    <div class="py-24 mb-16 mt-10 text-center item-features">
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <div class="w-64 mx-auto mb-16 md:mb-0">
            <feather-icon icon="AwardIcon" svgClasses="h-12 w-12 text-primary stroke-current" class="block mb-4" />
            <span class="font-semibold text-lg">100% Original</span>
            <p class="mt-2">Chocolate bar candy canes ice cream toffee cookie halvah.</p>
          </div>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <div class="w-64 mx-auto mb-16 md:mb-0">
            <feather-icon icon="ClockIcon" svgClasses="h-12 w-12 text-primary stroke-current" class="block mb-4" />
            <span class="font-semibold text-lg">10 Day Replacement</span>
            <p class="mt-2">Marshmallow biscuit donut dragée fruitcake wafer.</p>
          </div>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <div class="w-64 mx-auto">
            <feather-icon icon="ShieldIcon" svgClasses="h-12 w-12 text-primary stroke-current" class="block mb-4" />
            <span class="font-semibold text-lg">1 Year Warranty</span>
            <p class="mt-2">Cotton candy gingerbread cake I love sugar sweet.</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Related Products -->
    <div class="related-products text-center px-6" v-if="relatedWorkoutPlans">

      <div class="related-headings mb-8 text-center">
        <h2 class="uppercase">Related Workout Plans</h2>
        <p>People also search for this items</p>
      </div>
      <swiper :options="swiperOption" :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl" class="related-product-swiper px-12 py-6">
        <swiper-slide v-for="item in relatedWorkoutPlans" :key="item.id" class="p-6 rounded cursor-pointer">

          <!-- Item Heading -->
          <div class="item-heading mb-4" @click="navigate_to_detail_view(item.id)">
            <p class="text-lg font-semibold truncate">{{ item.name }}</p>
            <p class="text-sm">
              <span class="mr-2">by</span>
              <span>{{ item.public_user.first_name + ' ' +  item.public_user.last_name }}</span>
            </p>
          </div>

          <!-- Item Image -->
          <div class="img-container w-32 mx-auto my-base" @click="navigate_to_detail_view(item.id)">
            <img class="responsive" :src="item.image != null ? item.image_url_256 : 'https://api.ergmonkey.com/img/placeholders/workout_plans_image-256.jpg'" :alt="item.name">
          </div>

          <!-- Item Meta -->
          <div class="item-meta" @click="navigate_to_detail_view(item.id)">
            <p class="text-lg font-medium text-primary">${{ item.price }}</p>
          </div>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>

    <vs-popup title="Share Workout Plan" :active.sync="shareModal">
      <vs-row class="mb-2">
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="facebook" class="vs-component vs-button vs-button-null vs-button-filled btn-facebook w-full mr-2">
              <feather-icon icon="FacebookIcon" svgClasses="h-4 w-4" /> Facebook
            </network>
          </vs-col>
        </social-sharing>
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="twitter" class="vs-component vs-button vs-button-null vs-button-filled btn-twitter w-full ml-2">
              <feather-icon icon="TwitterIcon" svgClasses="h-4 w-4" /> Twitter
            </network>
          </vs-col>
        </social-sharing>
      </vs-row>
      <vs-row class="mb-2">
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="linkedin" class="vs-component vs-button vs-button-null vs-button-filled btn-linkedin w-full mr-2">
              <feather-icon icon="LinkedinIcon" svgClasses="h-4 w-4" /> LinkedIn
            </network>
          </vs-col>
        </social-sharing>
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="reddit" class="vs-component vs-button vs-button-null vs-button-filled btn-reddit w-full ml-2">
              <feather-icon icon="RedditIcon" svgClasses="h-4 w-4" /> Reddit
            </network>
          </vs-col>
        </social-sharing>
      </vs-row>
      <vs-row class="mb-2">
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="email" class="vs-component vs-button vs-button-warning vs-button-filled w-full mr-2">
              <feather-icon icon="MailIcon" svgClasses="h-4 w-4" /> Email
            </network>
          </vs-col>
        </social-sharing>
        <vs-col vs-w="6">
          <vs-button color="primary" v-clipboard:copy="shareUrl" v-clipboard:success="onCopySuccess" class="w-full ml-2">
            <feather-icon icon="CopyIcon" svgClasses="h-4 w-4" /> Copy
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import axios from 'axios'
import {mapGetters} from 'vuex'
import FormErrors from '@/components/mixins/FormErrors'
import Numbers from '@/components/mixins/Numbers'
import Form from "vform";
import moment from "moment";
import GroupWorkoutsList from "../../../components/groups/GroupWorkoutsList";
import LeaderboardList from "../../../components/groups/LeaderboardList";
import $ from 'jquery'
import GroupSummaryWidget from "../../../components/groups/GroupSummaryWidget";
import vSelect from 'vue-select'
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  components: {
    vSelect,
    swiper,
    swiperSlide,
  },
  // Set meta tags
  head: {
    title: function () {
      return {
        inner: this.title
      }
    },
  },

  mixins: [FormErrors, Numbers],

  data: () => ({
    workoutPlan: null,
    title: '',
    loaded: false,
    shareModal: false,
    shareDesription: '',
    shareQuote: '',
    shareHashtags: '',
    shareTwitterUser: 'ergmonkey_',
    relatedWorkoutPlans: null,
    swiperOption: {
      slidesPerView: 5,
      spaceBetween: 55,
      breakpoints: {
        1600: {
          slidesPerView: 4,
          spaceBetween: 55
        },
        1300: {
          slidesPerView: 3,
          spaceBetween: 55
        },
        900: {
          slidesPerView: 2,
          spaceBetween: 55
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 55
        }
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    },
  }),
  computed: {
    shareUrl: function () {
      return 'https://stats.ergmonkey.com/workout-plans/plan/' + this.$route.params.id;
    },
    shareTitle: function () {
      return this.item != null ? this.item.name : '';
    },
    ...mapGetters({
      authenticatedUser: 'auth/getAuthenticatedUser',
    })
  },
  watch: {

  },
  methods: {
    getWorkoutPlan(workoutPlanId) {
      this.loaded = false;

      axios.get('workout-plans/' + workoutPlanId+ '?include=public_user').then((data) => {
        this.workoutPlan = data.data.workout_plan;
        this.title = this.workoutPlan.name;
        this.page_title = this.workoutPlan.name;
        this.$emit('changeRouteTitle', this.page_title);
        this.loaded = true;
        this.getRelatedWorkoutPlans();
      }).catch(error => {
        this.$vs.notify({
          text: error.response.data.message,
          color:'danger',
          position:'top-right',
          time:5000,
        });
      });
    },

    getRelatedWorkoutPlans()
    {
      let params = new Form({
          search_name: '',
        });

      axios.get('workout-plans?per_page=1000&order_by=name&order_direction=asc&include=public_user&'+ $.param({filters: params})).then((data) => {
        debugger;
        this.relatedWorkoutPlans = data.data.workout_plans.data;
      }).catch(error => {
        this.$vs.notify({
          text: error.response.data.message,
          color:'danger',
          position:'top-right',
          time:5000,
        });
      });
    },

    navigate_to_detail_view(planId) {
      debugger;
      this.$router.push({name: 'public-workout-plans.show', params: {id: planId}})
        .catch(() => {
        })
    },

    onCopySuccess() {
      this.shareModal = false;
      this.$vs.notify({
        text: "Copied!",
        color:'success',
        position:'top-right',
        time:5000,
      });
    },
  },
  created() {
    this.getWorkoutPlan(this.$route.params.id);
  },
  beforeRouteUpdate(to, from, next) {
    this.getWorkoutPlan(to.params.id);
    next();
  },
}
</script>

<style lang="scss">

@import "@/assets/scss/vuexy/_variables.scss";

#item-detail-page {
  .color-radio {
    height: 2.28rem;
    width: 2.28rem;

    > div {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .item-features {
    background-color: #f7f7f7;

    .theme-dark & {
      background-color: $theme-dark-secondary-bg;
    }
  }

  .product-sales-meta-list {
    .vs-list--icon {
      padding-left: 0;
    }
  }

  .related-product-swiper {
    // padding-right: 2rem;
    // padding-left: 2rem;

    .swiper-wrapper {
      padding-bottom: 2rem;

      > .swiper-slide {
        background-color: #f7f7f7;
        box-shadow: 0 4px 18px 0 rgba(0,0,0,0.1), 0 5px 12px 0 rgba(0,0,0,0.08) !important;

        .theme-dark & {
          background-color: $theme-light-dark-bg;
        }
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      transform: scale(.5);
      filter: hue-rotate(40deg);
    }

    .swiper-button-next {
      right: 0
    }

    .swiper-button-prev {
      left: 0;
    }
  }
}
</style>
