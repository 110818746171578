import Vue from 'vue'

import moment from 'moment'
import {formatDate} from "../shared/utils";
import pluralize from 'pluralize'

Vue.filter('title', function (value, replacer = '_') {
  if (!value) return ''
  value = value.toString()

  const arr = value.split(replacer)
  const capitalized_array = []
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(' ')
})

Vue.filter('capitalize', function (value) {
  if (!value) {
    return ''
  }
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1)
});

Vue.filter('uppercase', function (value) {
  if (!value) {
    return ''
  }
  value = value.toString();
  return value.toUpperCase()
});

Vue.filter('truncate', function (text, length, clamp) {
  text = text || '';
  clamp = clamp || '...';
  length = length || 30;

  if (text.length <= length) return text;

  text = text + '';
  let tcText = text.slice(0, length - clamp.length);
  let last = tcText.length - 1;

  while (last > 0 && tcText[last] !== ' ' && tcText[last] !== clamp[0]) last -= 1;

  last = last || length - clamp.length;
  tcText = tcText.slice(0, last);

  tcText = tcText + clamp;

  return tcText;
});

Vue.filter('generateRandomString', function (length) {
  let len = length || 10, rand = "", possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  for (let i = 0; i < len; i = i + 1) {
    rand += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return rand;
});

Vue.filter('formatNumber', function (value, decimals) {
  value = parseFloat(value);
  if (!isNaN(value)) {
    return value.toFixed(decimals).replace(/\d(?=(\d{3})+(?!\d))/g, '$&,');
  } else {
    value = 0.00000000;
    return value.toFixed(decimals).replace(/\d(?=(\d{3})+(?!\d))/g, '$&,');
  }
});

Vue.filter('formatNumberToOrdinal', function (value) {
  let i = parseInt(value), j = i % 10, k = i % 100;

  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }

  return i + "th";
});

Vue.filter('formatCurrency', function (value, decimals) {
  value = parseFloat(value);
  if (!isNaN(value)) {
    return '$' + value.toFixed(decimals).replace(/\d(?=(\d{3})+(?!\d))/g, '$&,');
  } else {
    value = 0.00000000;
    return '$' + value.toFixed(decimals).replace(/\d(?=(\d{3})+(?!\d))/g, '$&,');
  }
});

Vue.filter('formatDate', function (value, format = 'DD/MMM/YYYY') {
  if (value) {
    try {
      return moment(String(value)).format(format);
    } catch (e) {
      return '';
    }
  } else {
    return '';
  }
});

Vue.filter('formatDateTime', function (value) {
  if (value) {
    try {
      return moment(String(value)).format('MM/DD/YYYY HH:mm');
    } catch (e) {
      return '';
    }
  } else {
    return '';
  }
});

Vue.filter('timeInHours', function(value) {
  if(value == 0)
  {
    return '';
  }
  let hours =  parseInt(Math.floor(value / 3600));
  let minutes = parseInt(Math.floor((value - (hours * 3600)) / 60));
  let seconds= parseInt((value - ((hours * 3600) + (minutes * 60))) % 60);

  let dHours = (hours > 9 ? hours : '0' + hours);
  let dMins = (minutes > 9 ? minutes : '0' + minutes);
  let dSecs = (seconds > 9 ? seconds : '0' + seconds);

  return dHours + ":" + dMins + ":" + dSecs;
});

Vue.filter('timeAsPace', function(value) {
  let hours =  parseInt(Math.floor(value / 3600));
  let minutes = parseInt(Math.floor((value - (hours * 3600)) / 60));
  let seconds= parseInt((value - ((hours * 3600) + (minutes * 60))) % 60);
  let miliSeconds= parseInt(Math.floor((value*1000) % 1000)/100);

  let dHours = (hours > 9 ? hours : '0' + hours);
  let dMins = (minutes > 9 ? minutes : '0' + minutes);
  let dSecs = (seconds > 9 ? seconds : '0' + seconds);
  let dMS = miliSeconds;

  if(hours > 0) {
    return dHours + ":" + dMins + ":" + dSecs + "." + dMS;
  } else {
    return dMins + ":" + dSecs + "." + dMS;
  }
});

Vue.filter('timeShort', function(value) {
  if(value == 0 || isNaN(value))
  {
    return 0;
  }

  let hours =  parseInt(Math.floor(value / 3600));
  let minutes = parseInt(Math.floor((value - (hours * 3600)) / 60));
  let seconds= parseInt((value - ((hours * 3600) + (minutes * 60))) % 60);

  let dHours = (hours > 9 ? hours : '0' + hours);
  let dMins = (minutes > 9 ? minutes : '0' + minutes);
  let dSecs = (seconds > 9 ? seconds : '0' + seconds);

  let output = "";
  if(hours > 0) {
    output = output + dHours + ":";
  }
  return output + dMins + ":" + dSecs;
});

Vue.filter('calculatePaceFromTime', function (value, distance) {
  let pace = '';

  if(distance > 0)
  {
    pace = ((500 * value) / distance).toFixed(2);
  }

  return pace;
});

Vue.filter('calculateCaloriesPerHour', function (value, time) {
  let caloriesPerH = 0;

  if(value > 0 && time > 0)
  {
    caloriesPerH = (value * 3600 / time).toFixed(0);
  }

  return caloriesPerH;
});


Vue.filter('paceToWatts', function (value) {
  let watts = '';

  if(value > 0)
  {
    watts = (28/Math.pow(value/500, 3)/10).toFixed(0);
  }

  return watts;
});

Vue.filter('formatPercentage', function (value) {
  if (value) {
    if (parseFloat(value) < 10) {
      return parseFloat(value).toFixed(1);
    } else if (parseFloat(value) < 99) {
      return Math.round(parseFloat(value)).toFixed(0);
    } else if (parseFloat(value) < 100) {
      return parseFloat(value).toFixed(1);
    } else {
      return Math.round(parseFloat(value)).toFixed(0).replace(/\d(?=(\d{3})+(?!\d))/g, '$&,');
    }
  } else {
    return 0;
  }
});

Vue.filter('formatTypeOfSession', function(value) {
  let type = "Undefined";

  switch(value) {
    case 1:
      type = "Rower";
      break;
    case 2:
      type = "SkiErg";
      break;
    case 3:
      type = "Bike";
      break;
    case 4:
      type = "Dynamic";
      break;
    case 5:
      type = "Slides";
      break;
    case 6:
      type = "Paddle";
      break;
    case 7:
      type = "Water";
      break;
    case 8:
      type = "Snow";
      break;
    case 9:
      type = "Rollerski";
      break;
    case 10:
      type = "MultiErg";
      break;
    default:
    // code block
  }
  return type;
});

Vue.filter('formatWeight', function(value) {
  let type = "Undefined";

  switch(value) {
    case 1:
      type = "Hwt";
      break;
    case 2:
      type = "Lwt";
      break;
    default:
    // code block
  }
  return type;
});

Vue.filter('formatWorkoutType', function(value) {
  let type = "Undefined";

  switch(value) {
    case 1:
      type = "Unknown";
      break;
    case 2:
      type = "Just Row";
      break;
    case 3:
      type = "Fixed Distance Splits";
      break;
    case 4:
      type = "Fixed Time Splits";
      break;
    case 5:
      type = "Fixed Calorie";
      break;
    case 6:
      type = "Fixed Time Interval";
      break;
    case 7:
      type = "Fixed Distance Interval";
      break;
    case 8:
      type = "Fixed Calorie Interval";
      break;
    case 9:
      type = "Fixed Variable Interval";
      break;
    case 10:
      type = "Fixed Variable Interval / Undefined Rest";
      break;
    default:
    // code block
  }
  return type;
});

Vue.filter('formatSummaryResposePeriod', function(data) {
  if(data.week != undefined)
  {
    return data.year + '/Week ' + data.week;
  }
  else if(data.month != undefined)
  {
    return data.year + '/' + moment.months(data.month - 1);
  } else if(data.date != undefined)
  {
    return formatDate(data.date);
  } else if(data.day_of_week != undefined)
  {
    return moment.weekdays(data.day_of_week);
  } else {
    return data.year;
  }
});

Vue.filter('pluralize', function (value, number) {
  return pluralize(value, number)
});

Vue.filter('formatGroupUserStatus', function(value) {
  let type = "Pending";

  switch(value) {
    case 1:
      type = "Approved";
      break;
    case 2:
      type = "Waiting For Approval";
      break;
    case 3:
      type = "Rejected";
      break;
    case 4:
      type = "Left";
      break;
    default:
    // code block
  }
  return type;
});

Vue.filter('formatGroupUserRole', function(value) {
  let type = "User";

  switch(value) {
    case 1:
      type = "Administrator";
      break;
    default:
    // code block
  }
  return type;
});

Vue.filter('formatWorkoutInspirationDuration', function(value) {
  let duration = "Unknown";

  switch(value) {
    case 1:
      duration = "Short";
      break;
    case 2:
      duration = "Medium";
      break;
    case 3:
      duration = "Long";
      break;
    default:
    // code block
  }
  return duration;
});

Vue.filter('formatDayOfWeek', function (value) {
  if (value) {
    try {
      return moment.weekdays(value);
    } catch (e) {
      return '';
    }
  } else {
    return '';
  }
});

Vue.filter('formatIntToHours', function (value) {
  if(value < 10)
  {
    return '0'+value+':00';
  } else {
    return value+':00';
  }
});

Vue.filter('formatSportName', function(value) {
  let sport = "Undefined";

  switch(value) {
    case 1:
      sport = "Row";
      break;
    case 2:
      sport = "Bike";
      break;
    case 3:
      sport = "Ski";
      break;
    case 4:
      sport = "Gym";
      break;
    case 5:
      sport = "Run";
      break;
    case 6:
      sport = "Swim";
      break;
    case 255:
      sport = "Other";
      break;
    default:
    // code block
  }
  return sport;
});
