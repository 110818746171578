<template>
  <vx-card title="Heart Rate Distribution">
    <template v-if="result.time_spent_in_heart_rate_zones != null && result.time_spent_in_heart_rate_zones != ''">
      <highcharts :options="chartOptions" style="height: 250px;"></highcharts>
      <div class="text-center">
        <router-link :to="{ name: 'reports.heart_rate', params: { id: this.result.id }}"
                     title="View more info">View in Heart Rate Report
        </router-link>
      </div>
    </template>
    <template v-else>
      There is no heart data
    </template>
  </vx-card>
</template>
<script>
  import axios from 'axios'
  import Highcharts from 'highcharts'
  import $ from 'jquery'
  import moment from 'moment'
  import {formatTime, formatTimeAsPace} from '@/shared/utils'
  import {mapGetters} from "vuex";
  import UpgradeMessageOnly from "../global/UpgradeMessageOnly";
  import {formatDate} from "../../shared/utils";

  export default {
    name: 'public-single-workout-hr-pie-chart',

    components: {
      UpgradeMessageOnly
    },
    props: {
      result: {
        type: [Array, Object],
        default: () => []
      }
    },
    computed: mapGetters({
      authenticatedUserHasPermissionToSee: 'auth/getAuthenticatedUserHasPermissionToSee'
    }),
    data() {
      return {
        loaded: false,
        items: [],
        chartOptions: {
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            backgroundColor: 'rgba(0,0,0,0)',
          },
          title: false,
          tooltip: {
            formatter: function () {
              let s = '<b>' + this.point.name + '</b><br>';
              s += this.point.percentage.toFixed(1) + '%';
              if (this.point.y > 10) {
                s += ' (' + formatTime(this.point.y / 10) + ')'
              } else {
                s += ' (< 1 sec)'
              }
              return s;
            },
            useHTML: true
          },
          accessibility: {
            point: {
              valueSuffix: '%'
            }
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
              }
            }
          },
          series: [],
          credits: {
            enabled: false
          }
        }
      }
    },

    methods: {},
    mounted() {
      if (this.result.time_spent_in_heart_rate_zones != null && this.result.time_spent_in_heart_rate_zones != "") {
        this.chartOptions.series.push({
          name: 'Zones',
          colorByPoint: true,
          data: [{
            name: 'Not In Zones',
            y: this.result.time_spent_in_heart_rate_zones.not_in_zones,
            color: '#c3c6c7'
          }, {
            name: 'Zone 1',
            y: this.result.time_spent_in_heart_rate_zones.zone_1,
            selected: true,
            color: '#6d7278'
          }, {
            name: 'Zone 2',
            y: this.result.time_spent_in_heart_rate_zones.zone_2,
            color: '#0b81b3'
          }, {
            name: 'Zone 3',
            y: this.result.time_spent_in_heart_rate_zones.zone_3,
            color: "#3e9449"
          }, {
            name: 'Zone 4',
            y: this.result.time_spent_in_heart_rate_zones.zone_4,
            color: '#e96126'
          }, {
            name: 'Zone 5',
            y: this.result.time_spent_in_heart_rate_zones.zone_5,
            color: '#d72433'
          }]
        });
      }
    }
  };
</script>
