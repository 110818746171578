<template>
  <vx-card>
    <template v-if="items.length == 0">
      Please select workouts to see the graph
    </template>
    <template v-else>
      <div v-if="intervals.length > 0" class="vx-col w-full">
        <v-select :options="intervals" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="selectedInterval"
                  class="md:mb-0"/>
      </div>
      <highcharts v-for="options in optionsList" :options="options" ref="highcharts" class="chart"
                  style="height: 250px;"></highcharts>
    </template>
  </vx-card>
</template>
<script>
  import Highcharts from 'highcharts'
  import stockInit from 'highcharts/modules/stock'
  import {formatTime, formatTimeAsPace} from "../../shared/utils";
  import {each} from "lodash";
  import vSelect from 'vue-select'

  stockInit(Highcharts);

  function sync(vm, event, type) {
    vm.$refs.highcharts.forEach(({chart}) => {
      if (chart === this.series.chart) return;
      chart.series.forEach((series) => {
        series.data.forEach((point) => {
          if (point.x === this.x) {
            if (type === 'over') {
              point.setState('hover');
              chart.tooltip.refresh(point);
              chart.xAxis[0].drawCrosshair(event, point);
            } else {
              point.setState();
              chart.xAxis[0].hideCrosshair();
            }
          }
        });
      });
    });
  }

  function syncZoom(vm, event) {
    vm.$refs.highcharts.forEach(({chart}) => {
      var xMin = event.min;
      var xMax = event.max;
      chart.xAxis[0].setExtremes(xMin, xMax, true);
    });
  }

  function syncLegend(vm, event) {
    vm.$refs.highcharts.forEach(({chart}) => {
      if (chart === event.target.chart) return;
      chart.series.forEach((series) => {
        if (event.target.name === series.name) {
          if (event.target.visible) {
            series.hide();
          } else {
            series.show();
          }
        }
      });
    });
  }

  function genOptions(vm, series) {
    if (series[0].title == 'Pace') {
      return {
        chart: {
          marginLeft: 40, // Keep all charts left aligned
          spacingTop: 20,
          spacingBottom: 20,
          zoomType: 'xy',
          backgroundColor: 'rgba(0,0,0,0)',
        },
        title: {
          text: series[0].title,
          align: 'left',
          margin: 0,
          x: 30
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        rangeSelector: {
          enabled: false
        },
        xAxis: {
          crosshair: {
            label: {
              enabled: true,
              formatter: function () {
                return formatTime(this.value);
              }
            }
          },
          labels: {
            formatter: function () {
              return formatTime(this.value);
            }
          },
          events: {
            afterSetExtremes: function (event) {
              syncZoom.call(this, vm, event);
            }
          }
        },
        yAxis: {
          title: {
            text: null
          },
          opposite: false,
          reversed: true,
          labels: {
            formatter: function () {
              return formatTime(this.value);
            }
          }
        },
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver: function (event) {
                  sync.call(this, vm, event, 'over');
                },
                mouseOut: function (event) {
                  sync.call(this, vm, event, 'out');
                },
              }
            },
            events: {
              legendItemClick: function (event) {
                syncLegend.call(this, vm, event);
              }
            }
          }
        },
        series: series,
        tooltip: {
          formatter: function () {
            let s = '<b>' + formatTime(this.x) + '</b>';
            s += '<br/>' + '<span style="color:' + this.point.color + '">\u25CF</span> <b>' + this.series.name + ':</b> ' + formatTimeAsPace(this.y);
            return s;
          },
          useHTML: true
        },
      };
    }
    if (series[0].title == 'Stroke Rate') {
      return {
        chart: {
          marginLeft: 40, // Keep all charts left aligned
          spacingTop: 20,
          spacingBottom: 20,
          zoomType: 'xy',
          backgroundColor: 'rgba(0,0,0,0)',
        },
        title: {
          text: series[0].title,
          align: 'left',
          margin: 0,
          x: 30
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        rangeSelector: {
          enabled: false
        },
        xAxis: {
          crosshair: {
            label: {
              enabled: true,
              formatter: function () {
                return formatTime(this.value);
              }
            }
          },
          labels: {
            formatter: function () {
              return formatTime(this.value);
            }
          },
          events: {
            afterSetExtremes: function (event) {
              syncZoom.call(this, vm, event);
            }
          }
        },
        yAxis: {
          title: {
            text: null
          },
          opposite: false,
        },
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver: function (event) {
                  sync.call(this, vm, event, 'over');
                },
                mouseOut: function (event) {
                  sync.call(this, vm, event, 'out');
                },
              }
            },
            events: {
              legendItemClick: function (event) {
                syncLegend.call(this, vm, event);
              }
            }
          }
        },
        series: series,
        tooltip: {
          formatter: function () {
            let s = '<b>' + formatTime(this.x) + '</b>';
            s += '<br/>' + '<span style="color:' + this.point.color + '">\u25CF</span> <b>' + this.series.name + ':</b> ' + this.y;
            return s;
          },
          useHTML: true
        },
      };
    }
    if (series[0].title == 'Heart Rate') {
      return {
        chart: {
          marginLeft: 40, // Keep all charts left aligned
          spacingTop: 20,
          spacingBottom: 20,
          zoomType: 'xy',
          backgroundColor: 'rgba(0,0,0,0)',
        },
        title: {
          text: series[0].title,
          align: 'left',
          margin: 0,
          x: 30
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        rangeSelector: {
          enabled: false
        },
        navigator: {
          enabled: true,
          xAxis: {
            labels: {
              formatter: function () {
                return formatTime(this.value);
              }
            }
          }
        },
        xAxis: {
          crosshair: {
            label: {
              enabled: true,
              formatter: function () {
                return formatTime(this.value);
              }
            }
          },
          labels: {
            formatter: function () {
              return formatTime(this.value);
            }
          },
          events: {
            afterSetExtremes: function (event) {
              syncZoom.call(this, vm, event);
            }
          }
        },
        yAxis: {
          title: {
            text: null
          },
          opposite: false,
        },
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver: function (event) {
                  sync.call(this, vm, event, 'over');
                },
                mouseOut: function (event) {
                  sync.call(this, vm, event, 'out');
                },
              }
            },
            events: {
              legendItemClick: function (event) {
                syncLegend.call(this, vm, event);
              }
            }
          }
        },
        legend: {
          floating: false,
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom',
          x: 0,
          y: 0
        },
        series: series,
        tooltip: {
          formatter: function () {
            let s = '<b>' + formatTime(this.x) + '</b>';
            s += '<br/>' + '<span style="color:' + this.point.color + '">\u25CF</span> <b>' + this.series.name + ':</b> ' + this.y;
            return s;
          },
          useHTML: true
        },
      };
    }
  }

  export default {
    name: 'synchronized-compare-chart',

    components: {
      vSelect
    },
    props: {
      items: {
        type: [Array, Object],
        default: () => []
      }
    },
    data() {
      return {
        loaded: false,
        optionsList: [],
        vm: null,
        intervals: [],
        selectedInterval: null
      }
    },
    watch: {
      items: {
        handler(items) {
          this.refreshGraphs(items);
        }
      },
      selectedInterval: {
        handler(val) {
          this.refreshGraphs(this.items);
        }
      }
    },
    computed: {},
    methods: {
      refreshGraphs(items) {
        if (items.length > 0) {
          var series = [];
          var paceSeries = [];
          var strokeRateSeries = [];
          var heartRateSeries = [];

          let title = '';
          let row_number = 1;

          if (items[0].details != undefined && items[0].details.workout.intervals != undefined) {
            let intervalItems = this.items[0].details.workout.intervals;
            title = 'Interval';
            this.intervals = [];

            each(intervalItems, intervalItem => {
              this.intervals.push({
                label: title + ' ' + row_number,
                value: row_number
              });

              row_number++;
            });

            if (this.selectedInterval == null) {
              this.selectedInterval = { label: 'Interval 1', value: 1 };
            }
          }

          _.each(this.items, item => {
            var pace = [];
            var strokeRate = [];
            var heartRate = [];

            var strokeData = [];
            if (item.details != undefined && item.details.workout.splits != undefined && item.details.workout.splits.length > 0) {
              strokeData = item.details.stroke_data.data;
            } else {
              var intervalItems = item.details.workout.intervals;
              var title = 'Interval';
              let row_number = 1;
              let previous_timestamp = 0;
              var i = 0;

              each(intervalItems, intervalItem => {
                intervalItem.stroke_data = [];
                intervalItem.title = title + ' ' + row_number;

                if (this.selectedInterval == null) {
                  this.selectedInterval = { label: 'Interval 1', value: 1 };
                }

                if (item.details.stroke_data.data != undefined) {
                  for (i; i < item.details.stroke_data.data.length; i++) {
                    if (item.details.stroke_data.data[i].t >= previous_timestamp) {
                      if ((intervalItem.type == 'distance' && (item.details.stroke_data.data[i].d / 10 <= intervalItem.distance)) || (intervalItem.type == 'time' && (item.details.stroke_data.data[i].t / 10 <= intervalItem.time))) {
                        intervalItem.stroke_data.push(item.details.stroke_data.data[i]);
                      }
                      previous_timestamp = item.details.stroke_data.data[i].t;
                    } else {
                      previous_timestamp = 0;
                      break;
                    }

                  }
                }

                if (row_number == this.selectedInterval.value) {
                  strokeData = intervalItem.stroke_data;
                }

                intervalItem.row_number = row_number++;
              });
            }

            _.each(strokeData, split => {
              pace.push([split.t / 10, split.p != 0 ? split.p / 10 : null]);
              strokeRate.push([split.t / 10, split.spm != 0 ? split.spm : null]);
              heartRate.push([split.t / 10, split.hr != 0 ? split.hr : null]);
            });

            paceSeries.push({
              title: 'Pace',
              name: item.date,
              data: pace,
              type: 'spline',
            });

            strokeRateSeries.push({
              title: 'Stroke Rate',
              name: item.date,
              data: strokeRate,
              type: 'spline',
            });

            heartRateSeries.push({
              title: 'Heart Rate',
              name: item.date,
              data: heartRate,
              type: 'spline',
            });
          });

          this.vm = this;
          series.push(paceSeries);
          series.push(strokeRateSeries);
          series.push(heartRateSeries);

          this.optionsList = series.map((sigleSeries, i) => {
            this.vm = this;
            return genOptions(this.vm, sigleSeries);
          })
        }
      }
    },
  };
</script>
