/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)


// axios
import axios from './axios.js'
Vue.prototype.$http = axios

// Filters
import './filters/filters.js'


// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'


// Vuex Store
import store from './store'

// Import Plugins
import './plugins'
import './plugins/intercom';

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('./assets/css/iconfont.css')

import Datetime from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

import HighchartsVue from 'highcharts-vue'

Vue.use(HighchartsVue);

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

var SocialSharing = require('vue-social-sharing');
Vue.use(SocialSharing);

import VueClipboard from 'vue-clipboard2'

// import VueHeap from "vue-heap";
// VueHeap.createHeap('663026641')

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard);


import VueMixpanel from 'vue-mixpanel'
Vue.use(VueMixpanel, {
  token: process.env.VUE_APP_MIXPANEL_API_KEY,
  config: {
    api_host: "https://api-eu.mixpanel.com"
  }
})

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.use(Datetime);
