import store from '@/store'

export default (to, from, next) => {
    if (store.getters['auth/getIsUserAuthenticated']) {
        if (store.getters['auth/getIsUserAuthenticatedAndActiveAndAdmin']) {
            next();
        } else {
            next({name: 'page-error-404'});
        }
    } else {
        next({name: 'web.auth.login'});
    }
}
