<template>
  <span>
    {{ params.value | formatGroupUserRole }}
  </span>
</template>

<script>
export default {
  name: 'CellRendererGroupUserRole',
  computed: {
  }
}
</script>
