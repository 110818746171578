<template>
  <div>
    <template v-if="items.length">
      <ag-grid-vue class="ag-theme-material w-100 ag-grid-table"
                   :columnDefs="columnDef"
                   :defaultColDef="defaultColDef"
                   :rowData="items"
                   @first-data-rendered="onFirstDataRendered">
      </ag-grid-vue>
    </template>
    <template v-else>
      There are no workouts
    </template>
  </div>
</template>

<script>
  import { AgGridVue } from 'ag-grid-vue'
  import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

  import CellRendererWorkoutLink from '../global/cell-renderer/CellRendererWorkoutLink.vue'
  import CellRendererDate from '../global/cell-renderer/CellRendererDate.vue'
  import CellRendererTime from '../global/cell-renderer/CellRendererTime.vue'
  import CellRendererPace from '../global/cell-renderer/CellRendererPace.vue'
  import CellRendererType from '../global/cell-renderer/CellRendererType.vue'
  import CellRendererWorkoutType from '../global/cell-renderer/CellRendererWorkoutType.vue'
  import CellRendererWorkoutActions from '../global/cell-renderer/CellRendererWorkoutActions.vue'
  import CellRendererDistance from '../global/cell-renderer/CellRendererDistance.vue'
  import CellRendererEtsScore from '../global/cell-renderer/CellRendererEtsScore.vue'
  import CellRendererFooterEtsScore from '../global/cell-renderer/CellRendererFooterEtsScore.vue'

  export default {
    name: 'ag-session-list',

    components: {
      AgGridVue,
      CellRendererWorkoutLink,
      CellRendererDate,
      CellRendererTime,
      CellRendererPace,
      CellRendererType,
      CellRendererWorkoutType,
      CellRendererWorkoutActions,
      CellRendererDistance,
      CellRendererEtsScore,
      CellRendererFooterEtsScore
    },
    props: {
      items: {
        type: [Array, Object],
        default: () => []
      },
      footClone: {
        type: Boolean,
        default: false
      },
      defaultColumns: {
        type: [Array, Object],
        default: () => []
      }
    },
    data: () => {
      return {
        defaultColDef: { resizable: true }
      }
    },
    computed: {
      avgPace() {
        let average = _.meanBy(this.items, 'pace');
        return average;
      },
      avgDistance() {
        let average = _.meanBy(this.items, 'distance');
        return average;
      },
      avgTime() {
        let average = _.meanBy(this.items, 'time');
        return average;
      },
      totalDistance() {
        return _(this.items)
          .groupBy('name')
          .map((objs, key) => {
            return {
              'name': key,
              'distance': _.sumBy(objs, 'distance')
            }
          })
          .value()[0].distance;
      },
      totalTime() {
        return _(this.items)
          .groupBy('name')
          .map((objs, key) => {
            return {
              'name': key,
              'time': _.sumBy(objs, 'time')
            }
          })
          .value()[0].time;
      },
      columnDef() {
        if(this.$store.state.windowWidth < 992) {
          return [
            {field: 'date', headerName: 'Date', sortable: true, cellRendererFramework: 'CellRendererDate', minWidth: 100, maxWidth: 100},
            {field: 'name', headerName: 'Name', sortable: true, cellRendererFramework: 'CellRendererWorkoutLink'},
            {field: 'actions', headerName: '', sortable: false, cellRendererFramework: 'CellRendererWorkoutActions', minWidth: 90, maxWidth:90},
          ]
        }
        if(this.defaultColumns.length > 0)
        {
          let columns = [];
          _.each(this.defaultColumns, column => {
            if (column == 'date') {
              columns.push({field: 'date', headerName: 'Date', sortable: true, cellRendererFramework: 'CellRendererDate'});
            }
            if (column == 'name') {
              columns.push({field: 'name', headerName: 'Name', sortable: true, cellRendererFramework: 'CellRendererWorkoutLink', suppressSizeToFit: true});
            }
            if (column == 'distance') {
              columns.push({field: 'distance', sortable: true, cellRendererFramework: 'CellRendererDistance'});
            }
            if (column == 'time') {
              columns.push({field: 'time', sortable: true, cellRendererFramework: 'CellRendererTime'});
            }
            if (column == 'pace') {
              columns.push({field: 'pace', sortable: true, cellRendererFramework: 'CellRendererPace'});
            }
            if (column == 'type') {
              columns.push({field: 'type', sortable: true, cellRendererFramework: 'CellRendererType'});
            }
            if (column == 'workout_type') {
              columns.push({field: 'workout_type', headerName: 'Workout Type', sortable: true, cellRendererFramework: 'CellRendererWorkoutType'});
            }
            if (column == 'comments') {
              columns.push({field: 'comments', sortable: true});
            }
            if (column == 'stroke_rate') {
              columns.push({field: 'stroke_rate', sortable: true, headerName: 'S/m'});
            }
            if (column == 'power') {
              columns.push({field: 'power', sortable: true, headerName: 'Watts'});
            }
            if (column == 'ets_score') {
              columns.push({field: 'ets_score', headerName: 'Training Load', sortable: true, cellRendererFramework: 'CellRendererEtsScore'});
            }
            if (column == 'actions') {
              columns.push({field: 'actions', sortable: false, cellRendererFramework: 'CellRendererWorkoutActions'});
            }
          });

          return columns;
        }
        return [
          {field: 'date', headerName: 'Date', sortable: true, cellRendererFramework: 'CellRendererDate'},
          {field: 'name', headerName: 'Name', sortable: true, cellRendererFramework: 'CellRendererWorkoutLink', suppressSizeToFit: true},
          {field: 'distance', sortable: true, cellRendererFramework: 'CellRendererDistance'},
          {field: 'time', sortable: true, cellRendererFramework: 'CellRendererTime'},
          {field: 'pace', sortable: true, cellRendererFramework: 'CellRendererPace'},
          {field: 'type', sortable: true, cellRendererFramework: 'CellRendererType'},
          {field: 'workout_type', headerName: 'Workout Type', sortable: true, cellRendererFramework: 'CellRendererWorkoutType'},
          // {key: 'weight', sortable: true},
          {field: 'comments', sortable: true},
          // {key: 'rank', sortable: true},
          {field: 'stroke_rate', sortable: true, headerName: 'S/m'},
          {field: 'power', sortable: true, headerName: 'Watts'},
          {field: 'ets_score', sortable: true, headerName: 'Training Load', cellRendererFramework: 'CellRendererEtsScore', minWidth: 70},
          {field: 'actions', sortable: false, cellRendererFramework: 'CellRendererWorkoutActions'},
        ];
      }

    },
    methods: {
      onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
      }
    },
    created() {
    }
  }
</script>
