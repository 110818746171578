<template>
  <div class="flex items-center">
    {{ params.value | formatDate }}<br /> {{ params.data.name }}
  </div>
</template>

<script>
export default {
  name: 'CellRendererDateAndName',
  computed: {
  }
}
</script>
