<template>
  <div class="vx-row">

    <div class="vx-col w-full mb-base">
      <privacy-policy-widget></privacy-policy-widget>
      <integrations-widget></integrations-widget>
      <upgrade-widget></upgrade-widget>
      <summary-widget/>
    </div>
    <div class="vx-col w-full mb-base">
      <my-latest-workouts />
    </div>
    <div class="vx-col w-full mb-base">
      <hr-distribution-stacked-bar-chart-with-ets />
    </div>
    <div class="vx-col lg:w-1/2 w-full mb-base">
      <cumulative-distance-trend />
    </div>
    <div class="vx-col lg:w-1/2 w-full mb-base">
      <this-week-vs-previous />
    </div>
    <div class="vx-col w-full mb-base">
      <workouts-heatmap />
    </div>
    <div class="vx-col lg:w-1/2 w-full mb-base">
      <workouts-inspiration-container />
    </div>
    <div class="vx-col lg:w-1/2 w-full mb-base">
      <badges-widget />
    </div>
  </div>
</template>

<script>
  import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
  import MyLatestWorkouts from "../components/dashboard/MyLatestWorkouts";
  import WorkoutsHeatmap from "../components/dashboard/WorkoutsHeatmap";
  import SummaryWidget from "../components/dashboard/SummaryWidget";
  import CumulativeDistanceTrend from "../components/dashboard/CumulativeDistanceTrend";
  import ThisWeekVsPrevious from "../components/dashboard/ThisWeekVSPrevious";
  import WorkoutsInspirationContainer from "../components/dashboard/WorkoutsInspirationContainer";
  import IntegrationsWidget from "../components/dashboard/IntegrationsWidget";
  import UpgradeWidget from "../components/dashboard/UpgradeWidget";
  import BadgesWidget from "@/components/dashboard/BadgesWidget";
  import PrivacyPolicyWidget from "../components/dashboard/PrivacyPolicyWidget";
  import HrDistributionStackedBarChartWithEts from "../components/dashboard/HrDistributionStackedBarChartWithETS";

  export default{
    components: {
      HrDistributionStackedBarChartWithEts,
      PrivacyPolicyWidget,
      BadgesWidget,
      WorkoutsInspirationContainer,
      ThisWeekVsPrevious,
      CumulativeDistanceTrend,
      SummaryWidget,
      WorkoutsHeatmap,
      MyLatestWorkouts,
      StatisticsCardLine,
      IntegrationsWidget,
      UpgradeWidget
    },
    data () {
      return {
      }
    },
    created () {
    }
  }
</script>
