<template>
  <div>
  <vx-card v-if="loaded">
    <portal to="title-bar-actions">
      <vs-button radius icon="icon-trash-2" color="danger" icon-pack="feather" title="Delete" @click.prevent="workoutPlanDeleteDialogShow()"/>
    </portal>
    <form @submit.prevent="workoutPlanFormSubmit">
      <!-- Img Row -->
      <vue-image-input :placeholder="'workout_plans_image-128.jpg'" :folder="'workout_plans_image'"
                       v-model="workoutPlanForm.image"></vue-image-input>

      <!-- Info -->
      <vs-input
        label="Name"
        name="name"
        v-model="workoutPlanForm.name"
        class="w-full mt-2"/>
      <span class="text-danger text-sm" v-if="formControlHasError(workoutPlanForm, 'name')">{{ formControlGetError(workoutPlanForm, 'name') }}</span>

      <vs-textarea
        label="Description"
        name="description"
        v-model="workoutPlanForm.description"
        class="w-full mt-2" height="200px"/>
      <span class="text-danger text-sm" v-if="formControlHasError(workoutPlanForm, 'description')">{{ formControlGetError(workoutPlanForm, 'description') }}</span>

      <vs-checkbox v-model="workoutPlanForm.public" class="mt-4">Public</vs-checkbox>
      <span class="text-danger text-sm" v-if="formControlHasError(workoutPlanForm, 'public')">{{ formControlGetError(workoutPlanForm, 'public') }}</span>

      <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
        <label class="text-sm opacity-75">Price</label>
        <v-select :options="prices" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="workoutPlanForm.price" placeholder="Select price"/>
      </div>

      <!-- Save & Reset Button -->
      <div class="flex-wrap items-center justify-end mt-2">
        <vs-button class="ml-auto mt-2" button="submit">Save</vs-button>
        <vs-button type="border" class="ml-4 mb-2" color="warning" @click.prevent="$router.go(-1)">Cancel</vs-button>
      </div>
    </form>
  </vx-card>
  </div>
</template>

<script>
import axios from 'axios'
import {mapGetters} from 'vuex'
import Form from 'vform'
import FormErrors from '@/components/mixins/FormErrors'
import Numbers from '@/components/mixins/Numbers'
import moment from 'moment'
import {formatDateTime} from "../../../shared/utils";
import vSelect from 'vue-select'

export default {
  name: 'edit-workout-plan',

  components: {
    vSelect
  },
  // Set meta tags
  head: {
    title: {
      inner: 'Edit Workout Plan'
    }
  },

  mixins: [FormErrors, Numbers],

  data: () => ({
    workoutPlan: null,
    loaded: false,
    workoutPlanForm: new Form({
      name: '',
      description: '',
      image: '',
      public: '',
      price: '',
      price_id: ''
    }),
    prices: [
      {
        label: 'Free',
        value: ''
      },
      {
        label: '1 GBP',
        value: '593812'
      },{
        label: '3 GBP',
        value: '766944'
      }],
  }),

  computed: mapGetters({
    authenticatedUser: 'auth/getAuthenticatedUser',
  }),

  methods: {
    workoutPlanFormSubmit() {
      this.formResetErrors(this.workoutPlanForm);

      this.workoutPlanForm.paddle_id = this.workoutPlanForm.price != '' ? this.workoutPlanForm.price.value : '';

      this.workoutPlanForm.post('/workout-plans/' + this.workoutPlan.id + '/update')
        .then(({data}) => {
          this.$vs.notify(
            {
              text: data.message,
              color:'success',
              position:'top-right',
              time:5000,
            }
          );

          this.$router.push({name: 'public-workout-plans.show', params: {id: this.workoutPlan.id}});
        })
        .catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
    },

    getWorkoutPlan(workoutPlanId) {
      this.loaded = false;

      axios.get('workout-plans/' + workoutPlanId).then((data) => {
        this.workoutPlan = data.data.workout_plan;

        if (this.workoutPlan.user_id == this.authenticatedUser.id) {
          this.workoutPlanForm.name = this.workoutPlan.name;
          this.workoutPlanForm.image = this.workoutPlan.image;
          this.workoutPlanForm.description = this.workoutPlan.description;
          this.workoutPlanForm.public = this.workoutPlan.public;
          let that = this;
          this.workoutPlanForm.price = this.prices.find(
            price => price.value == that.workoutPlan.paddle_id
          );
          this.loaded = true;
        } else {
          this.$vs.notify({
            text: 'You do not have permission to edit this workout plan.',
            color:'danger',
            position:'top-right',
            time:5000,
          });

          this.$router.go('-1');
        }
      }).catch(error => {
        this.$vs.notify({
          text: error.response.data.message,
          color:'danger',
          position:'top-right',
          time:5000,
        });
      });
    },

    workoutPlanDeleteDialogShow() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Please Confirm`,
        text: 'Are you sure you want to delete this workout plan?',
        acceptText: 'Yes, delete it',
        accept: this.deleteWorkoutPlan
      })
    },

    deleteWorkoutPlan() {
      axios.post('workout-plans/'+ this.workoutPlan.id +'/destroy')
        .then(({data}) => {
          this.$vs.notify(
            {
              text: data.message,
              color:'success',
              position:'top-right',
              time:5000,
            }
          );

          this.$router.push({ name: 'workout-plans.my' });
        })
        .catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
    },

  },
  created() {
    this.getWorkoutPlan(this.$route.params.id);
  },
}
</script>
