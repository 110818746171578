<template>
  <div style="line-height: 23px; !important; margin-top: 12px;">
    <strong>&nbsp;</strong><br/>
    <strong title="Average Pace">{{ avgPace | timeAsPace }}</strong> (avg)
  </div>
</template>

<script>
export default {
  name: 'CellRendererFooterPace',
  computed: {
    avgPace() {
      let average = _.meanBy(this.$parent.$parent.items, 'pace');
      return average;
    },
  }
}
</script>
