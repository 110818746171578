<template>
  <div>
    <template v-if="items.length">
      <ag-grid-vue class="ag-theme-material w-100 ag-grid-table"
                   :columnDefs="columnDef"
                   :defaultColDef="defaultColDef"
                   :rowData="items"
                   @first-data-rendered="onFirstDataRendered">
      </ag-grid-vue>
    </template>
    <template v-else>
      There are no workouts
    </template>
  </div>
</template>

<script>
  import { AgGridVue } from 'ag-grid-vue'
  import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

  import CellRendererBenchmarkCell from '../global/cell-renderer/CellRendererBenchmarkCell.vue'

  export default {
    name: 'benchmark-table',

    components: {
      AgGridVue,
      CellRendererBenchmarkCell
    },
    props: {
      items: {
        type: [Array, Object],
        default: () => []
      },
      footClone: {
        type: Boolean,
        default: false
      },
      defaultColumns: {
        type: [Array, Object],
        default: () => []
      }
    },
    data: () => {
      return {
        defaultColDef: { resizable: true }
      }
    },
    computed: {
      columnDef() {
        return [
          {field: 'name', headerName: 'Name', sortable: true},
          {field: 'line_1', headerName: 'Line 1', cellRendererFramework: 'CellRendererBenchmarkCell', sortable: true},
          {field: 'line_2', headerName: 'Line 2', cellRendererFramework: 'CellRendererBenchmarkCell', sortable: true},
          {field: 'line_pb', headerName: 'PB', cellRendererFramework: 'CellRendererBenchmarkCell', sortable: true},
        ];
      }

    },
    methods: {
      onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
      }
    },
    created() {
    }
  }
</script>
