<template>
  <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen" :value="active_tab">

    <!-- GENERAL -->
    <vs-tab icon-pack="feather" icon="icon-user" :label="!isSmallerScreen ? 'General' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <user-settings-general />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-heart" :label="!isSmallerScreen ? 'Health' : ''">
      <div class="tab-info md:ml-4 md:mt-0 mt-4 ml-0">
        <user-settings-heart-rate />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-link-2" :label="!isSmallerScreen ? 'Connections' : ''">
      <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
        <user-settings-connections />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-bell" :label="!isSmallerScreen ? 'Notifications' : ''">
      <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
        <user-settings-notifications />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-toggle-left" :label="!isSmallerScreen ? 'Preferences' : ''">
      <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
        <user-settings-preferences />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-dollar-sign" :label="!isSmallerScreen ? 'Billing' : ''">
      <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
        <user-settings-billing :key="key" />
      </div>
    </vs-tab>
  </vs-tabs>
</template>

<script>
import UserSettingsGeneral from './UserSettingsGeneral.vue'
import UserSettingsHeartRate from './UserSettingsHeartRate.vue'
import UserSettingsConnections from './UserSettingsConnections.vue'
import UserSettingsNotifications from './UserSettingsNotifications.vue'
import UserSettingsBilling from "./UserSettingsBilling";
import UserSettingsPreferences from "./UserSettingsPreferences";

export default {
  components: {
    UserSettingsPreferences,
    UserSettingsBilling,
    UserSettingsGeneral,
    UserSettingsHeartRate,
    UserSettingsConnections,
    UserSettingsNotifications
  },
  data () {
    return {
      active_tab: 0,
      key: 1,
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },

  created () {
    if(this.$route.name == 'account.integrations.edit')
    {
      this.active_tab = 2;
    }
    if(this.$route.name == 'account.preferences.edit')
    {
      this.active_tab = 4;
    }
    if(this.$route.name == 'account.billing.edit')
    {
      this.active_tab = 5;
    }
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
