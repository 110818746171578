<template>
    <ag-grid-vue class="ag-theme-material w-100 ag-grid-table"
                 :columnDefs="columnDef"
                 :defaultColDef="defaultColDef"
                 :rowData="items"
                 @first-data-rendered="onFirstDataRendered">
    </ag-grid-vue>
</template>
<script>
    import { each} from 'lodash';
    import { AgGridVue } from 'ag-grid-vue'
    import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

    import CellRendererTimeInTens from '../global/cell-renderer/CellRendererTimeInTens.vue'
    import CellRendererPace from '../global/cell-renderer/CellRendererPace.vue'
    import CellRendererDistance from '../global/cell-renderer/CellRendererDistance.vue'

    import {calculatePaceFromTime, paceToWatts} from "../../shared/utils"

    export default {
        name: 'interval-table',

        components: {
          AgGridVue,
          CellRendererTimeInTens,
          CellRendererPace,
          CellRendererDistance
        },
        props: {
            result: {
                type: [Array, Object],
                default: () => []
            }
        },
        data() {
            return {
                items: [],
                title: '',
                defaultColDef: { resizable: true },
                columnDef: [
                  {field: 'row_number', headerName: '#', sortable: true, minWidth: 40},
                  {field: 'time', headerName: 'Time', sortable: true, cellRendererFramework: 'CellRendererTimeInTens', minWidth: 80},
                  {field: 'distance', headerName: 'Distance', sortable: true, cellRendererFramework: 'CellRendererDistance', minWidth: 100},
                  {field: 'pace', headerName: 'Pace', sortable: true, cellRendererFramework: 'CellRendererPace', minWidth: 80},
                  {field: 'watts', headerName: 'Watts', sortable: true, minWidth: 60},
                  {field: 'cal', headerName: 'Cal', sortable: true, minWidth: 60},
                  {field: 'stroke_rate', headerName: 'S/m', sortable: true, minWidth: 60},
                  {field: 'rest_time', headerName: 'Rest', sortable: true, cellRendererFramework: 'CellRendererTimeInTens' , minWidth: 80},
                ],
            }
        },
        computed: {
            avgWatts() {
                let average = _.meanBy(this.items, 'watts');
                return average;
            },

        },
        created() {
            if(this.result.details.workout.intervals != undefined && this.result.details.workout.intervals.length > 0)
            {
                this.items = this.result.details.workout.intervals;
                this.title = 'Intervals';
            }
            if(this.result.details.workout.splits != undefined && this.result.details.workout.splits.length > 0)
            {
                this.items = this.result.details.workout.splits;
                this.title = 'Splits';
            }

            if(this.items.length)
            {
                let row_number = 1;
                each(this.items, item => {
                    item.row_number = row_number++;
                    item.pace = calculatePaceFromTime(item.time/10, item.distance);
                    item.watts = paceToWatts(item.pace);
                });
            }
        },
        methods: {
          onFirstDataRendered(params) {
            params.api.sizeColumnsToFit();
          }
        },
    };
</script>
