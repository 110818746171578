<template>
  <vx-card v-if="loaded">
    <form @submit.prevent="notificationSettingsFormSubmit">
      <div class="mb-base">
        <h6 class="mb-4">Email Notifications</h6>

        <div class="flex items-center mb-4" v-for="notificationSetting in notificationSettings">
          <vs-switch v-model="notificationSetting.email_notification" />
          <span class="ml-4">{{ notificationSetting.notification_template.display_name }}</span>
        </div>
      </div>

      <div class="flex-wrap items-center justify-end mt-base">
        <vs-button class="ml-auto mt-2" button="submit">Save</vs-button>
      </div>
    </form>
  </vx-card>
</template>

<script>
  import axios from "axios";

  export default {
    // Set meta tags
    head: {
      title: {
        inner: 'Notification Settings'
      }
    },
    components: {},
    data: () => ({
      key: 1,
      loaded: false,
      notificationSettings: null,
      busy: false,
    }),
    methods: {
      fetchNotificationSettings() {
        this.loaded = false;

        axios.get('users/me/notification-settings?include=notification_template').then((data) => {
          this.notificationSettings = data.data.data;
          this.loaded = true;
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },

      prepareNotificationSettings() {
        return this.notificationSettings;
      },

      notificationSettingsFormSubmit() {
        axios.post('users/me/notification-settings/update', {
          notification_settings: this.prepareNotificationSettings()
        }).then(({data}) => {
          this.$vs.notify(
            {
              text: data.message,
              color:'success',
              position:'top-right',
              time:5000,
            }
          );

        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      }
    },
    created() {
      this.fetchNotificationSettings();
    },
  }
</script>
