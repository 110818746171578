<!-- =========================================================================================
    File Name: Register.vue
    Description: Register Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center">
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="vx-row no-gutter">
                        <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                            <img src="@/assets/images/pages/register.jpg" alt="register" class="mx-auto">
                        </div>
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg">
                            <div class="px-8 pt-8 register-tabs-container">
                                <div class="vx-card__title mb-4">
                                    <h4 class="mb-4">Create Account</h4>
                                    <p>Fill the below form to create a new account.</p>
                                </div>
                              <form class="clearfix" @submit.prevent="onRegisterFormSubmit">
                                <vs-input
                                  label="First Name"
                                  name="first_name"
                                  v-model="registerForm.first_name"
                                  class="w-full mt-2" />
                                <span class="text-danger text-sm" v-if="formControlHasError(registerForm, 'first_name')">{{ formControlGetError(registerForm, 'first_name') }}</span>

                                <vs-input
                                  label="Last Name"
                                  name="last_name"
                                  v-model="registerForm.last_name"
                                  class="w-full mt-2" />
                                <span class="text-danger text-sm" v-if="formControlHasError(registerForm, 'last_name')">{{ formControlGetError(registerForm, 'last_name') }}</span>

                                <vs-input
                                  name="email"
                                  type="email"
                                  label="Email"
                                  v-model="registerForm.email"
                                  class="w-full mt-2" />
                                <span class="text-danger text-sm" v-if="formControlHasError(registerForm, 'email')">{{ formControlGetError(registerForm, 'email') }}</span>

                                <vs-input
                                  type="password"
                                  name="password"
                                  label="Password"
                                  v-model="registerForm.password"
                                  class="w-full mt-2" />
                                <span class="text-danger text-sm" v-if="formControlHasError(registerForm, 'password')">{{ formControlGetError(registerForm, 'password') }}</span>

                                <vs-input
                                  type="password"
                                  name="password_confirmation"
                                  label="Password Confirmation"
                                  v-model="registerForm.password_confirmation"
                                  class="w-full mt-2" />
                                <span class="text-danger text-sm" v-if="formControlHasError(registerForm, 'password_confirmation')">{{ formControlGetError(registerForm, 'password_confirmation') }}</span>

                                <div class="vs-component vs-con-input-label vs-input w-full mt-2 vs-input-primary" v-if="false">
                                  <label class="vs-input--label">Date Of Birth</label>
                                  <div class="vs-con-input">
                                    <datetime v-model="registerForm.date_of_birth" :class="{'is-invalid': formControlHasError(registerForm, 'date_of_birth')}" input-class="vs-inputx vs-input--input normal" zone="utc" value-zone="utc" :max-datetime="maxDatetime" class="theme-green w-full"></datetime>
                                  </div>
                                </div>

                                <vs-checkbox v-model="registerForm.privacy_policy" class="mt-4">I agree to <a @click.stop target="_blank" href="https://ergmonkey.com/terms">T.O.S.</a> and <a @click.stop target="_blank" href="https://ergmonkey.com/privacy">Privacy Policy</a></vs-checkbox>
                                <span class="text-danger text-sm" v-if="formControlHasError(registerForm, 'privacy_policy')">{{ formControlGetError(registerForm, 'privacy_policy') }}</span>

                                <vs-checkbox v-model="registerForm.receive_marketing_updates" class="mt-4">I agree to receive marketing updates</vs-checkbox>
                                <span class="text-danger text-sm" v-if="formControlHasError(registerForm, 'receive_marketing_updates')">{{ formControlGetError(registerForm, 'receive_marketing_updates') }}</span>

                                <div class="mt-0 pb-8">
                                  <vs-button  type="border" :to="{ name: 'web.auth.login' }" class="mt-6">Login</vs-button>
                                  <vs-button class="float-right mt-6" button="submit" :pressed.sync="registerForm.busy">Register</vs-button>
                                </div>
                              </form>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
  import Form from 'vform'
  import FormErrors from '@/components/mixins/FormErrors'
  import {DateTime} from 'luxon'

  export default {
    // Set meta tags
    head: {
      title: {
        inner: 'Register'
      }
    },

    mixins: [
      FormErrors
    ],

    data: () => ({
      registerForm: new Form({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        date_of_birth: '',
        privacy_policy: 0,
        receive_marketing_updates: 0,
      }),

      maxDatetime: DateTime.utc().minus({years: 20}).toISO()
    }),

    beforeMount: async function () {
      await this.$store.dispatch('auth/logout');
    },

    methods: {
      async onRegisterFormSubmit() {
        this.formResetErrors(this.registerForm);

        this.registerForm.post('/auth/register')
          .then(({data}) => {
            this.registerForm.reset();

            this.$vs.dialog({
              color:'primary',
              title: 'Registration Successful',
              acceptText: 'Open Gmail',
              text: data.message,
              accept: this.goToLoginPage,
              close: this.goToLoginPage
            })
          })
          .catch(error => {
            if (error.response && error.response.status && error.response.status !== 422 && error.response.data && error.response.data.message) {
              this.$vs.notify({
                text: error.response.data.message,
                color:'danger',
                position:'top-right',
                time:5000,
              });
            }

            this.$scrollTo('#form-register');
          });
      },

      goToLoginPage() {
        // this.$router.push({name: 'web.auth.login'})
        window.location.href="https://mail.google.com/mail/u/0/#search/from%3D(%40ergmonkey.com)+in%3Aanywhere";
      }
    }
  }
</script>

<style lang="scss">
.register-tabs-container {
  min-height: 517px;

  .con-tab {
    padding-bottom: 23px;
  }

  .vs-checkbox--input {
    width: 10%;
  }
}
</style>
