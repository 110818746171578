<template>
  <vx-card>
    <!-- SLOT = ACTIONS -->
    <template slot="actions" v-if="false">
      <change-time-duration-dropdown />
    </template>

    <div class="vs-row" v-if="loaded" style="display: flex">
      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
      </div>

      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
        <feather-icon icon="AwardIcon" svgClasses="h-12 w-12" class="inline-flex rounded-full mb-4"/>
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ summaryData.total_number_of_pbs | formatNumber }}</h2>
          <span>PBs</span>
        </div>
      </div>

      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
        <vs-icon icon-pack="material-icons" icon="format_list_numbered_rtl" class="inline-flex rounded-full mb-4" size="large"/>
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ summaryData.total_number_of_strokes | formatNumber }}</h2>
          <span>Strokes</span>
        </div>
      </div>

      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
        <vs-icon icon-pack="material-icons" icon="access_time" class="inline-flex rounded-full mb-4" size="large"/>
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ summaryData.longest_streak | formatNumber }} {{ 'day' | pluralize(summaryData.longest_streak) }}</h2>
          <span>Longest Streak</span>
        </div>
      </div>

<!--      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">-->
<!--        <vs-icon icon-pack="material-icons" icon="speed" class="inline-flex rounded-full mb-4" size="large"/>-->
<!--        <div class="truncate">-->
<!--          <h2 class="mb-1 font-bold">{{ summaryData.average_pace | timeAsPace }}</h2>-->
<!--          <span>Ave. Pace</span>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">-->
<!--        <vs-icon icon-pack="material-icons" icon="rowing" class="inline-flex rounded-full mb-4" size="large"/>-->
<!--        <div class="truncate">-->
<!--          <h2 class="mb-1 font-bold">{{ summaryData.average_stroke_rate | formatNumber }}</h2>-->
<!--          <span>Ave. Rate</span>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">-->
<!--        <vs-icon icon-pack="material-icons" icon="accessibility_new" class="inline-flex rounded-full mb-4" size="large"/>-->
<!--        <div class="truncate">-->
<!--          <h2 class="mb-1 font-bold">{{ summaryData.average_calories | formatNumber }}</h2>-->
<!--          <span>Ave. Calories</span>-->
<!--        </div>-->
<!--      </div>-->

    </div>
    <!--    <loader v-else/>-->
  </vx-card>
</template>

<script>
  import axios from 'axios';
  import Loader from "../global/Loader";
  import ChangeTimeDurationDropdown from '../../components/ChangeTimeDurationDropdown.vue'
  import moment from "moment";
  import $ from "jquery";

  export default {
    name: 'public-pbs-2022-summary',

    components: {
      Loader,
      ChangeTimeDurationDropdown
    },
    data: () => {
      return {
        loaded: false,
        summaryData: '',
      }
    },
    methods: {
      getResults() {
        this.loaded = false;

        let params = {
          date_from: '2022-01-01',
          date_to: '2022-12-31',
        };

        axios.get('reports/pb-summary?user_id='+this.$route.params.id + '&' + $.param({filters: params})).then((data) => {
          this.summaryData = data.data.data;
          this.loaded = true;
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      }
    },
    created() {
      this.getResults();
    }
  }
</script>
