import Vue from 'vue'
import axios from 'axios'
import moment from "moment";
import {MACHINE_TYPES} from "../../shared/constants";
import {each} from 'lodash';

// State
export const state = {
    accessToken: Vue.$storage.get('accessToken', ''),
    refreshToken: Vue.$storage.get('refreshToken', ''),
    expiresIn: Vue.$storage.get('expiresIn', ''),
    accountId: Vue.$storage.get('accountId', ''),
    user: null
};

// Getters
export const getters = {
    getAccessToken: state => state.accessToken,
    getRefreshToken: state => state.refreshToken,
    getExpiresIn: state => state.expiresIn,
    getAccountId: state => state.accountId,
    getAuthenticatedUser: state => state.user,
    getIsUserAuthenticated: state => state.user !== null,
    getIsUserAuthenticatedAndActive: state => state.user !== null && parseInt(state.user.status) === 1,
    getIsUserAuthenticatedAndActiveAndAdmin: state => state.user !== null && parseInt(state.user.status) === 1 && state.user.role == 1,
    getAuthenticatedUserHasSubscriptionExpired: state => {
      if(state.user !== null &&
        (moment(state.user.created_at).isBefore('2022-08-15')
          || moment().subtract(14, 'day').isBefore(state.user.email_verified_at)
          || (state.user.next_bill_date != '' && moment().subtract(1, 'day').isBefore(state.user.next_bill_date))
        )
      ) {
        return false;
      }

      return true;
    },
    getAuthenticatedUserHasPermissionToSee: (state) => (allowedPlans) => {
      if(state.user !== null && state.user.next_bill_date != '' && moment().subtract(1, 'day').isAfter(state.user.next_bill_date))
      {
        return false;
      }
      if(allowedPlans.indexOf(state.user.subscription_plan_id) < 0 && moment().subtract(14, 'day').isAfter(state.user.email_verified_at))
      {
        return false;
      }

      return true;
    },
    getAuthenticatedUserResultTypes: (state) => () => {
      let filteredTypes = [];
      let usersResultTypes = [];
      _.each(state.user.result_types, item => {
        usersResultTypes.push("" + item.type);
      });

      _.each(MACHINE_TYPES, item => {
        if(usersResultTypes.indexOf(item.value) >= 0)
        {
          filteredTypes.push(item);
        }
      });

      return filteredTypes;
    }
};

// Mutations
export const mutations = {
    AUTH_LOAD_FROM_STORAGE(state) {
        state.accessToken = Vue.$storage.get('accessToken', '');
        state.refreshToken = Vue.$storage.get('refreshToken', '');
        state.expiresIn = Vue.$storage.get('expiresIn', '');
        state.accountId = Vue.$storage.get('accountId', '');
        if (!state.accessToken) {
            state.user = null;
        }
    },

    AUTH_LOGIN(state, {accessToken, refreshToken, expiresIn, accountId, user}) {
        state.accessToken = accessToken;
        state.refreshToken = refreshToken;
        state.expiresIn = expiresIn;
        state.accountId = accountId;
        state.user = user;
        Vue.$storage.set('accessToken', accessToken);
        Vue.$storage.set('refreshToken', refreshToken);
        Vue.$storage.set('expiresIn', expiresIn);
        Vue.$storage.set('accountId', accountId);
        Vue.$storage.set('user', user);
    },

    AUTH_FETCH_USER_SUCCESS(state, {user}) {
        state.user = user;
    },

    AUTH_FETCH_USER_FAILURE(state) {
        state.accessToken = null;
        state.refreshToken = null;
        state.expiresIn = null;
        state.accountId = null;
        state.user = null;
        Vue.$storage.set('accessToken', null);
        Vue.$storage.set('refreshToken', null);
        Vue.$storage.set('expiresIn', null);
        Vue.$storage.set('accountId', null);
        Vue.$storage.set('user', null);
        Vue.$storage.remove('accessToken');
        Vue.$storage.remove('refreshToken');
        Vue.$storage.remove('expiresIn');
        Vue.$storage.remove('accountId');
        Vue.$storage.remove('user');
    },

    AUTH_LOGOUT(state) {
        state.accessToken = null;
        state.refreshToken = null;
        state.expiresIn = null;
        state.accountId = null;
        state.user = null;
        Vue.$storage.set('accessToken', null);
        Vue.$storage.set('refreshToken', null);
        Vue.$storage.set('expiresIn', null);
        Vue.$storage.set('accountId', null);
        Vue.$storage.set('user', null);
        Vue.$storage.remove('accessToken');
        Vue.$storage.remove('refreshToken');
        Vue.$storage.remove('expiresIn');
        Vue.$storage.remove('accountId');
        Vue.$storage.remove('user');
    },

    AUTH_SWITCH_ACCOUNT(state, {accountId}) {
        state.accountId = accountId;
        Vue.$storage.set('accountId', accountId);
    }
};

// Actions
export const actions = {
    async loadFromStorage({commit, dispatch}, payload) {
        commit('AUTH_LOAD_FROM_STORAGE', payload);
    },

    async login({commit, dispatch}, payload) {
        commit('AUTH_LOGIN', payload);
    },

    async fetchUser({commit}) {
        await axios.get('/users/me?include=user_tags,preferences,result_types')
            .then(response => {
                let data = response.data;

                commit('AUTH_FETCH_USER_SUCCESS', {user: data.user});
            })
            .catch(error => {
                commit('AUTH_FETCH_USER_FAILURE');
            });
    },

    async logout({commit}) {
        axios.post('/auth/logout')
            .then(response => {
            })
            .catch(error => {
            });

        commit('AUTH_LOGOUT');
    },

    async activateAccount({commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/auth/activate-account', {encrypted_id: payload.encrypted_id, encrypted_email: payload.encrypted_email})
                .then((response) => {
                    resolve(response)
                }, response => {
                    reject(response)
                })
                .catch(error => {
                    reject(error)
                });
        });
    }
};
