<template>
  <div>
    <template v-if="items.length">
      <ag-grid-vue class="ag-theme-material w-100 ag-grid-table"
                   :columnDefs="columnDef"
                   :defaultColDef="defaultColDef"
                   :rowData="items"
                   :gridOptions="topGridOptions"
                   rowSelection="multiple"
                   rowMultiSelectWithClick="true"
                   @selection-changed="onSelectionChanged"
                   @first-data-rendered="onFirstDataRendered">
      </ag-grid-vue>
      <ag-grid-vue class="ag-theme-material w-100" style="height: 95px; flex: none;"
                   :gridOptions="bottomGridOptions"
                   :headerHeight="0"
                   :columnDefs="columnDefBottom"
                   :rowData="bottomData"
      ></ag-grid-vue>
    </template>
    <template v-else>
      There are no workouts
    </template>
  </div>
</template>

<script>
  import { AgGridVue } from 'ag-grid-vue'
  import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

  import CellRendererDateWithPB from '../global/cell-renderer/CellRendererDateWithPB.vue'
  import CellRendererTime from '../global/cell-renderer/CellRendererTime.vue'
  import CellRendererPace from '../global/cell-renderer/CellRendererPace.vue'
  import CellRendererType from '../global/cell-renderer/CellRendererType.vue'
  import CellRendererWorkoutType from '../global/cell-renderer/CellRendererWorkoutType.vue'
  import CellRendererWorkoutActions from '../global/cell-renderer/CellRendererWorkoutActions.vue'
  import CellRendererDistance from '../global/cell-renderer/CellRendererDistance.vue'
  import CellRendererDifference from '../global/cell-renderer/CellRendererDifference.vue'
  import CellRendererFooterTotalNumberOfWorkouts from '../global/cell-renderer/CellRendererFooterTotalNumberOfWorkouts.vue'
  import CellRendererFooterTotalDistance from '../global/cell-renderer/CellRendererFooterTotalDistance.vue'
  import CellRendererFooterTotalTime from '../global/cell-renderer/CellRendererFooterTotalTime.vue'
  import CellRendererFooterPace from '../global/cell-renderer/CellRendererFooterPace.vue'
  import CellRendererFooterRate from '../global/cell-renderer/CellRendererFooterRate.vue'
  import CellRendererFooterPower from '../global/cell-renderer/CellRendererFooterPower.vue'
  import CellRendererFooterDifference from '../global/cell-renderer/CellRendererFooterDifference.vue'
  import CellRendererEtsScore from '../global/cell-renderer/CellRendererEtsScore.vue'
  import CellRendererFooterEtsScore from '../global/cell-renderer/CellRendererFooterEtsScore.vue'

  export default {
    name: 'selectable-ag-session-list-with-improvements',

    components: {
      AgGridVue,
      CellRendererDateWithPB,
      CellRendererTime,
      CellRendererPace,
      CellRendererType,
      CellRendererWorkoutType,
      CellRendererWorkoutActions,
      CellRendererDistance,
      CellRendererDifference,
      CellRendererFooterTotalNumberOfWorkouts,
      CellRendererFooterTotalDistance,
      CellRendererFooterTotalTime,
      CellRendererFooterPace,
      CellRendererFooterRate,
      CellRendererFooterPower,
      CellRendererFooterDifference,
      CellRendererEtsScore,
      CellRendererFooterEtsScore
    },
    props: {
      items: {
        type: [Array, Object],
        default: () => []
      },
      footClone: {
        type: Boolean,
        default: false
      }
    },
    data: () => {
      return {
        defaultColDef: { resizable: true },
        topGridOptions : {alignedGrids: [] },
        bottomGridOptions : {alignedGrids: [] },
      }
    },
    computed: {
      pb() {
        let pb = {id: '', pace: 10000};
        _.each(this.items, item => {
          if (item.pace < pb.pace) {
            pb = item;
          }
        });
        return pb;
      },
      bottomData () {
          if(this.items.length)
          {
            return [{
              'date': this.items.length,
            }]
          }
          return [];
      },
      columnDef() {
        if(this.$store.state.windowWidth < 992) {
          return [
            {field: 'date', headerName: 'Date', sortable: true, cellRendererFramework: 'CellRendererDateWithPB', headerCheckboxSelection: true, checkboxSelection: true, minWidth: 100},
            {field: 'actions', headerName: '', sortable: false, cellRendererFramework: 'CellRendererWorkoutActions', minWidth: 90, maxWidth:90},
          ]
        }
        return [
          {field: 'date', headerName: 'Date', sortable: true, cellRendererFramework: 'CellRendererDateWithPB', headerCheckboxSelection: true, checkboxSelection: true, minWidth: 150},
          {field: 'distance', sortable: true, cellRendererFramework: 'CellRendererDistance', minWidth: 100},
          {field: 'time', sortable: true, cellRendererFramework: 'CellRendererTime', minWidth: 80},
          {field: 'pace', sortable: true, cellRendererFramework: 'CellRendererPace', minWidth: 80},
          {field: 'stroke_rate', sortable: true, headerName: 'S/m', minWidth: 60},
          {field: 'power', sortable: true, headerName: 'Watts', minWidth: 60},
          {field: 'difference', sortable: true, headerName: 'Diff.', cellRendererFramework: 'CellRendererDifference', minWidth: 70},
          {field: 'ets_score', sortable: true, headerName: 'Training Load', cellRendererFramework: 'CellRendererEtsScore', minWidth: 70},
          {field: 'actions', sortable: false, cellRendererFramework: 'CellRendererWorkoutActions', minWidth: 90, maxWidth:90},
        ];
      },
      columnDefBottom() {
        if(this.$store.state.windowWidth < 992) {
          return [
            {field: 'date', headerName: 'Date', sortable: true, cellRendererFramework: 'CellRendererFooterTotalNumberOfWorkouts', minWidth: 100},
            {field: 'actions', headerName: '', sortable: false, minWidth: 90, maxWidth:90},
          ]
        }
        return [
          {field: 'date', headerName: 'Date', sortable: true, cellRendererFramework: 'CellRendererFooterTotalNumberOfWorkouts'},
          {field: 'distance', sortable: true, cellRendererFramework: 'CellRendererDistance', cellRendererFramework: 'CellRendererFooterTotalDistance', autoHeight: true},
          {field: 'time', sortable: true, cellRendererFramework: 'CellRendererTime', cellRendererFramework: 'CellRendererFooterTotalTime', autoHeight: true},
          {field: 'pace', sortable: true, cellRendererFramework: 'CellRendererPace', cellRendererFramework: 'CellRendererFooterPace', autoHeight: true},
          {field: 'stroke_rate', sortable: true, headerName: 'S/m', cellRendererFramework: 'CellRendererFooterRate', autoHeight: true},
          {field: 'power', sortable: true, headerName: 'Watts', cellRendererFramework: 'CellRendererFooterPower', autoHeight: true},
          {field: 'difference', sortable: true, headerName: 'Diff.', cellRendererFramework: 'CellRendererFooterDifference', autoHeight: true},
          {field: 'ets_score', sortable: true, headerName: 'Training Load', cellRendererFramework: 'CellRendererEtsScore', cellRendererFramework: 'CellRendererFooterEtsScore', autoHeight: true},
          {field: 'actions', sortable: false, minWidth: 90, maxWidth:90},
        ];
      },

    },
    methods: {
      onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
      },
      onSelectionChanged(event) {
        this.$parent.selected = event.api.getSelectedRows();
      },
    },
    beforeMount() {
      this.topGridOptions.alignedGrids.push(this.bottomGridOptions);
      this.bottomGridOptions.alignedGrids.push(this.topGridOptions);
    },
    created() {
    }
  }
</script>
