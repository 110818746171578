<template>
  <div v-if="loaded">
    <portal to="title-bar-actions">
      <template v-if="result.badges">
        <img v-for="badge in result.badges" :src="badge.image_url_64" class="responsive rounded-lg mr-2" v-bind:alt="badge.name" v-bind:title="badge.description" style="width: 36px; height: 36px;">
      </template>
      <v-select multiple taggable push-tags :closeOnSelect="false" v-model="result.tags" :options="result.user_tags" class="mr-2" style="min-width: 160px" placeholder="Tags">
        <div slot="no-options">No matching tags. Please create your own.</div>
      </v-select>
      <vs-button icon="icon-share-2" icon-pack="feather" @click="shareModal = true" title="Share Workout">Share</vs-button>
      <vs-button icon="icon-trash" icon-pack="feather" @click.prevent="resultDeleteDialogShow()" color="danger" class="ml-2" title="Delete Workout"/>
    </portal>
    <div class="vx-col w-full mb-base">
      <result-summary-widget :result="result"/>
    </div>
    <vs-row>
      <vs-col v-for="interval in intervalDataForChart">
        <vx-card class="mb-base" :title="interval.title">
          <split-chart :interval=interval :source="result.source"></split-chart>
        </vx-card>
      </vs-col>
    </vs-row>
    <div class="vx-row flex-col-reverse md:flex-col-reverse sm:flex-row lg:flex-row mb-base">
      <div class="vx-col w-full md:w-full sm:w-full lg:w-1/2 xl:w-1/2 flex flex-col justify-between">
        <interval-table-card :result="result"></interval-table-card>
      </div>
      <div class="vx-col w-full md:w-full sm:w-full lg:w-1/2 xl:w-1/2 flex flex-col lg:mb-0 md:mb-base sm:mb-0 mb-base">
        <public-history-comparasion :result="result"></public-history-comparasion>
      </div>
    </div>
    <div class="vx-row flex-col-reverse md:flex-col-reverse sm:flex-row lg:flex-row mb-base">
      <div class="vx-col w-full md:w-full sm:w-full lg:w-1/2 xl:w-1/2 flex flex-col justify-between">
        <vx-card title="Workout Details">
          Name: <span class="font-bold">{{ result.name }}</span><br/>
          Date: <span class="font-bold">{{ result.date | formatDateTime }}</span><br/>
          Workout: <span class="font-bold">{{ result.type | formatTypeOfSession }}</span><br/>
          Workout Type: <span class="font-bold">{{ result.workout_type | formatWorkoutType }}</span><br/>
          Weight Class: <span class="font-bold">{{ result.weight_class | formatWeight }}</span><br/>
          <template v-if="result.rest_distance > 0">
            Rest Distance: <span class="font-bold">{{ result.rest_distance | formatNumber }}</span>m<br/>
          </template>
          <template v-if="result.rest_time > 0">
            Rest Time: <span class="font-bold">{{ result.rest_time | timeInHours }}</span><br/>
          </template>
          Verified: <span class="font-bold">{{ result.verified ? 'Yes' : 'No' }}</span><br/>
          Entered: <span class="font-bold">{{ result.source_app }}</span><br/>
          Comment: <span class="font-bold">{{ result.comments }}</span><br/>
        </vx-card>
      </div>
      <div class="vx-col w-full md:w-full sm:w-full lg:w-1/2 xl:w-1/2 flex flex-col lg:mb-0 md:mb-base sm:mb-0 mb-base">
        <single-workout-hr-pie-chart :result="result"></single-workout-hr-pie-chart>
      </div>
    </div>
    <vs-row class="mb-base">
      <ergo-score-trend-chart :result="result"></ergo-score-trend-chart>
    </vs-row>
    <vs-row class="mb-base">
      <pace-trend-chart :result="result"></pace-trend-chart>
    </vs-row>
    <vs-row class="mb-base">
      <rate-and-pace-trend-chart :result="result"></rate-and-pace-trend-chart>
    </vs-row>
    <vs-row class="mb-base">
      <pb-trend-chart :result="result"></pb-trend-chart>
    </vs-row>

    <vs-row class="mb-base">
      <ets-trend-chart :result="result"></ets-trend-chart>
    </vs-row>

    <vs-popup title="Share Workout" :active.sync="shareModal">
      <vs-row class="mb-2">
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="facebook" class="vs-component vs-button vs-button-null vs-button-filled btn-facebook w-full mr-2">
              <feather-icon icon="FacebookIcon" svgClasses="h-4 w-4" /> Facebook
            </network>
          </vs-col>
        </social-sharing>
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="twitter" class="vs-component vs-button vs-button-null vs-button-filled btn-twitter w-full ml-2">
              <feather-icon icon="TwitterIcon" svgClasses="h-4 w-4" /> Twitter
            </network>
          </vs-col>
        </social-sharing>
      </vs-row>
      <vs-row class="mb-2">
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="linkedin" class="vs-component vs-button vs-button-null vs-button-filled btn-linkedin w-full mr-2">
              <feather-icon icon="LinkedinIcon" svgClasses="h-4 w-4" /> LinkedIn
            </network>
          </vs-col>
        </social-sharing>
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="reddit" class="vs-component vs-button vs-button-null vs-button-filled btn-reddit w-full ml-2">
              <feather-icon icon="RedditIcon" svgClasses="h-4 w-4" /> Reddit
            </network>
          </vs-col>
        </social-sharing>
      </vs-row>
      <vs-row class="mb-2">
        <social-sharing :url=shareUrl
                        :title=shareTitle
                        :description=shareDesription
                        :quote=shareQuote
                        :hashtags=shareHashtags
                        :twitter-user=shareTwitterUser
                        network-tag="button"
                        inline-template>
          <vs-col vs-w="6">
            <network network="email" class="vs-component vs-button vs-button-warning vs-button-filled w-full mr-2">
              <feather-icon icon="MailIcon" svgClasses="h-4 w-4" /> Email
            </network>
          </vs-col>
        </social-sharing>
        <vs-col vs-w="6">
          <vs-button color="primary" v-clipboard:copy="shareUrl" v-clipboard:success="onCopySuccess" class="w-full ml-2">
            <feather-icon icon="CopyIcon" svgClasses="h-4 w-4" /> Copy
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>

  </div>
</template>

<script>
  import axios from 'axios'
  import {each} from 'lodash';
  import SplitChart from "../../../components/result/SplitChart";
  import IntervalTableCard from "../../../components/result/IntervalTableCard";
  import ErgoScoreTrendChart from "../../../components/result/ErgoScoreTrendChart";
  import {formatDateTime} from "../../../shared/utils";
  import ResultSummaryWidget from "../../../components/result/ResultSummaryWidget";
  import PublicHistoryComparasion from "../../../components/result/PublicHistoryComparasion";
  import RateAndPaceTrendChart from "../../../components/result/RateAndPaceTrendChart";
  import PaceTrendChart from "../../../components/result/PaceTrendChart";
  import PbTrendChart from "../../../components/result/PbTrendChart";
  import SingleWorkoutHrPieChart from "../../../components/result/SingleWorkoutHrPieChart";
  import vSelect from 'vue-select'
  import EtsTrendChart from "../../../components/result/EtsTrendChart";

  export default {
    components: {
      EtsTrendChart,
      ResultSummaryWidget,
      PublicHistoryComparasion,
      SingleWorkoutHrPieChart,
      PbTrendChart,
      PaceTrendChart,
      RateAndPaceTrendChart,
      IntervalTableCard,
      SplitChart,
      ErgoScoreTrendChart,
      vSelect
    },
    computed: {
      intervalDataForChart: function () {
        let items = [];
        let title = '';
        let row_number = 1;
        let previous_timestamp = 0;
        var i = 0;

        if (this.result.details != undefined && this.result.details.workout.intervals != undefined) {
          items = this.result.details.workout.intervals;
          title = 'Interval';

          each(items, item => {
            item.stroke_data = [];
            item.title = title + ' ' + row_number;
            if(item.machine != undefined) {
              item.title = item.title + ' - ' + item.machine;
            }
            item.row_number = row_number++;

            if (this.strokeData != undefined) {
              for (i; i < this.strokeData.length; i++) {
                if (this.strokeData[i].t >= previous_timestamp) {
                  if ((item.type == 'distance' && (this.strokeData[i].d / 10 <= item.distance)) || (item.type == 'time' && (this.strokeData[i].t / 10 <= item.time)) || (item.type == 'stroke' && (this.strokeData[i].t / 10 <= item.time))) {
                    item.stroke_data.push(this.strokeData[i]);
                  }
                  previous_timestamp = this.strokeData[i].t;
                } else {
                  previous_timestamp = 0;
                  break;
                }

              }
            }
          });
        }
        if (this.result.details != undefined && this.result.details.workout.splits != undefined && this.result.details.workout.splits.length > 0) {
          let item = {};
          item.title = 'Workout Graph';
          item.stroke_data = this.strokeData;
          items.push(item);
        }

        return items;
      },
      shareUrl: function () {
        return 'https://stats.ergmonkey.com/share/' + this.$route.params.id;
      },
      shareTitle: function () {
        return this.result != null ? this.result.name : '';
      }
    },
    data: () => {
      return {
        loaded: false,
        result: null,
        tags: null,
        title: '',
        page_title: '',
        shareModal: false,
        shareDesription: '',
        shareQuote: '',
        shareHashtags: '',
        shareTwitterUser: 'ergmonkey_',
      }
    },
    watch: {
      result: {
        handler: function(val, oldVal) {
          if(this.loaded && oldVal != null) {
            if(val.tags != this.tags)
            {
              axios.post('results/' + this.$route.params.id +'/update', {
                'tags': val.tags
              }).then(({data}) => {
                this.$vs.notify({
                  text: data.message,
                  color:'success',
                  position:'top-right',
                  time:3000,
                });

                this.$store.dispatch('auth/fetchUser');
              }).catch(error => {
                this.$vs.notify({
                  text: error.response.data.message,
                  color:'danger',
                  position:'top-right',
                  time:5000,
                });
              });
            }
          }
        },
        deep: true
      },
    },
    methods: {
      getResult(resultId) {
        this.loaded = false;

        axios.get('users/me/results/' + resultId + '?include=details,user_tags,badges').then((data) => {
          this.result = data.data.result;
          this.tags = data.data.result.tags;
          this.title = this.result.name;
          if (this.result.details != undefined) {
            this.strokeData = this.result.details.stroke_data.data;
          }
          this.loaded = true;
          this.page_title = this.result.name + " (" + formatDateTime(this.result.date) + ")";
          this.$emit('changeRouteTitle', this.page_title);
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },
      onCopySuccess() {
        this.shareModal = false;
        this.$vs.notify({
          text: "Copied!",
          color:'success',
          position:'top-right',
          time:5000,
        });
      },

      resultDeleteDialogShow() {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: `Please Confirm`,
          text: 'Are you sure you want to delete this workout?',
          acceptText: 'Yes, delete it',
          accept: this.deleteResult
        })
      },

      deleteResult() {
        axios.post('/results/'+this.$route.params.id+'/destroy')
          .then(({data}) => {
            this.$vs.notify({
              text: data.message,
              color:'success',
              position:'top-right',
              time:5000,
            });

            this.$router.push({name: 'account.home.index'});
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      }
    },
    created() {
      this.getResult(this.$route.params.id);
    },
    beforeRouteUpdate(to, from, next) {
      this.getResult(to.params.id);
      next();
    },
  }
</script>
