<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto">
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Login</h4>
                  <p>Welcome back, please login to your account.</p>
                </div>

                <form @submit.prevent="onLoginFormSubmit" id="form-login">
                  <div class="pt-2">
                    <vs-input
                      name="email"
                      icon-no-border
                      icon="icon icon-user"
                      icon-pack="feather"
                      label="Email"
                      v-model="loginForm.email"
                      class="w-full"/>
                    <span class="text-danger text-sm" v-if="formControlHasError(loginForm, 'email')">{{ formControlGetError(loginForm, 'email') }}</span>

                    <vs-input
                      type="password"
                      name="password"
                      icon-no-border
                      icon="icon icon-lock"
                      icon-pack="feather"
                      label="Password"
                      v-model="loginForm.password"
                      class="w-full mt-2"/>
                    <span class="text-danger text-sm" v-if="formControlHasError(loginForm, 'password')">{{ formControlGetError(loginForm, 'password') }}</span>

                    <div class="flex flex-wrap justify-between my-5">
                      <span class="mb-3"></span>
                      <router-link :to="{ name: 'web.auth.forgot_password' }">Forgot Password?</router-link>
                    </div>
                    <vs-button type="border" :to="{ name: 'web.auth.register' }">Register</vs-button>
                    <vs-button button="submit" class="float-right" :pressed.sync="loginForm.busy">Login</vs-button>

                    <template>
                      <vs-divider>OR</vs-divider>

                      <div class="social-login-buttons flex flex-wrap items-center mt-4">

                        <!-- facebook -->
                        <div class="bg-facebook pt-3 pb-2 px-4 rounded-lg cursor-pointer mr-4" v-if="false">
                          <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f"
                               class="text-white h-4 w-4 svg-inline--fa fa-facebook-f fa-w-9" role="img"
                               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264 512">
                            <path fill="currentColor"
                                  d="M215.8 85H264V3.6C255.7 2.5 227.1 0 193.8 0 124.3 0 76.7 42.4 76.7 120.3V192H0v91h76.7v229h94V283h73.6l11.7-91h-85.3v-62.7c0-26.3 7.3-44.3 45.1-44.3z"></path>
                          </svg>
                        </div>

                        <!-- TWITTER -->
                        <div class="bg-twitter pt-3 pb-2 px-4 rounded-lg cursor-pointer mr-4" v-if="false">
                          <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter"
                               class="text-white h-4 w-4 svg-inline--fa fa-twitter fa-w-16" role="img"
                               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor"
                                  d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                          </svg>
                        </div>

                        <div id="google-signin-button"></div>

                        <!-- GOOGLE -->
                        <div class="bg-google pt-3 pb-2 px-4 rounded-lg cursor-pointer mr-4" v-if="false">
                          <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google"
                               class="text-white h-4 w-4 svg-inline--fa fa-google fa-w-16" role="img"
                               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512">
                            <path fill="currentColor"
                                  d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path>
                          </svg>
                        </div>

                        <!-- GITHUB -->
                        <div class="bg-github pt-3 pb-2 px-4 rounded-lg cursor-pointer mr-4" v-if="false">
                          <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="github-alt"
                               class="text-white h-4 w-4 svg-inline--fa fa-github-alt fa-w-15" role="img"
                               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 512">
                            <path fill="currentColor"
                                  d="M186.1 328.7c0 20.9-10.9 55.1-36.7 55.1s-36.7-34.2-36.7-55.1 10.9-55.1 36.7-55.1 36.7 34.2 36.7 55.1zM480 278.2c0 31.9-3.2 65.7-17.5 95-37.9 76.6-142.1 74.8-216.7 74.8-75.8 0-186.2 2.7-225.6-74.8-14.6-29-20.2-63.1-20.2-95 0-41.9 13.9-81.5 41.5-113.6-5.2-15.8-7.7-32.4-7.7-48.8 0-21.5 4.9-32.3 14.6-51.8 45.3 0 74.3 9 108.8 36 29-6.9 58.8-10 88.7-10 27 0 54.2 2.9 80.4 9.2 34-26.7 63-35.2 107.8-35.2 9.8 19.5 14.6 30.3 14.6 51.8 0 16.4-2.6 32.7-7.7 48.2 27.5 32.4 39 72.3 39 114.2zm-64.3 50.5c0-43.9-26.7-82.6-73.5-82.6-18.9 0-37 3.4-56 6-14.9 2.3-29.8 3.2-45.1 3.2-15.2 0-30.1-.9-45.1-3.2-18.7-2.6-37-6-56-6-46.8 0-73.5 38.7-73.5 82.6 0 87.8 80.4 101.3 150.4 101.3h48.2c70.3 0 150.6-13.4 150.6-101.3zm-82.6-55.1c-25.8 0-36.7 34.2-36.7 55.1s10.9 55.1 36.7 55.1 36.7-34.2 36.7-55.1-10.9-55.1-36.7-55.1z"></path>
                          </svg>
                        </div>
                      </div>
                    </template>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import Form from 'vform'
import FormErrors from '@/components/mixins/FormErrors'

export default {
  // Set meta tags
  head: {
    title: {
      inner: 'Login'
    }
  },

  mixins: [
    FormErrors
  ],

  data: () => ({
    loginForm: new Form({
      email: '',
      password: ''
    }),
    googleLoginForm: new Form({
      google_id_token: '',
    }),
  }),

  beforeMount: async function () {
    //await this.$store.dispatch('auth/logout');
  },

  mounted() {
    google.accounts.id.initialize({
      client_id: "208469978410-u7ol6cb3rq83vgam7ahg2dsph78mr7j0.apps.googleusercontent.com",
      callback: this.handleCredentialResponse,
      scope: 'https://www.googleapis.com/auth/cloud-platform.read-only',
    });
    google.accounts.id.renderButton(
      document.getElementById("google-signin-button"),
      {theme: "outline", size: "large"}  // customization attributes
    );
    google.accounts.id.prompt(); // also display the One Tap dialog
  },

  created() {
  },
  methods: {
    async onLoginFormSubmit() {
      this.formResetErrors(this.loginForm);

      await this.loginForm.post('/auth/login')
        .then(({data}) => {
          this.$store.dispatch('auth/login', {
            accessToken: data.token.access_token,
            refreshToken: data.token.refresh_token,
            expiresIn: parseInt(data.token.expires_in),
            user: data.user
          });

          this.$store.dispatch('auth/fetchUser');

          this.$router.push({name: 'account.home.index'});
        })
        .catch(error => {
          if (error.response && error.response.status && error.response.status !== 422 && error.response.data && error.response.data.message) {
            this.$vs.notify({
              text: error.response.data.message,
              color: 'danger',
              position: 'top-right',
              time: 5000,
            });
          }

          this.$scrollTo('#form-login');
        });
    },

    handleCredentialResponse(response) {
      this.googleLoginForm.google_id_token = response.credential;
      this.googleLoginForm.post('/auth/login-google')
        .then(({data}) => {
          this.$store.dispatch('auth/login', {
            accessToken: data.token.access_token,
            refreshToken: data.token.refresh_token,
            expiresIn: parseInt(data.token.expires_in),
            user: data.user
          });

          this.$store.dispatch('auth/fetchUser');

          this.$router.push({name: 'account.home.index'});
        })
        .catch(error => {
          if (error.response && error.response.status && error.response.status !== 422 && error.response.data && error.response.data.message) {
            this.$vs.notify({
              text: error.response.data.message,
              color: 'danger',
              position: 'top-right',
              time: 5000,
            });
          }

          this.$scrollTo('#form-login');
        });

    },
  }
}
</script>
