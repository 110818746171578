<template>
  <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen" :value="active_tab">

    <!-- GENERAL -->
    <vs-tab icon-pack="feather" icon="icon-info" :label="!isSmallerScreen ? 'General' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <badge-settings-general />
      </div>
    </vs-tab>
  </vs-tabs>
</template>

<script>
import axios from "axios";
import BadgeSettingsGeneral from "@/views/pages/badges/BadgeSettingsGeneral";

export default {
  components: {
    BadgeSettingsGeneral,
  },
  data () {
    return {
      active_tab: 0,
      badge: null,
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },

  methods: {
    getBadge(groupId) {
      this.loaded = false;

      axios.get('admin/badges/' + groupId).then((data) => {
        this.badge = data.data.badge;
      }).catch(error => {
        this.$vs.notify({
          text: error.response.data.message,
          color:'danger',
          position:'top-right',
          time:5000,
        });
      });
    },
  },

  created () {
    this.getBadge(this.$route.params.id);
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
