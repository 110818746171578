<template>
  <div>
    <template v-if="items.length">
      <ag-grid-vue class="ag-theme-material w-100 ag-grid-table"
                   :columnDefs="columnDef"
                   :defaultColDef="defaultColDef"
                   :rowData="items"
                   @first-data-rendered="onFirstDataRendered">
      </ag-grid-vue>
    </template>
    <template v-else>
      There are no workouts
    </template>
  </div>
</template>

<script>
  import { AgGridVue } from 'ag-grid-vue'
  import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

  import CellRendererTime from '../global/cell-renderer/CellRendererTime.vue'
  import CellRendererPace from '../global/cell-renderer/CellRendererPace.vue'
  import CellRendererPeriod from '../global/cell-renderer/CellRendererPeriod.vue'
  import CellRendererDistance from '../global/cell-renderer/CellRendererDistance.vue'
  import CellRendererEtsScore from '../global/cell-renderer/CellRendererEtsScore.vue'

  export default {
    name: 'ag-summary-list',

    components: {
      AgGridVue,
      CellRendererTime,
      CellRendererPace,
      CellRendererDistance,
      CellRendererPeriod,
      CellRendererEtsScore
    },
    props: {
      items: {
        type: [Array, Object],
        default: () => []
      },
      footClone: {
        type: Boolean,
        default: false
      }
    },
    data: () => {
      return {
        defaultColDef: { resizable: true }
      }
    },
    computed: {
      columnDef() {
        if(this.$store.state.windowWidth < 992) {
          return [
            {field: 'period', headerName: 'Period', sortable: true, cellRendererFramework: 'CellRendererPeriod'},
            {field: 'total_distance', headerName: 'Distance', sortable: true, cellRendererFramework: 'CellRendererDistance'},
            {field: 'total_time', headerName: 'Time', sortable: true, cellRendererFramework: 'CellRendererTime'},
          ]
        }
        return [
          {field: 'period', headerName: 'Period', sortable: true, cellRendererFramework: 'CellRendererPeriod'},
          {field: 'total_distance', headerName: 'Distance', sortable: true, cellRendererFramework: 'CellRendererDistance'},
          {field: 'total_time', headerName: 'Time', sortable: true, cellRendererFramework: 'CellRendererTime'},
          {field: 'total_number_of_workouts', headerName: 'Number of Workouts', sortable: true},
          {field: 'average_pace', headerName: 'Ave. Pace', sortable: true, cellRendererFramework: 'CellRendererPace'},
          {field: 'average_stroke_rate', sortable: true, headerName: 'Ave. Rate'},
          {field: 'average_ets_score', sortable: true, headerName: 'Ave. Training Load'},
          {field: 'total_ets_score', sortable: true, headerName: 'Total Training Load', cellRendererFramework: 'CellRendererEtsScore'},
        ];
      }

    },
    methods: {
      onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
      }
    },
    created() {
    }
  }
</script>
