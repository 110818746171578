<template>
  <vx-card :title="title">
    <div class="vs-row" style="display: flex">
      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
        <vs-icon icon-pack="material-icons" icon="settings_ethernet" class="inline-flex rounded-full mb-4" size="large"/>
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ result.distance | formatNumber }}m</h2>
          <span>Distance</span>
        </div>
      </div>

      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
        <vs-icon icon-pack="material-icons" icon="access_time" class="inline-flex rounded-full mb-4" size="large"/>
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ result.time | timeInHours }}</h2>
          <span>Time</span>
        </div>
      </div>

      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
        <vs-icon icon-pack="material-icons" icon="speed" class="inline-flex rounded-full mb-4" size="large"/>
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ result.pace | timeAsPace }}</h2>
          <span>Pace</span>
        </div>
      </div>

      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
        <vs-icon icon-pack="material-icons" icon="rowing" class="inline-flex rounded-full mb-4" size="large"/>
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ result.stroke_rate | formatNumber }}</h2>
          <span>Ave. Rate</span>
        </div>
      </div>

      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
        <vs-icon icon-pack="material-icons" icon="accessibility_new" class="inline-flex rounded-full mb-4" size="large"/>
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ result.ets_score | formatNumber }}</h2>
          <span>Training Load</span>
        </div>
      </div>

      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
        <vs-icon icon-pack="material-icons" icon="offline_bolt" class="inline-flex rounded-full mb-4" size="large"/>
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ result.power | formatNumber }}</h2>
          <span>Ave. Power</span>
        </div>
      </div>

    </div>
  </vx-card>
</template>

<script>
    export default {
        name: 'result-summary-widget',

      props: {
        title: {
          type: String,
          default: ''
        },
        result: {
          type: [Array, Object],
          default: () => []
        },
      },
    }
</script>
