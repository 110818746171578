<template>
  <div class="flex items-center">
    <template v-if="parseInt(params.value) > 0">
      {{ params.value/10 | timeInHours }}
    </template>
  </div>
</template>

<script>
export default {
  name: 'CellRendererTimeInTens',
  computed: {
  }
}
</script>
