<template>
    <vx-card :title="title">
      <interval-table :result="result"/>
    </vx-card>
</template>
<script>
    import IntervalTable from "./IntervalTable";

    export default {
        name: 'interval-table-card',

        components: {
          IntervalTable,
        },
        props: {
            result: {
                type: [Array, Object],
                default: () => []
            }
        },
        data() {
            return {
                items: [],
                title: '',
            }
        },
        computed: {
        },
        created() {
            if(this.result.details.workout.intervals != undefined && this.result.details.workout.intervals.length > 0)
            {
                this.items = this.result.details.workout.intervals;
                this.title = 'Intervals';
            }
            if(this.result.details.workout.splits != undefined && this.result.details.workout.splits.length > 0)
            {
                this.items = this.result.details.workout.splits;
                this.title = 'Splits';
            }
        },
        methods: {
        },
    };
</script>
