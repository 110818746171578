<template>
  <div class="flex items-center">
    <template v-if="params != null && params.value != undefined">
      <router-link :to="{ name: 'results.show', params: { id: params.value.id }}" @click.stop.prevent class="text-inherit hover:text-primary" :title="params.value.name" target="_blank">{{ params.value.power }}W ({{ params.value.pace | timeAsPace }})</router-link>
    </template>
    <template v-else>
      -
    </template>
  </div>
</template>

<script>
export default {
  name: 'CellRendererBenchmarkCell',
  computed: {
  }
}
</script>
