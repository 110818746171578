<template>
  <vx-card title="Progress" class="mb-base">
    <highcharts :options="chartOptions" style="height: 450px;"></highcharts>
  </vx-card>
</template>
<script>
  import Highcharts from 'highcharts'
  import $ from 'jquery'
  import {formatDate, formatDateTime, formatTime, formatTimeAsPace} from "../../shared/utils";
  import moment from "moment";

  export default {
    name: 'lockdown-progress-graph-distance',

    components: {},
    props: {
      summaryResults: {
        type: [Array, Object],
        default: () => []
      },
      minDate: {
        type: [String],
        default: '2020-01-01'
      }
    },
    data: () => {
      return {
        chartOptions: {
          chart: {
            type: 'line',
            zoomType: 'xy',
            backgroundColor: 'rgba(0,0,0,0)',
            events: {
              load: function() {
                /* On mobile, remove labels */
                if ($(window).width() < 900) {
                  this.yAxis[0].update({
                    title: false,
                  });
                  this.yAxis[1].update({
                    title: false,
                  });
                  this.yAxis[2].update({
                    title: false,
                  });
                  this.tooltip.update({
                    style: {
                      width: Math.min(300, 2*$(window).width()/3)
                    }
                  });
                }
              }
            }
          },
          title: false,
          xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
              week: '%e/%b'
            },
            min: 1577836800000,
            //max: 1609372800000,
            max: moment().add(3, 'months').valueOf(),
            units: [
              [
                'week', [1]
              ],
              [
                'month', [1]
              ]
            ]
          },
          yAxis: [{
            title: {
              text: 'Cumulative Distance',
            },
            allowDecimals: false,
          }],
          tooltip: {
            shared: true,
            formatter: function () {
              let s = '<b>' + Highcharts.dateFormat('%e-%b-%y', this.x) + '</b>';
              $.each(this.points, function (i, point) {
                let value = point.y;
                let name = this.series.name;
                if (name == 'Pace' || name == 'Time') {
                  value = formatTimeAsPace(value);
                }
                s += '<br/>' + '<span style="color:' + point.color + '">\u25CF</span> ' + this.series.name + ': ' + value + this.series.tooltipOptions.valueSuffix;
              });
              return s;
            },
            useHTML: true
          },
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0
            },
            series: {
              allowPointSelect: true,
            }
          },
          series: [],
          credits: {
            enabled: false
          }
        }
      }
    },

    methods: {},
    mounted() {
      var i = 0;
      let time = [];

      i = 0;
      let pace = [];
      let distance = [];
      let cumulativeDistance = 0;
      for (i; i < this.summaryResults.length; i++) {
        let y = moment(this.summaryResults[i].date, 'YYYY-MM-DD HH:mm:ss').format('YYYY');
        let m = moment(this.summaryResults[i].date, 'YYYY-MM-DD HH:mm:ss').format('M');
        let d = moment(this.summaryResults[i].date, 'YYYY-MM-DD HH:mm:ss').format('D');
        let itemDate = Date.UTC(y, m - 1, d);
        cumulativeDistance += this.summaryResults[i].total_distance;

        pace.push({x: itemDate, y: this.summaryResults[i].average_pace});
        distance.push({x: itemDate, y: cumulativeDistance});
      }

      this.chartOptions.xAxis.min = moment(this.minDate, 'YYYY-MM-DD').valueOf();
      this.chartOptions.xAxis.max = moment().valueOf();

      this.chartOptions.series.push({
        name: "Cumulative Distance",
        data: distance,
        tooltip: {
          valueSuffix: ' m'
        },
      });
    }
  };
</script>
