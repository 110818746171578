<template>
  <div class="container-fluid">
    <vs-row vs-w="12">
      <vs-col vs-justify="top" vs-align="top" vs-lg="2" vs-sm="4" vs-xs="12" >
          <div id='external-events' v-if="previousWorkoutsloaded" :key="favorite_workouts_key">
            <p>
              <strong>Favorite workouts</strong>
            </p>
            <template v-for="(workout, index) in previousWorkouts">
              <vs-row vs-w="12">
                <vs-col vs-lg="8" vs-sm="8" vs-xs="8" >
                  <div class='fc-event fc-day-grid-event' :data-workout_id="workout.id">{{ workout.name }}</div>
                </vs-col>
                <vs-col vs-type="flex" vs-lg="4" vs-sm="4" vs-xs="4">
                  <vs-button size="small" radius icon="icon-edit" color="success" type="flat" icon-pack="feather" title="Edit" @click.prevent="editWorkoutDialogShow(workout)"/>
                  <vs-button size="small" radius icon="icon-trash" color="danger" type="flat" icon-pack="feather" title="Delete" @click.prevent="deleteWorkoutDialogShow(workout)"/>
                </vs-col>
              </vs-row>
            </template>
            <vs-button color="success" type="flat" class="ml-4 mb-2" @click.prevent="newWorkoutDialogShow()">Add Workout</vs-button>
          </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="top" vs-align="top" vs-lg="10" vs-sm="8" vs-xs="12">
        <vx-card>
          <FullCalendar :defaultView="defaultView"
                        :plugins="calendarPlugins"
                        :header="{
                            left: this.left,
                            center: 'title',
                            right: this.right
                          }"
                        :events="events"
                        :config="config"
                        :views="views"
                        :default-date="defaultDate"
                        :valid-range="validRange"
                        :editable="true"
                        @eventClick="eventSelected"
                        @dateClick="dateClick"
                        :eventLimit="10"
                        :droppable="true"
                        @eventDrop="eventDrop"
                        @eventReceive="eventReceive"
                        :first-day="1"
                        height="auto"
          />
        </vx-card>
      </vs-col>
    </vs-row>

      <vs-popup title="New Favorite Workout" :active.sync="newWorkoutOptions.visibility">
        <form @submit.prevent="newWorkoutFormSubmit">
          <vs-row>
            <vs-input
              label="Name"
              name="name"
              v-model="newWorkoutForm.name"
              class="w-full mt-2"/>
            <span class="text-danger text-sm" v-if="formControlHasError(newWorkoutForm, 'name')">{{ formControlGetError(newWorkoutForm, 'name') }}</span>
          </vs-row>

          <vs-row>
            <div class="vx-col w-full">
              <label class="text-sm opacity-75">Sport</label>
              <v-select :options="sports" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="newWorkoutForm.sportChooser" placeholder="Select sport"/>
              <span class="text-danger text-sm" v-if="formControlHasError(newWorkoutForm, 'sport')">{{ formControlGetError(newWorkoutForm, 'sport') }}</span>
            </div>
          </vs-row>

          <vs-row>
            <vs-textarea
              label="Description"
              name="description"
              v-model="newWorkoutForm.description"
              class="w-full mt-2" height="200px"/>
            <span class="text-danger text-sm" v-if="formControlHasError(newWorkoutForm, 'description')">{{ formControlGetError(newWorkoutForm, 'description') }}</span>
          </vs-row>

          <div class="flex-wrap items-center justify-end mt-2">
            <vs-button class="ml-auto mt-2" button="submit" @click.prevent="newWorkoutFormSubmit()">Save</vs-button>
            <vs-button type="border" class="ml-4 mb-2" color="warning" @click.prevent="newWorkoutOptions.visibility = false">Cancel</vs-button>
          </div>
        </form>
      </vs-popup>

    <vs-popup title="Edit Favorite Workout" :active.sync="editWorkoutOptions.visibility">
      <form @submit.prevent="editWorkoutFormSubmit">
        <vs-row>
          <vs-input
            label="Name"
            name="name"
            v-model="editWorkoutForm.name"
            class="w-full mt-2"/>
          <span class="text-danger text-sm" v-if="formControlHasError(editWorkoutForm, 'name')">{{ formControlGetError(editWorkoutForm, 'name') }}</span>
        </vs-row>

        <vs-row>
          <div class="vx-col w-full">
            <label class="text-sm opacity-75">Sport</label>
            <v-select :options="sports" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="editWorkoutForm.sportChooser" :value="editWorkoutForm.sport" placeholder="Select sport"/>
            <span class="text-danger text-sm" v-if="formControlHasError(editWorkoutForm, 'sport')">{{ formControlGetError(editWorkoutForm, 'sport') }}</span>
          </div>
        </vs-row>

        <vs-row>
          <vs-textarea
            label="Description"
            name="description"
            v-model="editWorkoutForm.description"
            class="w-full mt-2" height="200px"/>
          <span class="text-danger text-sm" v-if="formControlHasError(editWorkoutForm, 'description')">{{ formControlGetError(editWorkoutForm, 'description') }}</span>
        </vs-row>

        <div class="flex-wrap items-center justify-end mt-2">
          <vs-button class="ml-auto mt-2" button="submit" @click.prevent="editWorkoutFormSubmit()">Save</vs-button>
          <vs-button type="border" class="ml-4 mb-2" color="warning" @click.prevent="editWorkoutOptions.visibility = false">Cancel</vs-button>
        </div>
      </form>
    </vs-popup>

    <vs-popup title="New Workout" :active.sync="newWorkoutPlanWorkoutOptions.visibility">
      <form @submit.prevent="newWorkoutPlanWorkoutFormSubmit">
        <vs-row>
          <vs-input
            label="Name"
            name="name"
            v-model="newWorkoutPlanWorkoutForm.name"
            class="w-full mt-2"/>
          <span class="text-danger text-sm" v-if="formControlHasError(newWorkoutPlanWorkoutForm, 'name')">{{ formControlGetError(newWorkoutPlanWorkoutForm, 'name') }}</span>
        </vs-row>

        <vs-row>
          <div class="vx-col w-full">
            <label class="text-sm opacity-75">Sport</label>
            <v-select :options="sports" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="newWorkoutPlanWorkoutForm.sportChooser" placeholder="Select sport"/>
            <span class="text-danger text-sm" v-if="formControlHasError(newWorkoutPlanWorkoutForm, 'sport')">{{ formControlGetError(newWorkoutPlanWorkoutForm, 'sport') }}</span>
          </div>
        </vs-row>

        <vs-row>
          <vs-textarea
            label="Description"
            name="description"
            v-model="newWorkoutPlanWorkoutForm.description"
            class="w-full mt-2" height="200px"/>
          <span class="text-danger text-sm" v-if="formControlHasError(newWorkoutPlanWorkoutForm, 'description')">{{ formControlGetError(newWorkoutPlanWorkoutForm, 'description') }}</span>
        </vs-row>

        <div class="flex-wrap items-center justify-end mt-2">
          <vs-button class="ml-auto mt-2" button="submit" @click.prevent="newWorkoutPlanWorkoutFormSubmit()">Save</vs-button>
          <vs-button type="border" class="ml-4 mb-2" color="warning" @click.prevent="newWorkoutPlanWorkoutForm.visibility = false">Cancel</vs-button>
        </div>
      </form>
    </vs-popup>

    <vs-popup title="Edit Workout" :active.sync="editWorkoutPlanWorkoutOptions.visibility">
      <form @submit.prevent="editWorkoutPlanWorkoutFormSubmit">
        <vs-row>
          <vs-input
            label="Name"
            name="name"
            v-model="editWorkoutPlanWorkoutForm.name"
            class="w-full mt-2"/>
          <span class="text-danger text-sm" v-if="formControlHasError(editWorkoutPlanWorkoutForm, 'name')">{{ formControlGetError(editWorkoutPlanWorkoutForm, 'name') }}</span>
        </vs-row>

        <vs-row>
          <div class="vx-col w-full">
            <label class="text-sm opacity-75">Sport</label>
            <v-select :options="sports" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="editWorkoutPlanWorkoutForm.sportChooser" :value="editWorkoutPlanWorkoutForm.sport" placeholder="Select sport"/>
            <span class="text-danger text-sm" v-if="formControlHasError(editWorkoutPlanWorkoutForm, 'sport')">{{ formControlGetError(editWorkoutPlanWorkoutForm, 'sport') }}</span>
          </div>
        </vs-row>

        <vs-row>
          <vs-textarea
            label="Description"
            name="description"
            v-model="editWorkoutPlanWorkoutForm.description"
            class="w-full mt-2" height="200px"/>
          <span class="text-danger text-sm" v-if="formControlHasError(editWorkoutPlanWorkoutForm, 'description')">{{ formControlGetError(editWorkoutPlanWorkoutForm, 'description') }}</span>
        </vs-row>

        <div class="flex-wrap items-center justify-end mt-2">
          <vs-button class="ml-auto mt-2" button="submit" @click.prevent="editWorkoutPlanWorkoutFormSubmit()">Save</vs-button>
          <vs-button type="border" class="ml-4 mb-2" color="warning" @click.prevent="editWorkoutPlanWorkoutForm.visibility = false">Cancel</vs-button>
          <vs-button type="border" class="ml-4 mb-2" color="danger" @click.prevent="deleteWorkoutPlanWorkoutDialogShow(editWorkoutPlanWorkoutForm.id)">Delete</vs-button>
        </div>
      </form>
    </vs-popup>

  </div>
</template>

<script>
  import axios from 'axios'

  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import timeGridPlugin from '@fullcalendar/timegrid'
  import listPlugin from '@fullcalendar/list'
  import interactionPlugin, {Draggable} from '@fullcalendar/interaction'
  import moment from 'moment'
  import Form from "vform";
  import FormErrors from '@/components/mixins/FormErrors'
  import $ from 'jquery'
  import vSelect from "vue-select";
  import {DateTime} from "luxon";
  import {MACHINE_TYPES, SPORTS} from "../../../shared/constants";

  export default {
    components: {
      FullCalendar, // make the <FullCalendar> tag available
      vSelect
    },
    mixins: [FormErrors],
    data: () => ({
      newWorkoutForm: new Form({
        name: '',
        sportChooser: '',
        sport: '',
        description: '',
        is_favorite: true
      }),
      editWorkoutForm: new Form({
        id: '',
        name: '',
        sportChooser: '',
        sport: '',
        description: '',
        is_favorite: false
      }),
      newWorkoutOptions: {
        busy: false,
        visibility: false
      },
      editWorkoutOptions: {
        busy: false,
        visibility: false
      },
      newWorkoutPlanWorkoutForm: new Form({
        name: '',
        sportChooser: '',
        sport: '',
        description: '',
        date: null,
      }),
      editWorkoutPlanWorkoutForm: new Form({
        id: '',
        name: '',
        sportChooser: '',
        sport: '',
        description: '',
        date: null,
      }),
      newWorkoutPlanWorkoutOptions: {
        busy: false,
        visibility: false
      },
      editWorkoutPlanWorkoutOptions: {
        busy: false,
        visibility: false
      },
      calendarPlugins: [ // plugins must be defined in the JS
        dayGridPlugin,
        timeGridPlugin,
        listPlugin,
        interactionPlugin // needed for dateClick
      ],
      config: {},
      calendarWeekends: true,
      events: [],
      loaded: false,
      defaultView: "dayGridMonth",
      defaultDate: '1990-01-01',
      validRange: {
        start: '1990-01-01'
      },
      right: "",
      left: "prev,next",
      previousWorkoutsloaded: false,
      previousWorkouts: [],
      views: {
        customListView: {
          type: 'listWeek',
          duration: {month: 1},
          buttonText: 'list'
        },
        dayGridMonth: { // name of view
          //titleFormat: { month: 'numeric' },
          titleFormat: function (date) {
            let numberOfMonths = moment(date.date.marker).diff(moment([1990, 0, 1]), 'month') + 1;
            return 'Month ' + numberOfMonths;
          },
          // other view-specific options here
        }
      },
      sports: SPORTS,
      favorite_workouts_key: 1,
      currentWorkout: null
    }),
    methods: {
      getEvents() {
        this.loaded = false;

        axios.get('/workout-plans/' + this.$route.params.id + '?include=workout_plan_workouts').then((data) => {
            this.events = data.data.workout_plan.workout_plan_workouts.map(item => {
                return {
                    // item,
                    id: item.id,
                    title: item.name,
                    start: item.date,
                    textColor: 'white',
                    extendedProps: {
                      id: item.id,
                      workout_id: item.workout_id,
                      sport: item.sport,
                      description: item.description,
                    }
                };
            });
            this.loaded = true;
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },
      getPreviousWorkouts() {
        this.previousEventsloaded = false;

        axios.get('users/me/workouts?per_page=1000&filters[is_favorite]=1&order_by=name&order_direction=asc').then((data) => {
            this.previousWorkouts = data.data.workouts.data;
            this.previousWorkoutsloaded = true;
            this.favorite_workouts_key++;

            this.makeDraggableEvents();

        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },
      eventSelected(info) {
        this.editWorkoutPlanWorkoutForm = new Form({
          id: info.event.id,
          name: info.event.title,
          sportChooser: this.sports.find(
            sport => sport.value == info.event.extendedProps.sport
          ),
          description: info.event.extendedProps.description,
          is_favorite: info.event.extendedProps.is_favorite
        });

        this.editWorkoutPlanWorkoutOptions.busy = false;
        this.editWorkoutPlanWorkoutOptions.visibility = true;
      },
      eventDrop(info) {
        let postData = {
          'id': info.event.extendedProps.id,
          'date': DateTime.fromMillis(Date.parse(info.event.start))
        };
        axios.post('/workout-plans/' + this.$route.params.id + '/update-workout', postData)
          .then(({data}) => {
            info.event.setExtendedProp( 'id', data.workout_plan_workout.id);

            this.$vs.notify(
              {
                text: data.message,
                color:'success',
                position:'top-right',
                time:5000,
              }
            );
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },
      eventReceive(info){
        let postData = {
          'workout_id': info.event.extendedProps.workout_id,
          'date': DateTime.fromMillis(Date.parse(info.event.start))
        };
        axios.post('/workout-plans/' + this.$route.params.id + '/create-workout', postData)
          .then(({data}) => {
            debugger;
            info.event.remove();
            this.getEvents();

            this.$vs.notify(
              {
                text: data.message,
                color:'success',
                position:'top-right',
                time:5000,
              }
            );
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },
      dateClick: function (info) {
        this.newWorkoutPlanWorkoutDialogShow(info.dateStr);
      },

      newWorkoutFormSubmit() {
        this.formResetErrors(this.newWorkoutForm);

        this.newWorkoutForm.sport = this.newWorkoutForm.sportChooser != '' ? this.newWorkoutForm.sportChooser.value : '';

        this.newWorkoutForm.post('/workouts/create')
          .then(({data}) => {
            this.$vs.notify(
              {
                text: data.message,
                color:'success',
                position:'top-right',
                time:5000,
              }
            );

            this.getPreviousWorkouts();
            this.getEvents();
            this.newWorkoutOptions.visibility = false;
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },

      newWorkoutDialogShow() {
        this.newWorkoutForm = new Form({
          name: '',
          sportChooser: '',
          sport: '',
          description: '',
          is_favorite: true
        });

        this.newWorkoutOptions.busy = false;
        this.newWorkoutOptions.visibility = true;
      },
      editWorkoutDialogShow(workout) {
        this.editWorkoutForm = new Form({
          id: workout.id,
          name: workout.name,
          sportChooser: this.sports.find(
            sport => sport.value == workout.sport
          ),
          description: workout.description,
          is_favorite: true
        });

        this.editWorkoutOptions.busy = false;
        this.editWorkoutOptions.visibility = true;
      },
      editWorkoutFormSubmit() {
        this.formResetErrors(this.editWorkoutForm);

        this.editWorkoutForm.sport = this.editWorkoutForm.sportChooser != '' ? this.editWorkoutForm.sportChooser.value : '';

        this.editWorkoutForm.post('/workouts/'+this.editWorkoutForm.id+'/update')
          .then(({data}) => {
            this.$vs.notify(
              {
                text: data.message,
                color:'success',
                position:'top-right',
                time:5000,
              }
            );

            this.getPreviousWorkouts();
            this.editWorkoutOptions.visibility = false;
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },
      deleteWorkoutDialogShow(workout) {
        this.currentWorkout = workout;
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: `Please Confirm`,
          text: 'Are you sure you want to delete this workout?',
          acceptText: 'Yes, delete it',
          accept: this.deleteWorkout
        })
      },
      deleteWorkout() {
        axios.post('/workouts/'+this.currentWorkout.id+'/destroy')
          .then(({data}) => {
            this.$vs.notify({
              text: data.message,
              color:'success',
              position:'top-right',
              time:5000,
            });

            this.getPreviousWorkouts();
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },
      newWorkoutPlanWorkoutDialogShow(date) {
        this.newWorkoutPlanWorkoutForm = new Form({
          name: '',
          sportChooser: '',
          sport: '',
          description: '',
          date: date,
        });

        this.newWorkoutPlanWorkoutOptions.busy = false;
        this.newWorkoutPlanWorkoutOptions.visibility = true;
      },
      newWorkoutPlanWorkoutFormSubmit() {
        this.formResetErrors(this.newWorkoutPlanWorkoutForm);

        this.newWorkoutPlanWorkoutForm.workout_plan_id = this.$route.params.id;
        this.newWorkoutPlanWorkoutForm.sport = this.newWorkoutPlanWorkoutForm.sportChooser != '' ? this.newWorkoutPlanWorkoutForm.sportChooser.value : '';

        this.newWorkoutPlanWorkoutForm.post('workout-plans/'+this.$route.params.id+'/create-workout')
          .then(({data}) => {
            this.$vs.notify(
              {
                text: data.message,
                color:'success',
                position:'top-right',
                time:5000,
              }
            );

            this.getEvents();
            this.newWorkoutPlanWorkoutOptions.visibility = false;
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },
      editWorkoutPlanWorkoutFormSubmit() {
        this.formResetErrors(this.editWorkoutPlanWorkoutForm);

        this.editWorkoutPlanWorkoutForm.sport = this.editWorkoutPlanWorkoutForm.sportChooser != '' ? this.editWorkoutPlanWorkoutForm.sportChooser.value : '';

        this.editWorkoutPlanWorkoutForm.post('/workout-plans/'+this.$route.params.id+'/update-workout')
          .then(({data}) => {
            this.$vs.notify(
              {
                text: data.message,
                color:'success',
                position:'top-right',
                time:5000,
              }
            );

            this.getEvents();
            this.editWorkoutPlanWorkoutOptions.visibility = false;
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },
      deleteWorkoutPlanWorkoutDialogShow(workoutId) {
        this.currentWorkout = workoutId;
        this.editWorkoutPlanWorkoutOptions.visibility = false;
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: `Please Confirm`,
          text: 'Are you sure you want to delete this workout?',
          acceptText: 'Yes, delete it',
          accept: this.deleteWorkoutPlanWorkout
        })
      },
      deleteWorkoutPlanWorkout() {
        axios.post('/workout-plans/'+this.$route.params.id+'/destroy-workout/'+this.currentWorkout)
          .then(({data}) => {
            this.$vs.notify({
              text: data.message,
              color:'success',
              position:'top-right',
              time:5000,
            });

            this.getEvents();
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },
      makeDraggableEvents: _.debounce(function () {
        const that = this;
        var containerEl = document.getElementById('external-events');

        new Draggable(containerEl, {
          itemSelector:  '.fc-event',
          eventData: function (eventEl) {
            return {
              workout_id: eventEl.dataset['workout_id'],
              title: eventEl.innerText,
            };
          }
        });
      }, 500)
    },
    created() {
      if (this.$route.query.goToDate != '' && this.$route.query.goToDate != undefined) {
        this.defaultDate = this.$route.query.goToDate;
      }
      this.getEvents();
      this.getPreviousWorkouts();
    },
  }

</script>
