<template>
  <vx-card title="Meter Mountain">
    <highcharts :options="chartOptions"></highcharts>
  </vx-card>
</template>
<script>
  import Highcharts from 'highcharts'
  import $ from 'jquery'
  import {timeInHours} from "../../shared/utils";
  import moment from "moment";
  import axios from "axios";

  export default {
    name: 'public-cumulative-distance-trend',

    components: {},
    props: {
      summaryResults: {
        type: [Array, Object],
        default: () => []
      }
    },
    data: () => {
      return {
        chartOptions: {
          chart: {
            type: 'line',
            zoomType: 'xy',
            backgroundColor: 'rgba(0,0,0,0)',
            events: {
              load: function() {
                /* On mobile, remove labels */
                if ($(window).width() < 900) {
                  // this.yAxis[0].update({
                  //   title: false,
                  // });
                  this.tooltip.update({
                    style: {
                      width: Math.min(300, 2*$(window).width()/3)
                    }
                  });
                }
              }
            }
          },
          title: false,
          xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
              day: '%e/%b'
            },
            //max: moment().add(3, 'months').valueOf(),
          },
          yAxis: [{
            title: {
              text: 'Cumulative Distance',
            },
            allowDecimals: false,
          }],
          responsive: {
            rules: [{
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  floating: false,
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                  x: 0,
                  y: 0
                }
              }
            }]
          },
          tooltip: {
            shared: true,
            formatter: function () {
              let s = '<b>' + Highcharts.dateFormat('%e-%b',this.points[0].point.options.precisedate) + '</b>';
              $.each(this.points, function(i, point) {
                let value = point.y;
                s += '<br/>' + '<span style="color:' + point.color + '">\u25CF</span> ' + this.series.name + ': ' + value + this.series.tooltipOptions.valueSuffix + ' ('+ timeInHours(point.point.cumulative_time) +')';
              });
              return s;
            },
            useHTML: true
          },
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0
            },
            series: {
              allowPointSelect: true,
              states: {
                inactive: {
                  opacity: 1
                }
              },
            }
          },
          series: [],
          credits: {
            enabled: false
          }
        }
      }
    },

    methods: {
      getResults() {
        axios.get('reports/public-summary?user_id='+this.$route.params.id + '&group_by=date&filters[date_from]=2020-01-01&filters[date_to]=2020-12-31').then((data) => {
          this.loaded = true;
          this.items = data.data.data;

          if(this.items.length > 0)
          {
            let years = [];
            var i = 0;
            let cumulativeDistance = [];
            let cumulativeTime = [];
            for (i; i < this.items.length; i++) {
              let y = moment(this.items[i].date, 'YYYY-MM-DD HH:mm:ss').format('YYYY');
              let m = moment(this.items[i].date, 'YYYY-MM-DD HH:mm:ss').format('M');
              let d = moment(this.items[i].date, 'YYYY-MM-DD HH:mm:ss').format('D');
              let itemDate = Date.UTC(y, m - 1, d);
              let itemDateForXAxes = Date.UTC(2020, m - 1, d);

              if(years[y] == undefined)
              {
                years[y] = [];
              }

              if(cumulativeDistance[y] == undefined)
              {
                cumulativeDistance[y] = 0;
              }

              if(cumulativeTime[y] == undefined)
              {
                cumulativeTime[y] = 0;
              }

              cumulativeDistance[y] = cumulativeDistance[y] + this.items[i].total_distance;
              cumulativeTime[y] = cumulativeTime[y] + this.items[i].total_time;

              years[y].push({x: itemDateForXAxes, y: cumulativeDistance[y], precisedate: itemDate, cumulative_time: cumulativeTime[y] });
            }

            let start = moment.utc(this.items[0].date, 'YYYY-MM-DD').startOf('year');
            let end = moment.utc().format('YYYY-MM-DD HH:mm:ss');

            let previousCumulativeDistance = 0;
            let previousCumulativeTime = 0;
            let previousYear = '';
            while (moment(end).diff(start, 'day') >= 0) {
              let yi = start.format('YYYY');
              let mi = start.format('M');
              let di = start.format('D');
              let itemDate = Date.UTC(yi, mi - 1, di);
              let itemDateForXAxes = Date.UTC(2020, mi - 1, di);

              if(yi != previousYear)
              {
                previousCumulativeDistance = 0;
                previousCumulativeTime = 0;
              }

              let exactDay = _.find(years[yi], { x: itemDateForXAxes});
              if(exactDay != undefined)
              {
                previousCumulativeDistance = exactDay.y;
                previousCumulativeTime = exactDay.cumulative_time;
              } else {
                if(years[yi] == undefined)
                {
                  years[yi] = [];
                }

                years[yi].push({x: itemDateForXAxes, y: previousCumulativeDistance, precisedate: itemDate, cumulative_time: previousCumulativeTime});
              }

              previousYear = yi;
              start = start.add(1, 'day');
            }

            for (var k in years){
              years[k] = _.sortBy(years[k], ['precisedate']);
              if(k == 2020)
              {
                this.chartOptions.series.push({
                  name: k,
                  data: years[k],
                  tooltip: {
                    valueSuffix: ' m'
                  },
                });
              }
            }
          }
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      }
    },
    mounted() {
      this.getResults();
    }
  };
</script>
