<template>
  <div class="flex items-center">
    <img
      :src="params.data.public_user.image != null ? params.data.public_user.image_url_64 : 'https://api.ergmonkey.com/img/placeholders/users_image-64.jpg'"
      class="img-avatar"
      alt="User Image" width="35px"/>
  </div>
</template>

<script>
export default {
  name: 'CellRendererGroupUserImage',
  computed: {
  }
}
</script>
