<template>
  <vx-card>
    <template v-if="this.authenticatedUser.subscription_status == 3">
      <div class="h5 text-warning mb-1 mt-2 text-center">Your subscription has been canceled!</div>
      <template v-if="subscriptionExpired">
        <div class="text-muted text-uppercase font-weight-bold font-xs">Your subscription ended on {{ this.authenticatedUser.next_bill_date | formatDate }}.</div>
      </template>
      <template v-else>
        <div class="text-muted text-uppercase font-weight-bold font-xs text-center">It will remain active until {{ this.authenticatedUser.next_bill_date | formatDate }}.</div>
      </template>
    </template>
    <template v-else>
      <template v-if="this.authenticatedUser.subscription_plan_id == 1 || this.authenticatedUser.subscription_plan_id == ''">
        <div class="h5 text-warning mb-1 mt-2 text-center">You are on a Free plan!</div>
        <div class="text-muted text-uppercase font-weight-bold font-xs text-center">To upgrade use the form below.</div>
      </template>
      <template v-if="this.authenticatedUser.subscription_plan_id == 2 || this.authenticatedUser.subscription_plan_id == 4">
        <div class="h5 text-info mb-1 mt-2 text-center">You are on a Performance plan!</div>
      </template>
      <template v-if="this.authenticatedUser.subscription_plan_id == 3 || this.authenticatedUser.subscription_plan_id == 5">
        <div class="h5 text-primary mb-1 mt-2 text-center">You are on a Premium plan!</div>
      </template>
      <template v-if="this.authenticatedUser.subscription_plan_id == 6 || this.authenticatedUser.subscription_plan_id == 7">
        <div class="h5 text-primary mb-1 mt-2 text-center">You are on a Lite plan!</div>
      </template>
      <template v-if="this.authenticatedUser.subscription_plan_id == 8 || this.authenticatedUser.subscription_plan_id == 9">
        <div class="h5 text-primary mb-1 mt-2 text-center">You are on a Standard plan!</div>
      </template>
      <template v-if="this.authenticatedUser.subscription_plan_id == 10 || this.authenticatedUser.subscription_plan_id == 11">
        <div class="h5 text-primary mb-1 mt-2 text-center">You are on a Plus plan!</div>
      </template>
      <template v-if="this.authenticatedUser.subscription_status == 2">
        <div class="text-muted text-uppercase font-weight-bold font-xs text-center">Next bill date: {{ this.authenticatedUser.next_bill_date | formatDate}}</div>
      </template>
    </template>

    <div class="flex flex-wrap items-center justify-center mb-base mt-base">
      <ul class="demo-alignment">
        <li class="m-2">
          <vs-radio v-model="selected" vs-value="yearly">Pay Annually</vs-radio>
        </li>
        <li class="m-2">
          <vs-radio v-model="selected" vs-value="monthly">Pay Monthly</vs-radio>
        </li>
      </ul>
    </div>

    <div class="vs-row match-height" style="display: flex" v-show="selected === 'monthly'">
      <div class="vx-col vs-xs-12 vs-sm-12 lg:w-1/3 p-2" v-if="false">
        <vx-card fixedHeight>
          <h3 class="text-center">Free</h3>
          <h1 class="text-center text-secondary m-3">$0</h1>
          <vs-list>
            <vs-list-item title="Dashboard Summary Stats" />
            <vs-list-item title="Basic Workout Summary" />
            <vs-list-item title="Stats Report" />
          </vs-list>
          <div slot="footer">
            <template v-if="this.authenticatedUser.subscription_plan_id == 1 || this.authenticatedUser.subscription_plan_id == ''">
              <vs-button button="submit" color="dark" class="w-full" block disabled>Current plan</vs-button>
            </template>
            <template v-else>
              <vs-button button="submit" color="dark" class="w-full" block @click="cancelSubscription">Choose</vs-button>
            </template>
          </div>
        </vx-card>
      </div>

      <div class="vx-col vs-xs-12 vs-sm-12 lg:w-1/3 p-2">
        <vx-card fixedHeight>
          <h3 class="text-center">Lite</h3>
          <h1 class="text-center text-secondary m-3">$2.99</h1>
          <vs-list>
            <vs-list-item title="Dashboard Summary Stats" />
            <vs-list-item title="Basic Workout Summary" />
            <vs-list-item title="Stats Report" />
            <vs-list-item title="View data from the last 365 days" />
          </vs-list>
          <div slot="footer">
            <template v-if="this.authenticatedUser.subscription_plan_id == 6 && this.authenticatedUser.subscription_status == 2">
              <vs-button button="submit" class="w-full" block @click="updateSubscription">Update payment method</vs-button>
            </template>
            <template v-else>
              <vs-button button="submit" color="dark" class="w-full" block @click="openCheckout(784543)">Choose</vs-button>
            </template>
          </div>
        </vx-card>
      </div>

      <div class="vx-col vs-xs-12 vs-sm-12 lg:w-1/3 p-2" v-if="false">
        <vx-card fixedHeight>
          <h3 class="text-center">Performance</h3>
          <h1 class="text-center text-info m-3">$2.99</h1>
          <vs-list>
            <vs-list-item title="Dashboard Summary Stats" />
            <vs-list-item title="Basic Workout Summary" />
            <vs-list-item title="Stats Report" />
            <vs-list-item title="Comparison Report" class="text-info"/>
            <vs-list-item title="Historic Workout Comparison" class="text-info"/>
            <vs-list-item title="Performance Report" class="text-info"/>
          </vs-list>
          <div slot="footer">
            <template v-if="this.authenticatedUser.subscription_plan_id == 2 && this.authenticatedUser.subscription_status == 2">
              <vs-button button="submit" class="w-full" block @click="updateSubscription">Update payment method</vs-button>
            </template>
            <template v-else>
              <vs-button button="submit" class="bg-info w-full" block @click="openCheckout(579176)">Choose</vs-button>
            </template>
          </div>
        </vx-card>
      </div>

      <div class="vx-col vs-xs-12 vs-sm-12 lg:w-1/3 p-2">
        <vx-card fixedHeight>
          <h3 class="text-center">Standard</h3>
          <h1 class="text-center text-info m-3">$4.99</h1>
          <vs-list>
            <vs-list-item title="Dashboard Summary Stats" />
            <vs-list-item title="Basic Workout Summary" />
            <vs-list-item title="Stats Report" />
            <vs-list-item title="View entire history" class="text-info" />
            <vs-list-item title="Comparison Report" class="text-info"/>
            <vs-list-item title="Historic Workout Comparison" class="text-info"/>
            <vs-list-item title="Performance Report" class="text-info"/>
            <vs-list-item title="Progress Report" class="text-info"/>
            <vs-list-item title="Trend Report" class="text-info"/>
            <vs-list-item title="Workout Trend Comparison" class="text-info"/>
            <vs-list-item title="Power Report" class="text-info"/>
          </vs-list>
          <div slot="footer">
            <template v-if="this.authenticatedUser.subscription_plan_id == 8 && this.authenticatedUser.subscription_status == 2">
              <vs-button button="submit" class="w-full" block @click="updateSubscription">Update payment method</vs-button>
            </template>
            <template v-else>
              <vs-button button="submit" block class="bg-info w-full" @click="openCheckout(784545)">Choose</vs-button>
            </template>
          </div>
        </vx-card>
      </div>

      <div class="vx-col vs-xs-12 vs-sm-12 lg:w-1/3 p-2" v-if="false">
        <vx-card fixedHeight>
          <h3 class="text-center">Premium</h3>
          <h1 class="text-center text-primary m-3">$4.99</h1>
          <vs-list>
            <vs-list-item title="Dashboard Summary Stats" />
            <vs-list-item title="Basic Workout Summary" />
            <vs-list-item title="Stats Report" />
            <vs-list-item title="Comparison Report" class="text-info"/>
            <vs-list-item title="Historic Workout Comparison" class="text-info"/>
            <vs-list-item title="Performance Report" class="text-info"/>
            <vs-list-item title="Progress Report" class="text-primary"/>
            <vs-list-item title="Trend Report" class="text-primary"/>
            <vs-list-item title="Workout Trend Comparison" class="text-primary"/>
            <vs-list-item title="Power Report" class="text-primary"/>
          </vs-list>
          <div slot="footer">
            <template v-if="this.authenticatedUser.subscription_plan_id == 3 && this.authenticatedUser.subscription_status == 2">
              <vs-button button="submit" class="w-full" block @click="updateSubscription">Update payment method</vs-button>
            </template>
            <template v-else>
              <vs-button button="submit" block class="w-full" @click="openCheckout(581403)">Choose</vs-button>
            </template>
          </div>
        </vx-card>
      </div>

      <div class="vx-col vs-xs-12 vs-sm-12 lg:w-1/3 p-2">
        <vx-card fixedHeight>
          <h3 class="text-center">Plus</h3>
          <h1 class="text-center text-primary m-3">$5.99</h1>
          <vs-list>
            <vs-list-item title="Dashboard Summary Stats" />
            <vs-list-item title="Basic Workout Summary" />
            <vs-list-item title="Stats Report" />
            <vs-list-item title="View entire history" class="text-info" />
            <vs-list-item title="Comparison Report" class="text-info"/>
            <vs-list-item title="Historic Workout Comparison" class="text-info"/>
            <vs-list-item title="Performance Report" class="text-info"/>
            <vs-list-item title="Progress Report" class="text-info"/>
            <vs-list-item title="Trend Report" class="text-info"/>
            <vs-list-item title="Workout Trend Comparison" class="text-info"/>
            <vs-list-item title="Power Report" class="text-info"/>
            <vs-list-item title="Calendar Planning" class="text-primary"/>
          </vs-list>
          <div slot="footer">
            <template v-if="this.authenticatedUser.subscription_plan_id == 10 && this.authenticatedUser.subscription_status == 2">
              <vs-button button="submit" class="w-full" block @click="updateSubscription">Update payment method</vs-button>
            </template>
            <template v-else>
              <vs-button button="submit" block class="w-full" @click="openCheckout(786222)">Choose</vs-button>
            </template>
          </div>
        </vx-card>
      </div>

    </div>

    <div class="vs-row match-height" style="display: flex" v-show="selected === 'yearly'">
      <div class="vx-col vs-xs-12 vs-sm-12 lg:w-1/3 p-2" v-if="false">
        <vx-card fixedHeight>
          <h3 class="text-center">Free</h3>
          <h1 class="text-center text-secondary m-3">$0</h1>
          <vs-list>
            <vs-list-item title="Dashboard Summary Stats" />
            <vs-list-item title="Basic Workout Summary" />
            <vs-list-item title="Stats Report" />
          </vs-list>
          <div slot="footer">
            <template v-if="this.authenticatedUser.subscription_plan_id == 1 || this.authenticatedUser.subscription_plan_id == ''">
              <vs-button button="submit" color="dark" class="w-full" block disabled>Current plan</vs-button>
            </template>
            <template v-else>
              <vs-button button="submit" color="dark" class="w-full" block @click="cancelSubscription">Choose</vs-button>
            </template>
          </div>
        </vx-card>
      </div>

      <div class="vx-col vs-xs-12 vs-sm-12 lg:w-1/3 p-2">
        <vx-card fixedHeight>
          <h3 class="text-center">Lite</h3>
          <h1 class="text-center text-secondary m-3">$24.99</h1>
          <vs-list>
            <vs-list-item title="Dashboard Summary Stats" />
            <vs-list-item title="Basic Workout Summary" />
            <vs-list-item title="Stats Report" />
            <vs-list-item title="View data from the last 365 days" />
          </vs-list>
          <div slot="footer">
            <template v-if="this.authenticatedUser.subscription_plan_id == 7 && this.authenticatedUser.subscription_status == 2">
              <vs-button button="submit" class="w-full" block @click="updateSubscription">Update payment method</vs-button>
            </template>
            <template v-else>
              <vs-button button="submit" color="dark" class="w-full" block @click="openCheckout(784542)">Choose</vs-button>
            </template>
          </div>
        </vx-card>
      </div>

      <div class="vx-col vs-xs-12 vs-sm-12 lg:w-1/3 p-2" v-if="false">
        <vx-card fixedHeight>
          <h3 class="text-center">Performance</h3>
          <h1 class="text-center text-info m-3">$19.99</h1>
          <vs-list>
            <vs-list-item title="Dashboard Summary Stats" />
            <vs-list-item title="Basic Workout Summary" />
            <vs-list-item title="Stats Report" />
            <vs-list-item title="Comparison Report" class="text-info"/>
            <vs-list-item title="Historic Workout Comparison" class="text-info"/>
            <vs-list-item title="Performance Report" class="text-info"/>
          </vs-list>
          <div slot="footer">
            <template v-if="this.authenticatedUser.subscription_plan_id == 4 && this.authenticatedUser.subscription_status == 2">
              <vs-button button="submit" class="w-full" block @click="updateSubscription">Update payment method</vs-button>
            </template>
            <template v-else>
              <vs-button button="submit" class="bg-info w-full" block @click="openCheckout(579206)">Choose</vs-button>
            </template>
          </div>
        </vx-card>
      </div>

      <div class="vx-col vs-xs-12 vs-sm-12 lg:w-1/3 p-2" v-if="false">
        <vx-card fixedHeight>
          <h3 class="text-center">Premium</h3>
          <h1 class="text-center text-primary m-3">$34.99</h1>
          <vs-list>
            <vs-list-item title="Dashboard Summary Stats" />
            <vs-list-item title="Basic Workout Summary" />
            <vs-list-item title="Stats Report" />
            <vs-list-item title="Comparison Report" class="text-info"/>
            <vs-list-item title="Historic Workout Comparison" class="text-info"/>
            <vs-list-item title="Performance Report" class="text-info"/>
            <vs-list-item title="Progress Report" class="text-primary"/>
            <vs-list-item title="Trend Report" class="text-primary"/>
            <vs-list-item title="Workout Trend Comparison" class="text-primary"/>
            <vs-list-item title="Power Report" class="text-primary"/>
          </vs-list>
          <div slot="footer">
            <template v-if="this.authenticatedUser.subscription_plan_id == 5 && this.authenticatedUser.subscription_status == 2">
              <vs-button button="submit" class="w-full" block @click="updateSubscription">Update payment method</vs-button>
            </template>
            <template v-else>
              <vs-button button="submit" block class="w-full" @click="openCheckout(579207)">Choose</vs-button>
            </template>
          </div>
        </vx-card>
      </div>

      <div class="vx-col vs-xs-12 vs-sm-12 lg:w-1/3 p-2">
        <vx-card fixedHeight>
          <h3 class="text-center">Standard</h3>
          <h1 class="text-center text-info m-3">$39.99</h1>
          <vs-list>
            <vs-list-item title="Dashboard Summary Stats" />
            <vs-list-item title="Basic Workout Summary" />
            <vs-list-item title="Stats Report" />
            <vs-list-item title="View entire history" class="text-info" />
            <vs-list-item title="Comparison Report" class="text-info"/>
            <vs-list-item title="Historic Workout Comparison" class="text-info"/>
            <vs-list-item title="Performance Report" class="text-info"/>
            <vs-list-item title="Progress Report" class="text-info"/>
            <vs-list-item title="Trend Report" class="text-info"/>
            <vs-list-item title="Workout Trend Comparison" class="text-info"/>
            <vs-list-item title="Power Report" class="text-info"/>
          </vs-list>
          <div slot="footer">
            <template v-if="this.authenticatedUser.subscription_plan_id == 9 && this.authenticatedUser.subscription_status == 2">
              <vs-button button="submit" class="w-full" block @click="updateSubscription">Update payment method</vs-button>
            </template>
            <template v-else>
              <vs-button button="submit" block class="bg-info w-full" @click="openCheckout(784544)">Choose</vs-button>
            </template>
          </div>
        </vx-card>
      </div>

      <div class="vx-col vs-xs-12 vs-sm-12 lg:w-1/3 p-2">
        <vx-card fixedHeight>
          <h3 class="text-center">Plus</h3>
          <h1 class="text-center text-primary m-3">$49.99</h1>
          <vs-list>
            <vs-list-item title="Dashboard Summary Stats" />
            <vs-list-item title="Basic Workout Summary" />
            <vs-list-item title="Stats Report" />
            <vs-list-item title="View entire history" class="text-info" />
            <vs-list-item title="Comparison Report" class="text-info"/>
            <vs-list-item title="Historic Workout Comparison" class="text-info"/>
            <vs-list-item title="Performance Report" class="text-info"/>
            <vs-list-item title="Progress Report" class="text-info"/>
            <vs-list-item title="Trend Report" class="text-info"/>
            <vs-list-item title="Workout Trend Comparison" class="text-info"/>
            <vs-list-item title="Power Report" class="text-info"/>
            <vs-list-item title="Calendar Planning" class="text-primary"/>
          </vs-list>
          <div slot="footer">
            <template v-if="this.authenticatedUser.subscription_plan_id == 11 && this.authenticatedUser.subscription_status == 2">
              <vs-button button="submit" class="w-full" block @click="updateSubscription">Update payment method</vs-button>
            </template>
            <template v-else>
              <vs-button button="submit" block class="w-full" @click="openCheckout(786225)">Choose</vs-button>
            </template>
          </div>
        </vx-card>
      </div>

    </div>
    To cancel your subscription click <a class="cursor-pointer" @click="cancelSubscription">here</a>
  </vx-card>
</template>

<script>
  import axios from 'axios'
  import {mapGetters} from 'vuex'
  import Numbers from '@/components/mixins/Numbers'

  export default {
    mixins: [Numbers],

    components: {},

    computed: mapGetters({
      authenticatedUser: 'auth/getAuthenticatedUser',
      subscriptionExpired : 'auth/getAuthenticatedUserHasSubscriptionExpired'
    }),

    mounted() {
      Paddle.Setup({vendor: 105993});
      if (this.monthlyPlans.indexOf(this.authenticatedUser.subscription_plan_id) >= 0) {
        this.selected = 'monthly';
      }
      this.$store.dispatch('auth/fetchUser');
    },
    data: () => ({
      selected: 'yearly',
      options: [
        {text: 'Pay Annually', value: 'yearly'},
        {text: 'Pay Monthly', value: 'monthly'},
      ],
      monthlyPlans: [2, 3],
      key: 1
    }),

    methods: {
      openCheckout(productId) {
        var self = this;
        if (this.authenticatedUser.subscription_plan_id == '' || this.authenticatedUser.subscription_status != 2) {
          Paddle.Checkout.open({
            product: productId,
            email: this.authenticatedUser.email,
            passthrough: this.authenticatedUser.id,
            successCallback: function (data) {
              self.$vs.notify({
                text: "Thank you!",
                color:'success',
                position:'top-right',
                time:5000,
              });
              self.$parent.key ++;
            },
            closeCallback: function (data) {
              //debugger;
              // console.log(data);
              // alert('Failed to checkout');
            }
          });
        } else {
          axios.post('users/me/subscription/move', {'id': productId}).then((data) => {
            self.$vs.notify({
              text: data.message,
              color:'success',
              position:'top-right',
              time:5000,
            });
            self.$parent.key ++;
          }).catch(error => {
            self.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
        }
      },

      updateSubscription() {
        var self = this;
        Paddle.Checkout.open({
          override: this.authenticatedUser.subscription_update_url,
          successCallback: function (data) {
            self.$vs.notify({
              text:  "Thank you!",
              color:'success',
              position:'top-right',
              time:5000,
            });

          },
          closeCallback: function (data) {
            // console.log(data);
            // alert('Failed to update');
            // this.key++;
          }
        });
      },

      cancelSubscription() {
        var self = this;
        Paddle.Checkout.open({
          override: this.authenticatedUser.subscription_cancel_url,
          successCallback: function (data) {
            self.$vs.notify({
              text:  "Thank you!",
              color:'success',
              position:'top-right',
              time:5000,
            });

            self.$parent.key ++;
          },
          closeCallback: function (data) {
            // console.log(data);
            // alert('Failed to cancel');
            // this.key++;
          }
        });
      }
    }
  }
</script>
