<template>
  <vx-card title="Badges">
    <!-- SLOT = ACTIONS -->
    <template slot="actions" v-if="false">
      <change-time-duration-dropdown />
    </template>

    <div class="vs-row" v-if="loaded" style="display: flex">
      <template v-for="badge in badgesData">
        <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2">
          <template v-if="badge.user_badge">
            <img :src="badge.image_url_256" class="responsive rounded-lg" v-bind:alt="badge.name" v-bind:title="badge.description">
          </template>
          <template v-else>
            <img :src="badge.image_url_256" class="responsive rounded-lg black-white" style="opacity: 30%" v-bind:alt="badge.name" v-bind:title="badge.description">
          </template>
          <div class="truncate">
            <template v-if="badge.count > 0">
              <span class="font-bold">x {{ badge.count }}</span>
            </template>
          </div>
        </div>
      </template>
    </div>
<!--    <loader v-else/>-->
  </vx-card>
</template>

<script>
    import axios from 'axios';
    import Loader from "../global/Loader";
    import ChangeTimeDurationDropdown from '../../components/ChangeTimeDurationDropdown.vue'

    export default {
        name: 'badges-widget',

        components: {
          Loader,
          ChangeTimeDurationDropdown
        },
        data: () => {
            return {
                loaded: false,
                badgesData: '',
            }
        },
        methods: {
            getResults() {
                this.loaded = false;

                axios.get('/users/me/badges?per_page=100&include=user_badge').then((data) => {
                    this.badgesData = data.data.badges.data;
                    this.loaded = true;
                }).catch(error => {
                  this.$vs.notify({
                    text: error.response.data.message,
                    color:'danger',
                    position:'top-right',
                    time:5000,
                  });
                });
            }
        },
        created() {
            this.getResults();
        }
    }
</script>
