<template>
    <ag-group-workouts-list :items="items" />
</template>


<script>
    import AgGroupWorkoutsList from "./AgGroupWorkoutsList";
    export default {
        name: 'group-workouts-list',

        components: {AgGroupWorkoutsList},
        props: {
            items: {
                type: [Array, Object],
                default: () => []
            }
        },
        data: () => {
            return {

            }
        },
      computed: {

      },
      methods: {
      },
      created() {
      }
    }
</script>
