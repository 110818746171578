<template>
  <vx-card title="Most Frequent Workouts">
    <!-- SLOT = ACTIONS -->
    <template slot="actions" v-if="false">
      <change-time-duration-dropdown />
    </template>

    <div class="vs-row" v-if="loaded" style="display: flex">
      <div class="vx-col vs-xs-6 vs-sm-4 lg:w-1/6 text-center p-2" v-for="workout in workouts">
        <div class="truncate">
          <h2 class="mb-1 font-bold">{{ workout.count }} X </h2>
          <span>{{ workout.name }}</span>
        </div>
      </div>

    </div>
<!--    <loader v-else/>-->
  </vx-card>
</template>

<script>
    import axios from 'axios';
    import Loader from "../global/Loader";
    import ChangeTimeDurationDropdown from '../../components/ChangeTimeDurationDropdown.vue'
    import moment from "moment";
    import $ from "jquery";

    export default {
        name: 'public-most-frequent-2021-workouts',

        components: {
          Loader,
          ChangeTimeDurationDropdown
        },
        data: () => {
            return {
                loaded: false,
                workouts: null,
            }
        },
        methods: {
            getResults() {
                this.loaded = false;

                let params = {
                  date_from: '2021-01-01',
                  date_to: '2021-12-31',
                };

                axios.get('/reports/'+ this.$route.params.id + '/public-grouped-results?limit=6&' + $.param({filters: params})).then((data) => {
                    this.workouts = data.data.data;
                    this.loaded = true;
                }).catch(error => {
                  this.$vs.notify({
                    text: error.response.data.message,
                    color:'danger',
                    position:'top-right',
                    time:5000,
                  });
                });
            }
        },
        created() {
            this.getResults();
        }
    }
</script>
