<template>
  <div>
    -
  </div>
</template>

<script>
  export default {
    async beforeMount() {
      await this.$store.dispatch('auth/logout');
      this.$router.push({name: 'web.auth.login'});
    }
  }
</script>
