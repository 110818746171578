<template>
  <vx-card title="ETS Trend">
    <template v-if="!this.authenticatedUserHasPermissionToSee(this.allowedPlans)">
      <upgrade-message-only title="To see ETS trend upgrade to Plus Plan"
                       border-variant="none"></upgrade-message-only>
    </template>
    <template v-else>
      <highcharts :options="chartOptions" style="height: 250px;"></highcharts>
    </template>
  </vx-card>
</template>
<script>
  import axios from 'axios'
  import Highcharts from 'highcharts'
  import $ from 'jquery'
  import moment from 'moment'
  import {formatTime, formatTimeAsPace} from '@/shared/utils'
  import {mapGetters} from "vuex";
  import UpgradeMessageOnly from "../global/UpgradeMessageOnly";
  import {formatDate} from "../../shared/utils";

  export default {
    name: 'ets-trend-chart',

    components: {
      UpgradeMessageOnly
    },
    props: {
      result: {
        type: [Array, Object],
        default: () => []
      }
    },
    computed: mapGetters({
      authenticatedUserHasPermissionToSee: 'auth/getAuthenticatedUserHasPermissionToSee'
    }),
    data() {
      return {
        loaded: false,
        items: [],
        allowedPlans: ['',1,2,3,4,5,6,7,8,9,10,11],
        chartOptions: {
          chart: {
            type: 'scatter',
            zoomType: 'xy',
            backgroundColor: 'rgba(0,0,0,0)',
            events: {
              load: function() {
                /* On mobile, remove labels */
                if ($(window).width() < 900) {
                  this.yAxis[0].update({
                    title: false,
                  });
                  this.tooltip.update({
                    style: {
                      width: Math.min(300, 2*$(window).width()/3)
                    }
                  });
                }
              }
            }
          },
          title: false,
          xAxis: {
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true,
            labels: {
              formatter() {
                return Highcharts.dateFormat('%e-%b-%y', this.value)
              }
            },
            type: 'datetime',
            dateTimeLabelFormats: { // don't display the dummy year
              month: '%e. %b',
              year: '%b'
            },
          },
          yAxis: [{
            title: {
              text: 'ETS'
            },
            plotLines: [],
          }],
          legend: false,
          tooltip: {
            shared: true,
            formatter: function () {
              let s = '<b>' + this.series.name + '</b><br>';
              s += '<br/>' + Highcharts.dateFormat('%e-%b-%y', this.point.date);
              s += '<br/> ETS : ' + this.point.y;
              s += '<br/> Stroke rate : ' + this.point.stroke_rate + ' spm';
              s += '<br/> Pace : ' + formatTimeAsPace(this.point.pace);
              return s;
            },
            useHTML: true
          },
          plotOptions: {
            scatter: {
              marker: {
                radius: 5,
                states: {
                  hover: {
                    enabled: true,
                    lineColor: 'rgb(100,100,100)'
                  }
                }
              },
              states: {
                hover: {
                  marker: {
                    enabled: false
                  }
                }
              },
            }
          },
          series: [],
          credits: {
            enabled: false
          }
        }
      }
    },

    methods: {
      getHistoryResultsForTrendChart() {
        axios.get('users/me/results/' + this.result.id + '/simillar?per_page=1000&order_by=date&order_direction=asc').then((data) => {
          this.loaded = true;
          this.items = data.data.results.data;

          let ets = [];
          var i = 0;
          let categories = [];
          for (i; i < this.items.length; i++) {
              if(this.items[i].ets_score > 0)
              {
                categories.push(formatDate(this.items[i].date));

                let y = moment(this.items[i].date, 'YYYY-MM-DD HH:mm:ss').format('YYYY');
                let m = moment(this.items[i].date, 'YYYY-MM-DD HH:mm:ss').format('M');
                let d = moment(this.items[i].date, 'YYYY-MM-DD HH:mm:ss').format('D');
                let itemDate = Date.UTC(y, m - 1, d);

                ets.push({x: itemDate, y: parseFloat(this.items[i].ets_score), name: this.items[i].name, date: itemDate, stroke_rate: this.items[i].stroke_rate, pace: parseFloat(this.items[i].pace)});
              }
          }
          this.chartOptions.series.push({
            name: 'ETS',
            data: ets,
          });
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      }
    },
    mounted() {
      if (this.authenticatedUserHasPermissionToSee(this.allowedPlans)) {
        this.getHistoryResultsForTrendChart();
      }
    }
  };
</script>
