<template>
  <div>
    <template v-if="items.length">
      <ag-grid-vue class="ag-theme-material w-100 ag-grid-table"
                   :columnDefs="columnDef"
                   :defaultColDef="defaultColDef"
                   :rowData="items"
                   @first-data-rendered="onFirstDataRendered">
      </ag-grid-vue>
    </template>
    <template v-else>
      There are no workouts
    </template>
  </div>
</template>

<script>
  import { AgGridVue } from 'ag-grid-vue'
  import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

  import CellRendererRowNumber from './../global/cell-renderer/CellRendererRowNumber.vue'
  import CellRendererDistance from './../global/cell-renderer/CellRendererDistance.vue'
  import CellRendererTime from './../global/cell-renderer/CellRendererTime.vue'
  import CellRendererPace from './../global/cell-renderer/CellRendererPace.vue'
  import CellRendererGroupUserImage from './cell-renderer/CellRendererGroupUserImage.vue'
  import CellRendererGroupUserName from './cell-renderer/CellRendererGroupUserName.vue'

  export default {
    name: 'ag-group-leaderboard-list',

    components: {
      AgGridVue,
      CellRendererGroupUserImage,
      CellRendererGroupUserName,
      CellRendererRowNumber,
      CellRendererDistance,
      CellRendererTime,
      CellRendererPace
    },
    props: {
      items: {
        type: [Array, Object],
        default: () => []
      },
      sortBy: {
        type: String,
        default: 'number_of_workouts'
      },
      sortDirection: {
        type: String,
        default: 'asc'
      }
    },
    data: () => {
      return {
        defaultColDef: { resizable: true },
        gridColumnApi: null,
      }
    },
    computed: {
      columnDef() {
        if(this.$store.state.windowWidth < 992) {
          return [
            {field: 'name', headerName: 'Name', sortable: true, cellRendererFramework: 'CellRendererGroupUserName'},
          ]
        }
        return [
          {field: 'rank', headerName: 'Rank', cellRendererFramework: 'CellRendererRowNumber'},
          {field: 'image', headerName: '', cellRendererFramework: 'CellRendererGroupUserImage'},
          {field: 'name', headerName: 'Name', cellRendererFramework: 'CellRendererGroupUserName', suppressSizeToFit: true},
          {field: 'number_of_workouts', headerName: 'Number of Workouts', sort: this.sortBy == 'number_of_workouts' ? this.sortDirection : null},
          {field: 'total_distance', headerName: 'Total Distance', cellRendererFramework: 'CellRendererDistance', sort: this.sortBy == 'total_distance' ? this.sortDirection : null},
          {field: 'total_time', headerName: 'Total Time', cellRendererFramework: 'CellRendererTime', sort: this.sortBy == 'total_time' ? this.sortDirection : null},
          {field: 'average_pace', headerName: 'Average Pace', cellRendererFramework: 'CellRendererPace'},
          {field: 'number_of_strokes', headerName: 'Number of Strokes', sort: this.sortBy == 'number_of_strokes' ? this.sortDirection : null},
        ];
      }

    },
    methods: {
      onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
      }
    },
    created() {
    }
  }
</script>
