<template>
  <div style="line-height: 23px; !important; margin-top: 12px;">
    <strong title="Total Distance">{{ totalDistance | formatNumber }}m</strong> (&Sigma;)<br/>
    <strong title="Average Distance">{{ avgDistance | formatNumber }}m</strong> (avg)
  </div>
</template>

<script>
export default {
  name: 'CellRendererFooterTotalDistance',
  computed: {
    totalDistance() {
      return _(this.$parent.$parent.items)
        .groupBy('name')
        .map((objs, key) => {
          return {
            'name': key,
            'distance': _.sumBy(objs, 'distance')
          }
        })
        .value()[0].distance;
    },
    avgDistance() {
      let average = _.meanBy(this.$parent.$parent.items, 'distance');
      return average;
    },
  }
}
</script>
