import { render, staticRenderFns } from "./PublicSummaryWidget2023PerType.vue?vue&type=template&id=fa3e7a94&"
import script from "./PublicSummaryWidget2023PerType.vue?vue&type=script&lang=js&"
export * from "./PublicSummaryWidget2023PerType.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports