<template>
  <vx-card v-if="loaded">
    <portal to="title-bar-actions">
      <vs-button radius icon="icon-trash-2" color="danger" icon-pack="feather" title="Delete" @click.prevent="badgeDeleteDialogShow()"/>
    </portal>
    <form @submit.prevent="badgeFormSubmit">
      <!-- Img Row -->
      <vue-image-input :placeholder="'badges_image-128.jpg'" :folder="'badges_image'"
                       v-model="badgeForm.image"></vue-image-input>

      <!-- Info -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <label class="text-sm opacity-75">Group</label>
          <v-select :options="groups" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="badgeForm.group" placeholder="Select Group"
                    class="md:mb-0"/>
        </div>
      </div>

      <vs-input
        label="Name"
        name="name"
        v-model="badgeForm.name"
        class="w-full mt-2"/>
      <span class="text-danger text-sm" v-if="formControlHasError(badgeForm, 'name')">{{ formControlGetError(badgeForm, 'name') }}</span>

      <vs-textarea
        label="Description"
        name="description"
        v-model="badgeForm.description"
        class="w-full mt-2" height="200px"/>
      <span class="text-danger text-sm" v-if="formControlHasError(badgeForm, 'description')">{{ formControlGetError(badgeForm, 'description') }}</span>

      <div class="vx-row">
        <div class="vx-col w-full">
          <label class="text-sm opacity-75">Type</label>
          <v-select :options="types" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="badgeForm.type_object" placeholder="Select Type"
                    class="md:mb-0"/>
        </div>
      </div>

      <vs-input
        label="Value"
        name="value"
        v-model="badgeForm.value"
        class="w-full mt-2"/>
      <span class="text-danger text-sm" v-if="formControlHasError(badgeForm, 'value')">{{ formControlGetError(badgeForm, 'value') }}</span>

      <vs-checkbox v-model="badgeForm.public" class="mt-4">Public</vs-checkbox>
      <span class="text-danger text-sm" v-if="formControlHasError(badgeForm, 'public')">{{ formControlGetError(badgeForm, 'public') }}</span>

      <!-- Save & Reset Button -->
      <div class="flex-wrap items-center justify-end mt-2">
        <vs-button class="ml-auto mt-2" button="submit">Save</vs-button>
        <vs-button type="border" class="ml-4 mb-2" color="warning" @click.prevent="$router.go(-1)">Cancel</vs-button>
      </div>
    </form>
  </vx-card>
</template>

<script>
  import axios from 'axios'
  import {mapGetters} from 'vuex'
  import Form from 'vform'
  import FormErrors from '@/components/mixins/FormErrors'
  import Numbers from '@/components/mixins/Numbers'
  import moment from 'moment'
  import {formatDateTime} from "../../../shared/utils";
  import {BADGE_GROUPS, BADGE_VALUE_TYPES} from "@/shared/constants";
  import vSelect from 'vue-select'

  export default {
    components: {
      vSelect
    },
    // Set meta tags
    head: {
      title: {
        inner: 'Edit badge'
      }
    },

    mixins: [FormErrors, Numbers],

    data: () => ({
      badge: null,
      loaded: false,
      badgeForm: new Form({
        group: '',
        group_id: '',
        name: '',
        description: '',
        image: '',
        type: '',
        type_object: '',
        value: '',
        public: ''
      }),
      groups: BADGE_GROUPS,
      types: BADGE_VALUE_TYPES,
    }),

    computed: mapGetters({
      authenticatedUser: 'auth/getAuthenticatedUser',
    }),

    methods: {
      badgeFormSubmit() {
        this.formResetErrors(this.badgeForm);

        this.badgeForm.group_id = this.badgeForm.group != '' ? this.badgeForm.group.value : '';
        this.badgeForm.type = this.badgeForm.type_object != '' ? this.badgeForm.type_object.value : '';

        this.badgeForm.post('admin/badges/' + this.badge.id + '/update')
          .then(({data}) => {
            this.$vs.notify(
              {
                text: data.message,
                color:'success',
                position:'top-right',
                time:5000,
              }
            );

            this.$router.push({ name: 'admin.badges' });
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },

      getBadge(badgeId) {
        this.loaded = false;

        axios.get('admin/badges/' + badgeId).then((data) => {
          this.badge = data.data.badge;

          if (this.authenticatedUser.role == 1) {
            this.badgeForm.name = this.badge.name;
            this.badgeForm.group = this.groups.find(
              group => group.value === this.badge.group_id
            );
            this.badgeForm.type_object = this.types.find(
              type => type.value == this.badge.type
            );
            this.badgeForm.image = this.badge.image;
            this.badgeForm.description = this.badge.description;
            this.badgeForm.value = this.badge.value;
            this.badgeForm.public = this.badge.public;
            this.loaded = true;
          } else {
            this.$vs.notify({
              text: 'You do not have permission to edit this badge.',
              color:'danger',
              position:'top-right',
              time:5000,
            });

            this.$router.go('-1');
          }
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },

      badgeDeleteDialogShow() {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: `Please Confirm`,
          text: 'Are you sure you want to delete this badge?',
          acceptText: 'Yes, delete it',
          accept: this.deleteBadge
        })
      },

      deleteBadge() {
        axios.post('admin/badges/'+ this.badge.id +'/destroy')
          .then(({data}) => {
            this.$vs.notify(
              {
                text: data.message,
                color:'success',
                position:'top-right',
                time:5000,
              }
            );

            this.$router.push({ name: 'admin.badges' });
          })
          .catch(error => {
            this.$vs.notify({
              text: error.response.data.message,
              color:'danger',
              position:'top-right',
              time:5000,
            });
          });
      },

    },
    created() {
      this.getBadge(this.$route.params.id);
    },
  }
</script>
