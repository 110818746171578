<template>
  <vx-card>
      <div class="h5 text-primary mb-1 mt-2">{{ title }}</div>
      <div class="text-muted text-uppercase font-weight-bold font-xs">{{ subtitle }}
        <router-link :to="{ name: 'account.billing.edit'}" title="Go to billing page">here</router-link>
      </div>
  </vx-card>
</template>

<script>
  export default {
    name: 'upgrade-message',
    props: {
      title: {
        type: String,
        default: 'You are using a free plan!'
      },
      subtitle: {
        type: String,
        default: 'To upgrade click'
      },
      borderVariant: {
        type: String,
        default: ''
      }
    },
  }
</script>
