<template>
  <div class="vx-card p-6">
    <template v-if="items.length">
      <ag-grid-vue class="ag-theme-material w-100 ag-grid-table"
                   :columnDefs="columnDef"
                   :defaultColDef="defaultColDef"
                   :rowData="items"
                   @first-data-rendered="onFirstDataRendered">
      </ag-grid-vue>
    </template>
    <template v-else>
      There are no workouts
    </template>
  </div>
</template>

<script>
  import { AgGridVue } from 'ag-grid-vue'
  import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

  import CellRendererWorkoutLink from '../global/cell-renderer/CellRendererWorkoutLink.vue'
  import CellRendererDate from '../global/cell-renderer/CellRendererDate.vue'
  import CellRendererDateAndName from '../global/cell-renderer/CellRendererDateAndName.vue'
  import CellRendererTime from '../global/cell-renderer/CellRendererTime.vue'
  import CellRendererPace from '../global/cell-renderer/CellRendererPace.vue'
  import CellRendererType from '../global/cell-renderer/CellRendererType.vue'
  import CellRendererWorkoutActions from '../global/cell-renderer/CellRendererWorkoutActions.vue'
  import CellRendererEtsScore from '../global/cell-renderer/CellRendererEtsScore.vue'
  import CellRendererFooterEtsScore from '../global/cell-renderer/CellRendererFooterEtsScore.vue'

  export default {
    name: 'ag-session-list-without-comments',

    components: {
      AgGridVue,
      CellRendererWorkoutLink,
      CellRendererDate,
      CellRendererDateAndName,
      CellRendererTime,
      CellRendererPace,
      CellRendererType,
      CellRendererWorkoutActions,
      CellRendererEtsScore,
      CellRendererFooterEtsScore
    },
    props: {
      items: {
        type: [Array, Object],
        default: () => []
      },
      footClone: {
        type: Boolean,
        default: false
      }
    },
    data: () => {
      return {
        defaultColDef: { resizable: true },
        rowHeight: 25,
      }
    },
    computed: {
      avgPace() {
        let average = _.meanBy(this.items, 'pace');
        return average;
      },
      avgDistance() {
        let average = _.meanBy(this.items, 'distance');
        return average;
      },
      avgTime() {
        let average = _.meanBy(this.items, 'time');
        return average;
      },
      totalDistance() {
        return _(this.items)
          .groupBy('name')
          .map((objs, key) => {
            return {
              'name': key,
              'distance': _.sumBy(objs, 'distance')
            }
          })
          .value()[0].distance;
      },
      totalTime() {
        return _(this.items)
          .groupBy('name')
          .map((objs, key) => {
            return {
              'name': key,
              'time': _.sumBy(objs, 'time')
            }
          })
          .value()[0].time;
      },
      columnDef() {
        if(this.$store.state.windowWidth < 992) {
          return [
            {field: 'name', headerName: 'Name', sortable: true, cellRendererFramework: 'CellRendererWorkoutLink', autoHeight: true, wrapText: true, minWidth: 100},
            {field: 'pace', headerName: 'Pace', sortable: true, cellRendererFramework: 'CellRendererPace', minWidth: 80, maxWidth: 80},
            {field: 'actions', headerName: '', sortable: false, cellRendererFramework: 'CellRendererWorkoutActions', minWidth: 90, maxWidth:90},
          ]
        }
        return [
          {field: 'date', headerName: 'Date', sortable: true, cellRendererFramework: 'CellRendererDate', minWidth: 100},
          {field: 'name', headerName: 'Name', sortable: true, cellRendererFramework: 'CellRendererWorkoutLink', suppressSizeToFit: true, minWidth: 100},
          {field: 'distance', sortable: true, minWidth: 100},
          {field: 'time', sortable: true, cellRendererFramework: 'CellRendererTime', minWidth: 80},
          {field: 'pace', sortable: true, cellRendererFramework: 'CellRendererPace', minWidth: 80},
          {field: 'type', sortable: true, cellRendererFramework: 'CellRendererType', minWidth: 100},
          //{field: 'workout_type', sortable: true, cellRendererFramework: 'CellRendererWorkoutType'},
          // {key: 'weight', sortable: true},
          //{key: 'comments', sortable: true},
          // {key: 'rank', sortable: true},
          {field: 'stroke_rate', sortable: true, headerName: 'S/m', minWidth: 60},
          {field: 'power', sortable: true, headerName: 'Watts', minWidth: 60},
          {field: 'ets_score', sortable: true, headerName: 'Training Load', cellRendererFramework: 'CellRendererEtsScore', minWidth: 70},
          {field: 'actions', sortable: false, cellRendererFramework: 'CellRendererWorkoutActions', minWidth: 90, maxWidth:90},
        ];
      }

    },
    methods: {
      onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
      }
    },
    created() {
    }
  }
</script>
