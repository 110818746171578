<template>
  <highcharts :options="chartOptions" style="height: 400px;"></highcharts>
</template>
<script>
import {each} from 'lodash';
import {formatTime, formatTimeAsPace} from '@/shared/utils'
import Highcharts from 'highcharts'
import $ from 'jquery'

export default {
  name: 'benchmark-chart-by-distance',

  components: {},
  props: {
    items: {
      type: [Array, Object],
      default: () => []
    }
  },
  data() {
    let distances = [];
    let tickPositions = [100, 250, 500, 1000, 2000, 5000, 6000, 21097, 42195];
    let line1 = [];
    let line2 = [];
    let pb = [];
    let line1Series = [];
    let line2Series = [];
    let pbSeries = [];

    //let sortedItems = _.orderBy(this.items, ['distance'],['asc']);

    _.each(this.items, item => {
      line1.push(item.line_1);
      line2.push(item.line_2);
      pb.push(item.line_pb);
    });

    line1 = _.orderBy(line1, ['distance'], ['asc']);
    line2 = _.orderBy(line2, ['distance'], ['asc']);
    pb = _.orderBy(pb, ['distance'], ['asc']);

    _.each(line1, item => {
      if(item != undefined)
      {
        line1Series.push({x: item.distance, y: parseFloat(item.power), name: item.name });
      }
    });

    _.each(line2, item => {
      if(item != undefined)
      {
        line2Series.push({x: item.distance, y: parseFloat(item.power), name: item.name });
      }
    });

    _.each(pb, item => {
      if(item != undefined)
      {
        pbSeries.push({x: item.distance, y: parseFloat(item.power), name: item.name });
      }
    });

    return {
      chartOptions: {
        chart: {
          zoomType: 'xy',
          backgroundColor: 'rgba(0,0,0,0)',
          events: {
            load: function () {
              /* On mobile, remove labels */
              if ($(window).width() < 900) {
                this.yAxis[0].update({
                  title: false,
                });
                this.tooltip.update({
                  style: {
                    width: Math.min(300, 2 * $(window).width() / 3)
                  }
                });
              }
            }
          }
        },
        title: false,
        xAxis: [{
          //tickPositions: tickPositions,
          labels: {
            rotation: -45
          }
        }],
        yAxis: [{ // Primary yAxis
          labels: {
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          title: {
            text: 'Power',
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          allowDecimals: false,

        }],
        plotOptions: {
          series: {
            marker: {
              enabled: true
            }
          }
        },
        series: [{
          name: 'Line 1',
          type: 'spline',
          data: line1Series,
          connectNulls: true,
          tooltip: {
            valueSuffix: 'W'
          },
        }, {
          name: 'Line 2',
          type: 'spline',
          data: line2Series,
          connectNulls: true,
          tooltip: {
            valueSuffix: 'W'
          },
        }, {
          name: 'PB',
          type: 'spline',
          data: pbSeries,
          connectNulls: true,
          tooltip: {
            valueSuffix: 'W'
          },
        }],
        credits: {
          enabled: false
        },
        tooltip: {
          shared: true,
          formatter: function () {
            let s = '<b>' + this.points[0].key + '</b>';
            $.each(this.points, function (i, point) {
              let value = point.y;
              let name = this.series.name;

              s += '<br/>' + '<span style="color:' + point.color + '">\u25CF</span> ' + this.series.name + ': ' + value + this.series.tooltipOptions.valueSuffix;
            });
            return s;
          },
          useHTML: true
        }
      }
    }
  },
};
</script>
