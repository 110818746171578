<template>
  <div>
    <portal to="title-bar-actions">
      <vs-button radius icon="icon-plus" icon-pack="feather" title="New Workout Plan" :to="{ name: 'workout-plans.create' }"/>
    </portal>
    <vs-row v-if="loaded">
      <template v-if="items.length">
        <template v-for="item in items">
          <div class="vx-col w-full lg:w-1/6 sm:w-1/2 mb-base mr-base">
            <single-workout-plan-card :item="item"></single-workout-plan-card>
          </div>
        </template>
      </template>
      <template v-else>
        <vx-card>
          There are no workout plans
        </vx-card>
      </template>
    </vs-row>
    <div v-else>
      <loader></loader>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import SingleWorkoutPlanCard from "../../../components/workout-plans/SingleWorkoutPlanCard";
  import Form from 'vform'
  import FormErrors from '@/components/mixins/FormErrors'
  import $ from 'jquery'

  export default {
    components: {SingleWorkoutPlanCard},
    mixins: [FormErrors],
    data: () => ({
      filterForm: new Form({
        search_name: '',
      }),
      loaded: false,
      items: [],
    }),
    watch: {
      filterForm: {
        handler(val) {
            this.filterFormSubmit();
        },
        deep: true
      },
    },
    methods: {
      async filterFormSubmit() {
        this.loaded = false;
        let params = this.filterForm;

        axios.get('users/me/workout-plans?per_page=1000&order_by=name&order_direction=asc'+ $.param({filters: params})).then((data) => {
          this.items = data.data.workout_plans.data;
          this.loaded = true;
        }).catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color:'danger',
            position:'top-right',
            time:5000,
          });
        });
      },
      resetFilter() {
        this.filterForm = new Form({
          search_name: '',
        });
        this.loaded = false;
      }
    },
    created() {
      this.filterForm = new Form({
        search_name: '',
      });
    }
  }

</script>
